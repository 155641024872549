import React, { useState } from 'react'
import { FetchDeliveryMethods } from '../../types/Orders'
import ShippingSettingsDropDown from '../DropDowns/ShippingSettingsDropDown'
import { t } from 'i18next'
import { checkedItems } from '../../types'

interface TableProps {
  checkedItems: checkedItems[],
  setCheckedItems: React.Dispatch<React.SetStateAction<checkedItems[]>>
  allDeliveryMethods?: FetchDeliveryMethods,
  setSelectedIdForDelete: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfPopup: React.Dispatch<React.SetStateAction<boolean>>
  setStartingIndex: React.Dispatch<React.SetStateAction<number>>
  setFailMsg: React.Dispatch<React.SetStateAction<string>>
  startingIndex: number
  direction: string
}

const ShippingSettingsTable = ({ checkedItems, setCheckedItems, direction, allDeliveryMethods, setSelectedIdForDelete, setDeleteConfPopup, setStartingIndex, startingIndex }: TableProps) => {

  const handleCheckAll = () => {
    if (allDeliveryMethods?.allDeliveryMethods.length !== checkedItems.length) {
      allDeliveryMethods !== undefined && setCheckedItems(allDeliveryMethods?.allDeliveryMethods.map(product => ({ id: product.id })))
    } else {
      setCheckedItems([])
    }
  };

  const handleCheck = (id: string) => {
    const isItemExist = checkedItems.some(item => item.id === id);

    if (isItemExist) {
      const newChckedItems = checkedItems.filter(item => item.id !== id);
      setCheckedItems(newChckedItems);
    } else {
      setCheckedItems([...checkedItems, { id: id }])
    }
  };

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
            <div className='relative flex'>
              <input type="checkbox" checked={allDeliveryMethods?.allDeliveryMethods.length === checkedItems.length} onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </th>

          <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 whitespace-nowrap">
            {t('shippingOption')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('price')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('deliveryTime')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('option')}
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {allDeliveryMethods?.allDeliveryMethods?.slice(startingIndex, startingIndex + 10).map(delivery => {
          return (
            <tr key={delivery.id}>
              <td className="px-4 py-4 text-sm font-medium">
                <div className='relative flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === delivery.id)} onChange={() => handleCheck(delivery.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>

              <td className="px-4 py-4 text-sm">
                <h2 className="text-gray-800">{delivery.name}</h2>
              </td>

              <td className="px-4 py-4 text-sm">
                <p className="text-gray-500">{delivery.price}</p>
              </td>

              <td className="px-4 py-4 text-sm">
                <p className="text-gray-500">{delivery.estimatedTime}</p>
              </td>

              <td className="px-4 py-4 text-sm">
                <ShippingSettingsDropDown direction={direction} id={delivery.id} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} />
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default ShippingSettingsTable