import React, { useEffect, useState } from 'react'
import SelectProductPopup from './SelectProductPopup'
import Tab1 from '../../components/Tabs/OrdersTabs/Tab1'
import Tab2 from '../../components/Tabs/OrdersTabs/Tab2'
import Tab3 from '../../components/Tabs/OrdersTabs/Tab3'
import Tab4 from '../../components/Tabs/OrdersTabs/Tab4'
import Tab5 from '../../components/Tabs/OrdersTabs/Tab5'
import { SelectedProductsProps } from '../../types/Products'
import SuccessAlert from '../../components/Alerts/SuccessAlert'
import FailAlert from '../../components/Alerts/FailAlert'
import AddAddressPopup from './AddAddressPopup'
import { ClientsProps, addressClientProps } from '../../types/Clients'
import { FetchDeliveryMethod, OrderPreviewDetails } from '../../types/Orders'
import { CreateDelivery } from '../../graphql'
import { useMutation } from '@apollo/client'
import useStore from '../../store/useStore'
import { FetchPaymentMethod } from '../../types'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget'
import PermissionsError from '../../components/Alerts/PermissionsError'

const AddOrders = ({ direction }: { direction: string }) => {
  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    create: false,
  })

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          create: true,
        })

        setInitialLoading(false)
        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "orders")

      let createPermission = filtered[0]?.permissions.includes("create")

      setPermissions({
        create: createPermission || false,
      })

      setInitialLoading(false)
    }
  }, [userData])

  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  // Tab 1
  const [selectedProductsList, setSelectedProductsList] = useState<SelectedProductsProps[]>([])
  const [addProductPopup, setAddProductPopup] = useState(false);

  // Tab 2
  const [AddressClient, setAddressClient] = useState<addressClientProps | null>(null);
  const [addAddressPopup, setAddAddressPopup] = useState(false);

  // Tab 3
  const [DeliveryMethod, setDeliveryMethod] = useState<FetchDeliveryMethod | null>(null);

  // Tab 2
  const [selectedClient, setSelectedClient] = useState<ClientsProps | null>(null)
  const [activeTab, setActiveTab] = useState(1);

  // Tab 4
  const [selectedPayment, setSelectedPayment] = useState<FetchPaymentMethod>();

  // Tab 5
  const [orderDetails, setOrderDetails] = useState<OrderPreviewDetails>({
    amount: [],
    shipping: ""
  })

  const nextPage = () => {
    // Tab 1
    if (selectedProductsList.length <= 0) {
      setFail(true);
      setFailMsg(t('haveToSelectProduct'))
      return
    }

    // Tab 2
    if (activeTab === 2) {
      if (AddressClient === null) {
        setFail(true);
        setFailMsg(t('haveToSelectClientAddress'));
        return
      }
    }

    // Tab 3
    if (activeTab === 3) {
      if (DeliveryMethod === null) {
        setFail(true);
        setFailMsg(t('haveToSelectDeliveryMethod'));
        return
      }
    }

    // Tab 4
    if (activeTab === 4) {
      if (selectedPayment === undefined) {
        setFail(true);
        setFailMsg(t('haveToSelectPaymentMethod'));
        return
      }
    }

    if (activeTab < 5) {
      setActiveTab(activeTab + 1)
    }

    // Tab 5
    setOrderDetails({
      amount: selectedProductsList.map(product => (
        product.price * product.quantity
      )),
      shipping: DeliveryMethod?.price
    })
  }

  const prevPage = () => {
    setActiveTab(activeTab - 1)
  }

  // Create
  const [createOrder, { error }] = useMutation(CreateDelivery, {
    update: (cache, { data: { createDelivery } }) => {
      cache.modify({
        fields: {
          allDeliveries(existedOrders = []) {
            return [...existedOrders, createDelivery];
          }
        }
      })
    }
  });

  const submit = async () => {

    await createOrder({
      variables: {
        content: {
          coupon: "",
          discount: 0,
          idAddressClient: AddressClient?.id,
          idClient: selectedClient?.id,
          idDeliveryMethod: DeliveryMethod?.id,
          idStore: storeData.id,
          note: "",
          idPaymentMethod: selectedPayment?.id,
          deliveryProduct: selectedProductsList.map(product => {
            return {
              idProduct: product.id,
              price: product.price,
              quantity: product.quantity,
            }
          })
        }
      }
    })

    if (!error) {
      setSuccessMsg(t('orderAddSuccess'));
      setSuccess(true);

      window.setTimeout(() => {
        navigate(`/orders`);
      }, 1000);
    }
  }

  return (
    <section className="my-4 mx-7">
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {/* Tab 1 */}
      {addProductPopup &&
        <SelectProductPopup setAddProductPopup={setAddProductPopup} selectedProductsList={selectedProductsList} setSelectedProductsList={setSelectedProductsList} />
      }

      {/* Tab 2 */}
      {addAddressPopup &&
        <AddAddressPopup setAddAddressPopup={setAddAddressPopup} selectedClient={selectedClient} setSelectedClient={setSelectedClient} />
      }

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('addOrder')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {
        initialLoading ?
          <LoadingWidget />
          :
          permissions.create === false ?
            <PermissionsError />
            :
            <div className='mt-3 bg-white rounded-md'>
              <div className="grid grid-cols-3 border-b border-gray-200 sm:grid-cols-5 whitespace-nowrap">
                <span className={`${activeTab === 1 && 'bg-primary text-white'} font-semibold text-gray-600 inline-flex justify-center items-center h-10 px-4 -mb-px text-sm text-center sm:text-base whitespace-nowrap focus:outline-none`}>
                  {t('products')}
                </span>

                <span className={`${activeTab === 2 && 'bg-primary text-white'} font-semibold text-gray-600 inline-flex justify-center items-center h-10 px-4 -mb-px text-sm text-center sm:text-base whitespace-nowrap focus:outline-none`}>
                  {t('account')}
                </span>

                <span className={`${activeTab === 3 && 'bg-primary text-white'} font-semibold text-gray-600 inline-flex justify-center items-center h-10 px-4 -mb-px text-sm text-center sm:text-base whitespace-nowrap focus:outline-none`}>
                  {t("delivery")}
                </span>

                <span className={`${activeTab === 4 && 'bg-primary text-white'} font-semibold text-gray-600 inline-flex justify-center items-center h-10 px-4 -mb-px text-sm text-center sm:text-base whitespace-nowrap focus:outline-none`}>
                  {t("payment")}
                </span>

                <span className={`${activeTab === 5 && 'bg-primary text-white'} font-semibold text-gray-600 inline-flex justify-center items-center h-10 px-4 -mb-px text-sm text-center sm:text-base whitespace-nowrap focus:outline-none`}>
                  {t("confirmWord")}
                </span>
              </div>

              <div className='p-2'>
                {activeTab === 1 &&
                  <Tab1
                    setAddProductPopup={setAddProductPopup}
                    selectedProductsList={selectedProductsList}
                    setSelectedProductsList={setSelectedProductsList}
                    nextPage={nextPage}
                    direction={direction}
                  />
                }

                {activeTab === 2 &&
                  <Tab2
                    addressClient={AddressClient}
                    setAddressClient={setAddressClient}
                    setAddAddressPopup={setAddAddressPopup}
                    selectedClient={selectedClient}
                    setSelectedClient={setSelectedClient}
                    prevPage={prevPage}
                    nextPage={nextPage}
                  />
                }

                {activeTab === 3 &&
                  <Tab3
                    deliveryMethod={DeliveryMethod}
                    setDeliveryMethod={setDeliveryMethod}
                    prevPage={prevPage}
                    nextPage={nextPage}
                  />
                }

                {activeTab === 4 &&
                  <Tab4
                    prevPage={prevPage}
                    nextPage={nextPage}
                    selectedPayment={selectedPayment}
                    setSelectedPayment={setSelectedPayment}
                  />
                }

                {activeTab === 5 &&
                  <Tab5
                    orderDetails={orderDetails}
                    submit={submit}
                  />
                }
              </div>
            </div>
      }
    </section >
  )
}

export default AddOrders