import { gql } from '@apollo/client';

export const DefinitionPage = gql`
query DefinitionPage($definitionPageId: ID) {
  definitionPage(id: $definitionPageId) {
    id
    title
    description
    publish
    createdAt
    updatedAt
    deletedAt
  }
}
`;