import { t } from 'i18next'
import React, { useState } from 'react'
import { ClientsProps, FetchArchivedClients } from '../../../types'
import ArchivedClientsDropDown from '../../DropDowns/SettingsDrops/ArchivedClientsDropDown'

interface TableProps {
  searchQuery: string,
  searchResult: ClientsProps[] | undefined,
  allClients?: FetchArchivedClients
  direction: string
  setSelectedIdForRestore: React.Dispatch<React.SetStateAction<string>>
  setRestoreConfirmPopup: React.Dispatch<React.SetStateAction<boolean>>
}

type checkedItems = {
  id: string,
}

const ArchivedClientsTable = ({ direction, searchQuery, searchResult, allClients, setSelectedIdForRestore, setRestoreConfirmPopup }: TableProps) => {
  const [checkedItems, setCheckedItems] = useState<checkedItems[]>([]);

  const handleCheckAll = () => {
    if (allClients?.allClientArchived.length !== checkedItems.length) {
      allClients !== undefined && setCheckedItems(allClients?.allClientArchived.map(client => ({ id: client.id })))
    } else {
      setCheckedItems([])
    }
  };

  const handleCheck = (id: string) => {
    const isItemExist = checkedItems.some(item => item.id === id);

    if (isItemExist) {
      const newChckedItems = checkedItems.filter(item => item.id !== id);
      setCheckedItems(newChckedItems);
    } else {
      setCheckedItems([...checkedItems, { id: id }])
    }
  };

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="px-3 py-4 text-sm text-left text-gray-500 rtl:text-right">
            <div className='flex'>
              <input type="checkbox" checked={allClients?.allClientArchived.length === checkedItems.length} onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </th>

          <th scope="col" className="py-3.5 px-3 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('clientName')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('deleteDate')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('phone')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('email')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('registrationDate')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('option')}
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {/* Show the Search result only when search keyword is set */}
        {searchQuery === "" ?
          allClients?.allClientArchived?.map(client => (
            <tr key={client.id}>
              <td className="px-3 py-4 text-sm font-medium">
                <div className='flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === client.id)} onChange={() => handleCheck(client.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>

              <td className="px-3 py-3 text-sm">
                <h2 className="text-gray-800">{client.name}</h2>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{new Date(client.deletedAt).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{client.phone}</p>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{client.email}</p>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{new Date(client.createdAt).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>
              </td>

              <td className="px-3 py-4 text-sm">
                <ArchivedClientsDropDown direction={direction} id={client.id} setSelectedIdForRestore={setSelectedIdForRestore} setRestoreConfirmPopup={setRestoreConfirmPopup} />
              </td>
            </tr>
          )
          )
          :
          searchResult?.map(client => (
            <tr key={client.id}>
              <td className="px-4 py-4 text-sm font-medium">
                <div className='flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === client.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>

              <td className="px-3 py-3 text-sm">
                <h2 className="text-gray-800">{client.name}</h2>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{new Date(client.deletedAt).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{client.phone}</p>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{client.email}</p>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{new Date(client.createdAt).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>
              </td>

              <td className="px-3 py-4 text-sm">
                <ArchivedClientsDropDown direction={direction} id={client.id} setSelectedIdForRestore={setSelectedIdForRestore} setRestoreConfirmPopup={setRestoreConfirmPopup} />
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

export default ArchivedClientsTable