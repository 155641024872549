import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import {
  Dashboard,
  Products,
  Login,
  Brands,
  AddProduct,
  Orders,
  Clients,
  AddBrands,
  AddClient,
  Categories,
  AddOrders,
  Profile,
  Reporting,
  AffiliateMarketing,
  AddMarketer,
  AbandonedCart,
  CartAlert,
  DiscountCoupons,
  Settings,
  StoreSettings,
  TeamSettings,
  EditBrands,
  EditProduct,
  ShippingSettings,
  PaymentSettings,
  EditClient,
  DefinitionPagesSettings,
  Subscription,
  Domain,
  BillSettings,
  PluginsSettings,
  Reviews,
  Countries,
  AdditionalSetting,
  Archive,
  ClientsArchive,
  StoreDesign,
  Theme,
  Sections,
  Colors,
  Navbar,
  Css,
  SmsSettings,
  Fonts,
  SeoSettings,
  EditPaymentMethod,
  AddDefinitionPages,
  EditDefinitionPages,
  EditMarketer,
  EditShipping,
  AddToTeam,
  EditWorker,
  ViewOrder,
  ViewMarketer,
  ForgotPassword
} from '../pages';

import useStore from '../store/useStore';
import Layout from './Layout';

import { history } from '../lib/helpers/history';

interface Props {
  direction: string
}

const MainLayout = ({ direction }: Props) => {
  const [menu, setMenu] = useState(false);

  const userData = useStore((state: any) => state.userData);

  history.navigate = useNavigate();
  history.location = useLocation();

  useEffect(() => {
    userData.store !== undefined &&
      console.log(userData);
  }, [userData])

  const routes = [
    {
      path: '/home',
      element: <Dashboard direction={direction} />
    },
    {
      path: '/products',
      element: <Products direction={direction} />
    },
    {
      path: '/products/create',
      element: <AddProduct direction={direction} />
    },
    {
      path: '/products/edit/:productId',
      element: <EditProduct direction={direction} />
    },
    {
      path: '/orders',
      element: <Orders direction={direction} />
    },
    {
      path: '/orders/view/:orderId',
      element: <ViewOrder />
    },
    {
      path: '/orders/create',
      element: <AddOrders direction={direction} />
    },
    {
      path: '/categories',
      element: <Categories showBreadcrumbs={true} direction={direction}/>
    },
    {
      path: '/clients',
      element: <Clients direction={direction} />
    },
    {
      path: '/clients/create',
      element: <AddClient />
    },
    {
      path: '/clients/edit/:clientId',
      element: <EditClient />
    },
    {
      path: '/brands',
      element: <Brands direction={direction} />
    },
    {
      path: '/brands/create',
      element: <AddBrands direction={direction} />
    },
    {
      path: '/brands/edit/:clientId',
      element: <EditClient />
    },
    {
      path: '/brands',
      element: <Brands direction={direction} />
    },
    {
      path: '/brands/create',
      element: <AddBrands direction={direction} />
    },
    {
      path: '/brands/edit/:brandId',
      element: <EditBrands direction={direction} />
    },
    {
      path: '/reporting',
      element: <Reporting />
    },
    {
      path: '/affiliate-marketing',
      element: <AffiliateMarketing direction={direction} />
    },
    {
      path: '/affiliate-marketing/edit/:marketerId',
      element: <EditMarketer />
    },
    {
      path: '/affiliate-marketing/view/:marketerId',
      element: <ViewMarketer />
    },
    {
      path: '/affiliate-marketing/create',
      element: <AddMarketer />
    },
    {
      path: '/abandoned-cart',
      element: <AbandonedCart direction={direction} />
    },
    {
      path: '/abandoned-cart/alerts',
      element: <CartAlert />
    },
    {
      path: '/discount-coupons',
      element: <DiscountCoupons direction={direction} />
    },
    {
      path: '/settings',
      element: <Settings />
    },
    {
      path: '/settings/store-settings',
      element: <StoreSettings />
    },
    {
      path: '/settings/team',
      element: <TeamSettings direction={direction} />
    },
    {
      path: '/settings/team/create',
      element: <AddToTeam />
    },
    {
      path: '/settings/team/edit/:workerId',
      element: <EditWorker />
    },
    {
      path: '/settings/shipping',
      element: <ShippingSettings direction={direction} />
    },
    {
      path: '/settings/shipping/edit/:shippingMethodId',
      element: <EditShipping />
    },
    {
      path: '/settings/payment',
      element: <PaymentSettings direction={direction} />
    },
    {
      path: '/settings/payment/edit/:methodId',
      element: <EditPaymentMethod />
    },
    {
      path: '/settings/definitionPages',
      element: <DefinitionPagesSettings direction={direction} />
    },
    {
      path: '/settings/definitionPages/create',
      element: <AddDefinitionPages />
    },
    {
      path: '/settings/definitionPages/edit/:pageId',
      element: <EditDefinitionPages />
    },
    {
      path: '/settings/subscription',
      element: <Subscription direction={direction} />
    },
    {
      path: '/settings/domain',
      element: <Domain />
    },
    {
      path: '/settings/bill',
      element: <BillSettings />
    },
    {
      path: '/settings/plugins',
      element: <PluginsSettings />
    },
    {
      path: '/settings/reviews',
      element: <Reviews />
    },
    {
      path: '/settings/countries',
      element: <Countries direction={direction} />
    },
    {
      path: '/settings/additionalSetting',
      element: <AdditionalSetting />
    },
    {
      path: '/settings/smsSettings',
      element: <SmsSettings />
    },
    {
      path: '/settings/archive',
      element: <Archive />
    },
    {
      path: '/settings/archive/clients',
      element: <ClientsArchive direction={direction} />
    },
    {
      path: '/settings/store-design',
      element: <StoreDesign />
    },
    {
      path: '/settings/store-design/theme',
      element: <Theme />
    },
    {
      path: '/settings/store-design/sections',
      element: <Sections direction={direction} />
    },
    {
      path: '/settings/store-design/colors',
      element: <Colors />
    },
    {
      path: '/settings/store-design/navbar',
      element: <Navbar />
    },
    {
      path: '/settings/store-design/css',
      element: <Css />
    },
    {
      path: '/settings/store-design/fonts',
      element: <Fonts />
    },
    {
      path: '/settings/seoSettings',
      element: <SeoSettings direction={direction} />
    },
    {
      path: '/settings/profile',
      element: <Profile />
    }
  ]

  return (
    <main>
      <Routes>
        <Route index path="/" element={<Login direction={direction} />} />
        <Route index path='/forgotPassword' element={<ForgotPassword />} />

        <Route element={<Layout menu={menu} setMenu={setMenu} direction={direction} />}>
          {routes.map((page, index) => (
            <Route key={index} index={index === 0 && true} path={page.path} element={page.element} />
          ))}
        </Route>

      </Routes>
    </main>
  )
}

export default MainLayout