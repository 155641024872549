import { gql, useLazyQuery } from "@apollo/client"
import { FetchReportsStores } from "../../types";

export const REPORTS_STORE = gql`
query ReportsStores($idStore: ID) {
  reportsStores(idStore: $idStore) {
    totalSales
    totalProductCosts
    totalDiscountCoupons
    totalShippingFees
    totalVAT
  }
}
`

export const useReportsStores = () => {
  return useLazyQuery<FetchReportsStores>(REPORTS_STORE, {
    fetchPolicy: 'cache-and-network',
  });
}