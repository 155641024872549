import { gql } from "@apollo/client"

export const Client = gql`
query Client($clientId: ID) {
  client(id: $clientId) {
    id
    name
    email
    phone
    dateBirth
    address
    addressClient {
      id
      name
      phone
      city
      address
      asDefault
    }
    gander
    note
    createdAt
    updatedAt
    deletedAt
  }
}
`