import { gql } from "@apollo/client"

export const UpdateMarketer = gql`
mutation UpdateMarketer($updateMarketerId: ID!, $content: contentMarketer!) {
  updateMarketer(id: $updateMarketerId, content: $content) {
    id
    name
    phone
    email
    otherInfo
    store {
      id
      name
      link
      logo
      icon
      description
      address
      numberProducts
      numberOrders
      numberClients
      admin {
        id
        profilePicture
        name
        email
        phone
        role
        activation
        emailVerify
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    marketingMethod
    commissionType
    value
    createdAt
    updatedAt
    deletedAt
  }
}
`