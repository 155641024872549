import { gql } from "@apollo/client";

export const CreateAnimatedProducts = gql`
mutation CreateAnimatedProducts($content: contentAnimatedProducts!) {
  createAnimatedProducts(content: $content) {
    id
    type
    link
    createdAt
    updatedAt
    deletedAt
  }
}
`;
