import React from 'react'
import { MdClose } from 'react-icons/md'
import Categories from '../Categories/Categories'

interface Props {
  setAddCategoriesPopup: React.Dispatch<React.SetStateAction<boolean>>
  direction: string
}

const AddCategoriesPopup = ({ setAddCategoriesPopup, direction }: Props) => {

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>
      <div className='w-5/6 p-2 overflow-y-auto bg-white border md:w-4/6 h-[80vh]'>
        <div className='flex justify-end py-2 text-gray-600'>
          <div onClick={() => setAddCategoriesPopup(false)} className='cursor-pointer'>
            <MdClose size={20} />
          </div>
        </div>

        <div>
          <Categories showBreadcrumbs={false} direction={direction} />
        </div>
      </div>
    </div>
  )
}

export default AddCategoriesPopup