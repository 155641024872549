import React from 'react';
import { t } from 'i18next';
import { HiHandRaised } from 'react-icons/hi2';

const PermissionsError = () => {
  return (
    <div className='px-5 py-5 my-2 bg-white rounded-md'>
      <div className='flex flex-col items-center justify-center'>
        <div className="inline-flex p-4 bg-red-300 rounded-full">
          <div className="p-5 bg-red-600 rounded-full">
            <HiHandRaised size={36} className='text-white' />
          </div>
        </div>
      </div>

      <div className='mt-3 text-center'>
        <h3 className='text-2xl font-semibold text-gray-700'>
          {t("You dont have permission")}
        </h3>
      </div>
    </div>
  )
}

export default PermissionsError