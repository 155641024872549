import { gql } from "@apollo/client";

export const CreateSquarePhotosSection = gql`
mutation CreateSquarePhotosSection($content: contentSquarePhotosSection!) {
  createSquarePhotosSection(content: $content) {
    id
    title
    squarePhotos {
      id
      type
      link
      image
      text
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`;
