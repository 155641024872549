import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import { t } from 'i18next'
import { useAllMarketers } from '../../graphql'
import { checkedItems } from '../../types'
import useStore from '../../store/useStore'
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget'
import MarketersTable from '../../components/Tables/MarketersTable'
import DeleteConfirmPopup from './DeleteConfirmPopup'
import SuccessAlert from '../../components/Alerts/SuccessAlert'
import FailAlert from '../../components/Alerts/FailAlert'

interface Props {
  direction: string
}

const AffiliateMarketing = ({ direction }: Props) => {
  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    delete: false,
    view: false
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          create: true,
          edit: true,
          delete: true,
          view: true,
        })

        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "marketing")

      let createPermission = filtered[0]?.permissions.includes("create")
      let editPermission = filtered[0]?.permissions.includes("edit")
      let deletePermission = filtered[0]?.permissions.includes("delete")
      let viewPermission = filtered[0]?.permissions.includes("view")

      setPermissions({
        create: createPermission || false,
        edit: editPermission || false,
        delete: deletePermission || false,
        view: viewPermission || false,
      })
    }
  }, [userData])

  const [deleteConfPopup, setDeleteConfPopup] = useState(false);

  // Alerts
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [allMarketers, { loading, data: dataAllMarketers }] = useAllMarketers();

  // This state hook is to specify which item going to start from in the table
  const [startingIndex, setStartingIndex] = useState(0)

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    storeData &&
      (async () => {
        await allMarketers({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()
  }, [storeData]);

  // This state contains the id of the client that will be deleted
  const [selectedIdForDelete, setSelectedIdForDelete] = useState("");

  const [checkedItems, setCheckedItems] = useState<checkedItems[]>([]);

  return (
    <section className='mx-3 my-4 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {deleteConfPopup && <DeleteConfirmPopup checkedItems={checkedItems} setDeleteConfPopup={setDeleteConfPopup} selectedIdForDelete={selectedIdForDelete} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('affiliateMarketing')}</h3>
          <span className='text-gray-600'>{t('numberOfMarketers')}: {dataAllMarketers?.allMarketers.length}</span>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        <div className='p-2 mt-3 bg-white rounded-md'>
          <div>
            <div className='my-2 md:flex md:justify-end'>
              <div>
                {permissions.create &&
                  <Link to="/affiliate-marketing/create" className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                    <AiOutlineAppstoreAdd size={20} />

                    <span className="mx-2">
                      {t('addMarketer')}
                    </span>
                  </Link>
                }
              </div>
            </div>
          </div>

          <div className='pt-2 overflow-x-scroll'>
            <MarketersTable checkedItems={checkedItems} setCheckedItems={setCheckedItems} permissions={permissions} direction={direction} startingIndex={startingIndex} dataAllMarketers={dataAllMarketers} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} setFailMsg={setFailMsg} />
          </div>

          {dataAllMarketers?.allMarketers.length === 0 &&
            <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
              </svg>
              <span className='px-2'>{t('No Marketers to show')}</span>
            </div>
          }

          <div className="flex justify-end gap-1.5 text-gray-500 py-4">
            <span className="font-medium text-gray-700">{startingIndex} - {startingIndex + 10}</span>
            <span>{t('of')} {dataAllMarketers?.allMarketers.length} {t('records')}</span>
          </div>

          {dataAllMarketers?.allMarketers &&
            startingIndex + 10 < dataAllMarketers?.allMarketers.length &&
            <div className="flex justify-end gap-1.5 text-gray-500 py-2">
              <button onClick={() => setStartingIndex(startingIndex + 10)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path>
                </svg>
                <span className="mx-2">
                  {t('next')}
                </span>
              </button>
            </div>
          }

        </div>
      }
    </section>
  )
}

export default AffiliateMarketing