import { gql, useLazyQuery } from '@apollo/client';
import { FetchRectangleBanner } from '../../../../types';

export const ALL_RECTANGLE_BANNER = gql`
query Query($idStore: ID) {
  allRectangleBanner(idStore: $idStore) {
    id
    type
    link
    image
    rank
    createdAt
    updatedAt
    deletedAt
  }

}`

export const useAllRectangleBanner = () => {
  return useLazyQuery<FetchRectangleBanner>(ALL_RECTANGLE_BANNER);
}
