import { useMutation } from '@apollo/client';
import { t } from 'i18next'
import React from 'react'
import { ALL_SERVICES, UpdateConversationServices, UpdateGoogleServices, UpdateSocialMediaServices } from '../../../graphql';
import useStore from '../../../store/useStore';

interface DeleteConfirmProps {
  setDeleteConfPopup: React.Dispatch<React.SetStateAction<boolean>>,
  selectedItemDelete: string,
  selectedTypeDelete: string,
  setSuccessMsg: React.Dispatch<React.SetStateAction<string>>,
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

const DeleteConfirmPopup = ({ selectedItemDelete, selectedTypeDelete, setDeleteConfPopup, setSuccess, setSuccessMsg }: DeleteConfirmProps) => {
  const storeData = useStore((state: any) => state.userData.store);

  const [updateGoogleServices, { error: updateError }] = useMutation(UpdateGoogleServices,
    {
      refetchQueries: [
        {
          query: ALL_SERVICES,
          variables: { idStore: storeData?.id }
        }
      ]
    }
  );

  const [updateSocialMediaServices, { error: updateSocialError }] = useMutation(UpdateSocialMediaServices,
    {
      refetchQueries: [
        {
          query: ALL_SERVICES,
          variables: { idStore: storeData?.id }
        }
      ]
    }
  );

  const [updateConversationServices, { error: updateConversationError }] = useMutation(UpdateConversationServices,
    {
      refetchQueries: [
        {
          query: ALL_SERVICES,
          variables: { idStore: storeData?.id }
        }
      ]
    }
  );

  const Delete = () => {
    if (selectedTypeDelete === "google_service") {
      // update
      const content = {
        ...(selectedItemDelete === "google_merchant_center" && { googleMerchantCenterApiKey: null }),
        ...(selectedItemDelete === "google_analytics" && { googleAnalyticsApiKey: null }),
        ...(selectedItemDelete === "google_ads" && { googleAdsApiKey: null }),
        ...(selectedItemDelete === "google_tag_manager" && { googleTagManagerApiKey: null })
      }

      updateGoogleServices({
        variables: {
          idStore: storeData.id,
          content: content
        }
      })

      if (!updateError) {
        setSuccessMsg(t('deleteSuccess'));
        setSuccess(true);
        setDeleteConfPopup(false)
      }

      return
    }

    if (selectedTypeDelete === "social_media") {
      // update
      const content = {
        ...(selectedItemDelete === "facebook_pixel" && { faceBookApiKey: null }),
        ...(selectedItemDelete === "twitterX_pixel" && { twitterApiKey: null }),
        ...(selectedItemDelete === "tiktok_pixel" && { tikTokApiKey: null }),
        ...(selectedItemDelete === "snapchat_pixel" && { snapchatApiKey: null }),
        ...(selectedItemDelete === "face_insta_catalog" && { facebookInstagramCatalogApiKey: null })
      }

      updateSocialMediaServices({
        variables: {
          idStore: storeData.id,
          content: content
        }
      })

      if (!updateSocialError) {
        setSuccessMsg(t('deleteSuccess'));
        setSuccess(true);
        setDeleteConfPopup(false)
      }

      return
    }

    if (selectedTypeDelete === "chat") {
      // update
      const content = {
        ...(selectedItemDelete === "tawk" && { tawkApiKey: null })
      }

      updateConversationServices({
        variables: {
          idStore: storeData.id,
          content: content
        }
      })

      if (!updateConversationError) {
        setSuccessMsg(t('deleteSuccess'));
        setSuccess(true);
        setDeleteConfPopup(false)
      }

      return
    }
  }

  return (
    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full min-h-screen bg-gray-900/10 backdrop-blur-md'>
      <div className='w-5/6 p-2 bg-white border rounded-md sm:w-4/6 md:w-3/6'>
        <div className="relative p-4 text-center">
          <svg className="text-gray-400 w-11 h-11 mb-3.5 mx-auto" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path>
          </svg>
          <p className="mb-4 text-gray-500">{t('deleteConfirm')}</p>
          <div className="flex items-center justify-center gap-4">
            <button onClick={() => Delete()} className="px-3 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700">
              {t('confirm')}
            </button>
            <button onClick={() => setDeleteConfPopup(false)} className="px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-gray-900 focus:z-10">
              {t('cancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteConfirmPopup