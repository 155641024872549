import { gql, useLazyQuery } from '@apollo/client';
import { FetchSeo } from '../../../types';

export const ALL_SEO = gql`
  query AllSeo($idStore: ID) {
    allSeo(idStore: $idStore) {
      id
      title
      description
      words
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const useAllSeo = () => {
  return useLazyQuery<FetchSeo>(ALL_SEO);
}
