import { gql, useLazyQuery } from "@apollo/client"
import { FetchAbandonedCart, FetchClients } from "../../types";

export const AbandonedCart = gql`
query AbandonedCart($idStore: ID) {
  abandonedCart(idStore: $idStore) {
    id
    total
    discount
    cartProducts {
      id
      price
      quantity
      discount
      product {
        id
        name
        price
        priceAfterDiscount
        cost
        quantity
        weight
        shortDescription
        description
        notRequireDelivery
        freeShipping
        posting
        imagesProduct {
          id
          name
          alt
          createdAt
          updatedAt
          deletedAt
        }
        brand {
          id
          name
          image
          classFirstLevel {
            id
            name
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
          deletedAt
        }
        classThirdLevel {
          id
          name
          classSecondLevel {
            id
            name
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
          deletedAt
        }
        productOptions {
          id
          name
          subOptions {
            id
            name
            price
            quantity
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        rating {
          id
          mark
          description
          client {
            id
            profilePicture
            name
            email
            phone
            address
            gander
            note
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
          deletedAt
        }
        numberSales
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    client {
      id
      name
      email
      phone
      dateBirth
      address
      gander
      note
      addressClient {
        id
        name
        phone
        city
        address
        asDefault
      }
      store {
        id
        name
        link
        logo
        icon
        description
        address
        welcomeMessage
        numberProducts
        numberOrders
        numberClients
        admin {
          id
          profilePicture
          name
          email
          phone
          role
          activation
          emailVerify
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useAbandonedCart = () => {
  return useLazyQuery<FetchAbandonedCart>(AbandonedCart, {
    fetchPolicy: 'cache-and-network',
  });
}