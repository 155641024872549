import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'

const Archive = () => {
  // I did scroll to the top because when the component is render it show the bottom of the page because the layout
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <section className='my-4 mx-3 md:mx-7'>
      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('archive')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div className='grid grid-cols-2 gap-4 mt-2 md:grid-cols-3 lg:grid-cols-4'>
        <div>
          <Link to="/settings/archive/clients">
            <div className="h-full p-6 bg-white rounded-lg shadow">
              <div className='flex flex-col items-center h-full'>
                <div className="p-2 text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M15 11h7v2h-7zm1 4h6v2h-6zm-2-8h8v2h-8zM4 19h10v-1c0-2.757-2.243-5-5-5H7c-2.757 0-5 2.243-5 5v1h2zm4-7c1.995 0 3.5-1.505 3.5-3.5S9.995 5 8 5 4.5 6.505 4.5 8.5 6.005 12 8 12z"></path>
                  </svg>
                </div>
                <h2 className="mb-1 text-xl text-center title-font">{t('clients')}</h2>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Archive