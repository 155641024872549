import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'

import googleMerchent from "../../../assets/logos/google-merchant-center.svg"
import googleAnalytics from "../../../assets/logos/google-analytics.svg"
import googleAds from "../../../assets/logos/google-ads.svg"
import googleTagManager from "../../../assets/logos/google-tag-manager.svg"

import facebook from "../../../assets/logos/facebook.svg"
import twitterX from "../../../assets/logos/twitter-x.svg"
import tiktok from "../../../assets/logos/tiktok.svg"
import snapchat from "../../../assets/logos/snapchat.svg"
import meta from "../../../assets/logos/meta.svg"

import tawk from "../../../assets/logos/tawk.png"
import {
  GoogleMerchantCenter,
  GoogleAnalytics,
  GoogleAds,
  GoogleTagManager,
  FacebookPixel,
  TwitterXPixel,
  TiktokPixel,
  SnapchatPixel,
  Tawk
} from './popups'
import LoadingWidget from '../../../components/LoadingWidget/LoadingWidget'
import PermissionsError from '../../../components/Alerts/PermissionsError'
import useStore from '../../../store/useStore'
import { useAllServices } from '../../../graphql'
import DeleteConfirmPopup from './DeleteConfirmPopup'
import SuccessAlert from '../../../components/Alerts/SuccessAlert'

const PluginsSettings = () => {
  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [permissions, setPermissions] = useState({
    view: false,
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          view: true,
        })

        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "settings")

      let viewPermission = filtered[0]?.permissions.includes("plugins")

      setPermissions({
        view: viewPermission || false,
      })

    }
  }, [userData])

  const [services, { loading, data: dataServices }] = useAllServices();

  useEffect(() => {
    storeData &&
      (async () => {
        await services({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()
  }, [storeData]);

  // I did scroll to the top because when the component is render it show the bottom of the page because the layout
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [openedPopup, setOpenedPopup] = useState<false | string>(false);

  const openPopup = (tab: string) => {
    setOpenedPopup(tab);
  }

  // This state contains the id of the product that will be deleted
  const [selectedTypeDelete, setSelectedTypeDelete] = useState("");
  const [selectedItemDelete, setSelectedItemDelete] = useState("");
  const [deleteConfPopup, setDeleteConfPopup] = useState(false);

  return (
    <section className='mx-3 my-4 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {deleteConfPopup && <DeleteConfirmPopup setDeleteConfPopup={setDeleteConfPopup} selectedItemDelete={selectedItemDelete} selectedTypeDelete={selectedTypeDelete} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      {openedPopup === "google_merchant_center" && <GoogleMerchantCenter setOpenedPopup={setOpenedPopup} />}
      {openedPopup === "google_analytics" && <GoogleAnalytics setOpenedPopup={setOpenedPopup} />}
      {openedPopup === "google_ads" && <GoogleAds setOpenedPopup={setOpenedPopup} />}
      {openedPopup === "google_tag_manager" && <GoogleTagManager setOpenedPopup={setOpenedPopup} />}
      {openedPopup === "facebook_pixel" && <FacebookPixel setOpenedPopup={setOpenedPopup} />}
      {openedPopup === "twitterX_pixel" && <TwitterXPixel setOpenedPopup={setOpenedPopup} />}
      {openedPopup === "tiktok_pixel" && <TiktokPixel setOpenedPopup={setOpenedPopup} />}
      {openedPopup === "snapchat_pixel" && <SnapchatPixel setOpenedPopup={setOpenedPopup} />}
      {openedPopup === "tawk" && <Tawk setOpenedPopup={setOpenedPopup} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('plugins')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        permissions.view === false ?
          <PermissionsError />
          :
          <main>
            <div>
              <h3 className='mt-2 font-semibold'>{t('Google Services')}</h3>
              <div className='grid grid-cols-2 gap-5 my-5 sm:grid-cols-3 lg:grid-cols-4'>
                <div className='bg-white rounded-md'>
                  <div className='flex flex-col items-center'>
                    <div className='flex justify-center p-10'>
                      <img src={googleMerchent} className='h-14' alt="" />
                    </div>
                    <h3 className='text-sm text-center text-gray-500'>Google Merchant Center</h3>

                    <div className='flex items-center gap-2 mt-3 mb-2'>
                      <button onClick={() => openPopup("google_merchant_center")} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                        {dataServices?.allServices !== null ?
                          dataServices?.allServices.google !== null && dataServices?.allServices.google.googleMerchantCenterApiKey === null ?
                            t('connect')
                            :
                            t('edit')
                          :
                          t('connect')
                        }
                      </button>

                      {dataServices?.allServices && dataServices?.allServices.google && dataServices?.allServices.google.googleMerchantCenterApiKey !== null &&
                        <div onClick={() => { setDeleteConfPopup(true); setSelectedTypeDelete("google_service"); setSelectedItemDelete("google_merchant_center") }} className='bg-[#FFE3E3] text-red-500 p-2 cursor-pointer rounded-md'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                            <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                          </svg>
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className='bg-white rounded-md'>
                  <div className='flex flex-col items-center'>
                    <div className='flex justify-center p-10'>
                      <img src={googleAnalytics} className='h-14' alt="" />
                    </div>
                    <h3 className='text-sm text-center text-gray-500'>Google Analytics</h3>
                    <div className='flex items-center gap-2 mt-3 mb-2'>
                      <button onClick={() => openPopup("google_analytics")} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                        {dataServices?.allServices !== null ?
                          dataServices?.allServices.google !== null
                            &&
                            dataServices?.allServices.google.googleAnalyticsApiKey === null ?
                            t('connect')
                            :
                            t('edit')
                          :
                          t('connect')
                        }
                      </button>

                      {dataServices?.allServices && dataServices?.allServices.google && dataServices?.allServices.google.googleAnalyticsApiKey !== null &&
                        <div onClick={() => { setDeleteConfPopup(true); setSelectedTypeDelete("google_service"); setSelectedItemDelete("google_analytics") }} className='bg-[#FFE3E3] text-red-500 p-2 cursor-pointer rounded-md'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                            <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                          </svg>
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className='bg-white rounded-md'>
                  <div className='flex flex-col items-center'>
                    <div className='flex justify-center p-10'>
                      <img src={googleAds} className='h-14' alt="" />
                    </div>
                    <h3 className='text-sm text-center text-gray-500'>Google Ads</h3>

                    <div className='flex items-center gap-2 mt-3 mb-2'>
                      <button onClick={() => openPopup("google_ads")} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                        {dataServices?.allServices !== null ?
                          dataServices?.allServices.google !== null &&
                            dataServices?.allServices.google.googleAdsApiKey === null ?
                            t('connect')
                            :
                            t('edit')
                          :
                          t('connect')
                        }
                      </button>

                      {dataServices?.allServices && dataServices?.allServices.google && dataServices?.allServices.google.googleAdsApiKey !== null &&
                        <div onClick={() => { setDeleteConfPopup(true); setSelectedTypeDelete("google_service"); setSelectedItemDelete("google_ads") }} className='bg-[#FFE3E3] text-red-500 p-2 cursor-pointer rounded-md'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                            <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                          </svg>
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className='bg-white rounded-md'>
                  <div className='flex flex-col items-center'>
                    <div className='flex justify-center p-10'>
                      <img src={googleTagManager} className='h-14' alt="" />
                    </div>
                    <h3 className='text-sm text-center text-gray-500'>Google Tag Manager</h3>

                    <div className='flex items-center gap-2 mt-3 mb-2'>
                      <button onClick={() => openPopup("google_tag_manager")} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                        {dataServices?.allServices !== null ?
                          dataServices?.allServices.google !== null &&
                            dataServices?.allServices.google.googleTagManagerApiKey === null ?
                            t('connect')
                            :
                            t('edit')
                          :
                          t('connect')
                        }
                      </button>

                      {dataServices?.allServices && dataServices?.allServices.google && dataServices?.allServices.google.googleTagManagerApiKey !== null &&
                        <div onClick={() => { setDeleteConfPopup(true); setSelectedTypeDelete("google_service"); setSelectedItemDelete("google_tag_manager") }} className='bg-[#FFE3E3] text-red-500 p-2 cursor-pointer rounded-md'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                            <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                          </svg>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3 className='mt-2 font-semibold'>{t('Social media Services')}</h3>
              <div className='grid grid-cols-2 gap-5 my-5 sm:grid-cols-3 lg:grid-cols-4'>
                <div className='bg-white rounded-md'>
                  <div className='flex flex-col items-center'>
                    <div className='flex justify-center p-10'>
                      <img src={facebook} className='h-14' alt="" />
                    </div>
                    <h3 className='text-sm text-center text-gray-500'>FaceBook Pixel</h3>

                    <div className='flex items-center gap-2 mt-3 mb-2'>
                      <button onClick={() => openPopup("facebook_pixel")} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                        {dataServices?.allServices !== null ?
                          dataServices?.allServices.socialMedia !== null ?
                            dataServices?.allServices.socialMedia.faceBookApiKey === null ?
                              t('connect')
                              :
                              t('edit')
                            :
                            t('connect')
                          :
                          t('connect')
                        }
                      </button>

                      {dataServices?.allServices && dataServices?.allServices.socialMedia && dataServices?.allServices.socialMedia.faceBookApiKey !== null &&
                        <div onClick={() => { setDeleteConfPopup(true); setSelectedTypeDelete("social_media"); setSelectedItemDelete("facebook_pixel") }} className='bg-[#FFE3E3] text-red-500 p-2 cursor-pointer rounded-md'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                            <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                          </svg>
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className='bg-white rounded-md'>
                  <div className='flex flex-col items-center'>
                    <div className='flex justify-center p-10'>
                      <img src={twitterX} className='h-14' alt="" />
                    </div>
                    <h3 className='text-sm text-center text-gray-500'>X Pixel</h3>

                    <div className='flex items-center gap-2 mt-3 mb-2'>
                      <button onClick={() => openPopup("twitterX_pixel")} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                        {dataServices?.allServices !== null ?
                          dataServices?.allServices.socialMedia !== null ?
                            dataServices?.allServices.socialMedia.twitterApiKey === null ?
                              t('connect')
                              :
                              t('edit')
                            :
                            t('connect')
                          :
                          t('connect')
                        }
                      </button>

                      {dataServices?.allServices && dataServices?.allServices.socialMedia && dataServices?.allServices.socialMedia.twitterApiKey !== null &&
                        <div onClick={() => { setDeleteConfPopup(true); setSelectedTypeDelete("social_media"); setSelectedItemDelete("twitterX_pixel") }} className='bg-[#FFE3E3] text-red-500 p-2 cursor-pointer rounded-md'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                            <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                          </svg>
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className='bg-white rounded-md'>
                  <div className='flex flex-col items-center'>
                    <div className='flex justify-center p-10'>
                      <img src={tiktok} className='h-14' alt="" />
                    </div>
                    <h3 className='text-sm text-center text-gray-500'>Tiktok Pixel</h3>

                    <div className='flex items-center gap-2 mt-3 mb-2'>
                      <button onClick={() => openPopup("tiktok_pixel")} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                        {dataServices?.allServices !== null ?
                          dataServices?.allServices.socialMedia !== null ?
                            dataServices?.allServices.socialMedia.tikTokApiKey === null ?
                              t('connect')
                              :
                              t('edit')
                            :
                            t('connect')
                          :
                          t('connect')
                        }
                      </button>

                      {dataServices?.allServices && dataServices?.allServices.socialMedia && dataServices?.allServices.socialMedia.tikTokApiKey !== null &&
                        <div onClick={() => { setDeleteConfPopup(true); setSelectedTypeDelete("social_media"); setSelectedItemDelete("tiktok_pixel") }} className='bg-[#FFE3E3] text-red-500 p-2 cursor-pointer rounded-md'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                            <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                          </svg>
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className='bg-white rounded-md'>
                  <div className='flex flex-col items-center'>
                    <div className='flex justify-center p-10'>
                      <img src={snapchat} className='h-14' alt="" />
                    </div>
                    <h3 className='text-sm text-center text-gray-500'>Snapchat Pixel</h3>

                    <div className='flex items-center gap-2 mt-3 mb-2'>
                      <button onClick={() => openPopup("snapchat_pixel")} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                        {dataServices?.allServices !== null ?
                          dataServices?.allServices.socialMedia !== null ?
                            dataServices?.allServices.socialMedia.snapchatApiKey === null ?
                              t('connect')
                              :
                              t('edit')
                            :
                            t('connect')
                          :
                          t('connect')
                        }
                      </button>

                      {dataServices?.allServices && dataServices?.allServices.socialMedia && dataServices?.allServices.socialMedia.snapchatApiKey !== null &&
                        <div onClick={() => { setDeleteConfPopup(true); setSelectedTypeDelete("social_media"); setSelectedItemDelete("snapchat_pixel") }} className='bg-[#FFE3E3] text-red-500 p-2 cursor-pointer rounded-md'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                            <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                          </svg>
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className='bg-white rounded-md'>
                  <div className='flex flex-col items-center'>
                    <div className='flex justify-center p-10'>
                      <img src={meta} className='h-14' alt="" />
                    </div>
                    <h3 className='text-sm text-center text-gray-500'>Facebook & Instagram Catalog</h3>

                    <div className='flex items-center gap-2 mt-3 mb-2'>
                      <button onClick={() => openPopup("face_insta_catalog")} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                        {dataServices?.allServices !== null ?
                          dataServices?.allServices.socialMedia !== null ?
                            dataServices?.allServices.socialMedia.facebookInstagramCatalogApiKey === null ?
                              t('connect')
                              :
                              t('edit')
                            :
                            t('connect')
                          :
                          t('connect')
                        }
                      </button>

                      {dataServices?.allServices && dataServices?.allServices.socialMedia && dataServices?.allServices.socialMedia.facebookInstagramCatalogApiKey !== null &&
                        <div onClick={() => { setDeleteConfPopup(true); setSelectedTypeDelete("social_media"); setSelectedItemDelete("face_insta_catalog") }} className='bg-[#FFE3E3] text-red-500 p-2 cursor-pointer rounded-md'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                            <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                          </svg>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3 className='mt-2 font-semibold'>{t('Chat Services')}</h3>
              <div className='grid grid-cols-2 gap-5 my-5 sm:grid-cols-3 lg:grid-cols-4'>
                <div className='bg-white rounded-md'>
                  <div className='flex flex-col items-center'>
                    <div className='flex justify-center p-10'>
                      <img src={tawk} className='h-14' alt="" />
                    </div>
                    <h3 className='text-sm text-center text-gray-500'>Tawk</h3>

                    <div className='flex items-center gap-2 mt-3 mb-2'>
                      <button onClick={() => openPopup("tawk")} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                        {dataServices?.allServices !== null ?
                          dataServices?.allServices.conversation !== null ?
                            dataServices?.allServices.conversation.tawkApiKey === null ?
                              t('connect')
                              :
                              t('edit')
                            :
                            t('connect')
                          :
                          t('connect')
                        }
                      </button>

                      {dataServices?.allServices && dataServices?.allServices.conversation && dataServices?.allServices.conversation.tawkApiKey !== null &&
                        <div onClick={() => { setDeleteConfPopup(true); setSelectedTypeDelete("chat"); setSelectedItemDelete("tawk") }} className='bg-[#FFE3E3] text-red-500 p-2 cursor-pointer rounded-md'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                            <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                          </svg>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
      }
    </section>
  )
}

export default PluginsSettings