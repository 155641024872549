import { gql, useLazyQuery } from "@apollo/client"
import { FetchDeliveries } from "../../types";

export const ALL_DELIVERIES = gql`
query AllDeliveries($idStore: ID) {
  allDeliveries(idStore: $idStore) {
    id
    discount
    coupon
    orderNumber
    note
    client {
      id
      name
      email
      phone
      dateBirth
      address
      gander
      note
      createdAt
      updatedAt
      deletedAt
    }
    paymentMethod {
      id
      bankName
      accountName
      number
      IBAN
      country {
        id
        name
        currency
        asDefault
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    deliveryProduct {
      product {
        id
        name
        price
        priceAfterDiscount
        cost
        quantity
        weight
        shortDescription
        description
        notRequireDelivery
        freeShipping
        posting
        imagesProduct {
          id
          name
          alt
          createdAt
          updatedAt
          deletedAt
        }
        brand {
          id
          name
          image
          classFirstLevel {
            id
            name
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
          deletedAt
        }
        classThirdLevel {
          id
          name
          classSecondLevel {
            id
            name
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
          deletedAt
        }
        productOptions {
          id
          name
          subOptions {
            id
            name
            price
            quantity
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      price
      quantity
    }
    addressClient {
      id
      name
      phone
      city
      address
      asDefault
    }
    deliveryMethod {
      id
      name
      price
      paymentWhenReceiving
      pricePaymentWhenReceiving
      estimatedTime
      cities
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useAllDeliveries = () => {
  return useLazyQuery<FetchDeliveries>(ALL_DELIVERIES, {
    fetchPolicy: 'cache-and-network',
  });
}