import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import useStore from '../../store/useStore';
import { RiNotification3Line } from 'react-icons/ri'
import { BiUser } from 'react-icons/bi'
import { LuLogOut } from 'react-icons/lu'
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import { LogOut, useGetCurrentUser } from '../../graphql';
import Emitter from '../../lib/Emitter';
import { IoLanguageSharp } from 'react-icons/io5';

interface Props {
  setMenu: React.Dispatch<React.SetStateAction<boolean>>
  direction: string
}

const MainHeader = ({ setMenu, direction }: Props) => {
  const storeData = useStore((state: any) => state.userData);

  const [notificationData, setNotificationData] = useState<any>([]);

  const [showNotificationNumber, setShowNotificationNumber] = useState<any>([]);

  useEffect(() => {
    Emitter.on("newOrder", (data: any) => {
      setShowNotificationNumber(true);
      setNotificationData([data, ...notificationData]);
    })
  }, [])

  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const [profileDropDown, setProfileDropDown] = useState(false);
  const [langDropDown, setLangDropDown] = useState(false);
  const notificationRef = useRef<any>();
  const profileRef = useRef<any>();
  const langRef = useRef<any>();

  const [getCurrentUserLazy, { data }]: any = useGetCurrentUser();

  useEffect(() => {
    storeData.id &&
      getCurrentUserLazy({
        onError: (error: any) => {
          console.error(error);
        }
      })
  }, [storeData]);

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (notificationRef.current || profileRef.current || langRef.current) {
        if (notificationRef.current.contains(e.target as Node) || profileRef.current.contains(e.target as Node) || langRef.current.contains(e.target as Node)) {
          return
        }

        // else close the dropdown
        setNotificationDropDown(false);
        setProfileDropDown(false);
        setLangDropDown(false);
      }
    })
  }, [])

  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    setProfileDropDown(false);
    i18n.changeLanguage(lng);
    localStorage.setItem('defaultLanguage', lng);
    setLangDropDown(false);
  };

  const [logout] = useMutation(LogOut, {
    onError: (error) => {
      // @ts-ignore
      const code = error.message;
      console.error(code);

    },
    onCompleted: () => {
      useStore.setState({ isAuth: false, token: "" });
      useStore.setState({ userData: {} });
      //navigate(`/${params.storeLink}/login`);
      window.location.reload();
    }
  })

  const submitLogOut = async () => {
    await logout()
  }

  const timeDifference = (date: string) => {
    const previousDate: any = new Date(date);
    const currentDate: any = new Date();

    const timeDifferenceMillis = currentDate - previousDate;
    const seconds = Math.floor(timeDifferenceMillis / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30); // Assuming 30 days in a month

    if (months > 0) {
      return `${months} ${months > 1 ? t('months ago') : t('month ago')}`;
    } else if (days > 0) {
      return `${days} ${days > 1 ? t("days ago") : t('day ago')} `;
    } else if (hours > 0) {
      return `${hours} ${t('hr ago')}`;
    } else if (minutes > 0) {
      return `${minutes} ${t('min ago')}`;
    } else {
      return t('now');
    }
  }

  return (
    <header className="md:px-6 px-3 py-4 bg-white">
      <div className="flex items-center justify-between mx-auto">
        <div>
          <button className={`${direction === "rtl" && "rotate-180"} md:hidden`} onClick={() => { setMenu(true) }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="rgba(0, 0, 0, 1)">
              <path d="M4 11h12v2H4zm0-5h16v2H4zm0 12h7.235v-2H4z"></path>
            </svg>
          </button>
        </div>

        <div className="flex justify-end">
          <div className="relative z-20 flex items-center bg-transparent bg-white opacity-100">
            <div className="flex items-center gap-4 px-2.5 py-1 bg-gray-100 rounded-full">
              <div ref={langRef} className='flex items-center'>
                <button onClick={() => setLangDropDown(!langDropDown)} className="text-gray-700 duration-300 outline-none hover:text-primary focus:outline-none">
                  <IoLanguageSharp size={20} />
                </button>

                <div className={`${!langDropDown && "hidden"} absolute ${direction === "ltr" ? "-left-full" : "-right-full"} top-12 border z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-sm`}>
                  <div onClick={() => { changeLanguage("en"); setProfileDropDown(false) }} className="p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">

                    <span className="mx-1 font-semibold">{t('en')}</span>
                  </div>
                  <div onClick={() => { changeLanguage("ar"); setProfileDropDown(false) }} className="p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">

                    <span className="mx-1 font-semibold">{t('ar')}</span>
                  </div>
                </div>
              </div>

              <div className='flex items-center' ref={notificationRef}>
                <button onClick={() => { setProfileDropDown(false); setNotificationDropDown(!notificationDropDown); setShowNotificationNumber(false) }} className="relative text-gray-700 duration-300 outline-none hover:text-primary focus:outline-none">
                  <RiNotification3Line size={20} />

                  {
                    showNotificationNumber &&
                    notificationData.length !== 0 &&
                    <div className='absolute px-1.5 py-px text-xs text-white rounded-full -top-2 -right-2 bg-primary'>
                      {notificationData.length}
                    </div>
                  }
                </button>
                <div className={`${!notificationDropDown && "hidden"} absolute ${direction === "ltr" ? 'right-12' : 'left-12'} top-12 border z-20 w-[410px] py-2 mt-2 bg-white rounded-md shadow-xl`}>
                  <div>
                    <div className='px-4 pt-2 pb-3 text-gray-700 border-b'>
                      <h3 className='text-xl font-semibold'>{t('Notification')}</h3>
                    </div>

                    <div className='px-4'>
                      {notificationData.length === 0 ?
                        <div className='py-2'>
                          {t('No notification to show')}
                        </div>
                        :
                        notificationData.map((notification: any) => (
                          <Link to={`/orders/view/${notification.idDelivery}`} key={notification.id} onClick={() => setNotificationDropDown(false)}>
                            <div className='flex items-start gap-3 py-2 border-b'>
                              <div className='flex items-center justify-center w-8 h-8 p-2 text-white bg-green-500 rounded-full'>
                                {notification.client.name[0]}
                              </div>
                              <div className='flex justify-between w-full'>
                                <div>
                                  <h3 className='text-lg font-semibold'>{notification.client.name}</h3>
                                  <p className='pb-1 text-sm text-gray-500'>{notification.client.name} {t('added new order')}...</p>
                                  <div className='inline-block px-1 text-sm font-semibold text-green-600 bg-green-100 rounded'>
                                    <p>
                                      {t('new order')}
                                    </p>
                                  </div>
                                </div>
                                <div className='text-xs font-semibold text-gray-400'>
                                  {timeDifference(notification.createdAt)}
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}
                      {/* <div className='flex items-start gap-3 py-2 border-b'>
                        <div className='flex items-center justify-center w-8 h-8 p-2 text-white bg-red-500 rounded-full'>
                          <span>W</span>
                        </div>
                        <div className='flex justify-between w-full'>
                          <div>
                            <h3 className='text-lg font-semibold'>Ahmed Mohamed</h3>
                            <p className='pb-1 text-sm text-gray-500'>Ahmed cancel an order...</p>
                            <div className='inline-block px-1 text-sm font-semibold text-red-600 bg-red-100 rounded'>
                              <p>
                                {t('cancel order')}
                              </p>
                            </div>
                          </div>
                          <div className='text-xs font-semibold text-gray-400'>
                            50 {t('min ago')}
                          </div>
                        </div>
                      </div>
                      
                      <div className='flex items-start gap-3 py-2 border-b'>
                        <div className='flex items-center justify-center w-8 h-8 p-2 text-white bg-green-500 rounded-full'>
                          <span>J</span>
                        </div>
                        <div className='flex justify-between w-full'>
                          <div>
                            <h3 className='text-lg font-semibold'>Jakel Javier</h3>
                            <p className='pb-1 text-sm text-gray-500'>Jakel plece new order...</p>
                            <div className='inline-block px-1 text-sm font-semibold text-green-600 bg-green-100 rounded'>
                              <p>
                                {t('new order')}
                              </p>
                            </div>
                          </div>
                          <div className='text-xs font-semibold text-gray-400'>
                            2 {t('hr ago')}
                          </div>
                        </div>
                      </div>
                      <div className='flex items-start gap-3 py-2 border-b'>
                        <div className='flex items-center justify-center w-8 h-8 p-2 text-white bg-orange-500 rounded-full'>
                          <span>O</span>
                        </div>
                        <div className='flex justify-between w-full'>
                          <div>
                            <h3 className='text-lg font-semibold'>Okmel Lete</h3>
                            <p className='pb-1 text-sm text-gray-500'>Okmel sent you a message...</p>
                            <div className='inline-block px-1 text-sm font-semibold text-orange-600 bg-orange-100 rounded'>
                              <p>
                                {t("new message")}
                              </p>
                            </div>
                          </div>
                          <div className='text-xs font-semibold text-gray-400'>
                            1 {t('day ago')}
                          </div>
                        </div> 
                      </div>
                        */}
                    </div>

                    {notificationData.length > 2 &&
                      <div className='flex cursor-pointer items-center justify-center w-full pt-1.5 font-semibold text-primary'>
                        {t('More')}
                      </div>
                    }
                  </div>
                </div>
              </div>

              <div ref={profileRef}>
                <button onClick={() => { setNotificationDropDown(false); setProfileDropDown(!profileDropDown) }} className="flex items-center justify-center w-10 h-10 font-semibold text-white rounded-full bg-primary focus:outline-none">
                  <div className="relative flex items-center justify-center overflow-hidden text-lg bottom-px">
                    {data?.currentUser.name[0]}
                  </div>
                </button>

                <div className={`${!profileDropDown && "hidden"} absolute ${direction === "ltr" ? "right-0" : "left-0"}  top-12 border z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl`}>
                  <Link to="/settings/profile" onClick={() => setProfileDropDown(!profileDropDown)} className="flex items-center gap-2 p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                    <BiUser size={21} />

                    <span className="mx-1 font-semibold">{t('profile')}</span>
                  </Link>

                  <div onClick={() => submitLogOut()} className="flex items-center gap-2 p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                    <LuLogOut size={21} />

                    <span className="mx-1 font-semibold">{t('logout')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header >
  )
}

export default MainHeader