import { gql, useLazyQuery } from "@apollo/client"
import { FetchSaleStatistics } from "../../types";

export const SALE_STATISTICS_STORE = gql`
query SaleStatisticsStore($idStore: ID, $firstDay: String, $lastDay: String) {
  saleStatisticsStore(idStore: $idStore, firstDay: $firstDay, lastDay: $lastDay) {
    day
    totalSales
  }
}
`
export const useSaleStatistics = () => {
  return useLazyQuery<FetchSaleStatistics>(SALE_STATISTICS_STORE, {
    fetchPolicy: 'cache-and-network',
  });
}