import React, { useEffect, useState } from 'react'

import movingBanner from "../../../../assets/movingBanner.svg"
import features from "../../../../assets/features.svg"
import homeProducts from "../../../../assets/home-products.svg"
import bnner from "../../../../assets/bnner.svg"
import banner02 from "../../../../assets/banner02.svg"
import square from "../../../../assets/square.svg"
import youtube from "../../../../assets/youtube.svg"
import testimonials from "../../../../assets/testimonials.svg"
import {
  AddHomeBanner,
  AddFeatures,
  AddProducts,
  AddWideBanner,
  AddImages,
  AddVideo,
  AddBanners,
  EditHomeBanner,
  EditProducts,
  EditWideBanner,
  EditYoutubeVideo,
  EditBaners
} from './Popups'
import SuccessAlert from '../../../../components/Alerts/SuccessAlert'
import FailAlert from '../../../../components/Alerts/FailAlert'
import useStore from '../../../../store/useStore'
import { UpdateAnimatedProducts, UpdateCarousel, UpdateFeatures, UpdateRectangleBanner, UpdateRectangleBannerWide, UpdateSquarePhotosSection, UpdateYouTubeVideo, useAllAnimatedProducts, useAllCarousels, useAllFeatures, useAllRectangleBanner, useAllRectangleBannerWide, useAllSquarePhotosSection, useAllYouTubeVideo } from '../../../../graphql'
import LoadingWidget from '../../../../components/LoadingWidget/LoadingWidget'
import DeleteConfirmPopup from './DeleteConfirmPopup'
import { SortableContainer, SortableContainerProps, SortableElement, SortableElementProps } from 'react-sortable-hoc';
import { useMutation } from '@apollo/client'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'
import { AiOutlineDelete, AiFillEdit } from 'react-icons/ai'

interface Section {
  type: string;
  id: string;
  rank: number;
}

const Sections = ({ direction }: { direction: string }) => {

  const SortableItem = SortableElement<SortableElementProps & { value: Section }>(({ value }: { value: Section }) =>
    <div dir={direction} className='flex items-center justify-between p-2 my-2 bg-gray-100 rounded cursor-move'>
      <div>
        {t(value.type)}
      </div>
      <div className='flex items-center gap-2'>
        <div onClick={() => confirmEdit(value.type)}>
          <button className='p-2 text-gray-800 rounded-md cursor-pointer bg-gray-50'>
            <AiFillEdit size={22} style={{ pointerEvents: 'none' }} />
          </button>
        </div>

        <div onClick={() => confirmDelete(value.id, value.type)}>
          <button className='p-2 text-red-500 bg-white border rounded-md cursor-pointer'>
            <AiOutlineDelete size={22} style={{ pointerEvents: 'none' }} />
          </button>
        </div>
      </div>
    </div>
  );

  const SortableList = SortableContainer<SortableContainerProps & { sections: any[] }>(({ sections }: { sections: any[] }) => {
    return (
      <div>
        {sections.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
      </div>
    );
  });

  const storeData = useStore((state: any) => state.userData.store);

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [sectionsSideBar, setSectionsSideBar] = useState(false)

  const [homeBannerPopup, setHomeBannerPopup] = useState(false);
  const [editHomeBannerPopup, setEditHomeBannerPopup] = useState(false);

  const [featuresPopup, setFeaturesPopup] = useState(false)

  const [addProductsPopup, setAddProductsPopup] = useState(false)
  const [editProductsPopup, setEditProductsPopup] = useState(false)

  const [bannersPopup, setBannersPopup] = useState(false)
  const [editBannersPopup, setEditBannersPopup] = useState(false)

  const [wideBannerPopup, setWideBannerPopup] = useState(false)
  const [editWideBannerPopup, setEditWideBannerPopup] = useState(false)

  const [imagesPopup, setImagesPopup] = useState(false)

  const [videosPopup, setVideosPopup] = useState(false)
  const [editVideosPopup, setEditVideosPopup] = useState(false)

  const [allCarousels, { data: dataAllCarousels }] = useAllCarousels();
  const [allFeatures, { data: dataAllFeatures }] = useAllFeatures();
  const [allAnimatedProducts, { data: dataAllAnimatedProducts }] = useAllAnimatedProducts();
  const [allRectangleBannerWide, { data: dataAllRectangleBannerWide }] = useAllRectangleBannerWide();
  const [allRectangleBanner, { data: dataAllRectangleBanner }] = useAllRectangleBanner();
  const [allSquarePhotosSection, { data: dataAllSquarePhotosSection }] = useAllSquarePhotosSection();
  const [allYouTubeVideo, { data: dataAllYouTubeVideo }] = useAllYouTubeVideo();

  useEffect(() => {
    if (storeData) {
      (async () => {
        await allCarousels({
          variables: { idStore: storeData.id }
        })

        await allFeatures({
          variables: { idStore: storeData.id }
        })

        await allAnimatedProducts({
          variables: { idStore: storeData.id }
        })

        await allRectangleBanner({
          variables: { idStore: storeData.id }
        })

        await allRectangleBannerWide({
          variables: { idStore: storeData.id }
        })

        await allSquarePhotosSection({
          variables: { idStore: storeData.id }
        })

        await allYouTubeVideo({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()
    }

  }, [storeData]);

  const [sections, setSections] = useState<any[]>([]);

  useEffect(() => {
    const fetchedSections: Section[] = [];

    if (dataAllCarousels && dataAllCarousels.allCarousels.length !== 0) {
      fetchedSections.push({
        type: 'Home Banner',
        id: dataAllCarousels.allCarousels[0].id,
        rank: dataAllCarousels.allCarousels[0].rank,
      })
    }

    if (dataAllFeatures && dataAllFeatures.allFeatures.length !== 0) {
      fetchedSections.push({
        type: 'Features',
        id: dataAllFeatures.allFeatures[0].id,
        rank: dataAllFeatures.allFeatures[0].rank,
      });
    }

    if (dataAllAnimatedProducts && dataAllAnimatedProducts.allAnimatedProducts.length !== 0) {
      fetchedSections.push({
        type: 'Products',
        id: dataAllAnimatedProducts.allAnimatedProducts[0].id,
        rank: dataAllAnimatedProducts.allAnimatedProducts[0].rank,
      });
    }

    if (dataAllRectangleBannerWide && dataAllRectangleBannerWide?.allRectangleBannerWide !== null) {
      fetchedSections.push({
        type: 'Wide Banner',
        id: dataAllRectangleBannerWide.allRectangleBannerWide.id,
        rank: dataAllRectangleBannerWide.allRectangleBannerWide.rank,
      });
    }

    if (dataAllRectangleBanner && dataAllRectangleBanner.allRectangleBanner.length !== 0) {
      fetchedSections.push({
        type: 'Banners',
        id: dataAllRectangleBanner.allRectangleBanner[0].id,
        rank: dataAllRectangleBanner.allRectangleBanner[0].rank,
      });
    }

    if (dataAllSquarePhotosSection && dataAllSquarePhotosSection.allSquarePhotosSection !== null) {
      fetchedSections.push({
        type: 'Images',
        id: dataAllSquarePhotosSection.allSquarePhotosSection.id,
        rank: dataAllSquarePhotosSection.allSquarePhotosSection.rank,
      });
    }

    if (dataAllYouTubeVideo && dataAllYouTubeVideo.allYouTubeVideo !== null) {
      fetchedSections.push({
        type: 'Video',
        id: dataAllYouTubeVideo.allYouTubeVideo.id,
        rank: dataAllYouTubeVideo.allYouTubeVideo.rank,
      });
    }

    const sortedSections = fetchedSections.sort((a, b) => a.rank - b.rank);
    setSections(sortedSections);
  }, [dataAllCarousels, dataAllFeatures, dataAllAnimatedProducts, dataAllRectangleBannerWide, dataAllRectangleBanner, dataAllSquarePhotosSection, dataAllYouTubeVideo]);

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    setSections((prevSections) => {
      const updatedSections = Array.from(prevSections);
      const [movedSection] = updatedSections.splice(oldIndex, 1);
      updatedSections.splice(newIndex, 0, movedSection);

      // Update the rank of each section
      const sortedSections = updatedSections.map((section, index) => ({
        ...section,
        rank: index + 1,
      }));

      return sortedSections;

    });
  };

  // Delete
  const [deleteConfPopup, setDeleteConfPopup] = useState(false)
  const [typeOfDelete, setTypeOfDelete] = useState("");
  const [selectedIdForDelete, setSelectedIdForDelete] = useState("");

  const confirmDelete = (id: string, type: string) => {
    setTypeOfDelete(type);
    setSelectedIdForDelete(id);
    setDeleteConfPopup(true)
  }

  const confirmEdit = (type: string) => {
    if (type === "Home Banner") {
      setEditHomeBannerPopup(true)
      return
    }

    if (type === "Products") {
      setEditProductsPopup(true)
      return
    }

    if (type === "Wide Banner") {
      setEditWideBannerPopup(true)
      return
    }

    if (type === "Banners") {
      setEditBannersPopup(true)
      return
    }

    if (type === "Video") {
      setEditVideosPopup(true)
      return
    }

    if (type === "Features") {
      setFeaturesPopup(true)
      return
    }

    if (type === "Images") {
      setImagesPopup(true)
      return
    }
  }

  const [updateCarousel, { error: carouselError }] = useMutation(UpdateCarousel);
  const [updateFeatures, { error: featuresError }] = useMutation(UpdateFeatures);
  const [updateAnimatedProducts, { error: animatedProductsError }] = useMutation(UpdateAnimatedProducts);
  const [updateRectangleBannerWide, { error: rectangleBannerWideError }] = useMutation(UpdateRectangleBannerWide);
  const [updateRectangleBanner, { error: rectangleBannerError }] = useMutation(UpdateRectangleBanner);
  const [updateSquarePhotosSection, { error: squarePhotosSectionError }] = useMutation(UpdateSquarePhotosSection);
  const [updateYouTubeVideo, { error: youTubeVideoError }] = useMutation(UpdateYouTubeVideo);

  const order = () => {
    sections.map(async (section) => {
      if (section.type === "Home Banner") {
        await updateCarousel({
          variables: {
            updateCarouselId: section.id,
            content: {
              idStore: storeData.id,
              rank: section.rank
            }
          }
        })
      }

      if (section.type === "Features") {
        const data: {
          description: string
          idStore: string,
          icon: string,
          rank: number,
          title: string,
        }[] = [];

        dataAllFeatures?.allFeatures.map(feature => (
          data.push({
            description: feature.description,
            idStore: storeData.id,
            icon: feature.icon,
            rank: section.rank,
            title: feature.title,
          })
        ));

        await updateFeatures({
          variables: {
            idStore: storeData.id,
            content: data
          }

        })
      }

      if (section.type === "Products") {
        await updateAnimatedProducts({
          variables: {
            updateAnimatedProductsId: section.id,
            content: {
              idStore: storeData.id,
              rank: section.rank
            }
          }
        })
      }

      if (section.type === "Wide Banner") {
        await updateRectangleBannerWide({
          variables: {
            updateRectangleBannerWideId: section.id,
            content: {
              idStore: storeData.id,
              rank: section.rank
            }
          }
        })
      }

      if (section.type === "Banners") {
        await updateRectangleBanner({
          variables: {
            content: [
              {
                id: dataAllRectangleBanner?.allRectangleBanner[0].id,
                rank: section.rank
              },
              {
                id: dataAllRectangleBanner?.allRectangleBanner[1].id,
                rank: section.rank
              }
            ]
          }
        })
      }

      if (section.type === "Images") {

        const data: { id: string }[] = []

        dataAllSquarePhotosSection?.allSquarePhotosSection.squarePhotos.map(photo => (
          data.push({
            id: photo.id
          })
        ))

        await updateSquarePhotosSection({
          variables: {
            content: {
              id: section.id,
              squarePhotos: data,
              rank: section.rank,
            }
          }
        })
      }

      if (section.type === "Video") {
        await updateYouTubeVideo({
          variables: {
            updateYouTubeVideoId: section.id,
            content: {
              idStore: storeData.id,
              rank: section.rank
            }
          }
        })
      }


      if (!youTubeVideoError && !carouselError && !squarePhotosSectionError && !featuresError && !animatedProductsError && !rectangleBannerWideError && !rectangleBannerError) {
        setSuccessMsg(t('updateSuccess'));
        setSuccess(true);
      }
    })
  }

  return (
    <section className='my-4 mx-3 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {homeBannerPopup && <AddHomeBanner setHomeBannerPopup={setHomeBannerPopup} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}
      {editHomeBannerPopup && <EditHomeBanner setEditHomeBannerPopup={setEditHomeBannerPopup} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      {featuresPopup && <AddFeatures setFeaturesPopup={setFeaturesPopup} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}
      {addProductsPopup && <AddProducts setAddProductsPopup={setAddProductsPopup} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}
      {editProductsPopup && <EditProducts setEditProductsPopup={setEditProductsPopup} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      {wideBannerPopup && <AddWideBanner setWideBannerPopup={setWideBannerPopup} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}
      {editWideBannerPopup && <EditWideBanner setEditWideBannerPopup={setEditWideBannerPopup} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      {bannersPopup && <AddBanners setBannersPopup={setBannersPopup} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}
      {editBannersPopup && <EditBaners setEditBannersPopup={setEditBannersPopup} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      {imagesPopup && <AddImages setImagesPopup={setImagesPopup} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      {videosPopup && <AddVideo setVideosPopup={setVideosPopup} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}
      {editVideosPopup && <EditYoutubeVideo setEditVideosPopup={setEditVideosPopup} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      {deleteConfPopup && <DeleteConfirmPopup setDeleteConfPopup={setDeleteConfPopup} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} selectedIdForDelete={selectedIdForDelete} typeOfDelete={typeOfDelete} />}

      {/* sections sidebar */}
      <div className={`fixed top-0 right-0 z-50 ${sectionsSideBar ? "w-full" : "w-0"} h-screen bg-gray-800/50 overflow-hidden`}>
        <div className={`absolute top-0 right-0 h-screen bg-white ${sectionsSideBar ? "w-80" : "w-0"} transition-all delay-200 duration-500 overflow-hidden`}>
          <div className='flex flex-col h-full bg-gray-100'>
            <div className='flex items-center justify-between p-4 bg-white'>
              <h3 className='text-lg'>
                {t('sections')}
              </h3>

              <span onClick={() => setSectionsSideBar(false)} className='text-red-500 cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm4.207 12.793-1.414 1.414L12 13.414l-2.793 2.793-1.414-1.414L10.586 12 7.793 9.207l1.414-1.414L12 10.586l2.793-2.793 1.414 1.414L13.414 12l2.793 2.793z"></path>
                </svg>
              </span>
            </div>

            <div className='flex-1 overflow-y-auto'>
              <div className='m-3'>
                <div className='relative'>
                  {dataAllCarousels?.allCarousels.length !== 0 &&
                    <div className='absolute top-0 left-0 z-30 w-full h-full bg-white/70'></div>
                  }
                  <div onClick={() => { setSectionsSideBar(false); setHomeBannerPopup(true); }} className='mb-5 bg-white rounded-md cursor-pointer'>
                    <h3 className='p-2 text-lg border-b'>{t('Home Banner')}</h3>
                    <div className='p-2'>
                      <img src={movingBanner} alt="banner" />
                    </div>
                  </div>
                </div>

                <div onClick={() => { setSectionsSideBar(false); setFeaturesPopup(true); }} className='mb-5 bg-white rounded-md cursor-pointer'>
                  <h3 className='p-2 text-lg border-b'>{t('Features')}</h3>
                  <div className='p-2'>
                    <img src={features} alt="features" />
                  </div>
                </div>

                <div className='relative'>
                  {dataAllAnimatedProducts?.allAnimatedProducts.length !== 0 &&
                    <div className='absolute top-0 left-0 z-30 w-full h-full bg-white/70'></div>
                  }
                  <div onClick={() => { setSectionsSideBar(false); setAddProductsPopup(true); }} className='mb-5 bg-white rounded-md cursor-pointer'>
                    <h3 className='p-2 text-lg border-b'>{t('Products')}</h3>
                    <div className='p-2'>
                      <img src={homeProducts} alt="homeProducts" />
                    </div>
                  </div>
                </div>

                <div className='relative'>
                  {dataAllRectangleBannerWide?.allRectangleBannerWide !== null &&
                    <div className='absolute top-0 left-0 z-30 w-full h-full bg-white/70'></div>
                  }
                  <div onClick={() => { setSectionsSideBar(false); setWideBannerPopup(true); }} className='mb-5 bg-white rounded-md cursor-pointer'>
                    <h3 className='p-2 text-lg border-b'>{t('Wide Banner')}</h3>
                    <div className='p-2'>
                      <img src={bnner} alt="banner" />
                    </div>
                  </div>
                </div>

                <div className='relative'>
                  {dataAllRectangleBanner?.allRectangleBanner.length !== 0 &&
                    <div className='absolute top-0 left-0 z-30 w-full h-full bg-white/70'></div>
                  }
                  <div onClick={() => { setSectionsSideBar(false); setBannersPopup(true); }} className='mb-5 bg-white rounded-md cursor-pointer'>
                    <h3 className='p-2 text-lg border-b'>{t('Banners')}</h3>
                    <div className='p-2'>
                      <img src={banner02} alt="banner02" />
                    </div>
                  </div>
                </div>

                <div onClick={() => { setSectionsSideBar(false); setImagesPopup(true); }} className='mb-5 bg-white rounded-md cursor-pointer'>
                  <h3 className='p-2 text-lg border-b'>{t('Images')}</h3>
                  <div className='p-2'>
                    <img src={square} alt="square" />
                  </div>
                </div>

                <div className='relative'>
                  {dataAllYouTubeVideo?.allYouTubeVideo !== null &&
                    <div className='absolute top-0 left-0 z-30 w-full h-full bg-white/70'></div>
                  }
                  <div onClick={() => { setSectionsSideBar(false); setVideosPopup(true); }} className='mb-5 bg-white rounded-md cursor-pointer'>
                    <h3 className='p-2 text-lg border-b'>{t('Youtube Video')}</h3>
                    <div className='p-2'>
                      <img src={youtube} alt="youtube" />
                    </div>
                  </div>
                </div>

                {/* <div className='mb-5 bg-white rounded-md cursor-pointer'>
                  <h3 className='p-2 text-lg border-b'>{t('Reviews')}</h3>
                  <div className='p-2'>
                    <img src={testimonials} alt="testimonials" />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('sections')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div className='p-2 mt-3 bg-white rounded-md'>
        <span>
          {t('customizedStoreSections')}
        </span>

        <div className='my-3'>
          <button onClick={() => setSectionsSideBar(true)} className='inline-flex items-center justify-center w-full px-4 py-2 my-2 overflow-hidden text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto hover:bg-primaryHover'>
            <AiOutlineAppstoreAdd size={20} />

            <span className="mx-2">
              {t('addSection')}
            </span>
          </button>
        </div>

        {initialLoading ?
          <LoadingWidget />
          :
          <>
            {sections.length !== 0 ?
              <div>
                <SortableList sections={sections} onSortEnd={onSortEnd} />

                <div className='flex justify-end mt-4 mb-2'>
                  <div onClick={(e) => { order() }} >
                    <button className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                      <span className="mx-2">
                        {t('save')}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              :
              <div className='flex items-center justify-center py-12'>
                <div className='flex flex-col items-center gap-3'>
                  <svg xmlns="http://www.w3.org/2000/svg" className='text-gray-400' width="35" height="35" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12.884 2.532c-.346-.654-1.422-.654-1.768 0l-9 17A.999.999 0 0 0 3 21h18a.998.998 0 0 0 .883-1.467L12.884 2.532zM13 18h-2v-2h2v2zm-2-4V9h2l.001 5H11z"></path>
                  </svg>
                  <p>{t('noSections')}</p>
                </div>
              </div>
            }
          </>
        }
      </div >
    </section >
  )
}



export default Sections