import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'
import { ClientsProps, FetchArchivedClients } from '../../../../types';
import { useAllClientArchived } from '../../../../graphql';
import useStore from '../../../../store/useStore';
import ArchivedClientsTable from '../../../../components/Tables/SettingsTables/ArchivedClientsTable';
import LoadingWidget from '../../../../components/LoadingWidget/LoadingWidget';
import SuccessAlert from '../../../../components/Alerts/SuccessAlert';
import FailAlert from '../../../../components/Alerts/FailAlert';
import RestoreclientPopup from './RestoreclientPopup';

interface Props {
  direction: string
}

const ClientsArchive = ({ direction }: Props) => {
  const storeData = useStore((state: any) => state.userData.store);

  // The state that contains Fetched Client, i didn't use dataAllClients from useAllClients beacuse i couldn't modify it to contain filtered result
  const [Clients, setClients] = useState<FetchArchivedClients>();

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [allClientsArchived, { loading, data: dataAllClientsArchived }] = useAllClientArchived();

  useEffect(() => {
    storeData &&
      (async () => {
        await allClientsArchived({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()

  }, [storeData]);

  useEffect(() => {
    storeData && dataAllClientsArchived !== undefined && setClients(dataAllClientsArchived)
  }, [dataAllClientsArchived, storeData]);

  // Search functionality
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState<ClientsProps[] | undefined>([]);

  useEffect(() => {
    if (storeData) {
      const filtered = dataAllClientsArchived?.allClientArchived?.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setSearchResult(filtered);
    }
  }, [searchQuery, dataAllClientsArchived, storeData]);

  // I did scroll to the top because when the component is render it show the bottom of the page because the layout
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Alerts
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [restoreConfirmPopup, setRestoreConfirmPopup] = useState(false);
  // This state contains the id of the client that will be deleted
  const [selectedIdForRestore, setSelectedIdForRestore] = useState("");

  return (
    <section className='my-4 mx-3 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {restoreConfirmPopup && <RestoreclientPopup setRestoreConfirmPopup={setRestoreConfirmPopup} selectedIdForRestore={selectedIdForRestore} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('clientsArchive')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        <div className='p-2 mt-3 bg-white rounded-md'>
          <div>
            <div className='my-4 md:flex md:justify-between'>
              <div className="relative flex items-center w-full h-12 overflow-hidden bg-white border rounded-lg md:w-2/6">
                <div className="grid w-12 h-full text-gray-300 place-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>

                <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="w-full h-full pr-2 text-sm text-gray-700 outline-none peer" type="text" id="search" placeholder={t('searchSomething')} />
              </div>
            </div>
          </div>

          <div className='pt-2 overflow-x-scroll'>
            <ArchivedClientsTable direction={direction} searchQuery={searchQuery} searchResult={searchResult} allClients={Clients} setSelectedIdForRestore={setSelectedIdForRestore} setRestoreConfirmPopup={setRestoreConfirmPopup}/>
          </div>
        </div>
      }

      {dataAllClientsArchived?.allClientArchived.length === 0 &&
        <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
            <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
          </svg>
          <span className='px-2'>{t('noClients')}</span>
        </div>
      }

      {searchQuery !== "" &&
        searchResult !== undefined &&
        searchResult.length === 0 &&
        <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
            <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
          </svg>
          <span className='px-2'>{t('notMatch')}</span>
        </div>
      }
    </section>
  )
}

export default ClientsArchive