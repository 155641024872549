import { gql, useLazyQuery } from "@apollo/client"
import { FetchOrdersStatistics } from "../../types";

export const ORDER_STATISTICS_STORE = gql`
query OrderStatisticsStore($idStore: ID, $firstDay: String, $lastDay: String) {
  orderStatisticsStore(
    idStore: $idStore
    firstDay: $firstDay
    lastDay: $lastDay
  ) {
    day
    totalOrders
  }
}

`
export const useOrderStatistics = () => {
  return useLazyQuery<FetchOrdersStatistics>(ORDER_STATISTICS_STORE, {
    fetchPolicy: 'cache-and-network',
  });
}