import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react'

const AbandonedCartDropDown = ({ id, direction }: { id: String, direction: string }) => {
  const [isDropDownOpen, setDropDownStatus] = useState(false);
  const ref = useRef<any>();

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (ref.current) {
        if (ref.current.contains(e.target as Node)) {
          return
        }

        // else close the dropdown
        setDropDownStatus(false);
      }
    })
  }, [])

  return (
    <div ref={ref}>
      <button onClick={() => { setDropDownStatus(!isDropDownOpen); }} className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
        </svg>
      </button>
      <div className={`${!isDropDownOpen && "hidden"} absolute ${direction === "ltr" ? 'right-3' : 'left-3'} z-20 w-48 py-2 mt-2 origin-top-right bg-white rounded-md shadow-xl border`}>
        <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
            <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
          </svg>

          <span className="mx-1">
            {t('delete')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default AbandonedCartDropDown