import { gql, useLazyQuery } from '@apollo/client';
import { FetchSuggestDomainStore } from '../../../types';

export const SuggestDomainStore = gql`
query AllDomainsStore($idStore: ID) {
  allDomainsStore(idStore: $idStore) {
    domain
    available
    currency
    definitive
    period
    price
    status
    loginInformation {
      url
      username
      password
    }
    store {
      id
      name
      link
      logo
      icon
      description
      address
      welcomeMessage
      cssFile
      listSubscription {
        subscription {
          id
          planName
          price
          discount
          features
          published
          createdAt
          updatedAt
          deletedAt
        }
        startDate
        endDate
        price
        createdAt
        updatedAt
        deletedAt
      }
      numberProducts
      numberOrders
      numberClients
      createdAt
      updatedAt
      deletedAt
    }
  }
}
`;

export const useDomainStore = () => {
  return useLazyQuery<FetchSuggestDomainStore>(SuggestDomainStore);
}