import React, { useEffect, useRef, useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';
import useStore from '../../store/useStore';
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import FailAlert from '../../components/Alerts/FailAlert';
import { useMutation } from '@apollo/client';
import { ALL_MARKETERS, CreateMarketer } from '../../graphql';
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget';
import PermissionsError from '../../components/Alerts/PermissionsError';
import { useNavigate } from 'react-router-dom';

const AddMarketer = () => {
  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [permissions, setPermissions] = useState({
    create: false,
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          create: true,
        })

        setInitialLoading(false)
        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "marketing")

      let createPermission = filtered[0]?.permissions.includes("create")

      setPermissions({
        create: createPermission || false
      })
      setInitialLoading(false)
    }
  }, [userData])

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [otherInfo, setOtherInfo] = useState("");
/*   const [marketingMethod, setMarketingMethod] = useState("");
  const [commisionType, setCommisionType] = useState(""); */

  const [value, setValue] = useState<number>(0);

  /* const TypeRef = useRef<any>(); */

  // Create
  const [createMarketer, { error }] = useMutation(CreateMarketer, {
    refetchQueries: [{
      query: ALL_MARKETERS,
      variables: { idStore: storeData?.id }
    }]
  });

  const navigate = useNavigate();

  const submit = () => {
    if (name === "") {
      setFailMsg(t('emptyClientNameAlert'));
      setFail(true);
      return
    }

    if (email === "") {
      setFailMsg(t('emptyEmailAlert'));
      setFail(true);
      return
    }

    if (phone === "") {
      setFailMsg(t('emptyPhoneAlert'));
      setFail(true);
      return
    }

    /*     

    if (marketingMethod === "") {
      setFailMsg(t('emptyMarketingMethodAlert'));
      setFail(true);
      return
    }

    if (commisionType === "") {
          setFailMsg(t('emptyCommisionTypeAlert'));
          setFail(true);
          return
        } */

    if (value === 0) {
      setFailMsg(t('emptyValue'));
      setFail(true);
      return
    }

    createMarketer({
      variables: {
        content: {
          email: email,
          name: name,
          phone: phone,
          otherInfo: otherInfo,
          value: value,
          idStore: storeData.id
        }
      }
    })

    if (!error) {
      setSuccessMsg(t('marketerAddSuccess'));
      setSuccess(true);

      window.setTimeout(() => {
        navigate(`/affiliate-marketing`);
      }, 1000);
    }
  }

  return (
    <section className='mx-3 my-4 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('addMarketer')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {
        initialLoading ?
          <LoadingWidget />
          :
          permissions.create === false ?
            <PermissionsError />
            :
            <form className='px-5 py-2 my-2 bg-white rounded-md'>
              <div className='grid grid-cols-2 gap-4 mb-2'>
                <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">{t('name')}</label>
                  <input type="text" value={name} onChange={(e) => setName(e.target.value)} id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('name')} required />
                </div>
                <div>
                  <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">{t('phone')}</label>
                  <input type="text" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('phone')} required />
                </div>
              </div>

              <div>
                <div className="mb-2">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">{t('email')}</label>
                  <input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('email')} required />
                </div>
              </div>

              <div>
                <div className="mb-2">
                  <label htmlFor="notice" className="block mb-2 text-sm font-medium text-gray-900">{t('moreInformation')}</label>
                  <textarea id="notice" value={otherInfo} onChange={(e) => setOtherInfo(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('moreInformation')} required />
                </div>
              </div>

              <div className='my-6'>
                <h3>{t('marketerCommission')}</h3>
              </div>

              {/* <div className='mt-4'>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900">{t('selectCommissionType')}</label>

            <div className="relative" ref={TypeRef}>
              <button onClick={(e) => { e.preventDefault(); setTypeDrop(!typeDrop) }} className="z-10 flex items-center justify-between w-full p-2 text-left text-gray-700 border border-gray-300 rounded-md bg-gray-50 focus:border-primary focus:outline-none">
                <span>
                  {commisionType !== "" ? marketingMethod : t('select')}
                </span>

                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M16.939 10.939 12 15.879l-4.939-4.94-2.122 2.122L12 20.121l7.061-7.06z"></path>
                  <path d="M16.939 3.939 12 8.879l-4.939-4.94-2.122 2.122L12 13.121l7.061-7.06z"></path>
                </svg>
              </button>
              {typeDrop &&
                <div className="absolute right-0 z-20 w-full py-2 mt-2 origin-top-right bg-white border border-gray-300 rounded-md shadow-xl">
                  <span onClick={() => { setCommisionType("Company profile"); setTypeDrop(false) }} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                    <span className="mx-1">
                      Company profile
                    </span>
                  </span>
                  <span onClick={() => { setCommisionType("Team"); setTypeDrop(false) }} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                    <span className="mx-1">
                      Team
                    </span>
                  </span>
                  <span onClick={() => { setCommisionType("Help"); setTypeDrop(false) }} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                    <span className="mx-1">
                      Help
                    </span>
                  </span>
                </div>
              }
            </div>
          </div>
        </div> */}

              <div>
                <div className="mt-2">
                  <label htmlFor="value" className="block mb-2 text-sm font-medium text-gray-900">{t('commitionValue')} %</label>
                  <input type="number" min={0} value={value} onChange={(e) => setValue(parseInt(e.target.value))} id="value" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder='Value' required />
                </div>
              </div>

              <div className='flex justify-end pt-5'>
                <button onClick={(e) => { e.preventDefault(); submit() }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                    <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                  </svg>

                  <span className="mx-2">
                    {t('addMarketer')}
                  </span>
                </button>
              </div>
            </form>
      }
    </section>
  )
}

export default AddMarketer