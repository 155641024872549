import { gql } from "@apollo/client";

export const CreateCarousel = gql`
mutation CreateCarousel($content: contentCarousel!) {
  createCarousel(content: $content) {
    id
    type
    title
    description
    link
    image
    rank
    createdAt
    updatedAt
    deletedAt
  }
}
`;
