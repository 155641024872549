import { gql } from '@apollo/client';

export const PaymentMethod = gql`
query PaymentMethod($paymentMethodId: ID) {
  paymentMethod(id: $paymentMethodId) {
    id
    bankName
    accountName
    number
    IBAN
    country {
      id
      name
      currency
      asDefault
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`;