import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client';
import { useAllInvoice, CreateInvoice, UpdateInvoice } from '../../../graphql';
import SuccessAlert from '../../../components/Alerts/SuccessAlert';
import FailAlert from '../../../components/Alerts/FailAlert';
import LoadingWidget from '../../../components/LoadingWidget/LoadingWidget';
import useStore from '../../../store/useStore';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';
import PermissionsError from '../../../components/Alerts/PermissionsError';

const BillSettings = () => {
  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    view: false,
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          view: true,
        })

        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "settings")

      let viewPermission = filtered[0]?.permissions.includes("bill settings")

      setPermissions({
        view: viewPermission || false,
      })

    }
  }, [userData])

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  // I did scroll to the top because when the component is render it show the bottom of the page because the layout
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [send, setSend] = useState(false);

  const [QR, setQR] = useState(false);
  const [status, setStatus] = useState(false);
  const [image, setImage] = useState(false);
  const [coupon, setCoupon] = useState(false);
  const [address, setAddress] = useState(false);

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [allInvoice, { loading, data: dataAllInvoice }] = useAllInvoice();

  useEffect(() => {
    storeData &&
      (async () => {
        await allInvoice({
          variables: { idStore: storeData.id }
        })
      })()

  }, [storeData]);

  useEffect(() => {
    if (dataAllInvoice?.allInvoice !== undefined && dataAllInvoice?.allInvoice !== null) {
      setSend(dataAllInvoice?.allInvoice.send)
      setQR(dataAllInvoice?.allInvoice.codeQR)
      setImage(dataAllInvoice?.allInvoice.image)
      setCoupon(dataAllInvoice?.allInvoice.coupon)
      setAddress(dataAllInvoice?.allInvoice.address)

      setInitialLoading(false)
    }
  }, [dataAllInvoice])

  const [createInvoice, { error }] = useMutation(CreateInvoice);
  const [updateInvoice, { error: updateError }] = useMutation(UpdateInvoice);

  const Submit = async (e: any) => {
    e.preventDefault();

    if (dataAllInvoice?.allInvoice === null) {
      await createInvoice({
        variables: {
          content: {
            address: address,
            codeQR: QR,
            coupon: coupon,
            idStore: storeData.id,
            image: image,
            send: send,
            status: status
          }
        }
      })

      if (!error) {
        setSuccessMsg(t('billAddedSuccess'));
        setSuccess(true);
      }
    } else {
      await updateInvoice({
        variables: {
          updateInvoiceId: dataAllInvoice?.allInvoice.id,
          content: {
            address: address,
            codeQR: QR,
            coupon: coupon,
            idStore: storeData.id,
            image: image,
            send: send,
            status: status
          }
        }
      })

      if (!updateError) {
        setSuccessMsg(t('billUpdatedSuccess'));
        setSuccess(true);
      }
    }
  }

  return (
    <section className='mx-3 my-4 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('billSettings')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading ?
        <LoadingWidget />
        :
        permissions.view === false ?
          <PermissionsError />
          :
          <>
            <div className='p-2 mt-3 bg-white rounded-md'>
              {initialLoading || loading ?
                <LoadingWidget />
                :
                <>
                  <span>
                    {t('sentBillsForCustomersQuastion')}
                  </span>
                  <form className='flex items-center gap-12 my-2.5' onSubmit={(e) => e.preventDefault()}>
                    <div className="flex items-center">
                      <input id="default-radio-1" checked={send === false} type="radio" onChange={(e) => setSend(false)} className="w-4 h-4 accent-primary" />
                      <label htmlFor="default-radio-1" className="mx-2 text-sm font-medium text-gray-900">{t('no')}</label>
                    </div>

                    <div className="flex items-center">
                      <input id="default-radio-2" checked={send === true} type="radio" onChange={(e) => setSend(true)} name="default-radio" className="w-4 h-4 accent-primary" />
                      <label htmlFor="default-radio-2" className="mx-2 text-sm font-medium text-gray-900">{t('yes')}</label>
                    </div>
                  </form>
                  <div className='flex justify-end my-2'>
                    <div>
                      <button onClick={(e) => { Submit(e) }} className='inline-flex items-center justify-center px-4 py-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary hover:bg-primaryHover'>
                        <span className="mx-2">
                          {t('save')}
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              }
            </div>

            <div className='p-2 mt-3 bg-white rounded-md'>
              {initialLoading ?
                <LoadingWidget />
                :
                loading ?
                  <LoadingWidget />
                  :
                  <>
                    <div className='flex flex-col gap-3 px-2 py-4'>
                      <div className="relative inline-flex items-center">
                        <div className="h-6 bg-gray-200 rounded-full cursor-pointer w-11" onClick={() => { setQR(!QR) }}>
                          <div className={`w-6 h-6 bg-primary ${QR && "ml-6"} duration-200 rounded-full`}></div>
                        </div>
                        <span className="mx-3 text-sm font-medium text-gray-900">{t('QRCode')}</span>
                      </div>

                      <div className="relative inline-flex items-center">
                        <div className="h-6 bg-gray-200 rounded-full cursor-pointer w-11" onClick={() => { setStatus(!status) }}>
                          <div className={`w-6 h-6 bg-primary ${status && "ml-6"} duration-200 rounded-full`}></div>
                        </div>
                        <span className="mx-3 text-sm font-medium text-gray-900">{t('orderStatus')}</span>
                      </div>

                      <div className="relative inline-flex items-center">
                        <div className="h-6 bg-gray-200 rounded-full cursor-pointer w-11" onClick={() => { setImage(!image) }}>
                          <div className={`w-6 h-6 bg-primary ${image && "ml-6"} duration-200 rounded-full`}></div>
                        </div>
                        <span className="mx-3 text-sm font-medium text-gray-900">{t('productImage')}</span>
                      </div>

                      <div className="relative inline-flex items-center">
                        <div className="h-6 bg-gray-200 rounded-full cursor-pointer w-11" onClick={() => { setCoupon(!coupon) }}>
                          <div className={`w-6 h-6 bg-primary ${coupon && "ml-6"} duration-200 rounded-full`}></div>
                        </div>
                        <span className="mx-3 text-sm font-medium text-gray-900">{t('discountCoupon')}</span>
                      </div>

                      <div className="relative inline-flex items-center">
                        <div className="h-6 bg-gray-200 rounded-full cursor-pointer w-11" onClick={() => { setAddress(!address) }}>
                          <div className={`w-6 h-6 bg-primary ${address && "ml-6"} duration-200 rounded-full`}></div>
                        </div>
                        <span className="mx-3 text-sm font-medium text-gray-900">{t('storeAddress')}</span>
                      </div>
                    </div>

                    <div className='flex justify-end my-2'>
                      <div>
                        <button onClick={(e) => { Submit(e) }} className='inline-flex items-center justify-center px-4 py-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary hover:bg-primaryHover'>
                          <span className="mx-2">
                            {t('save')}
                          </span>
                        </button>
                      </div>
                    </div>
                  </>
              }
            </div>
          </>
      }
    </section>
  )
}

export default BillSettings