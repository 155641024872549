import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'
import { RiPaintBrushFill } from 'react-icons/ri'
import { LuLayers } from 'react-icons/lu'
import { HiColorSwatch } from 'react-icons/hi'
import { AiOutlineFontColors } from 'react-icons/ai'
import { FaThList } from 'react-icons/fa'
import { GoCodeSquare } from 'react-icons/go'
import useStore from '../../../store/useStore'
import LoadingWidget from '../../../components/LoadingWidget/LoadingWidget'
import PermissionsError from '../../../components/Alerts/PermissionsError'

const StoreDesign = () => {
  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);
  
  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    view: false,
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          view: true,
        })

        setInitialLoading(false)
        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "settings")

      let viewPermission = filtered[0]?.permissions.includes("store design")

      setPermissions({
        view: viewPermission || false,
      })

      setInitialLoading(false)
    }
  }, [userData])

  // I did scroll to the top because when the component is render it show the bottom of the page because the layout
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <section className='mx-3 my-4 md:mx-7'>
      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('storeDesign')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {
        initialLoading ?
          <LoadingWidget />
          :
          permissions.view === false ?
            <PermissionsError />
            :
            <div className='grid grid-cols-2 gap-4 mt-2 md:grid-cols-3 lg:grid-cols-4'>
              <div>
                <Link to="/settings/store-design/theme">
                  <div className="h-full p-6 bg-white rounded-lg shadow">
                    <div className='flex flex-col items-center h-full'>
                      <div className="p-2 mb-2 text-primary">
                        <RiPaintBrushFill size={36} />
                      </div>
                      <div className='flex flex-col items-center justify-between h-full'>
                        <h2 className="mb-1 text-xl text-center title-font">{t('themeStore')}</h2>
                        <p className="pt-2 text-sm text-center text-gray-400">{t('themeSettings')}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div>
                <Link to="/settings/store-design/sections">
                  <div className="h-full p-6 bg-white rounded-lg shadow">
                    <div className='flex flex-col items-center h-full'>
                      <div className="p-2 mb-2 text-primary">
                        <LuLayers size={36} />
                      </div>
                      <div className='flex flex-col items-center justify-between h-full'>
                        <h2 className="mb-1 text-xl text-center title-font">{t('customizedStoreSections')}</h2>
                        <p className="pt-2 text-sm text-center text-gray-400">{t('customizedSectionsDescription')}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div>
                <Link to="/settings/store-design/colors">
                  <div className="h-full p-6 bg-white rounded-lg shadow">
                    <div className='flex flex-col items-center h-full'>
                      <div className="p-2 mb-2 text-primary">
                        <HiColorSwatch size={36} />
                      </div>
                      <div className='flex flex-col items-center justify-between h-full'>
                        <h2 className="mb-1 text-xl text-center title-font">{t('colors')}</h2>
                        <p className="pt-2 text-sm text-center text-gray-400">{t('settingStoreColors')}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div>
                <Link to="/settings/store-design/fonts">
                  <div className="h-full p-6 bg-white rounded-lg shadow">
                    <div className='flex flex-col items-center h-full'>
                      <div className="p-2 mb-2 text-primary">
                        <AiOutlineFontColors size={36} />
                      </div>
                      <div className='flex flex-col items-center justify-between h-full'>
                        <h2 className="mb-1 text-xl text-center title-font">{t('fonts')}</h2>
                        <p className="pt-2 text-sm text-center text-gray-400">{t('fontsManagement')}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div>
                <Link to="/settings/store-design/navbar">
                  <div className="h-full p-6 bg-white rounded-lg shadow">
                    <div className='flex flex-col items-center h-full'>
                      <div className="p-2 mb-2 text-primary">
                        <FaThList size={36} />
                      </div>
                      <div className='flex flex-col items-center justify-between h-full'>
                        <h2 className="mb-1 text-xl text-center title-font">{t('navbar')}</h2>
                        <p className="pt-2 text-sm text-center text-gray-400">{t('navbarSettings')}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div>
                <Link to="/settings/store-design/css">
                  <div className="h-full p-6 bg-white rounded-lg shadow">
                    <div className='flex flex-col items-center h-full'>
                      <div className="p-2 mb-2 text-primary">
                        <GoCodeSquare size={36} />
                      </div>
                      <div className='flex flex-col items-center justify-between h-full'>
                        <h2 className="mb-1 text-xl text-center title-font">CSS</h2>
                        <p className="pt-2 text-sm text-center text-gray-400">{t('writingCSScodes')}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
      }
    </section>
  )
}

export default StoreDesign