import { gql } from '@apollo/client';

export const CreateInvoice = gql`
mutation CreateInvoice($content: contentInvoice!) {
  createInvoice(content: $content) {
    id
    send
    codeQR
    status
    image
    coupon
    address
    createdAt
    updatedAt
    deletedAt
  }
}
`;
