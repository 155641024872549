import React from 'react'
import {
  Chart as ChartJS,
  registerables
} from 'chart.js/auto'

import { Bar } from 'react-chartjs-2';
import { t } from 'i18next';
import { FetchSaleStatistics } from '../../types';

ChartJS.register(
  ...registerables
);

//const labels = ["10am", "11am", "12am", "01pm", "02pm", "03pm", "04pm", "05pm", "06pm", "07pm"];

const ChartLine = ({ dataSaleStatistics }: { dataSaleStatistics: FetchSaleStatistics | undefined }) => {

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Set time to midnight for accurate comparison
  const endDate = currentDate.toISOString().split('T')[0];

  const labels = Array.from({ length: 7 }, (_, index) => {
    const currentLabelDate = new Date(endDate);
    currentLabelDate.setDate(currentLabelDate.getDate() - index);
    return currentLabelDate.toISOString().split('T')[0];
  });

  const dataSet = labels.map(label => {
    const matchingData = dataSaleStatistics?.saleStatisticsStore.find(item => item.day === label);
    return matchingData ? matchingData.totalSales : 0;
  });

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Sales",
        backgroundColor: "#7951F9",
        borderColor: "#7951F9",
        data: dataSet,
        tension: 0.3
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          min: 0,
          stepSize: 100
        }
      },
      x: {
        grid: {
          display: false,
        },
      },
    }
  }

  return (
    <div>
      <h3 className='px-6 py-3 text-2xl font-semibold text-black/70'>{t('salesDay')}</h3>
      <Bar data={data} options={options} className='px-12 py-6' />
    </div>
  )
}

export default ChartLine