import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'

const SmsSettings = () => {
  // I did scroll to the top because when the component is render it show the bottom of the page because the layout
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [activeTab, setActiveTab] = useState(1)

  return (
    <section className='my-4 mx-3 md:mx-7'>
      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('SMSSettings')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div className='p-2 mt-3 bg-white rounded-md'>
        <div className='grid grid-cols-2'>
          <div className='w-full'>
            <div>
              <button onClick={() => setActiveTab(1)} className={`flex items-center justify-center w-full px-4 py-2 my-2 overflow-hidden transition-colors duration-100 border ${activeTab === 1 ? "bg-primary text-white" : "bg-white"}`}>
                {t('SMSSettings')}
              </button>
            </div>
          </div>
          <div className='w-full'>
            <div>
              <button onClick={() => setActiveTab(2)} className={`flex items-center justify-center w-full px-4 py-2 my-2 overflow-hidden transition-colors duration-100 border ${activeTab === 2 ? "bg-primary text-white" : "bg-white"}`}>
                {t('bookSenderName')}
              </button>
            </div>
          </div>
        </div>

        <div className='my-2'>
          {activeTab === 1 &&
            <div>
              <div>
                <div className='grid grid-cols-3 gap-2'>
                  <div className='flex items-center justify-center col-span-1 px-2 py-1 border-2 border-dotted border-icons'>
                    {t('senderName')}: <span className='font-bold'>Matajer</span>
                  </div>
                  <div className='col-span-2 px-2 py-1 border-2 border-dotted border-icons'>
                    {t('currentBalance')}: <span className='ml-px font-bold'>0 {t('message')}</span>
                  </div>
                </div>
                <div className='flex justify-end my-3'>
                  <button className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                      <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                    </svg>

                    <span className="mx-2">
                      {t('chargeYourBalance')}
                    </span>
                  </button>
                </div>
              </div>

              <div className='px-3 py-1 my-2 border'>
                <p>{t('storeEntry')}</p>
                <div className="flex items-center">
                  <input type="checkbox" defaultChecked className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <label className="mx-2 font-medium text-gray-800">{t('activateOTP')}</label>
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </div>


              <div className='px-3 py-1 my-2 border'>
                <p>{t('notificationsOrderCases')}</p>
                <div className="flex items-center my-1">
                  <input type="checkbox" defaultChecked className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <label className="mx-2 font-medium text-gray-800">{t('all')}</label>
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>

                <div className="flex items-center my-1">
                  <input type="checkbox" className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <label className="mx-2 font-medium text-gray-800">{t('newOrder')}</label>
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>

                <div className="flex items-center my-1">
                  <input type="checkbox" className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <label className="mx-2 font-medium text-gray-800">{t('processed')}</label>
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>

                <div className="flex items-center my-1">
                  <input type="checkbox" className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <label className="mx-2 font-medium text-gray-800">{t('readyToShip')}</label>
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>

                <div className="flex items-center my-1">
                  <input type="checkbox" className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <label className="mx-2 font-medium text-gray-800">{t('deliveryInProgress')}</label>
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>

                <div className="flex items-center my-1">
                  <input type="checkbox" className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <label className="mx-2 font-medium text-gray-800">{t('orderDelivered')}</label>
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>

                <div className="flex items-center my-1">
                  <input type="checkbox" className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <label className="mx-2 font-medium text-gray-800">{t('orderCanceled')}</label>
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <div className="flex items-center my-1">
                  <input type="checkbox" className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <label className="mx-2 font-medium text-gray-800">{t('assessmentOrder')}</label>
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </div>

              <div className='flex justify-end my-2'>
                <button className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  {t('save')}
                </button>
              </div>
            </div>
          }

          {activeTab === 2 &&
            <div>
              <div className='grid gap-5 my-5 grid-col-1 sm:grid-cols-2 lg:grid-cols-3'>
                <div className='bg-white border'>
                  <div className='flex justify-center p-10'>
                    <img src="https://cdn.mapp.sa/cPanel/images/msegat.png" className='h-14' alt="" />
                  </div>
                  <div className='grid items-center grid-cols-2 py-2 border-t'>
                    <div className='flex justify-center border-r'>
                      <button className='text-sm'>
                        {t('register')}
                      </button>
                    </div>
                    <div className='flex justify-center'>
                      <button className='text-sm'>
                        {t('activate')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default SmsSettings