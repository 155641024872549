import React, { useEffect, useRef, useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';
import useStore from '../../store/useStore';
import {
  useBestSeller,
  useClientStatistics,
  useCompletedOrdersStatistics,
  useOrderStatistics,
  useReportsStores,
  useSaleStatistics
} from '../../graphql';
import OrdersChart from '../../components/Charts/OrdersChart';
import SalesChart from '../../components/Charts/SalesChart';
import ClientChart from '../../components/Charts/ClientChart';
import CompletedOrdersChart from '../../components/Charts/CompletedOrdersChart';
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget';
import PermissionsError from '../../components/Alerts/PermissionsError';

const Reports = () => {
  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    view: false,
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          view: true,
        })

        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "report")

      let viewPermission = filtered[0]?.permissions.includes("view")

      setPermissions({
        view: viewPermission || false
      })
    }
  }, [userData])

  const [dateDropDown, setDateDropDown] = useState(false);
  const [cityFilterDropDown, setCityFilterDropDown] = useState(false);

  const cityRef = useRef<any>();
  const dateRef = useRef<any>();

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [reportsStores, { loading: reportsStoresLoading, data: dataReportsStores }] = useReportsStores();
  const [orderStatistics, { loading: orderStatisticsLoading, data: dataOrderStatistics }] = useOrderStatistics();
  const [saleStatistics, { loading: saleStatisticsLoading, data: dataSaleStatistics }] = useSaleStatistics();
  const [clientsStatistics, { loading: clientStatisticsLoading, data: dataClientStatistics }] = useClientStatistics();
  const [completedOrdersStatistics, { loading: completedOrdersStatisticsLoading, data: dataCompletedOrdersStatistics }] = useCompletedOrdersStatistics();
  const [bestSeller, { loading: bestSellerLoading, data: dataBestSeller }] = useBestSeller();

  function getFormattedDates() {
    const today = new Date();

    // Current date
    const currentYear = today.getFullYear();
    const currentMonth = (today.getMonth() + 1).toString().padStart(2, '0');
    const currentDay = (today.getDate() + 1).toString().padStart(2, '0');
    const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

    // Date from a month ago
    const aMonthAgo = new Date();
    aMonthAgo.setMonth(today.getMonth() - 1);

    const monthAgoYear = aMonthAgo.getFullYear();
    const monthAgoMonth = (aMonthAgo.getMonth() + 1).toString().padStart(2, '0');
    const monthAgoDay = aMonthAgo.getDate().toString().padStart(2, '0');
    const monthAgoDate = `${monthAgoYear}-${monthAgoMonth}-${monthAgoDay}`;

    return { currentDate, monthAgoDate };
  }

  useEffect(() => {
    // Time period are one month
    const { currentDate, monthAgoDate } = getFormattedDates();

    const variable = {
      idStore: storeData?.id,
      lastDay: currentDate,
      firstDay: monthAgoDate,
    }

    storeData &&
      (async () => {
        await reportsStores({
          variables: {
            idStore: storeData.id
          }
        })

        await orderStatistics({
          variables: variable
        })

        await saleStatistics({
          variables: variable
        })

        await clientsStatistics({
          variables: variable
        })

        await completedOrdersStatistics({
          variables: variable
        })

        await bestSeller({
          variables: variable
        })

        setInitialLoading(false)
      })()
  }, [storeData]);

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (cityRef.current || dateRef.current) {
        if (cityRef.current.contains(e.target as Node)) {
          return
        }
        if (dateRef.current.contains(e.target as Node)) {
          return
        }

        // else close the dropdown
        setDateDropDown(false);
        setCityFilterDropDown(false);
      }
    })
  }, [])

  return (
    <section className='mx-3 my-4 md:mx-7'>
      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('reports')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {
        initialLoading ?
          <LoadingWidget />
          :
          permissions.view === false ?
            <PermissionsError />
            :
            <>
              <div className='p-2 mt-3 bg-white rounded-md'>
                {/* 
          <div className='flex flex-wrap gap-4'>
            <div className="relative inline-block" ref={dateRef}>
              <button onClick={() => { setCityFilterDropDown(false); setDateDropDown(!dateDropDown) }} className="z-10 flex items-center justify-between p-2 text-gray-700 bg-gray-100 border rounded-md w-36 focus:outline-none">
                <span className="mx-1">{t('time')}</span>
                <svg className="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z" fill="currentColor"></path>
                </svg>
              </button>

              {dateDropDown &&
                <div className="absolute right-0 z-20 w-full py-2 mt-2 origin-top-right bg-white border rounded-md shadow-xl">
                  <span className="block px-4 py-3 text-sm text-gray-600 capitalize duration-300 cursor-pointer hover:bg-gray-100">today</span>
                  <span className="block px-4 py-3 text-sm text-gray-600 capitalize duration-300 cursor-pointer hover:bg-gray-100">yesterday</span>
                  <span className="block px-4 py-3 text-sm text-gray-600 capitalize duration-300 cursor-pointer hover:bg-gray-100">last 7 days</span>
                  <span className="block px-4 py-3 text-sm text-gray-600 capitalize duration-300 cursor-pointer hover:bg-gray-100">last 30 days</span>
                  <span className="block px-4 py-3 text-sm text-gray-600 capitalize duration-300 cursor-pointer hover:bg-gray-100">1 year</span>
                  <span className="block px-4 py-3 text-sm text-gray-600 capitalize duration-300 cursor-pointer hover:bg-gray-100">all</span>
                </div>
              }
            </div> 


            <div className="relative inline-block" ref={cityRef}>
              <button onClick={() => { setDateDropDown(false); setCityFilterDropDown(!cityFilterDropDown) }} className="z-10 flex items-center justify-between p-2 text-gray-700 bg-gray-100 border rounded-md w-36 focus:outline-none">
                <span className="mx-1">{t('city')}</span>
                <svg className="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z" fill="currentColor"></path>
                </svg>
              </button>

              {cityFilterDropDown &&
                <div className="absolute right-0 z-20 w-full py-2 mt-2 origin-top-right bg-white border rounded-md shadow-xl">
                  <a href="/" className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">saudi arabia</a>
                  <a href="/" className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">yemen</a>
                </div>
              }
            </div> 
          </div>
        */}

                <div className='px-2 py-4'>
                  <div className='flex items-center justify-between px-3 py-3 text-gray-600 border-b'>
                    <div className='flex items-center gap-3'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M21.822 7.431A1 1 0 0 0 21 7H7.333L6.179 4.23A1.994 1.994 0 0 0 4.333 3H2v2h2.333l4.744 11.385A1 1 0 0 0 10 17h8c.417 0 .79-.259.937-.648l3-8a1 1 0 0 0-.115-.921zM17.307 15h-6.64l-2.5-6h11.39l-2.25 6z"></path>
                        <circle cx="10.5" cy="19.5" r="1.5"></circle>
                        <circle cx="17.5" cy="19.5" r="1.5"></circle>
                      </svg>
                      <span className='text-black'>{t('totalSales')}</span>
                    </div>
                    <div>
                      {reportsStoresLoading || initialLoading ? "-" : dataReportsStores?.reportsStores.totalSales}
                    </div>
                  </div>

                  <div className='flex items-center justify-between px-3 py-3 text-gray-600 border-b'>
                    <div className='flex items-center gap-3'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M15.999 8.5h2c0-2.837-2.755-4.131-5-4.429V2h-2v2.071c-2.245.298-5 1.592-5 4.429 0 2.706 2.666 4.113 5 4.43v4.97c-1.448-.251-3-1.024-3-2.4h-2c0 2.589 2.425 4.119 5 4.436V22h2v-2.07c2.245-.298 5-1.593 5-4.43s-2.755-4.131-5-4.429V6.1c1.33.239 3 .941 3 2.4zm-8 0c0-1.459 1.67-2.161 3-2.4v4.799c-1.371-.253-3-1.002-3-2.399zm8 7c0 1.459-1.67 2.161-3 2.4v-4.8c1.33.239 3 .941 3 2.4z"></path>
                      </svg>
                      <span className='text-black'>{t('costsOfProducts')}</span>
                    </div>
                    <div>
                      {reportsStoresLoading || initialLoading ? "-" : dataReportsStores?.reportsStores.totalProductCosts}
                    </div>
                  </div>

                  <div className='flex items-center justify-between px-3 py-3 text-gray-600 border-b'>
                    <div className='flex items-center gap-3'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M21 5H3a1 1 0 0 0-1 1v4h.893c.996 0 1.92.681 2.08 1.664A2.001 2.001 0 0 1 3 14H2v4a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-4h-1a2.001 2.001 0 0 1-1.973-2.336c.16-.983 1.084-1.664 2.08-1.664H22V6a1 1 0 0 0-1-1zM11 17H9v-2h2v2zm0-4H9v-2h2v2zm0-4H9V7h2v2z"></path>
                      </svg>
                      <span className='text-black'>{t("discountCoupons")}</span>
                    </div>
                    <div>
                      {reportsStoresLoading || initialLoading ? "-" : dataReportsStores?.reportsStores.totalDiscountCoupons}
                    </div>
                  </div>

                  <div className='flex items-center justify-between px-3 py-3 text-gray-600 border-b'>
                    <div className='flex items-center gap-3'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M19.15 8a2 2 0 0 0-1.72-1H15V5a1 1 0 0 0-1-1H4a2 2 0 0 0-2 2v10a2 2 0 0 0 1 1.73 3.49 3.49 0 0 0 7 .27h3.1a3.48 3.48 0 0 0 6.9 0 2 2 0 0 0 2-2v-3a1.07 1.07 0 0 0-.14-.52zM15 9h2.43l1.8 3H15zM6.5 19A1.5 1.5 0 1 1 8 17.5 1.5 1.5 0 0 1 6.5 19zm10 0a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5z"></path>
                      </svg>
                      <span className='text-black'>{t('shippingFees')}</span>
                    </div>
                    <div>
                      {reportsStoresLoading || initialLoading ? "-" : dataReportsStores?.reportsStores.totalShippingFees}
                    </div>
                  </div>

                  <div className='flex items-center justify-between px-3 py-3 text-gray-600 border-b'>
                    <div className='flex items-center gap-3'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M19.903 8.586a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.952.952 0 0 0-.051-.259c-.01-.032-.019-.063-.033-.093zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path><path d="M8 12h8v2H8zm0 4h8v2H8zm0-8h2v2H8z"></path>
                      </svg>
                      <span className='text-black'>VAT</span>
                    </div>
                    <div>
                      {reportsStoresLoading || initialLoading ? "-" : dataReportsStores?.reportsStores.totalVAT}
                    </div>
                  </div>

                  <div className='flex items-center justify-between px-3 py-3 text-white bg-primary'>
                    <div className='flex items-center gap-3'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M19 2H5c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM5 20V4h14l.001 16H5z"></path>
                        <path d="M7 12h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zM7 6h10v4H7zm4 10h2v2h-2zm4-4h2v6h-2z"></path>
                      </svg>
                      <span>{t('netSales')}</span>
                    </div>
                    <div>
                      {reportsStoresLoading || initialLoading ? "-" : parseInt(dataReportsStores?.reportsStores.totalSales || "0") - parseInt(dataReportsStores?.reportsStores.totalProductCosts || "0")}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className='grid gap-3 my-4 sm:grid-cols-2'>
                  <div className='my-3 h-72 sm:my-0'>
                    <div className='flex flex-col h-full p-4 bg-white'>
                      <div>
                        <span>{t('sales')}: {dataSaleStatistics?.saleStatisticsStore.length === 0 ? "-" : dataSaleStatistics?.saleStatisticsStore.length}</span>
                      </div>
                      <div className='flex flex-col items-center justify-center h-full'>
                        {dataSaleStatistics?.saleStatisticsStore.length === 0 ?
                          <div className='flex flex-col items-center justify-center h-full text-gray-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="currentColor">
                              <path d="M11.001 10h2v5h-2zM11 16h2v2h-2z"></path>
                              <path d="M13.768 4.2C13.42 3.545 12.742 3.138 12 3.138s-1.42.407-1.768 1.063L2.894 18.064a1.986 1.986 0 0 0 .054 1.968A1.984 1.984 0 0 0 4.661 21h14.678c.708 0 1.349-.362 1.714-.968a1.989 1.989 0 0 0 .054-1.968L13.768 4.2zM4.661 19 12 5.137 19.344 19H4.661z"></path>
                            </svg>

                            <span className='text-black'>{t('noData')}</span>
                          </div>
                          :
                          saleStatisticsLoading || initialLoading ?
                            <p>{t('loading')}</p>
                            :
                            dataSaleStatistics &&
                            <SalesChart dataSaleStatistics={dataSaleStatistics} />
                        }
                      </div>
                    </div>
                  </div>

                  <div className='my-3 h-72 sm:my-0'>
                    <div className='flex flex-col h-full p-4 bg-white'>
                      <div>
                        <span>{t("completedOrders")}: {dataCompletedOrdersStatistics?.completedOrdersStatistics.length === 0 ? "-" : dataCompletedOrdersStatistics?.completedOrdersStatistics.length}</span>
                      </div>
                      <div className='flex flex-col items-center justify-center h-full'>
                        {dataCompletedOrdersStatistics?.completedOrdersStatistics.length === 0 ?
                          <div className='flex flex-col items-center justify-center h-full text-gray-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="currentColor">
                              <path d="M11.001 10h2v5h-2zM11 16h2v2h-2z"></path>
                              <path d="M13.768 4.2C13.42 3.545 12.742 3.138 12 3.138s-1.42.407-1.768 1.063L2.894 18.064a1.986 1.986 0 0 0 .054 1.968A1.984 1.984 0 0 0 4.661 21h14.678c.708 0 1.349-.362 1.714-.968a1.989 1.989 0 0 0 .054-1.968L13.768 4.2zM4.661 19 12 5.137 19.344 19H4.661z"></path>
                            </svg>

                            <span className='text-black'>{t('noData')}</span>
                          </div>
                          :
                          completedOrdersStatisticsLoading || initialLoading ?
                            <p>{t('loading')}</p>
                            :
                            dataCompletedOrdersStatistics &&
                            <CompletedOrdersChart dataCompletedOrdersStatistics={dataCompletedOrdersStatistics} />
                        }
                      </div>
                    </div>
                  </div>

                  <div className='my-3 h-72 sm:my-0'>
                    <div className='flex flex-col h-full p-4 bg-white'>
                      <div>
                        <span>{t('numberOfOrders')}: {dataOrderStatistics?.orderStatisticsStore.length === 0 ? "-" : dataOrderStatistics?.orderStatisticsStore.length}</span>
                      </div>
                      <div className='flex flex-col items-center justify-center h-full'>
                        {dataOrderStatistics?.orderStatisticsStore.length === 0 ?
                          <div className='flex flex-col items-center justify-center h-full text-gray-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="currentColor">
                              <path d="M11.001 10h2v5h-2zM11 16h2v2h-2z"></path>
                              <path d="M13.768 4.2C13.42 3.545 12.742 3.138 12 3.138s-1.42.407-1.768 1.063L2.894 18.064a1.986 1.986 0 0 0 .054 1.968A1.984 1.984 0 0 0 4.661 21h14.678c.708 0 1.349-.362 1.714-.968a1.989 1.989 0 0 0 .054-1.968L13.768 4.2zM4.661 19 12 5.137 19.344 19H4.661z"></path>
                            </svg>

                            <span className='text-black'>{t('noData')}</span>
                          </div>
                          :
                          orderStatisticsLoading || initialLoading ?
                            <p>{t('loading')}</p>
                            :
                            dataOrderStatistics &&
                            <OrdersChart dataOrderStatistics={dataOrderStatistics} />
                        }
                      </div>
                    </div>
                  </div>

                  <div className='my-3 h-72 sm:my-0'>
                    <div className='flex flex-col h-full p-4 bg-white'>
                      <div>
                        <span>{t('clients')}: {dataClientStatistics?.clientStatisticsStore.length === 0 ? "-" : dataClientStatistics?.clientStatisticsStore.length}</span>
                      </div>
                      <div className='flex flex-col items-center justify-center h-full'>
                        {dataClientStatistics?.clientStatisticsStore.length === 0 ?
                          <div className='flex flex-col items-center justify-center h-full text-gray-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="currentColor">
                              <path d="M11.001 10h2v5h-2zM11 16h2v2h-2z"></path>
                              <path d="M13.768 4.2C13.42 3.545 12.742 3.138 12 3.138s-1.42.407-1.768 1.063L2.894 18.064a1.986 1.986 0 0 0 .054 1.968A1.984 1.984 0 0 0 4.661 21h14.678c.708 0 1.349-.362 1.714-.968a1.989 1.989 0 0 0 .054-1.968L13.768 4.2zM4.661 19 12 5.137 19.344 19H4.661z"></path>
                            </svg>

                            <span className='text-black'>{t('noData')}</span>
                          </div>
                          :
                          clientStatisticsLoading || initialLoading ?
                            <p>{t('loading')}</p>
                            :
                            dataClientStatistics &&
                            <ClientChart dataClientStatistics={dataClientStatistics} />
                        }
                      </div>
                    </div>
                  </div>

                  <div className='my-3 h-72 sm:my-0'>
                    <div className='flex flex-col h-full p-4 bg-white'>
                      <div>
                        <span>{t('mostOrderDays')}: -</span>
                      </div>
                      <div className='flex flex-col items-center justify-center h-full text-gray-500'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M11.001 10h2v5h-2zM11 16h2v2h-2z"></path>
                          <path d="M13.768 4.2C13.42 3.545 12.742 3.138 12 3.138s-1.42.407-1.768 1.063L2.894 18.064a1.986 1.986 0 0 0 .054 1.968A1.984 1.984 0 0 0 4.661 21h14.678c.708 0 1.349-.362 1.714-.968a1.989 1.989 0 0 0 .054-1.968L13.768 4.2zM4.661 19 12 5.137 19.344 19H4.661z"></path>
                        </svg>

                        <span className='text-black'>{t('noData')}</span>
                      </div>
                    </div>
                  </div>

                  <div className='my-3 h-72 sm:my-0'>
                    <div className='flex flex-col h-full p-4 bg-white'>
                      <div>
                        <span>{t('mostOrderHours')}: -</span>
                      </div>
                      <div className='flex flex-col items-center justify-center h-full text-gray-500'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M11.001 10h2v5h-2zM11 16h2v2h-2z"></path>
                          <path d="M13.768 4.2C13.42 3.545 12.742 3.138 12 3.138s-1.42.407-1.768 1.063L2.894 18.064a1.986 1.986 0 0 0 .054 1.968A1.984 1.984 0 0 0 4.661 21h14.678c.708 0 1.349-.362 1.714-.968a1.989 1.989 0 0 0 .054-1.968L13.768 4.2zM4.661 19 12 5.137 19.344 19H4.661z"></path>
                        </svg>

                        <span className='text-black'>{t('noData')}</span>
                      </div>
                    </div>
                  </div>

                  <div className='my-3 h-72 sm:my-0'>
                    <div className='flex flex-col h-full p-4 bg-white'>
                      <div>
                        <span>{t('mostOrderProducts')}</span>
                      </div>

                      <table className="w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="py-3.5 px-3 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                              {t('product')}
                            </th>
                            <th scope="col" className="py-3.5 px-3 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                              {t('quantity')}
                            </th>
                          </tr>
                        </thead>
                        {
                          dataBestSeller?.bestSellerProducts.length !== 0 &&
                          <tbody>
                            {dataBestSeller?.bestSellerProducts.map(product => (
                              <tr key={product.id}>
                                <td>
                                  {product.name}
                                </td>
                                <td>
                                  {product.quantity}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        }
                      </table>

                      {bestSellerLoading || initialLoading ?
                        <p>{t('loading')}</p>
                        :
                        dataBestSeller?.bestSellerProducts.length === 0 &&
                        <div className='flex flex-col items-center justify-center h-full text-gray-500'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M11.001 10h2v5h-2zM11 16h2v2h-2z"></path>
                            <path d="M13.768 4.2C13.42 3.545 12.742 3.138 12 3.138s-1.42.407-1.768 1.063L2.894 18.064a1.986 1.986 0 0 0 .054 1.968A1.984 1.984 0 0 0 4.661 21h14.678c.708 0 1.349-.362 1.714-.968a1.989 1.989 0 0 0 .054-1.968L13.768 4.2zM4.661 19 12 5.137 19.344 19H4.661z"></path>
                          </svg>

                          <span className='text-black'>{t('noData')}</span>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
      }
    </section>
  )
}

export default Reports