import { gql } from '@apollo/client';

export const CreateDomain = gql`
mutation CreateDomain($content: contentDomainRequest!) {
  createDomain(content: $content) {
    domain
    available
    currency
    definitive
    period
    price
    status
    store {
      id
      name
      link
      logo
      icon
      description
      address
      welcomeMessage
      cssFile
      listSubscription {
        subscription {
          id
          planName
          price
          discount
          features
          published
          createdAt
          updatedAt
          deletedAt
        }
        startDate
        endDate
        price
        createdAt
        updatedAt
        deletedAt
      }
      numberProducts
      numberOrders
      numberClients
      admin {
        id
        profilePicture
        name
        email
        phone
        role
        permissions {
          id
          name
          permissions
          createdAt
          updatedAt
          deletedAt
        }
        activation
        emailVerify
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
}
`;