import { gql, useLazyQuery } from '@apollo/client';
import { FetchStore } from '../../../types';

export const STORE = gql`
query Store($storeId: ID) {
  store(id: $storeId) {
    id
    name
    link
    logo
    icon
    description
    address
    welcomeMessage
    cssFile
    listSubscription {
      subscription {
        id
        planName
        price
        discount
        features
        published
        createdAt
        updatedAt
        deletedAt
      }
      startDate
      endDate
      price
      createdAt
      updatedAt
      deletedAt
    }
    numberProducts
    numberOrders
    numberClients
    admin {
      id
      profilePicture
      name
      email
      phone
      role
      store {
        id
        name
        link
        logo
        icon
        description
        address
        welcomeMessage
        cssFile
        numberProducts
        numberOrders
        numberClients
        createdAt
        updatedAt
        deletedAt
      }
      activation
      emailVerify
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`;

export const useAllStore = () => {
  return useLazyQuery<FetchStore>(STORE);
}