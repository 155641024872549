import { gql } from "@apollo/client";

export const CreateFeatures = gql`
mutation CreateFeatures($content: [contentFeatures]!) {
  createFeatures(content: $content) {
    id
    icon
    title
    description
    createdAt
    updatedAt
    deletedAt
  }
}
`;
