import React, { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import SuccessAlert from '../../../../../components/Alerts/SuccessAlert';
import FailAlert from '../../../../../components/Alerts/FailAlert';
import { t } from 'i18next';
import useStore from '../../../../../store/useStore';
import { useMutation } from '@apollo/client';
import { ALL_SERVICES, useAllServices, CreateServices, UpdateSocialMediaServices } from '../../../../../graphql';

interface Props {
  setOpenedPopup: React.Dispatch<React.SetStateAction<false | string>>,
}

const SnapchatPixel = ({ setOpenedPopup }: Props) => {

  const storeData = useStore((state: any) => state.userData.store);

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [pixelId, setPixelId] = useState("");

  const [services, { data: dataServices }] = useAllServices();

  const [createServices, { error: createError }] = useMutation(CreateServices,
    {
      refetchQueries: [
        {
          query: ALL_SERVICES,
          variables: { idStore: storeData?.id }
        }
      ]
    }
  );
  const [updateSocialMediaServices, { error: updateError }] = useMutation(UpdateSocialMediaServices,
    {
      refetchQueries: [
        {
          query: ALL_SERVICES,
          variables: { idStore: storeData?.id }
        }
      ]
    }
  );

  useEffect(() => {
    storeData &&
      (async () => {
        await services({
          variables: { idStore: storeData.id }
        })
      })()
  }, [storeData]);

  useEffect(() => {
    if (dataServices?.allServices && dataServices.allServices?.socialMedia?.snapchatApiKey) {
      setPixelId(dataServices.allServices.socialMedia.snapchatApiKey)
    }
  }, [dataServices])

  const submit = () => {
    if (dataServices?.allServices) {
      // update
      updateSocialMediaServices({
        variables: {
          idStore: storeData.id,
          content: {
            snapchatApiKey: pixelId,
          }
        }
      })

      if (!updateError) {
        setSuccessMsg(t('updateSuccess'));
        setSuccess(true);

        setTimeout(() => {
          setOpenedPopup(false)
        }, 2000)
      }
    } else {
      // create
      createServices({
        variables: {
          content: {
            socialMedia: {
              snapchatApiKey: pixelId,
            },
            idStore: storeData.id
          }
        }
      })

      if (!createError) {
        setSuccessMsg(t('createSuccess'));
        setSuccess(true);

        setTimeout(() => {
          setOpenedPopup(false)
        }, 2000)
      }
    }
  }

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='w-5/6 p-2 bg-white border rounded-md sm:w-4/6 md:w-3/6'>
        <div className='flex justify-end py-2 text-gray-600'>
          <div onClick={() => setOpenedPopup(false)} className='cursor-pointer'>
            <MdClose size={20} />
          </div>
        </div>
        <div className='my-3'>
          <div className="my-2">
            <label htmlFor="link" className="block mb-2 text-sm font-medium text-gray-900">Snapchat Pixel ID</label>
            <input type="text" id="link" value={pixelId} onChange={(e) => setPixelId(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={"id"} required />
          </div>

          <div className='flex justify-end my-2'>
            <div>
              <button onClick={() => { submit() }} className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                <span className="mx-2">
                  {t('save')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SnapchatPixel