import React from 'react'
import {
  Chart as ChartJS,
  registerables
} from 'chart.js/auto'

import { Line } from "react-chartjs-2"
import { FetchClientsStatistics } from '../../types';

ChartJS.register(
  ...registerables
);

const options = {
  scales: {
    y: {
      beginAtZero: true,
      suggestedMin: 0,
      grid: {
        display: false,
      },
      ticks: {
        min: 0,
        stepSize: 1
      }
    },
    x: {
      grid: {
        display: false,
      }
    },
  }
}

const ClientChart = ({ dataClientStatistics }: { dataClientStatistics: FetchClientsStatistics | undefined }) => {
  const labels = dataClientStatistics?.clientStatisticsStore.map(entry => entry.day.split('-')[2])

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Clients",
        backgroundColor: "#7951F9",
        borderColor: "#7951F9",
        data: dataClientStatistics?.clientStatisticsStore.map(entry => entry.totalSales),
        tension: 0.5
      },
    ],
  };

  return (
    <Line dir='ltr' data={data} options={options} />
  )
}

export default ClientChart