import { gql, useLazyQuery } from '@apollo/client';
import { FetchAllSquarePhotosSection } from '../../../../types';

export const ALL_SQUARE_PHOTOS_SECTION = gql`
query AllSquarePhotosSection($idStore: ID) {
  allSquarePhotosSection(idStore: $idStore) {
    id
    title
    rank
    squarePhotos {
      id
      type
      link
      image
      text
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}`

export const useAllSquarePhotosSection = () => {
  return useLazyQuery<FetchAllSquarePhotosSection>(ALL_SQUARE_PHOTOS_SECTION);
}
