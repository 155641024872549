import React, { useEffect, useRef, useState } from 'react'
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import { UpdateFontsStore } from '../../../../graphql';
import useStore from '../../../../store/useStore';
import SuccessAlert from '../../../../components/Alerts/SuccessAlert';
import { useAllSettings } from '../../../../graphql/settings/storeSettings/AllSettings';
import FailAlert from '../../../../components/Alerts/FailAlert';

const Fonts = () => {
  const storeData = useStore((state: any) => state.userData.store);

  const [categoriesDrop, setCategoriesDrop] = useState(false);

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const ref = useRef<any>();

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (ref.current) {
        if (ref.current.contains(e.target as Node)) {
          return
        }

        // else close the dropdown
        setCategoriesDrop(false);
      }
    })
  }, [])

  const fontList = [
    {
      name: "Tajawal",
      link: "https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700&display=swap",
    },
    {
      name: "Roboto",
      link: "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap",
    },
    {
      name: "Cairo",
      link: "https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600&display=swap",
    },
    {
      name: "lato",
      link: "https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap",
    },
    {
      name: "Montserrat",
      link: "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap",
    },
    {
      name: "Oswald",
      link: "https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600&display=swap",
    },
    {
      name: "Lateef",
      link: "https://fonts.googleapis.com/css2?family=Lateef:wght@400;500;600&display=swap",
    },
    {
      name: "Raleway",
      link: "https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap",
    },
    {
      name: "Merriweather",
      link: "https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;1,700&display=swap",
    },
    {
      name: "Changa",
      link: "https://fonts.googleapis.com/css2?family=Changa:wght@400;500;600&display=swap",
    },
  ]

  const [AllSettings, { data: dataAllSettings }] = useAllSettings();

  const [fontName, setFontName] = useState("");
  const [fontlink, setFontlink] = useState("");

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    storeData &&
      (async () => {
        await AllSettings({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()
  }, [storeData]);

  /* loading */

  useEffect(() => {
    if (dataAllSettings) {
      if (dataAllSettings?.allSettings.fontName !== "") {
        setFontName(dataAllSettings?.allSettings.fontName)
      }
      if (dataAllSettings?.allSettings.fontFamily !== "") {
        setFontlink(dataAllSettings?.allSettings.fontFamily)
      }
    }
  }, [dataAllSettings])


  const [updateFontsStore, { loading, error }] = useMutation(UpdateFontsStore);

  const updatefont = async () => {
    if (fontName === "" && fontlink === "") {
      setFail(true)
      setFailMsg(t('fontsNameEmpty'))
    }

    await updateFontsStore({
      variables: {
        idStore: storeData.id,
        content: {
          fontFamily: fontlink,
          fontName: fontName
        }
      }
    })

    if (!error) {
      setSuccessMsg(t('updateSuccess'));
      setSuccess(true);
    }
  }

  return (
    <section className='my-4 mx-3 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('fonts')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div className='relative p-2 mt-3 bg-white rounded-md'>
        {
          loading &&
          <div className='absolute left-0 z-30 flex items-center justify-center w-full h-full py-10 bg-white/70'>
            <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg>
          </div>
        }

        <div className='py-2'>
          {initialLoading ?
            <div className='absolute left-0 z-30 flex items-center justify-center w-full h-full py-10 bg-white/70'>
              <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
            </div>
            :
            <>
              <span>
                {t('customizeStoreFonts')}
              </span>

              <div className="relative py-1" ref={ref}>
                <button onClick={(e) => { e.preventDefault(); setCategoriesDrop(!categoriesDrop) }} className="z-10 flex items-center justify-between w-full p-2 text-left text-gray-700 border border-gray-300 rounded-md bg-gray-50 focus:border-primary focus:outline-none">
                  <span>
                    {fontName !== "" ? fontName : t('select')}
                  </span>

                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M16.939 10.939 12 15.879l-4.939-4.94-2.122 2.122L12 20.121l7.061-7.06z"></path>
                    <path d="M16.939 3.939 12 8.879l-4.939-4.94-2.122 2.122L12 13.121l7.061-7.06z"></path>
                  </svg>
                </button>
                {categoriesDrop &&
                  <div className="absolute right-0 z-20 w-full h-56 py-2 mt-2 overflow-y-auto origin-top-right bg-white border border-gray-300 rounded-md shadow-xl">
                    {fontList.map(font => (
                      <div key={font.name} onClick={() => { setFontName(font.name); setFontlink(font.link); setCategoriesDrop(false) }} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                        <span className="mx-1">
                          {font.name}
                        </span>
                      </div>
                    ))}
                  </div>
                }
              </div>

              <div className='flex justify-end my-2'>
                <button onClick={updatefont} className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                  <span className="mx-2">
                    {t('save')}
                  </span>
                </button>
              </div>
            </>
          }
        </div>
      </div>
    </section>
  )
}

export default Fonts