import { gql, useLazyQuery } from '@apollo/client';
import { FetchFeatures } from '../../../../types';

export const ALL_FEATURES = gql`
query AllFeatures($idStore: ID) {
  allFeatures(idStore: $idStore) {
    id
    icon
    title
    rank
    description
    createdAt
    updatedAt
    deletedAt
  }
}`

export const useAllFeatures = () => {
  return useLazyQuery<FetchFeatures>(ALL_FEATURES);
}
