import { gql, useLazyQuery } from '@apollo/client';
import { FetchSuggestDomain } from '../../../types';

export const SuggestDomain = gql`
query SuggestDomain($query: String, $country: String, $lengthMax: Int, $lengthMin: Int, $limit: Int) {
  suggestDomain(query: $query, country: $country, lengthMax: $lengthMax, lengthMin: $lengthMin, limit: $limit) {
    domain
    availability {
      available
      currency
      definitive
      domain
      period
      price
    }
  }
}
`;

export const useSuggestDomain = () => {
  return useLazyQuery<FetchSuggestDomain>(SuggestDomain);
}