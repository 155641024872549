import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import MainHeader from './Header/Header'
import SideBar from './SideBar/SideBar'
import WelcomePopup from './WelcomePopup/WelcomePopup'
import useStore from '../store/useStore'
import { IoSend } from 'react-icons/io5'
import { BsWhatsapp } from 'react-icons/bs'

interface Props {
  menu: boolean
  setMenu: React.Dispatch<React.SetStateAction<boolean>>
  direction: string
}

const Layout = ({ menu, setMenu, direction }: Props) => {
  const storeData = useStore((state: any) => state.userData.store);

  const [welcomePopup, setWelcomePopup] = useState<boolean>(false);
  const [showContact, setShowContact] = useState<boolean>(false);

  useEffect(() => {
    if (storeData) {
      const showWelcomePopup = storeData.welcomeMessage;

      showWelcomePopup === true && setWelcomePopup(true)
    }
  }, [storeData])

  return (
    <div className='flex'>
      {welcomePopup && <WelcomePopup setWelcomePopup={setWelcomePopup} />}

      <div className='sidebar'>
        <SideBar menu={menu} setMenu={setMenu} direction={direction} />
      </div>

      <div className={`relative w-full min-h-screen bg-gray-100 ${direction === "ltr" ? "md:pl-64" : "md:pr-64"}`} id="content">
        <div>
          <MainHeader setMenu={setMenu} direction={direction} />
        </div>
        <div>
          <Outlet />
        </div>

        <div onMouseEnter={() => setShowContact(true)} onMouseLeave={() => setShowContact(false)} className={`z-50 fixed ${direction === "ltr" ? "right-5" : "left-5"} bottom-5`}>
          {showContact &&
            <div className='relative flex items-center justify-center w-10 h-10 text-white duration-300 rounded-full cursor-pointer bottom-1 hover:bg-primaryHover bg-primary'>
              <BsWhatsapp size={16} />
            </div>
          }

          <div className='p-4 text-white duration-300 rounded-full cursor-pointer hover:bg-primaryHover bg-primary'>
            <IoSend size={20} className='relative -rotate-45 bottom-0.5' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout