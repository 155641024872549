import { gql, useLazyQuery } from "@apollo/client"
import { FetchStatisticsMarketers } from "../../types";

export const ALL_STATISTICS_MARKETERS = gql`
query StatisticsMarketer($idMarketer: ID, $firstDay: String, $lastDay: String) {
  statisticsMarketer(idMarketer: $idMarketer, firstDay: $firstDay, lastDay: $lastDay) {
    numberOrders
    numberSales
    numberClients
    numberCommissions
  }
}
`

export const useAllStatisticsMarketers = () => {
  return useLazyQuery<FetchStatisticsMarketers>(ALL_STATISTICS_MARKETERS);
}