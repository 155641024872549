import { gql, useLazyQuery } from "@apollo/client"
import { FetchSubscriptions } from "../../types";

export const ALL_SUBSCRIPTION = gql`
query AllSubscriptions {
  allSubscriptions {
    id
    planName
    price
    discount
    features
    published
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useAllSubscriptions = () => {
  return useLazyQuery<FetchSubscriptions>(ALL_SUBSCRIPTION);
}