import React, { useEffect, useState } from 'react'
import useStore from '../../../store/useStore';
import { useAllPaymentMethods } from '../../../graphql';
import LoadingWidget from '../../LoadingWidget/LoadingWidget';
import { FetchPaymentMethod } from '../../../types';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

interface Tab4Props {
  prevPage: () => void,
  nextPage: () => void,
  selectedPayment: FetchPaymentMethod | undefined,
  setSelectedPayment: React.Dispatch<React.SetStateAction<FetchPaymentMethod | undefined>>,
}

const Tab4 = ({ prevPage, nextPage, selectedPayment, setSelectedPayment }: Tab4Props) => {

  const storeData = useStore((state: any) => state.userData.store);

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [AllPaymentMethods, { loading, data: dataAllPaymentMethods }] = useAllPaymentMethods();

  useEffect(() => {
    storeData &&
      AllPaymentMethods({
        variables: { idStore: storeData.id }
      })

    setInitialLoading(false)
  }, [storeData]);


  return (
    <div className='my-3'>
      {initialLoading ?
        <LoadingWidget />
        :
        loading ?
          <LoadingWidget />
          :
          dataAllPaymentMethods?.allPaymentMethods.length === 0 ?
            <div className='w-full flex flex-col justify-center items-center'>
              <div className='rounded-2xl bg-blue-500 mx-2'>
                <div className='m-2 bg-white p-1.5 text-blue-400 rounded-full'>
                  <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 301.691 301.691">
                    <g>
                      <polygon points="119.151,0 129.6,218.406 172.06,218.406 182.54,0 	" />
                      <rect x="130.563" y="261.168" width="40.525" height="40.523" />
                    </g>
                  </svg>
                </div>
              </div>

              <h3 className='text-xl my-3'>{t('dontHavePaymentMethod')}</h3>

              <div>
                <Link to="/settings/payment" className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                  <span className="mx-2">
                    {t('addMethod')}
                  </span>
                </Link>
              </div>

            </div>
            :
            <>
              <div className='grid grid-cols-1 gap-6'>
                {dataAllPaymentMethods?.allPaymentMethods.map(method => (
                  <div key={method.id} onClick={() => setSelectedPayment(method)} className={`inline-block border ${selectedPayment?.id === method.id && 'border-rose-600'} py-4 px-6 cursor-pointer rounded-md`} >
                    <div className="bg-rose-100 text-rose-600 inline-block p-2.5 rounded-full" >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M2 8v4.001h1V18H2v3h16l3 .001V21h1v-3h-1v-5.999h1V8L12 2 2 8zm4 10v-5.999h2V18H6zm5 0v-5.999h2V18h-2zm7 0h-2v-5.999h2V18zM14 8a2 2 0 1 1-4.001-.001A2 2 0 0 1 14 8z"></path>
                      </svg>
                    </div>
                    <h3 className='mt-2 text-2xl'>{method.bankName}</h3>
                  </div>
                ))}
              </div>

              <div className='flex justify-between my-2'>
                <div onClick={() => { prevPage() }} >
                  <button className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                    <span className="mx-2">
                      {t('prev')}
                    </span>
                  </button>
                </div>
                <div onClick={() => { nextPage() }} >
                  <button className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                    <span className="mx-2">
                      {t('next')}
                    </span>
                  </button>
                </div>
              </div>
            </>
      }
    </div>
  )
}

export default Tab4