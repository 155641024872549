import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';
import { FaUserAlt } from 'react-icons/fa';
import { BiCart, BiDollar, BiSolidPhone } from 'react-icons/bi';
import { MdEmail } from 'react-icons/md';
import { useAllOrdersMarketers, useAllStatisticsMarketers, useMarketer } from '../../graphql';
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget';
import { AiFillCreditCard } from 'react-icons/ai';
import { LiaCoinsSolid } from 'react-icons/lia';
import useStore from '../../store/useStore';
import PermissionsError from '../../components/Alerts/PermissionsError';

const ViewMarketer = () => {
  const { marketerId } = useParams();

  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    view: false,
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          view: true,
        })

        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "marketing")

      let viewPermission = filtered[0]?.permissions.includes("view")

      setPermissions({
        view: viewPermission || false
      })
    }
  }, [userData])

  const [marketer, { loading, data: dataMarketer }] = useMarketer();

  const [allOrdersMarketers, { loading: loadingOrdersMarketers, data: dataAllOrdersMarketers }] = useAllOrdersMarketers();
  const [allStatisticsMarketers, { data: dataAllStatisticsMarketers }] = useAllStatisticsMarketers();

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await marketer({
        variables: { marketerId: marketerId }
      })

      await allOrdersMarketers({
        variables: { idMarketer: marketerId }
      })

      await allStatisticsMarketers({
        variables: {
          idMarketer: marketerId,
          firstDay: null,
          lastDay: null
        }
      })

      setInitialLoading(false);
    })()
  }, []);

  return (
    <section className='mx-3 my-4 md:mx-7'>
      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('View Marketer')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        permissions.view === false ?
          <PermissionsError />
          :
          <>
            <div className='px-5 py-4 my-2 bg-white rounded-md'>
              <div className='flex items-center gap-3 pb-3 border-b'>
                <div className='p-2 bg-gray-200 border rounded-full'>
                  <FaUserAlt size={24} className='text-white' />
                </div>
                <div>
                  <p>{t('name')}: <span className='font-semibold'>{dataMarketer?.marketer.name}</span></p>
                </div>
              </div>

              <div className='pt-3'>
                <div className="flex items-center gap-2">
                  <BiSolidPhone size={18} className='text-primary' />
                  <p>{dataMarketer?.marketer.phone}</p>
                </div>
                <div className="flex items-center gap-2">
                  <MdEmail size={18} className='text-primary' />
                  <p>{dataMarketer?.marketer.email}</p>
                </div>
              </div>
            </div>

            <div className='px-5 py-4 my-2 bg-white rounded-md'>
              <div className='py-5'>
                <div className='grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3'>
                  <div className="flex items-center gap-5 py-3 px-3 border border-[#EAEBEF] rounded-lg">
                    <div className="inline-flex items-center justify-center p-3 text-blue-500 rounded-full bg-blue-50">
                      <BiDollar size={26} />
                    </div>

                    <div className='relative flex flex-col items-center justify-center bottom-1'>
                      <h1 className="text-3xl font-semibold text-gray-700 capitalize">{dataAllStatisticsMarketers?.statisticsMarketer.numberSales}</h1>

                      <p className="text-sm text-gray-500">
                        {t('sales')}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-5 py-3 px-3 border border-[#EAEBEF] rounded-lg">
                    <div className="inline-flex items-center justify-center p-3 text-orange-500 rounded-full bg-orange-50">
                      <BiCart size={26} />
                    </div>

                    <div className='relative flex flex-col items-center justify-center bottom-1'>
                      <h1 className="text-3xl font-semibold text-gray-700 capitalize">{dataAllStatisticsMarketers?.statisticsMarketer.numberOrders}</h1>

                      <p className="text-sm text-gray-500">
                        {t('orders')}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center gap-5 py-3 px-3 border border-[#EAEBEF] rounded-lg">
                    <div className="inline-flex items-center justify-center rounded-full bg-[#FF8F6B]/10 text-[#FF8F6B] p-3">
                      <LiaCoinsSolid size={26} />
                    </div>

                    <div className='relative flex flex-col items-center justify-center bottom-1'>
                      <h1 className="text-3xl font-semibold text-gray-700 capitalize">{dataAllStatisticsMarketers?.statisticsMarketer.numberCommissions}</h1>

                      <p className="text-sm text-gray-500">
                        {t('totalCommissions')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className='flex items-center justify-between border border-[#EAEBEF] rounded-lg  px-3 py-3'>
                  <div className="flex items-center gap-5">
                    <div className="inline-flex items-center justify-center rounded-full bg-[#EFEBFE] text-[#605BFF] p-3">
                      <LiaCoinsSolid size={26} />
                    </div>

                    <div className='relative flex flex-col items-center justify-center bottom-1'>
                      <h1 className="text-3xl font-semibold text-gray-700 capitalize">{dataMarketer?.marketer.value}</h1>

                      <p className="text-sm text-gray-500">
                        {t('commissionPayable')}
                      </p>
                    </div>
                  </div>

                  <div>
                    <button className="flex items-center gap-2 px-3 py-2 font-medium text-gray-500 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-gray-900 focus:z-10">
                      {t('pay')}
                      <AiFillCreditCard size={20} />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className='grid grid-cols-2 gap-3 px-5 py-4 my-2 bg-white rounded-md'>
              <div>
                <div className='flex items-center gap-2 my-1'>
                  <div className='w-2 h-2 rounded-full bg-primary'></div>
                  <span className='font-semibold text-gray-600'>{t('marketingMethod')}</span>
                </div>
                <p className='text-sm'>{dataMarketer?.marketer.marketingMethod}</p>
              </div>

              <div>
                <div className='flex items-center gap-2 my-1'>
                  <div className='w-2 h-2 rounded-full bg-primary'></div>
                  <span className='font-semibold text-gray-600'>{t('commissionType')}</span>
                </div>
                <p className='text-sm'>{dataMarketer?.marketer.commissionType}</p>
              </div>

              <div>
                <div className='flex items-center gap-2 my-1'>
                  <div className='w-2 h-2 rounded-full bg-primary'></div>
                  <span className='font-semibold text-gray-600'>{t('value')}</span>
                </div>
                <p className='text-sm'>{dataMarketer?.marketer.value}</p>
              </div>

              {dataMarketer?.marketer.otherInfo !== "" &&
                <div>
                  <div className='flex items-center gap-2 my-1'>
                    <div className='w-2 h-2 rounded-full bg-primary'></div>
                    <span className='font-semibold text-gray-600'>{t('otherInfo')}</span>
                  </div>
                  <p className='text-sm'>{dataMarketer?.marketer.otherInfo}</p>
                </div>
              }
            </div>

            <div className='px-5 py-4 my-2 bg-white rounded-md'>
              <div className='py-5'>
                <table className="w-full rounded-md border border-[#EAEBEF] divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 px-10 text-sm font-semibold text-left rtl:text-right text-gray-500">
                        {t('orderNumber')}
                      </th>

                      <th scope="col" className="px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
                        {t('total')}
                      </th>

                      <th scope="col" className="px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
                        {t('date')}
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200">
                    {dataAllOrdersMarketers?.ordersMarketer.map(order => (
                      <tr key={order.id}>
                        <td className="px-3 py-4 text-sm">
                          {order.orderNumber}
                        </td>
                        <td className="px-3 py-4 text-sm">
                          {order.deliveryMethod ?
                            order?.deliveryMethod?.price + order?.deliveryProduct?.reduce(
                              (accumulator, product: any) => accumulator + (product?.price - order?.discount) * product?.quantity, 0
                            )
                            :
                            order?.deliveryProduct?.reduce(
                              (accumulator, product: any) => accumulator + (product?.price - order?.discount) * product?.quantity, 0
                            )}
                        </td>
                        <td className="px-3 py-4 text-sm">
                          <p className="text-gray-500">{new Date(order.createdAt).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {
                  dataAllOrdersMarketers?.ordersMarketer.length === 0 &&
                  <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                      <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                    </svg>
                    <span className='px-2'>{t('NoRequestsForMarketer')}</span>
                  </div>
                }
              </div>
            </div>
          </>
      }
    </section>
  )
}

export default ViewMarketer