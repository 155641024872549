import { gql } from "@apollo/client";

export const CURRENT_USER = gql`
query CurrentUser {
  currentUser {
    id
    profilePicture
    name
    email
    phone
    role
    store {
      id
      name
      link
      logo
      icon
      description
      address
      welcomeMessage
      cssFile
      listSubscription {
        subscription {
          id
          planName
          price
          discount
          features
          published
          createdAt
          updatedAt
          deletedAt
        }
        startDate
        endDate
        price
        createdAt
        updatedAt
        deletedAt
      }
      numberProducts
      numberOrders
      numberClients
      admin {
        id
        profilePicture
        name
        email
        phone
        role
        activation
        emailVerify
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    permissions {
      id
      name
      permissions
      createdAt
      updatedAt
      deletedAt
    }
    activation
    emailVerify
    createdAt
    updatedAt
    deletedAt
  }
}
`;