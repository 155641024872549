import React, { useEffect, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';

interface AddAlertPopupProps {
  setAddAlertPopup: React.Dispatch<React.SetStateAction<boolean>>,
}

const AddAlert = ({ setAddAlertPopup }: AddAlertPopupProps) => {
  const [smsAlert, setSmsAlert] = useState(false);
  const [emailAlert, setEmailAlert] = useState(true);

  const { i18n } = useTranslation();

  return (
    <div className='absolute top-0 left-0 z-50 flex items-center justify-center w-full h-screen bg-gray-900/10 backdrop-blur-md'>
      <div className='w-5/6 p-2 bg-white rounded-md sm:w-3/6 md:w-4/6'>
        <div className='flex justify-end text-black cursor-pointer' onClick={() => { setAddAlertPopup(false) }}>
          <MdClose size={20} />
        </div>

        <form className='px-5 py-2 my-2 bg-white'>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">{t("A reminder will be sent to the customer after leaving the cart for this period")}</label>
            <input type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('time')} required />
          </div>

          <div className='flex items-center gap-2 my-2'>
            <div style={{
              direction: "ltr"
            }} onClick={() => { setSmsAlert(!smsAlert) }} className={`${smsAlert && "bg-primary"} md:w-12 md:h-7 w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer`}>
              <div className={`${smsAlert && "transform translate-x-4"} duration-300 bg-white md:w-6 md:h-6 h-5 w-5 rounded-full shadow-md`}></div>
            </div>
            <div>
              {t('alerts')} SMS
            </div>
          </div>

          <div className='flex items-center gap-2 my-2'>
            <div style={{
              direction: "ltr"
            }} onClick={() => { setEmailAlert(!emailAlert) }} className={`${emailAlert && "bg-primary"} md:w-12 md:h-7 w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer`}>
              <div className={`${emailAlert && "transform translate-x-4"} duration-300 bg-white md:w-6 md:h-6 h-5 w-5 rounded-full shadow-md`}></div>
            </div>
            <div>
              {t('alerts')} {t('email')}
            </div>
          </div>

          {emailAlert &&
            <div className='pt-2'>
              <label className="block mb-2 text-sm font-medium text-gray-900">{t('description')}</label>
              <CKEditor editor={ClassicEditor} className="h-48" />
            </div>
          }

          <div className='flex justify-end pt-5'>
            <button onClick={(e) => { e.preventDefault() }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
              </svg>

              <span className="mx-2">
                {t('Add New Alert')}
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddAlert