import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import useStore from '../../store/useStore';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import { HideWelcomeMessage } from '../../graphql';

interface Props {
  setWelcomePopup: React.Dispatch<React.SetStateAction<boolean>>,
}

const WelcomePopup = ({ setWelcomePopup }: Props) => {
  const storeData = useStore((state: any) => state.userData.store);

  const [hideWelcomeMessage] = useMutation(HideWelcomeMessage);

  const [dontShowCheck, setDontShowCheck] = useState(false)

  const close = async () => {
    if (dontShowCheck) {
      await hideWelcomeMessage({
        variables: {
          hideWelcomeMessageId: storeData?.id
        }
      })
    }

    setWelcomePopup(false)
  }

  return (
    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full min-h-screen bg-gray-900/10 backdrop-blur-md'>
      <div className='w-5/6 p-2 bg-white border rounded-md sm:w-4/6 md:w-3/6'>
        <div className='p-2 bg-primary'>
          <div className='flex justify-end py-1'>
            <div onClick={() => close()} className='cursor-pointer'>
              <AiOutlineClose size={20} className='text-white' />
            </div>
          </div>

          <div className='py-1 text-white'>
            <h3 className='relative pb-5 text-3xl font-semibold text-center text-white'>
              {t('welcomeHeading')}
              <div className='absolute flex items-center justify-center w-full pt-2'>
                <div className='bottom-0 w-1/6 h-px mt-1 bg-white'></div>
              </div>
            </h3>
            <p className='pb-4 text-center'>
              {t('welcomeMessage')}
            </p>
          </div>
        </div>

        <div className='px-2 py-4'>
          <div className='flex flex-col gap-2'>
            <div className='flex items-center gap-3'>
              <span className='px-2 font-semibold text-black border border-gray-300 rounded-full'>
                1
              </span>
              <div className='flex items-center justify-between w-full'>
                <p className='font-semibold'>
                  {t('addPaymentMethod')}
                </p>
                <Link to="/settings/payment" onClick={() => setWelcomePopup(false)} className='inline-flex items-center justify-center px-3 py-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto hover:bg-primaryHover'>
                  {t('add')}
                </Link>
              </div>
            </div>
            <div className='flex items-center gap-3'>
              <span className='px-2 font-semibold text-black border border-gray-300 rounded-full'>
                2
              </span>
              <div className='flex items-center justify-between w-full'>
                <p className='font-semibold'>
                  {t('addDeliveryMethod')}
                </p>
                <Link to="/settings/shipping" onClick={() => setWelcomePopup(false)} className='inline-flex items-center justify-center px-3 py-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto hover:bg-primaryHover'>
                  {t('add')}
                </Link>
              </div>
            </div>

            <div className='flex items-center gap-3'>
              <span className='px-2 font-semibold text-black border border-gray-300 rounded-full'>
                3
              </span>
              <div className='flex items-center justify-between w-full'>
                <p className='font-semibold'>
                  {t('addCountryCurrency')}
                </p>
                <Link to="/settings/countries" onClick={() => setWelcomePopup(false)} className='inline-flex items-center justify-center px-3 py-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto hover:bg-primaryHover'>
                  {t('add')}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='p-2'>
          <div className='flex items-center gap-2'>
            <div className='flex'>
              <input type="checkbox" checked={dontShowCheck} onChange={() => setDontShowCheck(!dontShowCheck)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none cursor-pointer peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <span>{t('dontShowAgain')}</span>
          </div>

          <div>
            <div className='flex justify-end my-2'>
              <div>
                <button onClick={() => close()} className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                  <span className="mx-2">
                    {t('done')}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomePopup