import React, { useEffect } from 'react'
import { useMutation } from '@apollo/client';
import useStore from '../../../../store/useStore';
import { ALL_ANIMATED_PRODUCTS, ALL_CAROUSELS, ALL_FEATURES, ALL_RECTANGLE_BANNER, ALL_RECTANGLE_BANNER_WIDE, ALL_SQUARE_PHOTOS_SECTION, ALL_YOUTUBE_VIDEO, DeleteAnimatedProducts, DeleteCarousel, DeleteFeatures, DeleteRectangleBanner, DeleteRectangleBannerWide, DeleteSquarePhotosSection, DeleteYouTubeVideo } from '../../../../graphql';
import { t } from 'i18next';


interface DeleteConfirmProps {
  setDeleteConfPopup: React.Dispatch<React.SetStateAction<boolean>>,
  setSuccessMsg: React.Dispatch<React.SetStateAction<string>>,
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  selectedIdForDelete: string,
  typeOfDelete: string
}

const DeleteConfirmPopup = ({ setDeleteConfPopup, selectedIdForDelete, setSuccessMsg, setSuccess, typeOfDelete }: DeleteConfirmProps) => {
  const storeData = useStore((state: any) => state.userData.store);

  const [deleteCarousel, { error: carouselError }] = useMutation(DeleteCarousel);
  const [deleteFeatures, { error: featuresError }] = useMutation(DeleteFeatures);
  const [deleteRectangleBanner, { error: rectangleBannerError }] = useMutation(DeleteRectangleBanner);
  const [deleteRectangleBannerWide, { error: rectangleBannerWideError }] = useMutation(DeleteRectangleBannerWide);
  const [deleteAnimatedProducts, { error: animatedProductsError }] = useMutation(DeleteAnimatedProducts);
  const [deleteSquarePhotosSection, { error: squarePhotosSectionError }] = useMutation(DeleteSquarePhotosSection);
  const [deleteYouTubeVideo, { error: youTubeVideoError }] = useMutation(DeleteYouTubeVideo);

  const Delete = async () => {

    if (typeOfDelete === "Home Banner") {
      await deleteCarousel({
        variables: { deleteCarouselId: selectedIdForDelete },
        refetchQueries: [{ query: ALL_CAROUSELS, variables: { idStore: storeData.id } }]
      })

      if (!carouselError) {
        setSuccessMsg(t('homeBannerDeletedSuccess'));
        setSuccess(true);
        setDeleteConfPopup(false)
      }
    }

    if (typeOfDelete === "Features") {
      await deleteFeatures({
        variables: { idStore: storeData.id },
        refetchQueries: [{ query: ALL_FEATURES, variables: { idStore: storeData.id } }]
      })

      if (!featuresError) {
        setSuccessMsg(t('featuresDeletedSuccess'));
        setSuccess(true);
        setDeleteConfPopup(false)
      }
    }

    if (typeOfDelete === "Products") {
      await deleteAnimatedProducts({
        variables: { deleteAnimatedProductsId: selectedIdForDelete },
        refetchQueries: [{ query: ALL_ANIMATED_PRODUCTS, variables: { idStore: storeData.id } }]
      })

      if (!animatedProductsError) {
        setSuccessMsg(t('productsDeletedSuccess'));
        setSuccess(true);
        setDeleteConfPopup(false)
      }
    }

    if (typeOfDelete === "Wide Banner") {
      await deleteRectangleBannerWide({
        variables: { deleteRectangleBannerWideId: selectedIdForDelete },
        refetchQueries: [{ query: ALL_RECTANGLE_BANNER_WIDE, variables: { idStore: storeData.id } }]
      })

      if (!rectangleBannerWideError) {
        setSuccessMsg(t('wideBannerDeletedSuccess'));
        setSuccess(true);
        setDeleteConfPopup(false)
      }
    }

    if (typeOfDelete === "Banners") {
      await deleteRectangleBanner({
        variables: { idStore: storeData.id },
        refetchQueries: [{ query: ALL_RECTANGLE_BANNER, variables: { idStore: storeData.id } }]
      })

      if (!rectangleBannerError) {
        setSuccessMsg(t('bannersDeletedSuccess'));
        setSuccess(true);
        setDeleteConfPopup(false)
      }
    }

    if (typeOfDelete === "Images") {
      await deleteSquarePhotosSection({
        variables: { deleteSquarePhotosSectionId: selectedIdForDelete },
        refetchQueries: [{ query: ALL_SQUARE_PHOTOS_SECTION, variables: { idStore: storeData.id } }]
      })

      if (!squarePhotosSectionError) {
        setSuccessMsg(t('imagesDeletedSuccess'));
        setSuccess(true);
        setDeleteConfPopup(false)
      }
    }

    if (typeOfDelete === "Video") {
      await deleteYouTubeVideo({
        variables: { deleteYouTubeVideoId: selectedIdForDelete },
        refetchQueries: [{ query: ALL_YOUTUBE_VIDEO, variables: { idStore: storeData.id } }]
      })

      if (!youTubeVideoError) {
        setSuccessMsg(t('youtubeVideoDeletedsuccess'));
        setSuccess(true);
        setDeleteConfPopup(false)
      }
    }
  }

  return (
    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full min-h-screen bg-gray-900/10 backdrop-blur-md'>
      <div className='w-5/6 p-2 bg-white border rounded-md sm:w-4/6 md:w-3/6'>
        <div className="relative p-4 text-center">
          <svg className="text-gray-400 w-11 h-11 mb-3.5 mx-auto" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path>
          </svg>
          <p className="mb-4 text-gray-500">{t('deleteConfirm')}</p>
          <div className="flex items-center justify-center gap-4">
            <button onClick={() => setDeleteConfPopup(false)} className="px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-gray-900 focus:z-10">
              {t('cancel')}
            </button>
            <button onClick={() => Delete()} className="px-3 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700">
              {t('confirm')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteConfirmPopup