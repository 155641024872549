import { gql, useLazyQuery } from '@apollo/client';
import { FetchSettings } from '../../../types';

export const ALL_SETTINGS = gql`
query AllSettings($idStore: ID) {
  allSettings(idStore: $idStore) {
    id
    copyright
    activeMinimumOrder
    minimumOrder
    activeStreets
    productPicturesShowOneSize
    activeGift
    activePayment
    activeElectronicPayment
    defaultWeightProduct
    commercialRegistrationNumber
    showCommercialRegistrationNumber
    language
    maintenanceMode
    textColor
    buttonColor
    fontFamily
    fontName
    navBar {
      show
      text
      background
      link
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`;

export const useAllSettings = () => {
  return useLazyQuery<FetchSettings>(ALL_SETTINGS);
}