import { gql, useLazyQuery } from "@apollo/client"
import { FetchCoupons } from "../../types";

export const ALL_COUPONS = gql`
query AllCoupons($idStore: ID) {
  allCoupons(idStore: $idStore) {
    id
    coupon
    value
    expiryDate
    minimumPurchases
    totalNumberTimesUsed
    numberTimesUsedPerCustomer
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useAllCoupons = () => {
  return useLazyQuery<FetchCoupons>(ALL_COUPONS, {
    fetchPolicy: 'cache-and-network',
  });
}