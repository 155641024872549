import { gql, useLazyQuery } from '@apollo/client';
import { FetchSocialMedia } from '../../../../types';

export const ALL_SOCIAL_MEDIA = gql`
query AllSocialMedia($idStore: ID) {
  allSocialMedia(idStore: $idStore) {
    id
    name
    url
    createdAt
    updatedAt
    deletedAt
  }
}
`;

export const useAllSocialMedia = () => {
  return useLazyQuery<FetchSocialMedia>(ALL_SOCIAL_MEDIA);
}