import { gql, useLazyQuery } from '@apollo/client';
import { FetchRectangleBannerWide } from '../../../../types';

export const ALL_RECTANGLE_BANNER_WIDE = gql`
query AllRectangleBannerWide($idStore: ID) {
  allRectangleBannerWide(idStore: $idStore) {
    id
    type
    link
    image
    rank
    createdAt
    updatedAt
    deletedAt
  }
}`

export const useAllRectangleBannerWide = () => {
  return useLazyQuery<FetchRectangleBannerWide>(ALL_RECTANGLE_BANNER_WIDE);
}
