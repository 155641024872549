import { gql, useLazyQuery } from '@apollo/client';
import { FetchArchivedClients } from '../../../types';

export const ALL_CLIENT_ARCHIVED = gql`
query AllClientArchived($idStore: ID) {
  allClientArchived(idStore: $idStore) {
    id
    name
    email
    phone
    dateBirth
    address
    gander
    note
    addressClient {
      id
      name
      phone
      city
      address
      asDefault
      client {
        id
        name
        email
        phone
        dateBirth
        address
        gander
        note
        createdAt
        updatedAt
        deletedAt
      }
    }
    store {
      id
      name
      link
      logo
      icon
      description
      address
      welcomeMessage
      numberProducts
      numberOrders
      numberClients
      admin {
        id
        profilePicture
        name
        email
        phone
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`;

export const useAllClientArchived = () => {
  return useLazyQuery<FetchArchivedClients>(ALL_CLIENT_ARCHIVED);
}