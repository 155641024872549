import { gql, useLazyQuery } from "@apollo/client"
import { FetchCompletedOrdersStatistics } from "../../types";

export const COMPLETED_ORDERS_STATISTICS_STORE = gql`
query CompletedOrdersStatistics($firstDay: String, $lastDay: String) {
  completedOrdersStatistics(firstDay: $firstDay, lastDay: $lastDay) {
    day
    totalProfit
  }
}
`

export const useCompletedOrdersStatistics = () => {
  return useLazyQuery<FetchCompletedOrdersStatistics>(COMPLETED_ORDERS_STATISTICS_STORE, {
    fetchPolicy: 'cache-and-network',
  });
}