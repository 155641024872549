import React, { useEffect, useState } from 'react'
import SuccessAlert from '../../../components/Alerts/SuccessAlert';
import FailAlert from '../../../components/Alerts/FailAlert';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useMutation } from '@apollo/client';
import { CreateDefinitionPage } from '../../../graphql';
import useStore from '../../../store/useStore';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

const AddDefinitionPages = () => {
  const storeData = useStore((state: any) => state.userData.store);
  
  // Alerts
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [title, setTitle] = useState("");
  const [Description, setDescription] = useState("");

  // Create
  const [createDefinitionPage, { error }] = useMutation(CreateDefinitionPage);

  const navigate = useNavigate();
  
  const submit = async (e: any) => {
    e.preventDefault()

    if (title === "") {
      setFailMsg(t('emptyTitle'));
      setFail(true);
      return
    }
    if (Description === "") {
      setFailMsg(t('emptyDescriptionAlert'));
      setFail(true);
      return
    }

    await createDefinitionPage({
      variables: {
        content: {
          description: Description,
          title: title,
          publish: false,
          idStore: storeData.id
        }
      }
    })

    if (!error) {
      setSuccessMsg(t('pageAddedSuccess'));
      setSuccess(true);

      // CLear the form
      setTitle("");
      setDescription("");
      window.setTimeout(() => {
        navigate(`/settings/definitionPages`);
      }, 1000);
    }
  }

  return (
    <section className='my-4 mx-3 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('addDefinitionPage')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <form className='relative px-5 py-2 my-2 bg-white rounded-md'>
        <div className="mb-2">
          <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900">{t('title')}</label>
          <input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('title')} required />
        </div>

        <div className='pt-2'>
          <label className="block mb-2 text-sm font-medium text-gray-900">{t('description')}</label>
          <CKEditor editor={ClassicEditor} data={Description} className="h-48" onChange={(event: any, editor: any) => { const data = editor.getData(); setDescription(data) }} />
        </div>

        <div className='flex justify-end my-2'>
          <div onClick={(e) => { submit(e) }} >
            <button className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary hover:bg-primaryHover'>
              <span className="mx-2">
                {t('save')}
              </span>
            </button>
          </div>
        </div>
      </form>
    </section>
  )
}

export default AddDefinitionPages