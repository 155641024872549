import { useMutation, useQuery } from '@apollo/client';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { ALL_BRANDS, Brand, UpdateBrand, useAllClassFirstLevel } from '../../graphql';
import { FetchBrand, selectedCategoriesProps } from '../../types';
import { useParams } from 'react-router-dom';
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import FailAlert from '../../components/Alerts/FailAlert';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget';
import useStore from '../../store/useStore';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';
import PermissionsError from '../../components/Alerts/PermissionsError';

interface Props {
  direction: string
}

const EditBrands = ({ direction }: Props) => {
  const { brandId } = useParams();

  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    edit: false,
  })

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          edit: true,
        })

        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "Products")

      let editPermission = filtered[0]?.permissions.includes("edit")

      setPermissions({
        edit: editPermission || false,
      })

    }
  }, [userData])

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  // For the current images
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [categoriesDrop, setCategoriesDrop] = useState(false);
  const [name, setName] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<selectedCategoriesProps[]>([]);

  // fetch the categories
  const [classFirstLevelData, { data: dataClassFirstLevelData }] = useAllClassFirstLevel();

  useEffect(() => {
    storeData &&
      classFirstLevelData({
        variables: { idStore: storeData.id }
      })
  }, [storeData])

  // Update
  const [updateBrand, { error: updateError, loading: updateLoading }] = useMutation(UpdateBrand, {
    refetchQueries: [{
      query: ALL_BRANDS,
      variables: { idStore: storeData.id }
    }]
  });

  // File input
  const [selectedPreviewImagesUrl, setSelectedPreviewImagesUrl] = useState<string[]>([]);
  const [imageToCrop, setImageToCrop] = useState<any>();
  const [cropPopup, setCropPopup] = useState(false);

  // For the api
  const [selectedImages, setSelectedImages] = useState<File | null>();

  const cropperRef = useRef<any>();

  const { data: brand, loading: brandLoading } = useQuery<FetchBrand>(Brand, {
    variables: { brandId: brandId }
  });

  const ref = useRef<any>();

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (ref.current) {
        if (ref.current.contains(e.target as Node)) {
          return
        }

        // else close the dropdown
        setCategoriesDrop(false);
      }
    })
  }, [])

  useEffect(() => {
    if (brand !== undefined) {
      setName(brand?.brand.name)
      setPreviewUrls([brand?.brand.image])
      
      setInitialLoading(false)
    };
  }, [brand]);

  const handleCategoryClick = (categoryId: string, categoryName: string) => {
    const selectedCat = {
      id: categoryId,
      name: categoryName
    }
    if (!selectedCategories.find(cat => cat.id === categoryId)) {
      setSelectedCategories([...selectedCategories, selectedCat]);
    }
  };

  const handleCategoryDelete = (categoryId: string) => {
    setSelectedCategories(selectedCategories.filter((cat) => cat.id !== categoryId));
  };

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const urls = Array.from(files).map((file) => URL.createObjectURL(file));

      setImageToCrop(urls[0]);
      setCropPopup(true)
    }
  };

  const handleCrop = () => {
    if (cropperRef.current) {

      if (typeof cropperRef.current.cropper.getCroppedCanvas() === 'undefined') {
        return;
      }
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      const croppedImageUrl = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
      setSelectedPreviewImagesUrl([croppedImageUrl]);

      croppedCanvas.toBlob((blob: any) => {
        const file = new File([blob], "cropped-image.png", { type: "image/png" });
        setSelectedImages(file);
      });
      setCropPopup(false);

    }
  };

  const handlePopupClose = () => {
    setImageToCrop("");
    setCropPopup(false);
  };

  const submit = async (e: any) => {
    e.preventDefault();

    if (name === brand?.brand.name && selectedImages === undefined) {
      setFailMsg(t('nothingToChange'));
      setFail(true)
      return
    }

    if (name !== brand?.brand.name) {
      if (name === "") {
        setFailMsg(t('emptyName'));
        setFail(true)
        return
      }

      await updateBrand({
        variables: {
          updateBrandId: brandId,
          content: {
            name: name
          }
        }
      })
    }

    if (selectedImages !== null) {
      await updateBrand({
        variables: {
          updateBrandId: brandId,
          content: {
            image: selectedImages
          }
        }
      })
    }

    if (!updateError) {
      setSuccessMsg(t('updateSuccess'));
      setSuccess(true);
    }
  }

  return (
    <section className='mx-3 my-4 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {cropPopup && (
        <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>
          <div className='relative w-full max-h-full p-3 overflow-y-auto bg-white border md:w-5/6'>
            <Cropper
              ref={cropperRef}
              src={imageToCrop}
              style={{ height: 400, width: '100%' }}
            />

            <div className='flex justify-end gap-2 mt-3'>
              <button onClick={handleCrop} className="px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-gray-900 focus:z-10">{t('cropImage')}</button>
              <button onClick={handlePopupClose} className="px-3 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700">{t('close')}</button>
            </div>
          </div>
        </div>
      )}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('edit')} {t('brand')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>
      {
        initialLoading ?
          <LoadingWidget />
          :
          permissions.edit === false ?
            <PermissionsError />
            :
            <form className='relative px-5 py-2 my-2 bg-white rounded-md' onSubmit={(e) => submit(e)}>
              {
                updateLoading &&
                <div className='absolute top-0 left-0 z-30 w-full h-full'>
                  <LoadingWidget />
                </div>
              }

              {brandLoading ?
                <LoadingWidget />
                :
                <>
                  <div className='mt-2'>
                    <div className='mb-2'>
                      <label className="block mb-2 text-sm font-medium text-gray-900">Main Category</label>
                      <div className='flex gap-2 my-2'>
                        {selectedCategories.map(category => {
                          return (
                            <div key={category.id} className='flex items-center'>
                              <div className={`px-2 py-1.5 text-sm text-white bg-blue-800 ${direction === 'ltr' ? 'rounded-l-md' : 'rounded-r-md'}`}>
                                <span>
                                  {category.name}
                                </span>
                              </div>
                              <div onClick={() => { handleCategoryDelete(category.id) }} className={`px-2 py-1 text-white bg-blue-800 cursor-pointer ${direction === 'ltr' ? 'rounded-r-md' : 'rounded-l-md'}`}>
                                <span>
                                  x
                                </span>
                              </div>
                            </div>
                          )
                        })}
                      </div>

                      <div className="relative" ref={ref}>
                        <button onClick={(e) => { e.preventDefault(); setCategoriesDrop(!categoriesDrop) }} className="z-10 flex items-center justify-between w-full p-2 text-left text-gray-700 border border-gray-300 rounded-md bg-gray-50 focus:border-primary focus:outline-none">
                          <span>
                            {t('select')}
                          </span>

                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M16.939 10.939 12 15.879l-4.939-4.94-2.122 2.122L12 20.121l7.061-7.06z"></path>
                            <path d="M16.939 3.939 12 8.879l-4.939-4.94-2.122 2.122L12 13.121l7.061-7.06z"></path>
                          </svg>
                        </button>
                        {categoriesDrop &&
                          <div className="absolute right-0 z-20 w-full py-2 mt-2 origin-top-right bg-white border border-gray-300 rounded-md shadow-xl">
                            {dataClassFirstLevelData?.allClassFirstLevels.map(((category: any) => (
                              <div key={category.id} onClick={() => handleCategoryClick(category.id, category.name)} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                                <span className="mx-1">
                                  {category.name}
                                </span>
                              </div>
                            )
                            ))}
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="mb-4">
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">{t('name')}</label>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('name')} />
                  </div>

                  <div className="flex items-center justify-center w-full">
                    <label className="flex flex-col w-full h-32 border-2 border-dashed rounded-md cursor-pointer hover:bg-gray-100 hover:border-gray-300">
                      <div className="flex flex-col items-center justify-center pt-7">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                        </svg>
                        <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                          {t('selectPhoto')}
                        </p>
                      </div>
                      <input type="file" onChange={handleFileSelect} className="opacity-0" multiple={true} />
                    </label>
                  </div>

                  <div className="flex flex-wrap gap-2 mt-2">
                    {selectedImages !== undefined ?
                      <>
                        {selectedPreviewImagesUrl.map((url, key) => {
                          return (
                            <div key={key} className="relative overflow-hidden">
                              <img className="w-20 h-20 rounded-md" src={url} alt="" />
                            </div>
                          )
                        })}
                      </>
                      :
                      <>
                        {previewUrls.map((url, key) => {
                          return (
                            <div key={key} className="relative overflow-hidden">
                              <img className="w-20 h-20 rounded-md" src={`https://store-api.qafilaty.com/images/${url}`} alt="" />
                            </div>
                          )
                        })}
                      </>
                    }
                  </div>

                  <div className='flex justify-end pt-5'>
                    <button type='submit' className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M5 21h14a2 2 0 0 0 2-2V8a1 1 0 0 0-.29-.71l-4-4A1 1 0 0 0 16 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2zm10-2H9v-5h6zM13 7h-2V5h2zM5 5h2v4h8V5h.59L19 8.41V19h-2v-5a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v5H5z"></path>
                      </svg>

                      <span className="mx-2">
                        {t('save')}
                      </span>
                    </button>
                  </div>
                </>
              }

            </form>
      }
    </section>
  )
}

export default EditBrands