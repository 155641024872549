import { gql, useLazyQuery } from '@apollo/client';
import { FetchDefinitionPages } from '../../../types';

export const ALL_DEFINITION_PAGE = gql`
query AllDefinitionPage($idStore: ID) {
  allDefinitionPage(idStore: $idStore) {
    id
    title
    description
    publish
    createdAt
    updatedAt
    deletedAt
  }
}
`;

export const useAllDefinitionPage = () => {
  return useLazyQuery<FetchDefinitionPages>(ALL_DEFINITION_PAGE);
}