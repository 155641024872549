import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useMutation, useQuery } from '@apollo/client';
import { ALL_PRODUCTS, Product, UpdateProduct, useAllBrands, useAllClassFirstLevel } from '../../graphql';
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import FailAlert from '../../components/Alerts/FailAlert';
import { FetchProduct, Input } from '../../types/Products';
import { useParams } from 'react-router-dom';
import OptionsPopup from './Options/OptionsPopup';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget';
import useStore from '../../store/useStore';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';
import { AiOutlineClose } from 'react-icons/ai';
import AddCategoriesPopup from './AddCategoriesPopup';
import PermissionsError from '../../components/Alerts/PermissionsError';

interface Props {
  direction: string
}

const EditProduct = ({ direction }: Props) => {
  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    edit: false,
  })

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          edit: true,
        })

        setInitialLoading(false)
        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "Products")

      let editPermission = filtered[0]?.permissions.includes("edit")

      setPermissions({
        edit: editPermission || false,
      })

      setInitialLoading(false)
    }
  }, [userData])

  const { productId } = useParams();

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [categoriesDrop, setCategoriesDrop] = useState(false);
  const [addCategoriesPopup, setAddCategoriesPopup] = useState(false);

  const ref = useRef<any>();

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (ref.current) {
        if (ref.current.contains(e.target as Node)) {
          return
        }

        // else close the dropdown
        setCategoriesDrop(false);
      }
    })
  }, [])

  // Form Fields State
  // Checkboxes
  const [noShipping, setNoShipping] = useState(false)
  const [freeShipping, setFreeShipping] = useState(false)
  const [publishOnStore, setPublishOnStore] = useState(false)

  const [name, setName] = useState("")
  const [price, setPrice] = useState(0)
  const [cost, setCost] = useState(0)

  const [isUnlimitedQuantity, setIsUnlimitedQuantity] = useState(false)
  const [quantity, setQuantity] = useState(0)
  const [weight, setWeight] = useState(0)

  const [isDiscount, setIsDiscount] = useState(false);
  const [discount, setDiscount] = useState(0);

  const [Description, setDescription] = useState("");

  // File input
  const [selectedPreviewImagesUrl, setSelectedPreviewImagesUrl] = useState<string[]>([]);
  const [oldImagesUrl, setOldImagesUrl] = useState<string[]>([]);
  const [imageToCrop, setImageToCrop] = useState<any>();
  const [cropPopup, setCropPopup] = useState(false);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);

  const cropperRef = useRef<any>();

  const [previewSelectedCategories, setPreviewSelectedCategories] = useState<any[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const [shortDesc, setShortDesc] = useState("");
  const [selectedBrand, setSelectedBrand] = useState<string | undefined>(undefined);

  const [optionsDrop, setOptionsDrop] = useState(false);
  const [optionsPopup, setOptionsPopup] = useState(false);
  const [oldOptions, setOldOptions] = useState<any>();
  const [options, setOptions] = useState<Input[]>([
    { name: '', subOptions: [{ name: '', price: 0, quantity: 0 }] }
  ])

  const handleSaveOptions = (options: Input[]) => {
    setOptions(options);
    setOptionsPopup(false)
    setSuccessMsg(t("saved"))
    setSuccess(true);
  };

  const [classFirstLevelData, { loading: categoriesLoading, data: dataClassFirstLevelData }] = useAllClassFirstLevel();
  const [allBrands, { data: dataAllBrands }] = useAllBrands();

  useEffect(() => {
    if (storeData) {
      classFirstLevelData({
        variables: { idStore: storeData.id }
      })
      allBrands({
        variables: { idStore: storeData.id }
      })
    }
  }, [storeData])

  const { data: product, loading } = useQuery<FetchProduct>(Product, {
    variables: { productId: productId }
  });

  // Update
  const [updateProduct, { error: updateError, loading: updateLoadig }] = useMutation(UpdateProduct, {
    refetchQueries: [{
      query: ALL_PRODUCTS,
      variables: { idStore: storeData?.id }
    }],
    onCompleted: () => {
      if (!updateError) {
        setSuccessMsg(t('updateSuccess'));
        setSuccess(true);
      }
    }
  });

  useEffect(() => {
    if (product !== undefined) {
      setNoShipping(product?.product.notRequireDelivery)
      setFreeShipping(product?.product.freeShipping)
      setPublishOnStore(product?.product.posting)

      setName(product?.product.name)
      setPrice(product?.product.price)
      setCost(product?.product.cost)

      product?.product.quantity === 99999 ? setIsUnlimitedQuantity(true) : setQuantity(product?.product.quantity)

      setWeight(product?.product.weight)

      if (product?.product.priceAfterDiscount !== 0) {
        setIsDiscount(true);
        setDiscount(product?.product.priceAfterDiscount)
      }

      setShortDesc(product?.product.shortDescription)
      setDescription(product?.product.description)
      product?.product.brand && setSelectedBrand(product?.product.brand.id)

      setOldImagesUrl(product?.product.imagesProduct.map(image => {
        return image.name
      }))

      if (product?.product.productOptions.length > 0) {
        setOptionsDrop(true);

        setOldOptions(product?.product.productOptions);
        setOptions(product?.product.productOptions);

      } else {
        setOldOptions([
          { name: '', subOptions: [{ name: '', price: 0, quantity: 0 }] }
        ]);
      }
    };

  }, [product])

  useEffect(() => {
    product?.product.classThirdLevel.map(thirdSubCat => {
      if (!selectedCategories.find((item) => item === thirdSubCat.id)) {
        setSelectedCategories(prev => [
          ...prev,
          thirdSubCat.id,
        ]);
      }

      if (!previewSelectedCategories.find((item) => item.id === thirdSubCat.id)) {
        setPreviewSelectedCategories(prev => [
          ...prev,
          thirdSubCat,
        ]);
      }

    })
  }, [product])

  const handleCategoryClick = (categoryThirdLevel: any) => {
    if (!selectedCategories.find((item) => item === categoryThirdLevel.id)) {
      // Item does not exist
      setSelectedCategories(prev => [
        ...prev,
        categoryThirdLevel.id,
      ]);
    }

    if (!previewSelectedCategories.find((item) => item.id === categoryThirdLevel.id)) {
      // Item does not exist
      setPreviewSelectedCategories(prev => [
        ...prev,
        categoryThirdLevel,
      ]);
    }
  };

  const handleCategoryDelete = (categoryId: string) => {
    setPreviewSelectedCategories(previewSelectedCategories.filter((cat) => cat.id !== categoryId));
    setSelectedCategories(selectedCategories.filter((cat) => cat !== categoryId));
  };

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const urls = Array.from(files).map((file) => URL.createObjectURL(file));

      setImageToCrop(urls[0]);
      setCropPopup(true)
    }
  };

  const handleCrop = () => {
    if (cropperRef.current) {

      if (typeof cropperRef.current.cropper.getCroppedCanvas() === 'undefined') {
        return;
      }
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      const croppedImageUrl = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
      setSelectedPreviewImagesUrl([...selectedPreviewImagesUrl, croppedImageUrl]);

      croppedCanvas.toBlob((blob: any) => {
        const file = new File([blob], "cropped-image.png", { type: "image/png" });
        setSelectedImages([...selectedImages, file]);
      });
      setCropPopup(false);
    }
  };

  const handlePopupClose = () => {
    setImageToCrop("");

    setCropPopup(false);
  };

  const areArraysOfObjectsEqual = (array1: any, array2: any) => {
    if (array1.length !== array2.length) {
      return false; // Different lengths, arrays are not the same
    }

    // Custom comparison function for objects
    const objectComparator = (obj1: any, obj2: any) => {
      // Replace this logic with your specific comparison criteria
      return obj1.id === obj2.id && obj1.name === obj2.name;
    };

    // Check if every object in array1 is equal to the corresponding object in array2
    return array1.every((obj1: any, index: any) => objectComparator(obj1, array2[index]));
  };

  const Submit = async (e: any) => {
    e.preventDefault(e);

    const productData = product?.product;

    if (noShipping !== productData?.notRequireDelivery) {

      await updateProduct({
        variables: {
          updateProductId: productId,
          content: {
            notRequireDelivery: noShipping
          }
        },
        refetchQueries: [{ query: Product, variables: { productId: productId } }]
      })
    }

    if (freeShipping !== productData?.freeShipping) {
      await updateProduct({
        variables: {
          updateProductId: productId,
          content: {
            freeShipping: freeShipping
          }
        },
        refetchQueries: [{ query: Product, variables: { productId: productId } }]
      })
    }

    if (publishOnStore !== productData?.posting) {
      await updateProduct({
        variables: {
          updateProductId: productId,
          content: {
            posting: publishOnStore
          }
        },
        refetchQueries: [{ query: Product, variables: { productId: productId } }]
      })
    }

    if (name !== productData?.name) {
      if (name === "") {
        setFailMsg(t('emptyProductNameAlert'));
        setFail(true)
        return
      }

      await updateProduct({
        variables: {
          updateProductId: productId,
          content: {
            name: name
          }
        },
        refetchQueries: [{ query: Product, variables: { productId: productId } }]
      })
    }

    if (price !== productData?.price) {
      if (price <= 0) {
        setFailMsg(t('invalidPriceAlert'));
        setFail(true)
        return
      }

      await updateProduct({
        variables: {
          updateProductId: productId,
          content: {
            price: price
          }
        },
        refetchQueries: [{ query: Product, variables: { productId: productId } }]
      })
    }

    if (cost !== productData?.cost) {
      if (cost <= 0) {
        setFailMsg(t('invalidCostAlert'));
        setFail(true)
        return
      }

      await updateProduct({
        variables: {
          updateProductId: productId,
          content: {
            cost: cost
          }
        },
        refetchQueries: [{ query: Product, variables: { productId: productId } }]
      })
    }

    if (!areArraysOfObjectsEqual(productData?.classThirdLevel, previewSelectedCategories)) {
      await updateProduct({
        variables: {
          updateProductId: productId,
          content: {
            classThirdLevel: selectedCategories
          }
        },
        refetchQueries: [{ query: Product, variables: { productId: productId } }]
      })
    }

    if (!isUnlimitedQuantity) {
      if (quantity !== productData?.quantity) {

        if (quantity <= 0) {
          setFailMsg(t('invalidQuantityAlert'));
          setFail(true);
          return
        }

        await updateProduct({
          variables: {
            updateProductId: productId,
            content: {
              quantity: quantity
            }
          },
          refetchQueries: [{ query: Product, variables: { productId: productId } }]
        })
      }
    } else {
      if (productData?.quantity !== 99999) {

        await updateProduct({
          variables: {
            updateProductId: productId,
            content: {
              quantity: 99999
            }
          },
          refetchQueries: [{ query: Product, variables: { productId: productId } }]
        })
      }
    }

    if (weight !== productData?.weight) {
      if (weight <= 0) {
        setFailMsg(t('invalidWeightAlert'));
        setFail(true)
        return
      }

      await updateProduct({
        variables: {
          updateProductId: productId,
          content: {
            weight: weight
          }
        },
        refetchQueries: [{ query: Product, variables: { productId: productId } }]
      })
    }

    if (isDiscount) {
      if (discount !== product?.product.priceAfterDiscount) {

        if (discount <= 0) {
          setFailMsg(t('invalidDiscountAlert'));
          setFail(true)
          return
        }

        await updateProduct({
          variables: {
            updateProductId: productId,
            content: {
              priceAfterDiscount: discount
            }
          },
          refetchQueries: [{ query: Product, variables: { productId: productId } }]
        })
      }
    }

    if (shortDesc !== productData?.shortDescription) {
      if (shortDesc === "") {
        setFailMsg(t('emptyShortDescriptionAlert'));
        setFail(true)
        return
      }

      await updateProduct({
        variables: {
          updateProductId: productId,
          content: {
            shortDescription: shortDesc
          }
        },
        refetchQueries: [{ query: Product, variables: { productId: productId } }]
      })
    }

    if (selectedBrand !== productData?.brand?.id) {
      await updateProduct({
        variables: {
          updateProductId: productId,
          content: {
            idBrand: selectedBrand !== undefined ? selectedBrand : null
          }
        },
        refetchQueries: [{ query: Product, variables: { productId: productId } }]
      })
    }

    if (Description !== productData?.description) {

      if (Description === "") {
        setFailMsg(t('emptyDescriptionAlert'));
        setFail(true)
        return
      }

      await updateProduct({
        variables: {
          updateProductId: productId,
          content: {
            description: Description
          }
        },
        refetchQueries: [{ query: Product, variables: { productId: productId } }]
      })
    }

    if (selectedImages.length !== 0) {

      await updateProduct({
        variables: {
          updateProductId: productId,
          content: {
            imagesProduct: Array.from(selectedImages).map((image) => ({
              alt: "alt",
              image: image,
            })),
          }
        },
        refetchQueries: [{ query: Product, variables: { productId: productId } }]
      })
    }

    if (JSON.stringify(options) !== JSON.stringify(oldOptions)) {
      await updateProduct({
        variables: {
          updateProductId: productId,
          content: {
            productOptions: Array.from(options).map(option => (
              {
                name: option.name,
                subOptions: Array.from(option.subOptions).map(subOpt => (
                  {
                    name: subOpt.name,
                    price: subOpt.price,
                    quantity: subOpt.quantity
                  }
                ))
              }
            ))
          }
        },
        refetchQueries: [{ query: Product, variables: { productId: productId } }]
      })
    }

    // Success message is on the onComplete function in useMutation
  }

  return (
    <section className='mx-3 my-4 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {addCategoriesPopup && <AddCategoriesPopup direction={direction} setAddCategoriesPopup={setAddCategoriesPopup} />}

      {optionsPopup && <OptionsPopup setOptionsPopup={setOptionsPopup} options={options} onSave={handleSaveOptions} />}

      {cropPopup && (
        <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>
          <div className='relative w-full max-h-full p-3 overflow-y-auto bg-white border md:w-5/6'>
            <Cropper
              ref={cropperRef}
              src={imageToCrop}
              style={{ height: 400, width: '100%' }}
            />

            <div className='flex justify-end gap-2 mt-3'>
              <button onClick={handleCrop} className="px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-gray-900 focus:z-10">{t('cropImage')}</button>
              <button onClick={handlePopupClose} className="px-3 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700">{t('close')}</button>
            </div>
          </div>
        </div>
      )}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('edit')} {t('product')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {
        initialLoading ?
          <LoadingWidget />
          :
          permissions.edit === false ?
            <PermissionsError />
            :
            <form className='relative px-5 py-2 my-2 bg-white rounded-md'>
              {
                updateLoadig &&
                <div className='absolute left-0 z-30 flex items-center justify-center w-full h-full py-10 my-2 bg-white/70'>
                  <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                </div>
              }

              {
                loading &&
                <div className='absolute left-0 z-30 flex items-center justify-center w-full h-full py-10 my-2 bg-white/70'>
                  <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                </div>
              }

              <div className='flex flex-wrap gap-4 my-4'>
                <div className="flex items-center">
                  <input type="checkbox" checked={noShipping || false} onChange={() => setNoShipping(!noShipping)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <label className="mx-2 font-medium text-gray-800">{t('noShipping')}</label>
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>

                <div className="flex items-center">
                  <input type="checkbox" checked={freeShipping || false} onChange={() => setFreeShipping(!freeShipping)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" required />
                  <label className="mx-2 font-medium text-gray-800">{t('freeShipping')}</label>
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>

                <div className="flex items-center">
                  <input type="checkbox" checked={publishOnStore || false} onChange={() => setPublishOnStore(!publishOnStore)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" required />
                  <label className="mx-2 font-medium text-gray-800">{t('publishOnStore')}</label>
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </div>

              <div>
                <div className="mb-4">
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">{t('name')}</label>
                  <input type="text" id="name" value={name || ''} onChange={(e) => setName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder='product name' required />
                </div>
              </div>

              <div className='grid grid-cols-2 gap-4'>
                <div>
                  <label htmlFor="price" className="block mb-2 text-sm font-medium text-gray-900">{t('price')}</label>
                  <input type="number" id="price" value={price || 0} onChange={(e) => setPrice(parseInt(e.target.value) || 0)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder='price' required />
                </div>
                <div>
                  <label htmlFor="cost" className="block mb-2 text-sm font-medium text-gray-900">{t('cost')}</label>
                  <input type="number" id="cost" value={cost || 0} onChange={(e) => setCost(parseInt(e.target.value) || 0)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder="cost" required />
                </div>
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input type="checkbox" checked={isDiscount} onChange={() => { setIsDiscount(!isDiscount) }} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" required />
                    <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </div>
                  <label className="mx-2 text-sm font-medium text-gray-800">{t('discount')}</label>
                </div>

                {isDiscount &&
                  <div>
                    <input type="number" value={discount || 0} onChange={(e) => setDiscount(parseInt(e.target.value) || 0)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder="price after discount" required />
                  </div>
                }
              </div>

              <div className='grid grid-cols-2 gap-4 mt-5'>
                <div>
                  <div className='flex items-center justify-between'>
                    <label htmlFor="quantity" className="block mb-2 text-sm font-medium text-gray-900">{t('quantity')}</label>
                    <div className="flex items-center mb-2 text-sm">
                      <input type="checkbox" checked={isUnlimitedQuantity} onChange={() => setIsUnlimitedQuantity(!isUnlimitedQuantity)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                      <label className="mx-2 font-medium text-gray-800">{t('unlimited')}</label>
                      <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </div>
                  </div>
                  <div className='relative'>
                    {isUnlimitedQuantity && <div className='absolute w-full h-full bg-white/60'></div>}
                    <input type="number" id="quantity" value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value) || 0)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder='quantity' required />
                  </div>
                </div>
                <div>
                  <label htmlFor="weight" className="block mb-2 text-sm font-medium text-gray-900">{t('wieght')}</label>
                  <input type="text" id="weight" value={weight || ''} onChange={(e) => setWeight(parseInt(e.target.value) || 0)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder="weight" required />
                </div>
              </div>

              <div className='mt-4'>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">{t('categories')}</label>

                  <div className='flex flex-wrap gap-2 my-2'>
                    {previewSelectedCategories.map(category => {
                      return (
                        <div key={category.id} className={`bg-primary rounded-md flex items-center gap-2 px-2 py-1.5 text-sm text-white`}>
                          <span>
                            {category.name}
                          </span>
                          <div onClick={() => { handleCategoryDelete(category.id) }} className={`text-white cursor-pointer`}>
                            <AiOutlineClose size={15} />
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <div className='grid grid-cols-8 gap-2'>
                    <div className="relative col-span-7" ref={ref}>
                      <button onClick={(e) => { e.preventDefault(); setCategoriesDrop(!categoriesDrop) }} className="z-10 flex items-center justify-between w-full p-2 text-left text-gray-700 border border-gray-300 rounded-md bg-gray-50 focus:border-primary focus:outline-none">
                        <span>
                          {t('select')}
                        </span>

                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M16.939 10.939 12 15.879l-4.939-4.94-2.122 2.122L12 20.121l7.061-7.06z"></path>
                          <path d="M16.939 3.939 12 8.879l-4.939-4.94-2.122 2.122L12 13.121l7.061-7.06z"></path>
                        </svg>
                      </button>
                      {categoriesDrop &&
                        <div className="absolute right-0 z-20 w-full py-2 mt-2 origin-top-right bg-white border border-gray-300 rounded-md shadow-xl">
                          {categoriesLoading ?
                            <LoadingWidget />
                            :
                            dataClassFirstLevelData?.allClassFirstLevels.length === 0 ?
                              <div>
                                <p className='px-2 font-semibold text-center text-red-600'>{t('noCategories')}</p>
                              </div>
                              :
                              dataClassFirstLevelData?.allClassFirstLevels.map(category => (
                                <div key={category.id}>
                                  <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                                    <span className="mx-1">
                                      {category.name}
                                    </span>
                                  </div>
                                  <div>
                                    {category.classSecondLevel.map(subCat => (
                                      <div key={subCat.id}>
                                        <div className={`flex items-center py-1 ${direction === "ltr" ? "pl-8" : "pr-8"} text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100`}>
                                          <span className="mx-1">
                                            {subCat.name}
                                          </span>
                                        </div>
                                        <div>
                                          {subCat.classThirdLevel.map(thirdSubCat => (
                                            <div key={thirdSubCat.id}>
                                              <div onClick={() => { handleCategoryClick(thirdSubCat) }} className={`flex items-center py-1 ${direction === "ltr" ? "pl-12" : "pr-12"} text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100`}>
                                                <span className="mx-1">
                                                  {thirdSubCat.name}
                                                </span>
                                              </div>
                                            </div>
                                          )
                                          )}
                                        </div>
                                      </div>
                                    )
                                    )}
                                  </div>
                                </div>
                              )
                              )}
                        </div>
                      }
                    </div>
                    <div className='col-span-1'>
                      <div onClick={() => { setAddCategoriesPopup(true) }} className='flex items-center justify-center cursor-pointer w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                        <span className="mx-2">
                          {t('add')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='pt-2'>
                <div className="mb-4">
                  <div className='flex items-center justify-between'>
                    <label htmlFor="shortDesc" className="block mb-2 text-sm font-medium text-gray-900">{t('shortDescription')}</label>
                    <span className='text-sm'>
                      {shortDesc.length}/20
                    </span>
                  </div>
                  <input type="text" id="shortDesc" value={shortDesc || ''} onChange={(e) => setShortDesc(e.target.value)} maxLength={20} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder='ex. 50% Discount' required />
                </div>
              </div>

              <div className='pt-2'>
                <label className="block mb-2 text-sm font-medium text-gray-900">{t('description')}</label>
                <CKEditor editor={ClassicEditor} data={Description || ''} className="h-48" onChange={(event: any, editor: any) => { const data = editor.getData(); setDescription(data) }} />
              </div>

              <div className="w-full mt-5">
                <span className="flex justify-center items-center text-[12px] mb-1 text-red-500">{/* message */}</span>
                <div className="flex items-center justify-center w-full">
                  <label className="flex flex-col w-full h-32 border-2 border-dashed rounded-md cursor-pointer hover:bg-gray-100 hover:border-gray-300">
                    <div className="flex flex-col items-center justify-center pt-7">
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                      </svg>
                      <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                        {t('selectPhoto')}
                      </p>
                    </div>
                    <input type="file" onChange={handleFileSelect} className="opacity-0" multiple={true} />
                  </label>
                </div>
                <div className="flex flex-wrap gap-2 mt-2">

                  {selectedPreviewImagesUrl.length !== 0 ?
                    selectedPreviewImagesUrl.map((url, key) => {
                      return (
                        <div key={key} className="relative overflow-hidden">
                          <img className="w-20 h-20 rounded-md" src={url} alt="" />
                        </div>
                      )
                    })
                    :
                    oldImagesUrl.map((url, key) => {
                      return (
                        <div key={key} className="relative overflow-hidden">
                          <img className="w-20 h-20 rounded-md" src={`https://store-api.qafilaty.com/images/${url}`} alt="" />
                        </div>
                      )
                    })
                  }
                </div>
              </div>

              <div className="mt-2 overflow-hidden">
                <label className="block mb-2 text-sm font-medium text-gray-900">{t('productOptions')}</label>

                <button onClick={(e) => { e.preventDefault(); setOptionsDrop(!optionsDrop) }} className="z-10 flex items-center justify-between w-full p-2 text-left text-gray-700 border border-gray-300 rounded-md bg-gray-50 focus:border-primary focus:outline-none">
                  <span className='text-sm'>
                    {t('productOptionDescription')}
                  </span>

                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M16.939 10.939 12 15.879l-4.939-4.94-2.122 2.122L12 20.121l7.061-7.06z"></path>
                    <path d="M16.939 3.939 12 8.879l-4.939-4.94-2.122 2.122L12 13.121l7.061-7.06z"></path>
                  </svg>
                </button>

                <div className={`${optionsDrop ? "max-h-[1000px]" : "max-h-[0px]"} my-4 transition-all duration-500 overflow-hidden`}>
                  <div>
                    <button type='button' onClick={(e) => { setOptionsPopup(true) }} className='flex items-center justify-center w-full px-8 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow my-2 md:my-0 hover:bg-primaryHover'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                        <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                      </svg>

                      <span className="mx-2">
                        {t('addOptions')}
                      </span>
                    </button>
                  </div>

                  <div className="mt-6">
                    <div className="relative mt-6">
                      <select value={selectedBrand} onChange={(e) => setSelectedBrand(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary block w-full p-2.5 outline-none">
                        {dataAllBrands?.allBrands.length === 0 ?
                          <option value={undefined}>{t('noBrands')}</option>
                          :
                          <>
                            <option value={undefined}>{t('select')}</option>
                            {
                              dataAllBrands?.allBrands.map(brands => (
                                <option value={brands.id} key={brands.id}>{brands.name}</option>
                              ))
                            }
                          </>
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex justify-end pt-5'>
                <button onClick={(e) => Submit(e)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M5 21h14a2 2 0 0 0 2-2V8a1 1 0 0 0-.29-.71l-4-4A1 1 0 0 0 16 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2zm10-2H9v-5h6zM13 7h-2V5h2zM5 5h2v4h8V5h.59L19 8.41V19h-2v-5a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v5H5z"></path>
                  </svg>

                  <span className="mx-2">
                    {t('save')}
                  </span>
                </button>
              </div>
            </form>
      }
    </section >
  )
}

export default EditProduct