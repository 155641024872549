import React from 'react'
import { SelectedProductsProps } from '../../../types/Products'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import { t } from 'i18next'

interface Tab1Props {
  setAddProductPopup: React.Dispatch<React.SetStateAction<boolean>>,
  selectedProductsList: SelectedProductsProps[],
  setSelectedProductsList: React.Dispatch<React.SetStateAction<SelectedProductsProps[]>>
  nextPage: () => void,
  direction: string,
}

const Tab1 = ({
  setAddProductPopup,
  selectedProductsList,
  setSelectedProductsList,
  nextPage,
  direction
}: Tab1Props) => {

  const counterUp = (item: SelectedProductsProps) => {
    const updatedArray = selectedProductsList.map(product => {
      if (product.id === item.id) {
        return { ...product, quantity: product.quantity + 1 }; // Update the desired properties
      }
      return product;
    });

    setSelectedProductsList(updatedArray);
  }

  const counterDown = (item: SelectedProductsProps) => {
    if (item.quantity > 1) {
      const updatedArray = selectedProductsList.map(product => {
        if (product.id === item.id) {
          return { ...product, quantity: product.quantity - 1 }; // Update the desired properties
        }

        return product;
      });

      setSelectedProductsList(updatedArray);
    }
  }

  return (
    <div className='my-2'>
      <div className='flex justify-end'>
        <button onClick={() => setAddProductPopup(true)} className='inline-flex items-center justify-center px-4 py-3 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
          <AiOutlineAppstoreAdd size={20} />

          <span className="mx-2">
            {t('selectProduct')}
          </span>
        </button>
      </div>

      <div className='h-full mt-2 overflow-x-scroll'>
        <table className="w-full divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
                {t('product')}
              </th>

              <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                {t('quantity')}
              </th>

              <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                {t('price')}
              </th>

              <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                {t('total')}
              </th>

              <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                {t('option')}
              </th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200">
            {selectedProductsList.map(product => {
              return (
                <tr key={product.id}>
                  <td className="px-4 py-4 text-sm">
                    <h2 className="text-gray-800">{product.name}</h2>
                  </td>

                  <td className="px-12 py-4 text-sm">
                    <div className="w-min">
                      <div className="relative flex items-center w-full h-10 mt-1 bg-transparent rounded-lg">
                        <button className={`px-3 py-2 text-gray-600 bg-gray-300 ${direction === "rtl" ? "rounded-r" : "rounded-l"} cursor-pointer hover:text-gray-700 hover:bg-gray-400`}
                          onClick={() => { counterDown(product) }}>
                          <span className="text-xl font-thin" >−</span>
                        </button>
                        <span className='p-3 bg-gray-300'>{product.quantity}</span>
                        <button className={`px-3 py-2 text-gray-600 bg-gray-300 ${direction === "rtl" ? "rounded-l" : "rounded-r"} cursor-pointer hover:text-gray-700 hover:bg-gray-400`}
                          onClick={() => { counterUp(product) }}>
                          <span className="text-xl font-thin">+</span>
                        </button>
                      </div>
                    </div>
                  </td>

                  <td className="px-4 py-4 text-sm">
                    {product.price}
                  </td>

                  <td className="px-4 py-4 text-sm">
                    {product.price * product.quantity}
                  </td>

                  <td>
                    <div className='flex items-center justify-center p-2 m-2 text-red-600 bg-red-200 rounded-md cursor-pointer' onClick={() => { setSelectedProductsList(selectedProductsList.filter(item => item.id !== product.id)) }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                        <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                      </svg>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {selectedProductsList.length === 0 &&
        <div className='flex w-full p-3 mt-2 text-red-600 bg-red-100'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
            <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path></svg>
          <span className='px-2'>{t('noProductsSelected')}</span>
        </div>
      }

      <div className='flex justify-end my-2'>
        <div onClick={() => { nextPage() }} >
          <button className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
            <span className="mx-2">
              {t('next')}
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Tab1