import { gql, useLazyQuery } from "@apollo/client"
import { FetchCoupon } from "../../types";

export const COUPONS = gql`
query Coupon($couponId: ID) {
  coupon(id: $couponId) {
    id
    coupon
    value
    expiryDate
    minimumPurchases
    totalNumberTimesUsed
    numberTimesUsedPerCustomer
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useCoupon = () => {
  return useLazyQuery<FetchCoupon>(COUPONS, {
    fetchPolicy: 'cache-and-network',
  });
}