import { gql, useLazyQuery } from "@apollo/client"
import { FetchStatistics } from "../../types";

export const ALL_STATISTICS_STORE = gql`
query StatisticsStores($idStore: ID) {
  statisticsStores(idStore: $idStore) {
    numberOrders
    numberProducts
    numberBrands
    numberCategories
    numberClients
  }
}
`
export const useStatistics = () => {
  return useLazyQuery<FetchStatistics>(ALL_STATISTICS_STORE, {
    fetchPolicy: 'cache-and-network',
  });
}