import React, { useState } from 'react'
import SuccessAlert from '../../../components/Alerts/SuccessAlert'
import FailAlert from '../../../components/Alerts/FailAlert'
import { MdClose, MdDownloadDone } from 'react-icons/md'
import { t } from 'i18next'
import { useMutation } from '@apollo/client'
import { CreateDomain, SuggestDomainStore } from '../../../graphql'
import useStore from '../../../store/useStore'
import { FiAlertCircle } from 'react-icons/fi'

interface Props {
  setAlreadyBookDomainPopup: React.Dispatch<React.SetStateAction<boolean>>
}

const AlreadyBookDomainPopup = ({ setAlreadyBookDomainPopup }: Props) => {
  const storeData = useStore((state: any) => state.userData.store);

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [domain, setDomain] = useState("")
  const [link, setLink] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const [createDomain] = useMutation(CreateDomain, {
    onError: (error) => {
      console.error(error);
    },
    onCompleted: () => {
      setSuccessMsg(t('domainRequestSucc'));
      setSuccess(true);

      setTimeout(() => setAlreadyBookDomainPopup(false), 2000)
    },
    refetchQueries: [{
      query: SuggestDomainStore,
      variables: { idStore: storeData.id }
    }]
  });

  const Submit = async (e: any) => {
    e.preventDefault();

    if (domain === "") {
      setFailMsg(t('emptyDomain'));
      setFail(true);
      return
    }

    if (link === "") {
      setFailMsg(t('invalidLink'));
      setFail(true);
      return
    }

    if (username === "") {
      setFailMsg(t('EmptyUsername'));
      setFail(true);
      return
    }

    if (password === "") {
      setFailMsg(t('emptyCurrentPassword'));
      setFail(true);
      return
    }

    await createDomain({
      variables: {
        content: {
          status: "pending",
          domain: domain,
          idStore: storeData.id,
          loginInformation: {
            password: password,
            url: link,
            username: username
          }
        }
      }
    })
  }

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='w-5/6 p-2 overflow-y-auto bg-white border sm:w-4/6 md:w-3/6 h-[90%] rounded-md'>
        <div className='flex justify-end py-2 text-gray-600'>
          <div onClick={() => setAlreadyBookDomainPopup(false)} className='cursor-pointer'>
            <MdClose size={20} />
          </div>
        </div>

        <form className='flex flex-col items-center justify-center w-full h-[inherit]'>
          <div className='w-full'>
            <div className="flex items-center w-full gap-3 px-2 py-2 mb-5 bg-gray-100 rounded-md">
              <div className='text-gray-600'>
                <FiAlertCircle size={23} />
              </div>

              <div className="w-full">
                <p className='font-semibold'>
                  {t('domainAlert')}
                </p>
              </div>
            </div>

            <div>
              <div className="mb-4">
                <label htmlFor="domain" className="block mb-2 text-sm font-medium text-gray-900">{t('domain')}</label>
                <input type="text" id="domain" value={domain} onChange={(e) => setDomain(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('domain')} required />
              </div>
            </div>

            <div>
              <div className="mb-4">
                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">{t('dashboardLink')}</label>
                <input type="text" id="name" value={link} onChange={(e) => setLink(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('dashboardLink')} required />
              </div>
            </div>

            <div className='grid gap-4 mb-2 grid-flow-col-1 sm:grid-cols-2'>
              <div>
                <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900">{t('username')}</label>
                <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('username')} />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">{t('password')}</label>
                <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('password')} />
              </div>
            </div>

            <div className='flex justify-end pt-5'>
              <button onClick={(e) => { Submit(e) }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                  <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                </svg>

                <span className="mx-2">
                  {t('save')}
                </span>
              </button>
            </div>
          </div>
        </form>

      </div>
    </div>
  )
}

export default AlreadyBookDomainPopup