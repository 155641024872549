import { t } from 'i18next'
import React, { useState } from 'react'
import { FetchCoupons, checkedItems } from '../../types'
import CouponDropDown from '../DropDowns/CouponDropDown'

interface TableProps {
  checkedItems: checkedItems[],
  setCheckedItems: React.Dispatch<React.SetStateAction<checkedItems[]>>
  dataAllCoupons?: FetchCoupons
  setSelectedIdForDelete: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfPopup: React.Dispatch<React.SetStateAction<boolean>>
  setFailMsg: React.Dispatch<React.SetStateAction<string>>
  direction: string
  startingIndex: number
  permissions: {
    create: boolean,
    edit: boolean,
    delete: boolean,
  }
}

const CouponsTable = ({ checkedItems, setCheckedItems, permissions, dataAllCoupons, setSelectedIdForDelete, setFailMsg, setDeleteConfPopup, direction, startingIndex }: TableProps) => {

  const handleCheckAll = () => {
    if (dataAllCoupons?.allCoupons.length !== checkedItems.length) {
      dataAllCoupons !== undefined && setCheckedItems(dataAllCoupons.allCoupons.map((brand: any) => ({ id: brand.id })))
    } else {
      setCheckedItems([])
    }
  };

  const handleCheck = (id: string) => {
    const isItemExist = checkedItems.some(item => item.id === id);

    if (isItemExist) {
      const newCheckedItems = checkedItems.filter(item => item.id !== id);
      setCheckedItems(newCheckedItems);
    } else {
      setCheckedItems([...checkedItems, { id: id }])
    }
  };

  return (
    <table className="w-full divide-y divide-gray-200 ">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500">
            <div className='relative flex'>
              <input type="checkbox" checked={dataAllCoupons?.allCoupons.length === checkedItems.length} onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </th>

          <th scope="col" className="py-3.5 px-4 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('coupon')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('discountEndDate')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('timesOfUse')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('Number of uses per customer')}
          </th>

          {permissions.delete ?
            <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
              {t('option')}
            </th>
            : null
          }
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {dataAllCoupons?.allCoupons.slice(startingIndex, startingIndex + 10).map(coupon => (
          <tr key={coupon.id}>
            <td className="px-4 py-4 text-sm font-medium">
              <div className='relative flex'>
                <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === coupon.id)} onChange={() => handleCheck(coupon.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
            </td>

            <td className="px-3 py-3 text-sm">
              {coupon.coupon}
            </td>

            <td className="px-3 py-3 text-sm">
              {coupon.expiryDate}
            </td>

            <td className="px-3 py-3 text-sm">
              {coupon.totalNumberTimesUsed}
            </td>

            <td className="px-3 py-3 text-sm">
              {coupon.numberTimesUsedPerCustomer}
            </td>
            {permissions.delete ?
              <td className="px-3 py-3 text-sm">
                <CouponDropDown permissions={permissions} id={coupon.id} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} direction={direction} />
              </td>
              : null
            }
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default CouponsTable