import React, { useEffect, useRef, useState } from 'react'
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import FailAlert from '../../components/Alerts/FailAlert';
import statesData from '../../data/yemen-states.json';
import { useMutation } from '@apollo/client';
import { CreateAddressClient } from '../../graphql';
import { ClientsProps } from '../../types/Clients';
import { MdClose } from 'react-icons/md'
import { t } from 'i18next';

interface AddAddressProps {
  setAddAddressPopup: React.Dispatch<React.SetStateAction<boolean>>,
  selectedClient: ClientsProps | null,
  setSelectedClient: React.Dispatch<React.SetStateAction<ClientsProps | null>>
}

const AddAddressPopup = ({ setAddAddressPopup, selectedClient, setSelectedClient }: AddAddressProps) => {
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [phone, setPhone] = useState("");
  const [cityDropDown, setCityDropDown] = useState(false);
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [asDefault, setAsDefault] = useState(true);

  const ref = useRef<any>();

  const [createAddressClient, { error }] = useMutation(CreateAddressClient, {
    onCompleted: (data) => {
      setSelectedClient(data.createAddressClient.client)
    }
  });

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (ref.current) {
        if (ref.current.contains(e.target as Node)) {
          return
        }

        // else close the dropdown
        setCityDropDown(false);
      }
    })
  }, [])

  const submit = async (e: any) => {
    e.preventDefault();

    if (phone === "") {
      setFailMsg(t('emptyPhoneAlert'));
      setFail(true);
      return
    }
    if (city === "") {
      setFailMsg(t('emptyCityAlert'));
      setFail(true);
      return
    }

    if (address === "") {
      setFailMsg(t('emptyAddressAlert'));
      setFail(true);
      return
    }

    await createAddressClient({
      variables: {
        content: {
          address: address,
          asDefault: asDefault,
          city: city,
          idClient: selectedClient?.id,
          name: address,
          phone: phone
        }
      }
    })


    if (!error) {
      setSuccessMsg(t('addressAddedSuccess'));
      setSuccess(true);

      // CLear the form
      setPhone("");
      setAddress("");
      setCity("");

      setAddAddressPopup(false)
    }

  }

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='w-5/6 p-2 bg-white border sm:w-4/6 md:w-3/6 rounded-md'>
        <div className='flex justify-end py-2 text-gray-600'>
          <div onClick={() => setAddAddressPopup(false)} className='cursor-pointer'>
            <MdClose size={20} />
          </div>
        </div>

        <div className='mb-3'>
          <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">{t('phone')}</label>
          <input type="text" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder="phone" required />
        </div>

        <div className='mb-3'>
          <label className="block mb-2 text-sm font-medium text-gray-900">{t('city')}</label>

          <div className="relative" ref={ref}>
            <button onClick={(e) => { e.preventDefault(); setCityDropDown(!cityDropDown) }} className="z-10 flex items-center justify-between w-full p-2 text-left text-gray-700 border border-gray-300 rounded-md bg-gray-50 focus:border-primary focus:outline-none">
              <span>
                {city === "" ? t('select') : city}
              </span>

              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                <path d="M16.939 10.939 12 15.879l-4.939-4.94-2.122 2.122L12 20.121l7.061-7.06z"></path>
                <path d="M16.939 3.939 12 8.879l-4.939-4.94-2.122 2.122L12 13.121l7.061-7.06z"></path>
              </svg>
            </button>
            {cityDropDown &&
              <div className="absolute right-0 z-20 w-full py-2 mt-2 overflow-y-auto origin-top-right bg-white border border-gray-300 rounded-md shadow-xl h-52">
                {statesData.map((state, index) => (
                  <div key={index} onClick={() => { setCity(state.name); setCityDropDown(false) }} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                    <span className="mx-1">
                      {state.name}
                    </span>
                  </div>
                ))}
              </div>
            }
          </div>
        </div>

        <div className='mb-3'>
          <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900">{t('Address')}</label>
          <input type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder="address" required />
        </div>

        <div className="flex items-center">
          <input type="checkbox" checked={asDefault} onChange={() => setAsDefault(!asDefault)} className="w-4 h-4 border border-gray-300 rounded bg-gray-50" />
          <label className="mx-2 font-medium text-gray-800">{t('setDefaultAddress')}</label>
        </div>

        <div className='flex justify-end my-2'>
          <div onClick={(e) => { submit(e) }} >
            <button className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
              <span className="mx-2">
                {t('save')}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddAddressPopup