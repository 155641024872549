import { gql, useLazyQuery } from '@apollo/client';
import { FetchServices } from '../../../types';

export const ALL_SERVICES = gql`
query AllServices($idStore: ID) {
  allServices(idStore: $idStore) {
    id
    google {
      googleMerchantCenterApiKey
      googleAnalyticsApiKey
      googleAdsApiKey
      googleTagManagerApiKey
    }
    socialMedia {
      faceBookApiKey
      twitterApiKey
      tikTokApiKey
      snapchatApiKey
      facebookInstagramCatalogApiKey
    }
    conversation {
      tawkApiKey
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`;

export const useAllServices = () => {
  return useLazyQuery<FetchServices>(ALL_SERVICES);
}