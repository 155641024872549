import { gql } from "@apollo/client"

export const CreateCoupon = gql`
mutation CreateCoupon($content: contentCoupon!) {
  createCoupon(content: $content) {
    id
    coupon
    value
    expiryDate
    minimumPurchases
    totalNumberTimesUsed
    numberTimesUsedPerCustomer
    createdAt
    updatedAt
    deletedAt
  }
}
`