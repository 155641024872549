import { gql, useLazyQuery } from "@apollo/client"
import { FetchBrand } from "../../types";

export const Brand = gql`
query Query($brandId: ID) {
  brand(id: $brandId) {
    id
    name
    image
    classFirstLevel {
      id
      name
      classSecondLevel {
        id
        name
        classThirdLevel {
          id
          name
          classSecondLevel {
            id
          }
          createdAt
          updatedAt
          deletedAt
        }
        classFirstLevel {
          id
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    store {
      id
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useBrand = () => {
  return useLazyQuery<FetchBrand>(Brand);
}