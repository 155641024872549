import { gql } from '@apollo/client';

export const CreateWorker = gql`
mutation CreateWorker($content: contentWorker!) {
  createWorker(content: $content) {
    id
    name
    email
    phone
    permissions {
      id
      name
      permissions
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`;