import { gql, useLazyQuery } from "@apollo/client"
import { FetchProduct } from "../../types";

export const Product = gql`
query Product($productId: ID) {
  product(id: $productId) {
    id
    name
    price
    priceAfterDiscount
    cost
    quantity
    weight
    shortDescription
    description
    notRequireDelivery
    freeShipping
    posting
    imagesProduct {
      id
      name
      alt
      createdAt
      updatedAt
      deletedAt
    }
    brand {
      id
      name
      image
      classFirstLevel {
        id
        name
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    classThirdLevel {
      id
      name
      classSecondLevel {
        id
        name
        classThirdLevel {
          id
          name
          createdAt
          updatedAt
          deletedAt
        }
        classFirstLevel {
          id
          name
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    productOptions {
      id
      name
      subOptions {
        id
        name
        price
        quantity
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    rating {
      id
      mark
      description
      product {
        id
        name
        price
        priceAfterDiscount
        cost
        quantity
        weight
        shortDescription
        description
        notRequireDelivery
        freeShipping
        posting
        createdAt
        updatedAt
        deletedAt
      }
      client {
        id
        profilePicture
        name
        email
        phone
        gander
        createdAt
        updatedAt
        deletedAt
        address
      }
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useProduct = () => {
  return useLazyQuery<FetchProduct>(Product);
}