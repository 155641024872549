import { gql, useLazyQuery } from "@apollo/client"
import { FetchBestSellerStatistics } from "../../types";

export const BEST_SELLER_PRODUCT = gql`
query BestSellerProducts($idStore: ID) {
  bestSellerProducts(idStore: $idStore) {
    id
    name
    price
    priceAfterDiscount
    cost
    quantity
    weight
    shortDescription
    description
    notRequireDelivery
    freeShipping
    posting
    imagesProduct {
      id
      name
      alt
      createdAt
      updatedAt
      deletedAt
    }
    brand {
      id
      name
      image
      classFirstLevel {
        id
        name
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    classThirdLevel {
      id
      name
      classSecondLevel {
        id
        name
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    productOptions {
      id
      name
      subOptions {
        id
        name
        price
        quantity
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    rating {
      id
      mark
      description
      product {
        id
        name
        price
        priceAfterDiscount
        cost
        quantity
        weight
        shortDescription
        description
        notRequireDelivery
        freeShipping
        posting
        numberSales
        createdAt
        updatedAt
        deletedAt
      }
      client {
        id
        profilePicture
        name
        email
        phone
        dateBirth
        address
        gander
        note
        addressClient {
          id
          name
          phone
          city
          address
          asDefault
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    numberSales
    store {
      id
      name
      link
      logo
      icon
      description
      address
      welcomeMessage
      cssFile
      numberProducts
      numberOrders
      numberClients
      admin {
        id
        profilePicture
        name
        email
        phone
        role
        activation
        emailVerify
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useBestSeller = () => {
  return useLazyQuery<FetchBestSellerStatistics>(BEST_SELLER_PRODUCT, {
    fetchPolicy: 'cache-and-network',
  });
}