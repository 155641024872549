import { gql, useLazyQuery } from '@apollo/client';
import { FetchInvoice } from '../../../types';

export const ALL_INVOICE = gql`
query AllInvoice($idStore: ID) {
  allInvoice(idStore: $idStore) {
    id
    send
    codeQR
    status
    image
    coupon
    address
    createdAt
    updatedAt
    deletedAt
  }
}
`;

export const useAllInvoice = () => {
  return useLazyQuery<FetchInvoice>(ALL_INVOICE);
}
