import { gql, useLazyQuery } from "@apollo/client"
import { FetchMarketers } from "../../types";

export const ALL_MARKETERS = gql`
query AllMarketers($idStore: ID) {
  allMarketers(idStore: $idStore) {
    id
    name
    phone
    email
    otherInfo
    marketingMethod
    commissionType
    value
    referralLink
    store {
      id
      name
      link
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`
export const useAllMarketers = () => {
  return useLazyQuery<FetchMarketers>(ALL_MARKETERS);
}