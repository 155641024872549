import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BiDollarCircle, BiCartDownload, BiBarChartAlt2 } from 'react-icons/bi'
import { TbMessage2Check } from 'react-icons/tb'
import { HiBellAlert } from 'react-icons/hi2'
import { t } from 'i18next'
import useStore from '../../store/useStore'
import { useAbandonedCart } from '../../graphql'
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget'
import { FetchAbandonedCart } from '../../types'
import AbandonedCartTable from '../../components/Tables/AbandonedCartTable'

const AbandonedCart = ({ direction }: { direction: string }) => {

  const storeData = useStore((state: any) => state.userData.store);

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  // The state that contains Fetched Brands, i didn't use dataAllBrands from useAllBrands beacuse i couldn't modify it to contain filtered result
  const [AbandonedCart, setAbandonedCart] = useState<FetchAbandonedCart>();

  // This state hook is to specify which item going to start from in the table
  const [startingIndex, setStartingIndex] = useState(0)

  const [allAbandonedCart, { loading, data: dataAbandonedCart }] = useAbandonedCart();

  useEffect(() => {
    storeData &&
      (async () => {
        await allAbandonedCart({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()
  }, [storeData]);

  useEffect(() => {
    storeData && dataAbandonedCart !== undefined && setAbandonedCart(dataAbandonedCart)
  }, [dataAbandonedCart, storeData]);

  return (
    <section className='my-4 mx-3 md:mx-7'>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4'>
        <div>
          <div className="flex flex-col items-center h-full p-6 bg-white border border-gray-200 rounded-lg">
            <div className='flex flex-col items-center h-full'>
              <div className="p-2 text-primary">
                <BiDollarCircle size={40} />
              </div>

              <div className='flex flex-col items-center justify-between h-full'>
                <h2 className="mb-1 text-sm text-center text-gray-500 title-font">
                  {t('valueOfAbandonedCart')}
                </h2>
                <div className="pt-2 text-3xl font-semibold">
                  {AbandonedCart ?
                    <>
                      ${AbandonedCart?.abandonedCart.reduce(
                        (accumulator, cart: any) => accumulator + cart.total, 0
                      )}
                    </>
                    :
                    <p>-</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="flex flex-col items-center h-full p-6 bg-white border border-gray-200 rounded-lg">
            <div className='flex flex-col items-center h-full'>
              <div className="p-2 text-primary">
                <BiCartDownload size={40} />
              </div>

              <div className='flex flex-col items-center justify-between h-full'>
                <h2 className="mb-1 text-sm text-center text-gray-500 title-font">
                  {t('numbersOfAbandonedCart')}
                </h2>
                <div className="pt-2 text-3xl font-semibold">
                  {AbandonedCart ?
                    <>
                      {AbandonedCart?.abandonedCart.length}
                    </>
                    :
                    <p>-</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="flex flex-col items-center h-full p-6 bg-white border border-gray-200 rounded-lg">
            <div className='flex flex-col items-center h-full'>
              <div className="p-2 text-primary">
                <TbMessage2Check size={40} />
              </div>
              <div className='flex flex-col items-center justify-between h-full'>
                <h2 className="mb-1 text-sm text-center text-gray-500 title-font">
                  {t('numberMessagesSent')}
                </h2>
                <p className="pt-2 text-3xl font-semibold">0/1</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="flex flex-col items-center h-full p-6 bg-white border border-gray-200 rounded-lg">
            <div className='flex flex-col items-center h-full'>
              <div className="p-2 text-primary">
                <BiBarChartAlt2 size={40} />
              </div>
              <div className='flex flex-col items-center justify-between h-full'>
                <h2 className="mb-1 text-sm text-center text-gray-500 title-font">
                  {t('numberSuccessfulTransfers')}
                </h2>
                <p className="pt-2 text-3xl font-semibold">0</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='p-2 mt-3 bg-white rounded-md'>
        {loading || initialLoading ?
          <LoadingWidget />
          :
          <>
            <div>
              <h3 className='text-xl font-semibold'>{t('abandonedCarts')}</h3>
            </div>
            <div>
              <div className='my-4 md:flex md:justify-end'>
                <Link to="/abandoned-cart/alerts" className='inline-flex items-center justify-center px-4 py-3 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary hover:bg-primaryHover'>
                  <HiBellAlert size={20} />

                  <span className="mx-2">
                    {t('alert')}
                  </span>
                </Link>
              </div>
            </div>

            <div className='pt-2 overflow-x-scroll'>
              <AbandonedCartTable AbandonedCart={AbandonedCart} direction={direction} startingIndex={startingIndex} />
            </div>
          </>
        }

        {dataAbandonedCart?.abandonedCart.length === 0 &&
          <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
              <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
            </svg>
            <span className='px-2'>{t('noCartsShow')}</span>
          </div>
        }

        <div className="flex justify-end gap-1.5 text-gray-500 py-4">
          <span className="font-medium text-gray-700">{startingIndex} - {startingIndex + 10}</span>
          <span>{t('of')} {dataAbandonedCart?.abandonedCart.length} {t('records')}</span>
        </div>

        {dataAbandonedCart?.abandonedCart &&
          startingIndex + 10 < dataAbandonedCart?.abandonedCart.length &&
          <div className="flex justify-end gap-1.5 text-gray-500 py-2">
            <button onClick={() => setStartingIndex(startingIndex + 10)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path>
              </svg>
              <span className="mx-2">
                {t('next')}
              </span>
            </button>
          </div>
        }
      </div>
    </section>
  )
}

export default AbandonedCart