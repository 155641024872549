import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'
import { useParams } from 'react-router-dom';
import { useDelivery } from '../../graphql';
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget';
import { BiSolidPhone, BiSolidTruck } from 'react-icons/bi';
import { MdEmail, MdPayments } from 'react-icons/md';
import { FaUserAlt } from 'react-icons/fa';
import useStore from '../../store/useStore';
import PermissionsError from '../../components/Alerts/PermissionsError';

const ViewOrder = () => {
  const { orderId } = useParams();
  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    view: false,
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          view: true,
        })

        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "orders")

      let viewPermission = filtered[0]?.permissions.includes("view")

      setPermissions({
        view: viewPermission || false,
      })

    }
  }, [userData])

  const [initialLoading, setInitialLoading] = useState(true);

  const [delivery, { loading, data: dataDelivery }] = useDelivery();

  useEffect(() => {
    (async () => {
      await delivery({
        variables: { deliveryId: orderId }
      })

      setInitialLoading(false)
    })()
  }, []);

  return (
    <section className='mx-3 my-4 md:mx-7'>
      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('viewOrder')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div>
        {initialLoading || loading ?
          <div className='relative px-5 py-2 my-2 bg-white'>
            <LoadingWidget />
          </div>
          :
          permissions.view === false ?
            <PermissionsError />
            :
            <div>
              <div className='px-5 py-4 my-2 bg-white'>
                <div className='grid grid-cols-2 gap-1'>
                  <div>
                    <span className='text-gray-500'>{t("orderNumber")}</span>
                    <p className='font-semibold '>{dataDelivery?.delivery.orderNumber}</p>
                  </div>
                  <div>
                    <span className='text-gray-500'>{t('clientName')}</span>
                    <p className='font-semibold'>{dataDelivery?.delivery.client.name}</p>
                  </div>
                  <div>
                    <span className='text-gray-500'>{t("date")}</span>
                    {dataDelivery &&
                      <p className='font-semibold'>{new Date(dataDelivery.delivery.createdAt).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>
                    }
                  </div>
                  <div>
                    <span className='text-gray-500'>{t('total')}</span>
                    <p className='font-semibold'>
                      ${dataDelivery &&
                        dataDelivery?.delivery.deliveryMethod?.price + dataDelivery?.delivery.deliveryProduct?.reduce(
                          (accumulator, product: any) => accumulator + (product.price - dataDelivery?.delivery.discount) * product.quantity, 0
                        )}
                    </p>
                  </div>
                </div>
              </div>

              <div className='grid grid-cols-2 gap-2'>
                <div className='px-5 py-4 my-2 bg-white'>
                  <div className='flex items-center gap-5 pb-2 border-b'>
                    <BiSolidTruck size={36} className='text-primary' />
                    <p className='font-semibold text-md'>{dataDelivery?.delivery.deliveryMethod.name}</p>
                  </div>
                  <div className='pt-2 text-sm'>
                    <p>{t('price')}: <span className='font-semibold'>${dataDelivery?.delivery.deliveryMethod.price}</span></p>
                    <p>{t('address')}: <span className='font-semibold'>{dataDelivery?.delivery.addressClient.address}, {dataDelivery?.delivery.addressClient.city}</span></p>
                  </div>
                </div>
                <div className='relative px-5 py-3 my-2 bg-white'>
                  <div className='flex items-center gap-5 pb-2 border-b'>
                    <MdPayments size={36} className='text-primary' />
                    <p className='font-semibold text-md'>{dataDelivery?.delivery.paymentMethod.bankName}</p>
                  </div>
                  <div className='pt-2 text-sm'>
                    <p>{t('accountHolderName')}: <span className='font-semibold'>{dataDelivery?.delivery.paymentMethod.accountName}</span></p>
                  </div>
                </div>
              </div>

              <div className='px-5 py-4 my-2 bg-white'>
                <div className='flex items-center gap-3 pb-3 border-b'>
                  <div className='p-1.5 bg-gray-200 border border-gray-300 rounded-full'>
                    <FaUserAlt size={26} className='text-white' />
                  </div>
                  <div>
                    <p>{t('name')}: <span className='font-semibold'>{dataDelivery?.delivery.client.name}</span></p>
                  </div>
                </div>

                <div className='pt-3'>
                  <div className="flex items-center gap-2">
                    <BiSolidPhone size={18} className='text-primary' />
                    <p>{dataDelivery?.delivery.client.phone}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <MdEmail size={18} className='text-primary' />
                    <p>{dataDelivery?.delivery.client.email}</p>
                  </div>
                </div>
              </div>

              <div className='py-3 my-4 bg-white'>
                <h3 className='px-5 pb-3 text-xl font-semibold'>{t('products')}</h3>
                <table className="w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="font-semibold py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500">
                        {t('product')}
                      </th>

                      <th scope="col" className="font-semibold px-12 py-3.5 text-sm text-left rtl:text-right text-gray-500">
                        {t('name')}
                      </th>

                      <th scope="col" className="font-semibold px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500">
                        {t('quantity')}
                      </th>

                      <th scope="col" className="font-semibold px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500">
                        {t('price')}
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200">
                    {dataDelivery?.delivery.deliveryProduct.map((product, index) => (
                      <tr key={index}>
                        <td className="px-4 py-4 text-sm">
                          <img className='object-cover w-16 h-14' src={`https://store-api.qafilaty.com/images/${product.product?.imagesProduct[0]?.name}`} alt="not found" />
                        </td>
                        <td className="px-12 py-4 text-sm">
                          <p className="text-gray-500">{product.product ? product.product?.name : "N/A"}</p>
                        </td>

                        <td className="px-4 py-4 text-sm">
                          <p className="text-gray-500">{product.product?.quantity === 99999 ? "unlimited" : product?.quantity}</p>
                        </td>

                        <td className="px-4 py-4 text-sm">
                          ${product?.price}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
        }
      </div>
    </section>
  )
}

export default ViewOrder