import React, { useEffect, useState } from 'react'
import {
  useAllClassFirstLevel,
  CreateClassFirstLevel,
  UpdateClassFirstLevel,
  ALL_CLASS_FIRST_LEVEL,
} from '../../graphql'
import { useMutation } from '@apollo/client'
import SuccessAlert from '../../components/Alerts/SuccessAlert'
import DeleteConfirmPopup from './DeleteConfirmPopup'
import DeleteSecondConfirmPopup from './DeleteSecondConfirmPopup'
import FailAlert from '../../components/Alerts/FailAlert'
import DeleteThirdConfirmPopup from './DeleteThirdConfirmPopup'
import { ClassFirstLevels } from "../../types"
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget'
import useStore from '../../store/useStore'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'

const Categories = ({ showBreadcrumbs, direction }: { showBreadcrumbs: boolean, direction: string }) => {
  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    delete: false,
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          create: true,
          edit: true,
          delete: true,
        })

        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "categories")

      let createPermission = filtered[0]?.permissions.includes("create")
      let editPermission = filtered[0]?.permissions.includes("edit")
      let deletePermission = filtered[0]?.permissions.includes("delete")

      setPermissions({
        create: createPermission || false,
        edit: editPermission || false,
        delete: deletePermission || false,
      })
    }
  }, [userData]);

  // This state will contains the updated fields to save when click on save button
  const [modifiedCategories, setModifiedCategories] = useState<any>([]);

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [classFirstLevelData, { loading, data: dataClassFirstLevel }] = useAllClassFirstLevel();

  useEffect(() => {
    storeData &&
      (async () => {
        await classFirstLevelData({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()

  }, [storeData])

  // Create
  const [createClassFirstLevel, { error }] = useMutation(CreateClassFirstLevel, {
    refetchQueries: [{
      query: ALL_CLASS_FIRST_LEVEL,
      variables: { idStore: storeData?.id }
    }]
  });

  // Update 
  const [updateClassFirstLevel, { error: updateFirstLevelError }] = useMutation(UpdateClassFirstLevel, {
    refetchQueries: [{
      query: ALL_CLASS_FIRST_LEVEL,
      variables: { idStore: storeData?.id }
    }]
  });

  const [addcategoryField, setAddcategoryField] = useState(false);
  const [addSubcategoryField, setAddSubcategoryField] = useState("");
  const [addThirdSubcategoryField, setAddThirdSubcategoryField] = useState("");

  const [deleteConfPopup, setDeleteConfPopup] = useState(false);
  const [deleteSecondConfPopup, setDeleteSecondConfPopup] = useState(false);
  const [deleteThirdConfPopup, setDeleteThirdConfPopup] = useState(false);

  const [firstLevelIdForDelete, setFirstLevelIdForDelete] = useState("");
  const [secondLevelIdForDelete, setSecondLevelIdForDelete] = useState("");
  const [thirdLevelIdForDelete, setThirdLevelIdForDelete] = useState("");

  const [categoryNameFieldValue, setcategoryNameFieldValue] = useState("");
  const [subcategoryNameFieldValue, setSubcategoryNameFieldValue] = useState("");
  const [thirdSubcategoryNameFieldValue, setThirdSubcategoryNameFieldValue] = useState("");

  const addFirstClassLevel = () => {
    if (categoryNameFieldValue === "") {
      setFailMsg(t('haveToSelectCategoryName'));
      setFail(true);
      return
    }

    createClassFirstLevel({
      variables: {
        content: {
          idStore: storeData.id,
          name: categoryNameFieldValue
        }
      }
    })

    if (!error) {
      setSuccessMsg(t('categorieAddedSuccess'));
      setSuccess(true);
      setAddcategoryField(false);
      setcategoryNameFieldValue("")
    }
  }

  const addSecondClassLevel = (firstLevelId: string) => {
    if (subcategoryNameFieldValue === "") {
      setFailMsg(t('haveToSelectCategorySubName'));
      setFail(true);
      return
    }

    const parentLevel = dataClassFirstLevel?.allClassFirstLevels.find((category) => category.id === firstLevelId)
    const currentSecondLevel = parentLevel?.classSecondLevel;

    const oldSecondLevel: { id: string, name: string, classThirdLevel?: any[] }[] = [];

    currentSecondLevel !== undefined &&
      currentSecondLevel.map((secondLevelCat: any) => {
        oldSecondLevel.push({
          name: secondLevelCat.name,
          id: secondLevelCat.id,
          classThirdLevel: secondLevelCat.classThirdLevel.length > 0 ?
            secondLevelCat.classThirdLevel.map((thirdLevel: any) => (
              {
                name: thirdLevel.name,
                id: thirdLevel.id
              }
            )) : []
        })
      })

    updateClassFirstLevel({
      variables: {
        updateClassFirstLevelId: firstLevelId,
        content: {
          classSecondLevel: [
            ...oldSecondLevel,
            {
              name: subcategoryNameFieldValue
            }
          ]
        }
      }
    })

    if (!updateFirstLevelError) {
      setSuccessMsg(t('subcategorieAddedSuccess'));
      setSuccess(true);
      setAddSubcategoryField("");
      setSubcategoryNameFieldValue("")
    }
  }

  const addThirdClassLevel = (firstLevelId: string, secondLevelId: string) => {
    if (thirdSubcategoryNameFieldValue === "") {
      setFailMsg(t('haveToSelectSubCategoryName'));
      setFail(true);
      return
    }

    const firstParentLevel = dataClassFirstLevel?.allClassFirstLevels.find((category) => category.id === firstLevelId);
    const secondParentLevel = firstParentLevel?.classSecondLevel;

    const oldSecondLevel: { id: string, name: string, classThirdLevel: any[] }[] = [];
    const oldThirdLevel: { id: string, name: string }[] = [];

    secondParentLevel !== undefined &&
      secondParentLevel.map(secondLevelCat => {
        secondLevelCat.id === secondLevelId &&
          secondLevelCat.classThirdLevel.map(thirdLevel => (
            oldThirdLevel.push({
              id: thirdLevel.id,
              name: thirdLevel.name
            })
          ))
      })

    secondParentLevel !== undefined &&
      secondParentLevel.map(secondLevelCat => {
        oldSecondLevel.push({
          name: secondLevelCat.name,
          id: secondLevelCat.id,
          classThirdLevel: secondLevelCat.id === secondLevelId ?
            [
              ...oldThirdLevel,
              {
                name: thirdSubcategoryNameFieldValue
              }
            ] : secondLevelCat.classThirdLevel.map(thirdLevel => (
              {
                id: thirdLevel.id,
                name: thirdLevel.name,
              }
            ))
        })
      })

    updateClassFirstLevel({
      variables: {
        updateClassFirstLevelId: firstLevelId,
        content: {
          classSecondLevel: oldSecondLevel,
        }
      }
    })

    if (!updateFirstLevelError) {
      setSuccessMsg(t('subcategorieAddedSuccess'));
      setSuccess(true);
      setAddThirdSubcategoryField("");
      setThirdSubcategoryNameFieldValue("")
    }
  }

  const handleCategoryChange = (e: any, classLevel: string, firstClassId: string, secondClassId: string, thirdClassId: string, newValue: string) => {

    const updatedCategory = {
      classType: classLevel,
      firstClassId: firstClassId,
      secondClassId: secondClassId,
      thirdClassId: thirdClassId,
      newValue: e.target.value,
    };

    setModifiedCategories((prevModifiedCategories: any) => {

      let categoryIndex = 0;

      if (classLevel === "thirdLevel")
        categoryIndex = prevModifiedCategories.findIndex((category: any) => category.classType === classLevel && category.thirdClassId === thirdClassId)

      if (classLevel === "secondLevel")
        categoryIndex = prevModifiedCategories.findIndex((category: any) => category.classType === classLevel && category.secondClassId === secondClassId)

      if (classLevel === "firstLevel")
        categoryIndex = prevModifiedCategories.findIndex((category: any) => category.classType === classLevel && category.firstClassId === firstClassId)


      const updatedModifiedCategories = [...prevModifiedCategories];

      if (categoryIndex > -1 && updatedModifiedCategories[categoryIndex].classType === classLevel) {
        updatedModifiedCategories[categoryIndex] = updatedCategory;

        if (e.target.value === newValue) {
          updatedModifiedCategories.splice(categoryIndex, 1);
        }
      } else {
        updatedModifiedCategories.push(updatedCategory);
      }

      return updatedModifiedCategories;

    });
  }

  const Update = async () => {
    if (modifiedCategories.length > 0) {
      for (var i = 0; i < modifiedCategories.length; i++) {
        if (modifiedCategories[i].classType === "firstLevel") {
          updateClassFirstLevel({
            variables: {
              updateClassFirstLevelId: modifiedCategories[i].firstClassId,
              content: {
                idStore: storeData.id,
                name: modifiedCategories[i].newValue
              }
            }
          })

          if (!updateFirstLevelError) {
            setSuccessMsg(t('categoryUpdatedSuccess'));
            setSuccess(true);
            setDeleteConfPopup(false)
          }
        } else if (modifiedCategories[i].classType === "secondLevel") {

          const parentLevel = dataClassFirstLevel?.allClassFirstLevels.find((category) => category.id === modifiedCategories[i].firstClassId);

          updateClassFirstLevel({
            variables: {
              updateClassFirstLevelId: modifiedCategories[i].firstClassId,
              content: {
                classSecondLevel: parentLevel?.classSecondLevel?.map(secondLevel => (
                  secondLevel.id === modifiedCategories[i].secondClassId ?
                    {
                      id: modifiedCategories[i].secondClassId,
                      name: modifiedCategories[i].newValue,
                      classThirdLevel: secondLevel.classThirdLevel.map(third => (
                        {
                          id: third.id,
                          name: third.name
                        }
                      ))
                    }
                    :
                    {
                      id: secondLevel.id,
                      name: secondLevel.name,
                      classThirdLevel: secondLevel.classThirdLevel.map(third => (
                        {
                          id: third.id,
                          name: third.name
                        }
                      ))
                    }
                ))
              }
            }
          })

          if (!updateFirstLevelError) {
            setSuccessMsg(t('categoryUpdatedSuccess'));
            setSuccess(true);
            setDeleteConfPopup(false)
          }
        } else if (modifiedCategories[i].classType === "thirdLevel") {

          const parentLevel = dataClassFirstLevel?.allClassFirstLevels.find((category) => category.id === modifiedCategories[i].firstClassId)

          updateClassFirstLevel({
            variables: {
              updateClassFirstLevelId: modifiedCategories[i].firstClassId,
              content: {
                classSecondLevel: parentLevel?.classSecondLevel.map(second => (
                  {
                    id: second.id,
                    name: second.name,
                    classThirdLevel: second.classThirdLevel.map(third => (
                      third.id === modifiedCategories[i].thirdClassId ?
                        {
                          id: modifiedCategories[i].thirdClassId,
                          name: modifiedCategories[i].newValue,
                        }
                        :
                        {
                          id: third.id,
                          name: third.name,
                        }
                    ))
                  }
                ))
              }
            }
          })

          if (!updateFirstLevelError) {
            setSuccessMsg(t('categoryUpdatedSuccess'));
            setSuccess(true);
            setDeleteConfPopup(false)
          }
        }
      }
    }
  }

  return (
    <section className='mx-3 my-4 md:mx-7'>

      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {deleteConfPopup && <DeleteConfirmPopup setDeleteConfPopup={setDeleteConfPopup} firstLevelIdForDelete={firstLevelIdForDelete} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}
      {deleteSecondConfPopup && <DeleteSecondConfirmPopup dataClassFirstLevelData={dataClassFirstLevel} setDeleteSecondConfPopup={setDeleteSecondConfPopup} firstLevelIdForDelete={firstLevelIdForDelete} secondLevelIdForDelete={secondLevelIdForDelete} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}
      {deleteThirdConfPopup && <DeleteThirdConfirmPopup dataClassFirstLevelData={dataClassFirstLevel} setDeleteThirdConfPopup={setDeleteThirdConfPopup} firstLevelIdForDelete={firstLevelIdForDelete} secondLevelIdForDelete={secondLevelIdForDelete} thirdLevelIdForDelete={thirdLevelIdForDelete} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('categories')}</h3>
        </div>
        {
          showBreadcrumbs &&
          <div>
            <Breadcrumbs />
          </div>
        }
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        <div className='px-5 py-2 my-2 bg-white rounded-md'>
          {dataClassFirstLevel?.allClassFirstLevels.length === 0 &&
            <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
              </svg>
              <span className='px-2'>{t('noCategories')}</span>
            </div>
          }

          {dataClassFirstLevel?.allClassFirstLevels.map((category: ClassFirstLevels) => {
            return (
              <div key={category.id} className='mb-5'>
                <div className='flex items-center mb-2'>
                  <div className="flex-1">
                    <input type="text" defaultValue={category.name} onChange={(e) => handleCategoryChange(e, "firstLevel", category.id, "", "", category.name)} className="bg-gray-50 border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('AddCategory')} required />
                  </div>
                  {permissions.delete &&
                    <div onClick={() => { setFirstLevelIdForDelete(category.id); setDeleteConfPopup(true) }} className='bg-[#FFE3E3] text-red-500 m-2 p-2 cursor-pointer rounded-md'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                        <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                      </svg>
                    </div>
                  }
                </div>

                <div className={`${direction === "ltr" ? "ml-5" : "mr-5"}`}>
                  {category.classSecondLevel.map(subCat => {
                    return (
                      <div key={subCat.id}>
                        <div className='flex items-center mb-2'>
                          <div className="flex-1">
                            <input type="text" defaultValue={subCat.name} onChange={(e) => handleCategoryChange(e, "secondLevel", category.id, subCat.id, "", subCat.name)} className="bg-gray-50 border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('addSubCategory')} required />
                          </div>
                          {permissions.delete &&
                            <div onClick={() => { setFirstLevelIdForDelete(category.id); setSecondLevelIdForDelete(subCat.id); setDeleteSecondConfPopup(true) }} className='bg-[#FFE3E3] text-red-500 m-2 p-2 cursor-pointer rounded-md'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                                <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                              </svg>
                            </div>
                          }
                        </div>

                        <div className={`${direction === "ltr" ? "ml-5" : "mr-5"}`}>
                          {subCat.classThirdLevel.map(thirdSubCat => {
                            return (
                              <div key={thirdSubCat.id}>
                                <div className='flex items-center mb-2'>
                                  <div className="flex-1">
                                    <input type="text" defaultValue={thirdSubCat.name} onChange={(e) => handleCategoryChange(e, "thirdLevel", category.id, subCat.id, thirdSubCat.id, thirdSubCat.name)} className="bg-gray-50 border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('addSubCategory')} required />
                                  </div>
                                  {permissions.delete &&
                                    <div onClick={() => { setFirstLevelIdForDelete(category.id); setSecondLevelIdForDelete(subCat.id); setThirdLevelIdForDelete(thirdSubCat.id); setDeleteThirdConfPopup(true) }} className='bg-[#FFE3E3] text-red-500 m-2 p-2 cursor-pointer rounded-md'>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                                        <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                                      </svg>
                                    </div>
                                  }
                                </div>
                              </div>
                            )
                          })
                          }

                          {addThirdSubcategoryField === subCat.id &&
                            <div className='my-4'>
                              <div>
                                <label htmlFor="subName" className="block mb-2 text-sm font-medium text-gray-900">{t('addSubCategorieFor')} {subCat.name}</label>
                                <input type="text" id="subName" value={thirdSubcategoryNameFieldValue} onChange={(e) => setThirdSubcategoryNameFieldValue(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('addSubCategory')} />
                              </div>

                              <div className='flex justify-end my-2'>
                                <div onClick={() => addThirdClassLevel(category.id, subCat.id)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover cursor-pointer'>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                                    <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                                  </svg>

                                  <span className="mx-2">
                                    {t('save')}
                                  </span>
                                </div>
                              </div>
                            </div>
                          }

                          {
                            permissions.create &&
                            <div className='mb-5'>
                              <button onClick={(e) => { setAddThirdSubcategoryField(subCat.id) }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                                {t('addSubCategoryForSub')}
                              </button>
                            </div>
                          }
                        </div>

                      </div>
                    )
                  })
                  }

                  {addSubcategoryField === category.id &&
                    <div className='my-4'>
                      <div>
                        <label htmlFor="subName" className="block mb-2 text-sm font-medium text-gray-900">{t("addSubCategoryForSubP")} {category.name}</label>
                        <input type="text" id="subName" value={subcategoryNameFieldValue} onChange={(e) => setSubcategoryNameFieldValue(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder='Add Sub category' />
                      </div>

                      <div className='flex justify-end my-2'>
                        <div onClick={() => addSecondClassLevel(category.id)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover cursor-pointer'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                            <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                          </svg>

                          <span className="mx-2">
                            {t('save')}
                          </span>
                        </div>
                      </div>
                    </div>
                  }

                  {permissions.create &&
                    <div>
                      <button onClick={(e) => { setAddSubcategoryField(category.id) }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                        {t('addSubCategory')}
                      </button>
                    </div>
                  }
                </div>
              </div>
            )
          })
          }

          {addcategoryField &&
            <div className='my-4'>
              <div>
                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">{t('categoryName')}</label>
                <input type="text" id="name" value={categoryNameFieldValue} onChange={(e) => setcategoryNameFieldValue(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder='Name' required />
              </div>

              <div className='flex justify-end my-2'>
                <div onClick={() => addFirstClassLevel()} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover cursor-pointer'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                    <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                  </svg>

                  <span className="mx-2">
                    {t('save')}
                  </span>
                </div>
              </div>
            </div>
          }

          {
            permissions.create &&
            <div className='mt-2'>
              <div onClick={() => { setAddcategoryField(true) }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                  <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                </svg>

                <span className="mx-2">
                  {t('AddCategory')}
                </span>
              </div>
            </div>
          }

          {permissions.edit &&
            <div className='flex justify-end mt-5'>
              <div onClick={() => { Update() }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                  <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                </svg>

                <span className="mx-2">
                  {t('save')}
                </span>
              </div>
            </div>
          }
        </div>
      }


    </section>
  )
}

export default Categories