import React, { useEffect, useState } from 'react'
import { BsFillBoxSeamFill, BsHandbagFill } from 'react-icons/bs'
import { HiMiniUsers } from 'react-icons/hi2'
import { AiTwotoneStar } from 'react-icons/ai'
import { t } from 'i18next'
import {
  useAllStore,
  useBestSellerProducts,
  useClientStatistics,
  useLastThreeDelivery,
  useSaleStatistics,
  useStatistics
} from '../../graphql';
import useStore from '../../store/useStore';
import { BiSolidCartDownload } from 'react-icons/bi'
import { FaTags } from 'react-icons/fa'
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import helloImg from '../../assets/hello.svg';
import SalesBar from '../../components/Charts/SalesBar';
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget';
import ClientChart from '../../components/Charts/ClientChart';

interface Props {
  direction: string
}

const Dashboard = ({ direction }: Props) => {
  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(false);

  const [statistics, { data: dataStatistics }] = useStatistics();
  const [store, { data: dataStore }] = useAllStore();
  const [saleStatistics, { data: dataSaleStatistics }] = useSaleStatistics();
  const [bestSellerProducts, { loading: bestSellerProductsLoading, data: dataBestSellerProducts }] = useBestSellerProducts();
  const [clientsStatistics, { data: dataClientStatistics }] = useClientStatistics();
  const [lastThreeDelivery, { loading: lastDeliveryLoading, data: dataLastThreeDelivery }] = useLastThreeDelivery();
  
  function getDatesInFormat() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const currentDay = String(currentDate.getDate()).padStart(2, '0');

    const aWeekAgo = new Date();
    aWeekAgo.setDate(aWeekAgo.getDate() - 7);
    const aWeekAgoYear = aWeekAgo.getFullYear();
    const aWeekAgoMonth = String(aWeekAgo.getMonth() + 1).padStart(2, '0');
    const aWeekAgoDay = String(aWeekAgo.getDate()).padStart(2, '0');

    const currentDateFormatted = `${currentYear}-${currentMonth}-${currentDay}`;
    const aWeekAgoFormatted = `${aWeekAgoYear}-${aWeekAgoMonth}-${aWeekAgoDay}`;

    return { currentDateFormatted, aWeekAgoFormatted };
  }

  useEffect(() => {
    const { currentDateFormatted, aWeekAgoFormatted } = getDatesInFormat();

    storeData &&
      (async () => {
        await statistics({
          variables: { idStore: storeData.id }
        })
        await store({
          variables: { storeId: storeData.id }
        })

        await saleStatistics({
          variables: {
            idStore: storeData?.id,
            lastDay: currentDateFormatted,
            firstDay: aWeekAgoFormatted,
          }
        })

        await bestSellerProducts({
          variables: { idStore: storeData.id }
        })

        await lastThreeDelivery({
          variables: { idStore: storeData.id }
        })

        await clientsStatistics({
          variables: {
            idStore: storeData?.id,
            lastDay: currentDateFormatted,
            firstDay: aWeekAgoFormatted,
          }
        })

        setInitialLoading(false)
      })()
  }, [storeData]);

  function calculateRepresentativeRating(ratingsArray: any) {
    if (ratingsArray.length !== 0) {

      // Find the minimum and maximum ratings in the array
      let minRating = 5; // Initialize with the highest possible value
      let maxRating = 1; // Initialize with the lowest possible value

      for (const ratingObj of ratingsArray) {
        if (ratingObj.mark < minRating) {
          minRating = ratingObj.mark;
        }
        if (ratingObj.mark > maxRating) {
          maxRating = ratingObj.mark;
        }
      }

      // Calculate the middle point between min and max
      const middleRating = (maxRating + minRating) / 2;

      // Return the middle point as a representative rating (rounded to the nearest whole number)
      return Math.round(middleRating);
    } else {
      return 0
    }
  }

  return (
    <section className='pb-5 mx-3 md:mx-7'>
      <div className='justify-between gap-5 px-4 py-5 mt-5 bg-white rounded-md sm:flex'>
        <div className='flex flex-col justify-between col-span-2'>
          <div>
            <h3 className='text-2xl font-semibold text-black/70'>{t('welcome')} {userData.name} {t("toAtlaeq")} 👋</h3>
            <p className='w-full pt-2 text-sm text-gray-600 sm:w-5/6'>{t('grettings text')}</p>
          </div>

          <div className='mt-2'>
            <Link target='_blank' to={`https://store-landing.qafilaty.com/store/${dataStore?.store.link}`} className='flex font-semibold gap-1.5 items-center duration-300 rounded-md text-primary hover:underline'>
              <FiExternalLink />
              {t('visiteStore')}
            </Link>
          </div>
        </div>

        <div className='flex items-center justify-center col-span-1 p-5 mt-8 sm:p-0 sm:mt-0 sm:block'>
          <img src={helloImg} alt='hello' className='w-48 h-48' />
        </div>
      </div>

      <div className='mt-5'>
        <h3 className='text-2xl font-semibold text-black/70'>{t("Overview for this month")}</h3>
      </div>

      <div className="grid grid-cols-1 gap-4 my-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <Link to="/brands">
          <div className="flex items-center gap-5 p-5 bg-white rounded-md lg:p-6">
            <div className="inline-flex items-center justify-center p-3 text-blue-500 rounded-full bg-blue-50">
              <FaTags size={30} />
            </div>
            <div>
              <p className="text-3xl font-semibold leading-relaxed text-black/70">{initialLoading ? "-" : dataStatistics?.statisticsStores.numberBrands}</p>
              <h2 className="mb-1 font-medium text-gray-500 title-font">{t('numberBrands')}</h2>
            </div>
          </div>
        </Link>

        <Link to="/products">
          <div className="flex items-center gap-5 p-5 bg-white rounded-md lg:p-6">
            <div className="inline-flex items-center justify-center p-3 text-orange-500 rounded-full bg-orange-50">
              <BsFillBoxSeamFill size={30} />
            </div>
            <div>
              <p className="text-3xl font-semibold leading-relaxed text-black/70">{initialLoading ? "-" : dataStatistics?.statisticsStores.numberProducts}</p>
              <h2 className="mb-1 font-medium text-gray-500 title-font">{t('stockProducts')}</h2>
            </div>
          </div>
        </Link>

        <Link to="/categories">
          <div className="flex items-center gap-5 p-5 bg-white rounded-md lg:p-6">
            <div className="inline-flex items-center justify-center rounded-full bg-[#FF8F6B]/10 text-[#FF8F6B] p-3">
              <BsHandbagFill size={30} />
            </div>
            <div>
              <p className="text-3xl font-semibold leading-relaxed text-black/70">{initialLoading ? "-" : dataStatistics?.statisticsStores.numberCategories}</p>
              <h2 className="mb-1 font-medium text-gray-500 title-font">{t('numberCategories')}</h2>
            </div>
          </div>
        </Link>

        <Link to="/clients">
          <div className="flex items-center gap-5 p-5 bg-white rounded-md lg:p-6">
            <div className="inline-flex items-center justify-center rounded-full bg-[#EFEBFE] text-[#605BFF] p-3">
              <HiMiniUsers size={30} />
            </div>
            <div>
              <p className="text-3xl font-semibold leading-relaxed text-black/70">{initialLoading ? "-" : dataStatistics?.statisticsStores.numberClients}</p>
              <h2 className="mb-1 font-medium text-gray-500 title-font">{t('numberClient')}</h2>
            </div>
          </div>
        </Link>

        <Link to="/orders">
          <div className="flex items-center gap-5 p-5 bg-white rounded-md lg:p-6">
            <div className="inline-flex items-center justify-center p-3 text-[#F2383A] rounded-full bg-[#FFE9E9]">
              <BiSolidCartDownload size={30} />
            </div>
            <div>
              <p className="text-3xl font-semibold leading-relaxed text-black/70">{initialLoading ? "-" : dataStatistics?.statisticsStores.numberOrders}</p>
              <h2 className="mb-1 font-medium text-gray-500 title-font">{t('numberOrders')}</h2>
            </div>
          </div>
        </Link>
      </div>

      <div className='grid grid-cols-1 my-4 lg:gap-3 lg:grid-cols-3'>
        <div className='bg-white rounded-md md:col-span-2'>
          <SalesBar dataSaleStatistics={dataSaleStatistics} />
        </div>

        <div className='mt-3 bg-white rounded-md md:col-span-1 lg:mt-0'>
          <h3 className='px-6 py-3 text-2xl font-semibold text-black/70'>{t('clientsDay')}</h3>
          <div className='flex justify-center h-full mt-4 md:items-center md:mt-0'>
            <ClientChart dataClientStatistics={dataClientStatistics} />
          </div>
        </div>
      </div>

      <div className='grid grid-cols-1 gap-3 my-4 md:grid-cols-3'>
        <div className='px-4 overflow-x-scroll bg-white rounded-md md:col-span-2'>
          <h3 className='py-3 text-2xl font-semibold text-black/70'>{t('recentOrder')}</h3>

          {
            initialLoading || lastDeliveryLoading ?
              <LoadingWidget />
              :
              <div className='flex flex-col gap-2 mt-1'>
                {dataLastThreeDelivery?.lastThreeDeliveries.length === 0 ?
                  <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                      <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                    </svg>
                    <span className='px-2'>{t('noOrders')}</span>
                  </div>
                  :
                  <table className="w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th scope="col" className="px-3 py-3 text-sm font-normal text-left text-gray-500 whitespace-nowrap rtl:text-right">
                          {t('trakingNo')}
                        </th>

                        <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                          {t('numberOfProducts')}
                        </th>

                        <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                          {t('totalAmount')}
                        </th>

                        <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                          {t('date')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataLastThreeDelivery?.lastThreeDeliveries.map(order => (
                        <tr key={order.id}>
                          <td className="px-3 py-3 text-sm">
                            <div>
                              <h2 className="text-gray-700">{order.orderNumber}</h2>
                            </div>
                          </td>
                          <td className="px-3 py-3 text-sm">
                            <p className="text-gray-500">{order.deliveryProduct.length}</p>
                          </td>

                          <td className="px-3 py-4 text-sm">
                            ${order.deliveryMethod ?
                              order?.deliveryMethod?.price + order?.deliveryProduct?.reduce(
                                (accumulator, product: any) => accumulator + (product?.price - order?.discount - order?.coupon) * product?.quantity, 0
                              )
                              :
                              order?.deliveryProduct?.reduce(
                                (accumulator, product: any) => accumulator + (product?.price - order?.discount - order?.coupon) * product?.quantity, 0
                              )}
                          </td>
                          <td className="px-3 py-4 text-sm">
                            {new Date(order.createdAt).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
              </div>
          }
        </div>

        <div className='px-4 bg-white rounded-md md:col-span-1'>
          <h3 className='py-3 text-2xl font-semibold text-black/70'>{t('topSellingProducts')}</h3>
          {
            initialLoading || bestSellerProductsLoading ?
              <LoadingWidget />
              :
              <div className='flex flex-col gap-2 mt-1'>
                {dataBestSellerProducts?.bestSellerProducts.length === 0 ?
                  <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                      <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                    </svg>
                    <span className='px-2'>{t('noProducts')}</span>
                  </div>
                  :
                  dataBestSellerProducts?.bestSellerProducts.slice(0, 3).map(product => (
                    <div key={product.id} className='flex items-center gap-3'>
                      <div>
                        <img src={`https://store-api.qafilaty.com/images/${product.imagesProduct[0]?.name}`} alt="" className='w-24 h-20 bg-blue-100 bg-cover rounded-md' />
                      </div>
                      <div>
                        <h3 className='mb-1 text-md'>{product.name}</h3>

                        <div className='flex items-center mb-0.5'>
                          {[...Array(5)].map((_, index) => (
                            <div className={index < calculateRepresentativeRating(product.rating) ? 'text-yellow-400' : 'text-gray-300'} key={index}>
                              <AiTwotoneStar size={18} />
                            </div>
                          ))}
                        </div>
                        <span className='font-bold'>${product.price}</span>
                      </div>
                    </div>
                  ))
                }
              </div>
          }
        </div>
      </div>

    </section>
  )
}

export default Dashboard