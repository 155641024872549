import { gql, useLazyQuery } from '@apollo/client';
import { FetchRatings } from '../../../types';

export const ALL_RATINGS = gql`
query AllRating($idStore: ID) {
  allRating(idStore: $idStore) {
    id
    mark
    description
    product {
      id
      name
      price
      priceAfterDiscount
      cost
      quantity
      weight
      shortDescription
      description
      notRequireDelivery
      freeShipping
      posting
      imagesProduct {
        id
        name
        alt
        createdAt
        updatedAt
        deletedAt
      }
      brand {
        id
        name
        image
        classFirstLevel {
          id
          name
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      classThirdLevel {
        id
        name
        classSecondLevel {
          id
          name
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      productOptions {
        id
        name
        subOptions {
          id
          name
          price
          quantity
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      rating {
        id
        mark
        description
        createdAt
        updatedAt
        deletedAt
      }
      numberSales
      store {
        id
        name
        link
        logo
        icon
        description
        address
        welcomeMessage
        cssFile
        listSubscription {
          subscription {
            id
            planName
            price
            discount
            features
            published
            createdAt
            updatedAt
            deletedAt
          }
          startDate
          endDate
          price
          createdAt
          updatedAt
          deletedAt
        }
        numberProducts
        numberOrders
        numberClients
        admin {
          id
          profilePicture
          name
          email
          phone
          role
          activation
          emailVerify
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    client {
      id
      profilePicture
      name
      email
      phone
      dateBirth
      address
      gander
      note
      addressClient {
        id
        name
        phone
        city
        address
        asDefault
      }
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`;

export const useAllRating = () => {
  return useLazyQuery<FetchRatings>(ALL_RATINGS);
}