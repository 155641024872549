import React, { useState } from 'react'
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import demo1 from '../../../../assets/demo-1.png';

const Theme = () => {
  const [hoveredElement, setHoveredElement] = useState(null);

  const handleMouseEnter = (element: any) => {
    setHoveredElement(element);
  };

  const handleMouseLeave = () => {
    setHoveredElement(null);
  };

  return (
    <section className='mx-3 my-4 md:mx-7'>
      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('themeStore')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div className='grid grid-cols-3 gap-4 my-3'>
        <div className='flex flex-col justify-between p-1 bg-white border'>
          <div className='relative' onMouseEnter={() => handleMouseEnter('div1')} onMouseLeave={handleMouseLeave}>
            <img src={demo1} alt="" className='w-full overflow-hidden' />

            <div className={`${hoveredElement !== 'div1' ? "opacity-0" : "opacity-100"} absolute w-full h-full top-0 transition-opacity duration-300 ease-in-out bg-white/80 backdrop-blur-sm`}>
              <div className='flex items-center justify-center h-full'>
                <Link target='_blank' to="https://store-landing.qafilaty.com/en/store/DemoElectronicDevices" className='flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 9a3.02 3.02 0 0 0-3 3c0 1.642 1.358 3 3 3 1.641 0 3-1.358 3-3 0-1.641-1.359-3-3-3z"></path><path d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 12c-5.351 0-7.424-3.846-7.926-5C4.578 10.842 6.652 7 12 7c5.351 0 7.424 3.846 7.926 5-.504 1.158-2.578 5-7.926 5z"></path>
                  </svg>

                  <span className="mx-2">
                    {t('view')}
                  </span>
                </Link>
              </div>
            </div>

          </div>
          <div className='flex items-center justify-between px-2 my-2'>
            <span className='text-gray-500'>
              {t('free')}
            </span>

            <div className='text-green-500'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M19 3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zm-7.933 13.481-3.774-3.774 1.414-1.414 2.226 2.226 4.299-5.159 1.537 1.28-5.702 6.841z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Theme