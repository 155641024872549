import { gql } from '@apollo/client';

export const CreateDefinitionPage = gql`
mutation CreateDefinitionPage($content: contentDefinitionPage!) {
  createDefinitionPage(content: $content) {
    id
    title
    description
    publish
    createdAt
    updatedAt
    deletedAt
  }
}
`;