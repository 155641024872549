import React, { useEffect, useState } from 'react'
import { BrandProps, FetchBrands, checkedItems } from '../../types'
import BrandsDropdown from '../DropDowns/BrandsDropDown'
import { t } from 'i18next'

interface TableProps {
  checkedItems: checkedItems[],
  setCheckedItems: React.Dispatch<React.SetStateAction<checkedItems[]>>
  direction: string
  searchQuery: string
  searchResult: BrandProps[] | undefined
  allBrands?: FetchBrands
  setSelectedIdForDelete: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfPopup: React.Dispatch<React.SetStateAction<boolean>>
  setFailMsg: React.Dispatch<React.SetStateAction<string>>
  startingIndex: number
  permissions: {
    create: boolean,
    edit: boolean,
    delete: boolean,
  }
}

const BrandsTable = ({ checkedItems, setCheckedItems, permissions, direction, searchQuery, searchResult, allBrands, setSelectedIdForDelete, setDeleteConfPopup, startingIndex }: TableProps) => {

  const handleCheckAll = () => {
    if (allBrands?.allBrands.length !== checkedItems.length) {
      allBrands !== undefined && setCheckedItems(allBrands?.allBrands.map(brand => ({ id: brand.id })))
    } else {
      setCheckedItems([])
    }
  };

  const handleCheck = (id: string) => {
    const isItemExist = checkedItems.some(item => item.id === id);

    if (isItemExist) {
      const newCheckedItems = checkedItems.filter(item => item.id !== id);
      setCheckedItems(newCheckedItems);
    } else {
      setCheckedItems([...checkedItems, { id: id }])
    }
  };

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500">
            <div className='relative flex'>
              <input type="checkbox" checked={allBrands?.allBrands.length === checkedItems.length} onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </th>

          <th scope="col" className="py-3.5 px-10 text-sm font-semibold text-left rtl:text-right text-gray-500">
            {t('brand')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
            {t('name')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
            {t('categories')}
          </th>

          {permissions.edit || permissions.delete ?
            <th scope="col" className="font-semibold px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500">
              {t('option')}
            </th>
            :
            null
          }
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {searchQuery !== "" ?
          searchResult?.slice(startingIndex, startingIndex + 10).map(brand => (
            <tr key={brand.id}>
              <td className="px-4 py-3 text-sm text-left text-gray-500 rtl:text-right">
                <div className='relative flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === brand.id)} onChange={() => handleCheck(brand.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>

              <td className='px-10 py-3 text-sm'>
                <img className='w-14 h-14' src={`https://store-api.qafilaty.com/images/${brand.image}`} alt="" />
              </td>

              <td className='px-4 py-3 text-sm whitespace-nowrap'>
                {brand.name}
              </td>

              <td className='px-4 py-3 text-sm'>
                {brand?.classFirstLevel[0]?.name}
              </td>

              {permissions.edit || permissions.delete ?
                <td className='px-4 py-3 text-sm'>
                  <BrandsDropdown permissions={permissions} direction={direction} id={brand.id} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} />
                </td>
                :
                null
              }
            </tr>
          ))
          :
          allBrands?.allBrands.map(brand => {
            return (
              <tr key={brand.id}>
                <td className="px-4 py-3 text-sm text-left text-gray-500 rtl:text-right">
                  <div className='relative flex'>
                    <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === brand.id)} onChange={() => handleCheck(brand.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                    <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </div>
                </td>

                <td className='px-10 py-3 text-sm'>
                  <img className='w-14 h-14' src={`https://store-api.qafilaty.com/images/${brand.image}`} alt="" />
                </td>

                <td className='px-4 py-3 text-sm whitespace-nowrap'>
                  {brand.name}
                </td>

                <td className='px-4 py-3 text-sm'>
                  {brand?.classFirstLevel[0]?.name}
                </td>

                {permissions.edit || permissions.delete ?
                  <td className='px-4 py-3 text-sm'>
                    <BrandsDropdown permissions={permissions} direction={direction} id={brand.id} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} />
                  </td>
                  :
                  null
                }
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}

export default BrandsTable