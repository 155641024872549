import { gql } from "@apollo/client";

export const CreateYouTubeVideo = gql`
mutation CreateYouTubeVideo($content: contentYouTubeVideo!) {
  createYouTubeVideo(content: $content) {
    id
    link
    createdAt
    updatedAt
    deletedAt
  }
}
`;
