import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import useStore from '../../../../../store/useStore';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import FailAlert from '../../../../../components/Alerts/FailAlert';
import { useMutation } from '@apollo/client';
import { ALL_RECTANGLE_BANNER, CreateRectangleBanner, useAllBrands, useAllClassFirstLevel, useAllProducts } from '../../../../../graphql';
import LoadingWidget from '../../../../../components/LoadingWidget/LoadingWidget';
import { MdClose } from 'react-icons/md'
import { t } from 'i18next';

interface Props {
  setBannersPopup: React.Dispatch<React.SetStateAction<boolean>>,
  setSuccessMsg: React.Dispatch<React.SetStateAction<string>>,
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
}

const AddBanners = ({ setBannersPopup, setSuccess, setSuccessMsg }: Props) => {
  const storeData = useStore((state: any) => state.userData.store);

  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [selectedLink, setSelectedLink] = useState("product");
  const [selectedLink2, setSelectedLink2] = useState("product");
  const [selectedOption, setSelectedOption] = useState<any>("");
  const [selectedOption2, setSelectedOption2] = useState<any>("");

  const [linkName, setLinkName] = useState("");
  const [link2Name, setLink2Name] = useState("");

  const [linkDrop, setLinkDrop] = useState(false);
  const [optionDrop, setOptionDrop] = useState(false);
  const [link2Drop, setLink2Drop] = useState(false);
  const [option2Drop, setOption2Drop] = useState(false);

  const [isExternLink, setIsExternLink] = useState(false);
  const [isExternLink2, setIsExternLink2] = useState(false);

  // Drops ref's
  const linkRef = useRef<any>();
  const OptionRef = useRef<any>();
  const link2Ref = useRef<any>();
  const Option2Ref = useRef<any>();

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (!isExternLink) {
        if (linkRef.current || OptionRef.current) {
          if (linkRef.current.contains(e.target as Node) || OptionRef.current.contains(e.target as Node)) {
            return
          }

          // else close the dropdown
          setLinkDrop(false);
          setOptionDrop(false);

        }
      }

      if (!isExternLink2) {
        if (link2Ref.current || Option2Ref.current) {
          if (link2Ref.current.contains(e.target as Node) || Option2Ref.current.contains(e.target as Node)) {
            return
          }

          setLink2Drop(false);
          setOption2Drop(false);
        }

      }
    })
  }, [])

  // File input
  const [selectedPreviewImagesUrl, setSelectedPreviewImagesUrl] = useState<string[]>([]);
  const [selectedPreview2ImagesUrl, setSelectedPreview2ImagesUrl] = useState<string[]>([]);
  const [imageToCrop, setImageToCrop] = useState<any>();
  const [cropPopup, setCropPopup] = useState(false);

  const [selectedImage, setSelectedImage] = useState<File>();
  const [selectedImage2, setSelectedImage2] = useState<File>();

  // Create
  const [createRectangleBanner, { error, loading: CreateLoading }] = useMutation(CreateRectangleBanner);

  const cropperRef = useRef<any>();

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [allProducts, { loading: ProductLoading, data: dataAllProducts }] = useAllProducts();
  const [classFirstLevelData, { loading: CategoriesLoading, data: dataClassFirstLevelData }] = useAllClassFirstLevel();
  const [allBrands, { loading: BrandLoading, data: dataAllBrands }] = useAllBrands();

  useEffect(() => {
    setInitialLoading(true);

    if (storeData) {
      (async () => {
        await allProducts({
          variables: { idStore: storeData.id }
        })

        await classFirstLevelData({
          variables: { idStore: storeData.id }
        })

        await allBrands({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()
    }

  }, [storeData]);

  // this state contain what image is about to get crop (first or second image)
  const [typeOfimg, setTypeOfimg] = useState("");

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>, type: string) => {
    type === "first" && setTypeOfimg(type)
    type === "second" && setTypeOfimg(type)

    const files = e.target.files;

    if (files && files.length > 0) {
      const urls = Array.from(files).map((file) => URL.createObjectURL(file));

      setImageToCrop(urls[0]);
      setCropPopup(true)
    }
  };

  const handleCrop = () => {
    if (cropperRef.current) {

      if (typeof cropperRef.current.cropper.getCroppedCanvas() === 'undefined') {
        return;
      }
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      const croppedImageUrl = cropperRef.current.cropper.getCroppedCanvas().toDataURL();

      typeOfimg === "first" && setSelectedPreviewImagesUrl([croppedImageUrl]);
      typeOfimg === "second" && setSelectedPreview2ImagesUrl([croppedImageUrl]);

      croppedCanvas.toBlob((blob: any) => {
        const file = new File([blob], "cropped-image.png", { type: "image/png" });
        typeOfimg === "first" && setSelectedImage(file);
        typeOfimg === "second" && setSelectedImage2(file);
      });
      setCropPopup(false);
    }
  };

  const handlePopupClose = () => {
    setImageToCrop("");
    setCropPopup(false);
  };

  const submit = async (e: any) => {
    e.preventDefault();

    if (!isExternLink) {
      if (selectedOption === "") {
        if (selectedLink === "product") {
          setFailMsg(t('haveToSelectProduct'))
          setFail(true)
          return
        }
        if (selectedLink === "brand") {
          setFailMsg(t('haveToSelectBrand'))
          setFail(true)
          return
        }
        if (selectedLink === "categories") {
          setFailMsg(t('haveToSelectCategory'))
          setFail(true)
          return
        }
      }
    }

    if (!isExternLink2) {
      if (selectedOption2 === "") {
        if (selectedLink2 === "product") {
          setFailMsg(t('haveToSelectProduct'))
          setFail(true)
          return
        }
        if (selectedLink2 === "brand") {
          setFailMsg(t('haveToSelectBrand'))
          setFail(true)
          return
        }
        if (selectedLink2 === "categories") {
          setFailMsg(t('haveToSelectCategory'))
          setFail(true)
          return
        }
      }
    }

    if (isExternLink) {
      if (linkName === "") {
        setFailMsg(t('invalidLink'))
        setFail(true)
        return
      }
    }

    if (isExternLink2) {
      if (link2Name === "") {
        setFailMsg(t('invalidLink'))
        setFail(true)
        return
      }
    }

    if (selectedImage === undefined || selectedImage2 === undefined) {
      setFailMsg(t('haveToSelectImage'))
      setFail(true)
      return
    }

    await createRectangleBanner({
      variables: {
        content: [
          {
            idStore: storeData.id,
            image: selectedImage,
            link: isExternLink ? linkName : selectedOption !== "" ? selectedOption.id : "",
            type: isExternLink ? "external" : selectedLink,
            rank: 5
          },
          {
            idStore: storeData.id,
            image: selectedImage2,
            link: isExternLink2 ? link2Name : selectedOption2 !== "" ? selectedOption2.id : "",
            type: isExternLink2 ? "external" : selectedLink2,
            rank: 5
          }
        ]
      },
      refetchQueries: [{ query: ALL_RECTANGLE_BANNER, variables: { idStore: storeData.id } }]
    })


    if (!error) {
      setSuccessMsg(t('bannersAddedSuccess'));
      setSuccess(true);
      setBannersPopup(false)
    }
  }

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>
      {cropPopup && (
        <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>
          <div className='relative w-full max-h-full p-3 overflow-y-auto bg-white border md:w-5/6'>
            <Cropper
              ref={cropperRef}
              src={imageToCrop}
              style={{ height: 400, width: '100%' }}
            />

            <div className='flex justify-end gap-2 mt-3'>
              <button onClick={handleCrop} className="px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-gray-900 focus:z-10">{t('cropImage')}</button>
              <button onClick={handlePopupClose} className="px-3 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700">{t('close')}</button>
            </div>
          </div>
        </div>
      )}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='w-full p-2 overflow-y-auto bg-white border md:w-5/6 h-[90%]'>
        {
          CreateLoading &&
          <div className='absolute top-0 left-0 z-30 flex items-center justify-center w-full h-full py-10 bg-white/70'>
            <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg>
          </div>
        }

        <div className='flex justify-end py-2 text-gray-600'>
          <div onClick={() => setBannersPopup(false)} className='cursor-pointer'>
            <MdClose size={20} />
          </div>
        </div>

        <div className='mb-8'>
          {!isExternLink &&
            <>
              <div className='my-3' ref={linkRef}>
                <div className="relative mb-2">
                  <label className="block mb-2 text-sm font-medium text-gray-900">{t('selectLink')}</label>

                  <button onClick={(e) => { e.preventDefault(); setLinkDrop(!linkDrop) }} className="z-10 flex items-center justify-between w-full p-2 text-left text-gray-700 border border-gray-300 rounded-md bg-gray-50 focus:border-primary focus:outline-none">
                    <span>
                      {selectedLink}
                    </span>

                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M16.939 10.939 12 15.879l-4.939-4.94-2.122 2.122L12 20.121l7.061-7.06z"></path>
                      <path d="M16.939 3.939 12 8.879l-4.939-4.94-2.122 2.122L12 13.121l7.061-7.06z"></path>
                    </svg>
                  </button>
                  {linkDrop &&
                    <div className="absolute right-0 z-20 w-full py-2 mt-2 origin-top-right bg-white border border-gray-300 rounded-md shadow-xl">
                      <div>
                        <div onClick={() => { setLinkDrop(false); setSelectedOption(""); setSelectedLink("product") }} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                          <span className="mx-1">
                            {t('productLink')}
                          </span>
                        </div>

                        <div onClick={() => { setLinkDrop(false); setSelectedOption(""); setSelectedLink("categories") }}>
                          <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                            <span className="mx-1">
                              {t('categoriesLink')}
                            </span>
                          </div>
                        </div>

                        <div onClick={() => { setLinkDrop(false); setSelectedOption(""); setSelectedLink("brand") }}>
                          <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                            <span className="mx-1">
                              {t('brandLink')}
                            </span>
                          </div>
                        </div>

                        <div onClick={() => { setLinkDrop(false); setSelectedOption(""); setSelectedLink("Best Selling") }}>
                          <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                            <span className="mx-1">
                              {t('bestSelling')}
                            </span>
                          </div>
                        </div>

                        <div onClick={() => { setLinkDrop(false); setSelectedOption(""); setSelectedLink("Newest Products") }}>
                          <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                            <span className="mx-1">
                              {t('newestProducts')}
                            </span>
                          </div>
                        </div>

                        <div onClick={() => { setLinkDrop(false); setSelectedOption(""); setSelectedLink("Discounts Products") }}>
                          <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                            <span className="mx-1">
                              {t('discountsProducts')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>

              <div ref={OptionRef}>
                {selectedLink !== "" &&
                  selectedLink !== "Newest Products" && selectedLink !== "Best Selling" && selectedLink !== "Discounts Products" &&
                  <div className="relative mb-4">
                    <button onClick={(e) => { e.preventDefault(); setOptionDrop(!optionDrop) }} className="z-10 flex items-center justify-between w-full p-2 text-left text-gray-700 border border-gray-300 rounded-md bg-gray-50 focus:border-primary focus:outline-none">
                      <span>
                        {selectedOption !== "" ? selectedOption.name : t('select')}
                      </span>

                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M16.939 10.939 12 15.879l-4.939-4.94-2.122 2.122L12 20.121l7.061-7.06z"></path>
                        <path d="M16.939 3.939 12 8.879l-4.939-4.94-2.122 2.122L12 13.121l7.061-7.06z"></path>
                      </svg>
                    </button>

                    {optionDrop &&
                      <div className="absolute right-0 z-20 w-full h-64 py-2 mt-2 overflow-y-auto origin-top-right bg-white border border-gray-300 rounded-md shadow-xl">
                        <div className="relative flex items-center w-full h-12 overflow-hidden bg-white border">
                          <div className="grid w-12 h-full text-gray-300 place-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                          </div>

                          <input className="w-full h-full pr-2 text-sm text-gray-700 outline-none" type="text" id="search" placeholder={t('searchSomething')} />

                        </div>

                        {selectedLink === 'product' ?
                          initialLoading ?
                            <LoadingWidget />
                            :
                            ProductLoading ?
                              <LoadingWidget />
                              :
                              dataAllProducts?.allProducts.length === 0 ?
                                <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                                    <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                                  </svg>
                                  <span className='px-2'>{t('noProducts')}</span>
                                </div>
                                :
                                dataAllProducts?.allProducts.map((product: any) => (
                                  <div key={product.id} onClick={() => { setSelectedOption(product); setOptionDrop(false) }}>
                                    <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                                      <span className="mx-1">
                                        {product.name}
                                      </span>
                                    </div>
                                  </div>
                                ))
                          :
                          selectedLink === 'brand' ?
                            initialLoading ?
                              <LoadingWidget />
                              :
                              BrandLoading ?
                                <LoadingWidget />
                                :
                                dataAllBrands?.allBrands.length === 0 ?
                                  <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                      <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                                      <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                                    </svg>
                                    <span className='px-2'>{t('noBrands')}</span>
                                  </div>
                                  :
                                  dataAllBrands?.allBrands.map((brand: any) => (
                                    <div key={brand.id} onClick={() => { setSelectedOption(brand); setOptionDrop(false) }}>
                                      <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                                        <span className="mx-1">
                                          {brand.name}
                                        </span>
                                      </div>
                                    </div>
                                  ))
                            :
                            selectedLink === 'categories' &&
                              initialLoading ?
                              <LoadingWidget />
                              :
                              CategoriesLoading ?
                                <LoadingWidget />
                                :
                                dataClassFirstLevelData?.allClassFirstLevels.length === 0 ?
                                  <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                      <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                                      <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                                    </svg>
                                    <span className='px-2'>{t('noCategories')}</span>
                                  </div>
                                  :
                                  dataClassFirstLevelData?.allClassFirstLevels.map((category: any) => (
                                    <div key={category.id} onClick={() => { setSelectedOption(category); setOptionDrop(false) }}>
                                      <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                                        <span className="mx-1">
                                          {category.name}
                                        </span>
                                      </div>
                                    </div>
                                  ))
                        }
                      </div>
                    }
                  </div>
                }
              </div>
            </>
          }

          <div className="flex items-start">
            <div className="relative flex items-center h-5">
              <input type="checkbox" checked={isExternLink} onChange={() => { setIsExternLink(!isExternLink) }} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" required />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <label className="ml-2 text-sm font-medium text-gray-800">{t('externalLink')}</label>
          </div>

          {isExternLink &&
            <div className="my-2">
              <label htmlFor="linkName" className="block mb-2 text-sm font-medium text-gray-900">{t('link')}</label>
              <input type="text" id="linkName" value={linkName} onChange={(e) => setLinkName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('link')} required />
            </div>
          }

          <div className="w-full mt-5">
            <span className="flex justify-center items-center text-[12px] mb-1 text-red-500">{/* message */}</span>
            <div className="flex items-center justify-center w-full">
              <label className="flex flex-col w-full h-32 border-2 border-dashed rounded-md cursor-pointer hover:bg-gray-100 hover:border-gray-300">
                <div className="flex flex-col items-center justify-center pt-7">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                  </svg>
                  <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                    {t('selectPhoto')}
                  </p>
                </div>
                <input type="file" onChange={(e) => handleFileSelect(e, "first")} className="opacity-0" multiple={true} />
              </label>
            </div>

            <div className="flex flex-wrap gap-2 mt-2">
              {selectedPreviewImagesUrl.map((url, key) => {
                return (
                  <div key={key} className="relative overflow-hidden">
                    <img className="w-20 h-20 rounded-md" src={url} alt="" />
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className='mb-8'>
          {!isExternLink2 &&
            <>
              <div className='my-3'>
                <div className="relative mb-2" ref={link2Ref}>
                  <label className="block mb-2 text-sm font-medium text-gray-900">Select Link</label>

                  <button onClick={(e) => { e.preventDefault(); setLink2Drop(!linkDrop) }} className="z-10 flex items-center justify-between w-full p-2 text-left text-gray-700 border border-gray-300 rounded-md bg-gray-50 focus:border-primary focus:outline-none">
                    <span>
                      {selectedLink2}
                    </span>

                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M16.939 10.939 12 15.879l-4.939-4.94-2.122 2.122L12 20.121l7.061-7.06z"></path>
                      <path d="M16.939 3.939 12 8.879l-4.939-4.94-2.122 2.122L12 13.121l7.061-7.06z"></path>
                    </svg>
                  </button>
                  {link2Drop &&
                    <div className="absolute right-0 z-20 w-full py-2 mt-2 origin-top-right bg-white border border-gray-300 rounded-md shadow-xl">
                      <div>
                        <div onClick={() => { setLink2Drop(false); setSelectedOption2(""); setSelectedLink2("product") }} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                          <span className="mx-1">
                            {t('productLink')}
                          </span>
                        </div>

                        <div onClick={() => { setLink2Drop(false); setSelectedOption2(""); setSelectedLink2("categories") }}>
                          <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                            <span className="mx-1">
                              {t('categoriesLink')}
                            </span>
                          </div>
                        </div>

                        <div onClick={() => { setLink2Drop(false); setSelectedOption2(""); setSelectedLink2("brand") }}>
                          <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                            <span className="mx-1">
                              {t('brandLink')}
                            </span>
                          </div>
                        </div>

                        <div onClick={() => { setLink2Drop(false); setSelectedOption2(""); setSelectedLink2("Best Selling") }}>
                          <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                            <span className="mx-1">
                              {t('bestSelling')}
                            </span>
                          </div>
                        </div>

                        <div onClick={() => { setLink2Drop(false); setSelectedOption2(""); setSelectedLink2("Newest Products") }}>
                          <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                            <span className="mx-1">
                              {t('newestProducts')}
                            </span>
                          </div>
                        </div>

                        <div onClick={() => { setLink2Drop(false); setSelectedOption2(""); setSelectedLink2("Discounts Products") }}>
                          <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                            <span className="mx-1">
                              {t('discountsProducts')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>

              <div ref={Option2Ref}>
                {selectedLink2 !== "" &&
                  selectedLink2 !== "Newest Products" && selectedLink2 !== "Best Selling" && selectedLink2 !== "Discounts Products" &&
                  <div className="relative mb-4">
                    <button onClick={(e) => { e.preventDefault(); setOption2Drop(!option2Drop) }} className="z-10 flex items-center justify-between w-full p-2 text-left text-gray-700 border border-gray-300 rounded-md bg-gray-50 focus:border-primary focus:outline-none">
                      <span>
                        {selectedOption2 !== "" ? selectedOption2.name : t("select")}
                      </span>

                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M16.939 10.939 12 15.879l-4.939-4.94-2.122 2.122L12 20.121l7.061-7.06z"></path>
                        <path d="M16.939 3.939 12 8.879l-4.939-4.94-2.122 2.122L12 13.121l7.061-7.06z"></path>
                      </svg>
                    </button>

                    {option2Drop &&
                      <div className="absolute right-0 z-20 w-full h-64 py-2 mt-2 overflow-y-auto origin-top-right bg-white border border-gray-300 rounded-md shadow-xl">
                        <div className="relative flex items-center w-full h-12 overflow-hidden bg-white border">
                          <div className="grid w-12 h-full text-gray-300 place-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                          </div>

                          <input className="w-full h-full pr-2 text-sm text-gray-700 outline-none" type="text" id="search" placeholder={t('searchSomething')} />

                        </div>

                        {selectedLink2 === 'product' ?
                          initialLoading ?
                            <LoadingWidget />
                            :
                            ProductLoading ?
                              <LoadingWidget />
                              :
                              dataAllProducts?.allProducts.length === 0 ?
                                <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                                    <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                                  </svg>
                                  <span className='px-2'>{t('noProducts')}</span>
                                </div>
                                :
                                dataAllProducts?.allProducts.map((product: any) => (
                                  <div key={product.id} onClick={() => { setSelectedOption2(product); setOption2Drop(false) }}>
                                    <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                                      <span className="mx-1">
                                        {product.name}
                                      </span>
                                    </div>
                                  </div>
                                ))
                          :
                          selectedLink2 === 'brand' ?
                            initialLoading ?
                              <LoadingWidget />
                              :
                              BrandLoading ?
                                <LoadingWidget />
                                :
                                dataAllBrands?.allBrands.length === 0 ?
                                  <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                      <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                                      <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                                    </svg>
                                    <span className='px-2'>{t('noBrands')}</span>
                                  </div>
                                  :
                                  dataAllBrands?.allBrands.map((brand: any) => (
                                    <div key={brand.id} onClick={() => { setSelectedOption2(brand); setOption2Drop(false) }}>
                                      <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                                        <span className="mx-1">
                                          {brand.name}
                                        </span>
                                      </div>
                                    </div>
                                  ))
                            :
                            selectedLink2 === 'categories' &&
                              initialLoading ?
                              <LoadingWidget />
                              :
                              CategoriesLoading ?
                                <LoadingWidget />
                                :
                                dataClassFirstLevelData?.allClassFirstLevels.length === 0 ?
                                  <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                      <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                                      <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                                    </svg>
                                    <span className='px-2'>{t('noCategories')}</span>
                                  </div>
                                  :
                                  dataClassFirstLevelData?.allClassFirstLevels.map((category: any) => (
                                    <div key={category.id} onClick={() => { setSelectedOption2(category); setOption2Drop(false) }}>
                                      <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                                        <span className="mx-1">
                                          {category.name}
                                        </span>
                                      </div>
                                    </div>
                                  ))
                        }
                      </div>
                    }
                  </div>
                }
              </div>
            </>
          }

          <div className="flex items-start">
            <div className="relative flex items-center h-5">
              <input type="checkbox" checked={isExternLink2} onChange={() => { setIsExternLink2(!isExternLink2) }} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" required />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <label className="mx-2 text-sm font-medium text-gray-800">{t('externalLink')}</label>
          </div>

          {isExternLink2 &&
            <div className="my-2">
              <label htmlFor="linkName" className="block mb-2 text-sm font-medium text-gray-900">Link</label>
              <input type="text" id="linkName" value={link2Name} onChange={(e) => setLink2Name(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder="Link" required />
            </div>
          }

          <div className="w-full mt-5">
            <span className="flex justify-center items-center text-[12px] mb-1 text-red-500">{/* message */}</span>
            <div className="flex items-center justify-center w-full">
              <label className="flex flex-col w-full h-32 border-2 border-dashed rounded-md cursor-pointer hover:bg-gray-100 hover:border-gray-300">
                <div className="flex flex-col items-center justify-center pt-7">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                  </svg>
                  <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                    {t('selectPhoto')}
                  </p>
                </div>
                <input type="file" onChange={(e) => handleFileSelect(e, "second")} className="opacity-0" multiple={true} />
              </label>
            </div>

            <div className="flex flex-wrap gap-2 mt-2">
              {selectedPreview2ImagesUrl.map((url, key) => {
                return (
                  <div key={key} className="relative overflow-hidden">
                    <img className="w-20 h-20 rounded-md" src={url} alt="" />
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className='flex justify-end my-2'>
          <div onClick={(e) => { submit(e) }} >
            <button className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
              <span className="mx-2">
                {t('save')}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddBanners