import React, { useEffect, useState } from 'react'
import { OrderPreviewDetails } from '../../../types/Orders'
import { t } from 'i18next'

interface Tab5Props {
  orderDetails: OrderPreviewDetails,
  submit: () => void,
}

const Tab5 = ({ orderDetails, submit }: Tab5Props) => {

  const [TotalAmount, setTotalAmount] = useState(0)

  useEffect(() => {
    const sum = orderDetails.amount.reduce((total, currentValue) => total = total + currentValue, 0)
    setTotalAmount(sum)
  }, [orderDetails])

  return (
    <div className='my-2'>
      <div>
        <h3 className='text-2xl text-center'>{t('orderDetail')}</h3>
      </div>

      <div className='p-2 m-5 border'>
        <div className='flex items-center justify-between'>
          <span>{t('amount')}</span>
          <p className='text-gray-500'>{TotalAmount}</p>
        </div>
        <div className='w-full h-px mt-2 bg-gray-200'></div>

        <div className='flex items-center justify-between mt-2'>
          <span>{t('shipping')}</span>
          <p className='text-gray-500'>{orderDetails.shipping}</p>
        </div>
        <div className='w-full h-px mt-2 bg-gray-200'></div>
        <div className='flex items-center justify-between mt-2'>
          <span>{t('total')}</span>
          <p className='text-2xl text-gray-500'>{orderDetails.shipping && TotalAmount + orderDetails.shipping}</p>
        </div>
      </div>

      <div className='flex justify-end my-2'>
        <div>
          <button onClick={() => submit()} className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
            <span className="mx-2">
              {t('save')}
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Tab5