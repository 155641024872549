import { gql, useLazyQuery } from '@apollo/client';
import { FetchCss } from '../../../../types';

export const ALL_CSS = gql`
query Query($idStore: ID) {
  cssStore(idStore: $idStore)
}
`;

export const useAllCss = () => {
  return useLazyQuery<FetchCss>(ALL_CSS);
}