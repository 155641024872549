import React, { useEffect, useState } from 'react'
import AddShippingPopup from './AddShippingPopup'
import { useAllDeliveryMethods } from '../../../graphql';
import { checkedItems } from '../../../types';
import ShippingSettingsTable from '../../../components/Tables/ShippingSettingsTable';
import DeleteConfirmPopup from './DeleteConfirmPopup';
import SuccessAlert from '../../../components/Alerts/SuccessAlert';
import FailAlert from '../../../components/Alerts/FailAlert';
import LoadingWidget from '../../../components/LoadingWidget/LoadingWidget';
import useStore from '../../../store/useStore';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';
import PermissionsError from '../../../components/Alerts/PermissionsError';

interface Props {
  direction: string
}

const ShippingSettings = ({ direction }: Props) => {
  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    view: false,
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          view: true,
        })

        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "settings")

      let viewPermission = filtered[0]?.permissions.includes("view shipping methods")

      setPermissions({
        view: viewPermission || false,
      })

    }
  }, [userData])

  // I did scroll to the top because when the component is render it show the bottom of the page because the layout
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [addShippingPopup, setAddShippingPopup] = useState(false);

  // This state hook is to specify which item going to start from in the table
  const [startingIndex, setStartingIndex] = useState(0);

  const [deleteConfPopup, setDeleteConfPopup] = useState(false);

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [allDeliveryMethods, { loading, data: dataAllDeliveryMethods }] = useAllDeliveryMethods();

  useEffect(() => {
    storeData &&
      (async () => {
        await allDeliveryMethods({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()

  }, [storeData]);

  const [selectedIdForDelete, setSelectedIdForDelete] = useState("");

  const [checkedItems, setCheckedItems] = useState<checkedItems[]>([]);

  return (
    <section className='mx-6 my-4'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {addShippingPopup && <AddShippingPopup setAddShippingPopup={setAddShippingPopup} />}

      {deleteConfPopup && <DeleteConfirmPopup checkedItems={checkedItems} setDeleteConfPopup={setDeleteConfPopup} selectedIdForDelete={selectedIdForDelete} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('shippingSettings')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div>
        {initialLoading || loading ?
          <LoadingWidget />
          :
          permissions.view === false ?
            <PermissionsError />
            :
            <>
              <div className='grid grid-cols-1 gap-5 my-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
                <div className='bg-white rounded-md'>
                  <div className='flex items-center justify-center p-8'>
                    <img src="https://cdn.mapp.sa/cPanel/images/smsa.png" className='w-40 h-20 p-5' alt="logo" />
                  </div>
                  <div className='flex items-center justify-center py-2 border-t'>
                    <div className='flex items-center gap-1'>
                      <div className='text-gray-500'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"></path><path d="m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"></path>
                        </svg>
                      </div>
                      <button>
                        {t('connectService')}
                      </button>
                    </div>
                  </div>
                </div>

                <div className='bg-white rounded-md'>
                  <div className='flex items-center justify-center p-8'>
                    <img src="https://cdn.mapp.sa/cPanel/images/aramex.png" className='w-40 h-20 p-5' alt="logo" />
                  </div>
                  <div className='flex items-center justify-center py-2 border-t'>
                    <div className='flex items-center gap-1'>
                      <div className='text-gray-500'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"></path><path d="m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"></path>
                        </svg>
                      </div>
                      <button>
                        {t('connectService')}
                      </button>
                    </div>
                  </div>
                </div>

                <div className='bg-white rounded-md'>
                  <div className='flex items-center justify-center p-8'>
                    <img src="https://cdn.mapp.sa/cPanel/images/aymakan.png" className='w-40 h-20 p-5' alt="logo" />
                  </div>
                  <div className='flex items-center justify-center py-2 border-t'>
                    <div className='flex items-center gap-1'>
                      <div className='text-gray-500'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"></path><path d="m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"></path>
                        </svg>
                      </div>
                      <button>
                        {t('connectService')}
                      </button>
                    </div>
                  </div>
                </div>

                <div className='bg-white rounded-md'>
                  <div className='flex items-center justify-center p-8'>
                    <img src="https://cdn.mapp.sa/cPanel/images/saudi_post.png" className='w-40 h-20 p-5' alt="" />
                  </div>
                  <div className='flex items-center justify-center py-2 border-t'>
                    <div className='flex items-center gap-1'>
                      <div className='text-gray-500'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"></path><path d="m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"></path>
                        </svg>
                      </div>
                      <button>
                        {t('connectService')}
                      </button>
                    </div>
                  </div>
                </div>

                <div className='relative w-full bg-white'>
                  <div className='py-1 text-sm text-center'>
                    {t('freeShippingActive')}
                  </div>
                  <div className='flex items-center justify-center p-8 h-3/5 text-primary'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M20 7h-1.209A4.92 4.92 0 0 0 19 5.5C19 3.57 17.43 2 15.5 2c-1.622 0-2.705 1.482-3.404 3.085C11.407 3.57 10.269 2 8.5 2 6.57 2 5 3.57 5 5.5c0 .596.079 1.089.209 1.5H4c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7c1.103 0 2-.897 2-2V9c0-1.103-.897-2-2-2zm-4.5-3c.827 0 1.5.673 1.5 1.5C17 7 16.374 7 16 7h-2.478c.511-1.576 1.253-3 1.978-3zM7 5.5C7 4.673 7.673 4 8.5 4c.888 0 1.714 1.525 2.198 3H8c-.374 0-1 0-1-1.5zM4 9h7v2H4V9zm2 11v-7h5v7H6zm12 0h-5v-7h5v7zm-5-9V9.085L13.017 9H20l.001 2H13z"></path>
                    </svg>
                  </div>
                  <div className='absolute bottom-0 flex items-center justify-center w-full py-2 border-t'>
                    <div className=''>
                      <button className='px-2 text-white bg-red-600 rounded-md'>
                        {t('disabled')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='p-2 mt-3 bg-white rounded-md'>
                <div className='flex justify-end'>
                  <div onClick={() => { setAddShippingPopup(true) }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover cursor-pointer'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                      <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                    </svg>

                    <span className="mx-2">
                      {t('AddShipping')}
                    </span>
                  </div>
                </div>

                <div className='pt-2 overflow-x-scroll'>
                  <ShippingSettingsTable checkedItems={checkedItems} setCheckedItems={setCheckedItems} direction={direction} allDeliveryMethods={dataAllDeliveryMethods} setSelectedIdForDelete={setSelectedIdForDelete} startingIndex={startingIndex} setStartingIndex={setStartingIndex} setDeleteConfPopup={setDeleteConfPopup} setFailMsg={setFailMsg} />
                </div>

                {dataAllDeliveryMethods?.allDeliveryMethods.length === 0 &&
                  <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                      <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                    </svg>
                    <span className='px-2'>{t('noShippingMethods')}</span>
                  </div>
                }
              </div>
            </>
        }
      </div>
    </section>
  )
}

export default ShippingSettings