import { gql } from "@apollo/client"

export const SelectSubscription = gql`
mutation SelectSubscription($content: contentSelectSubscription!) {
  selectSubscription(content: $content) {
    subscription {
      id
      planName
      price
      discount
      features
      published
      createdAt
      updatedAt
      deletedAt
    }
    startDate
    endDate
    price
    createdAt
    updatedAt
    deletedAt
  }
}
`