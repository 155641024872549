import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react'
import { FetchCountries } from '../../../types';
import { ALL_COUNTRIES, CreatePaymentMethod } from '../../../graphql';
import SuccessAlert from '../../../components/Alerts/SuccessAlert';
import FailAlert from '../../../components/Alerts/FailAlert';
import LoadingWidget from '../../../components/LoadingWidget/LoadingWidget';
import useStore from '../../../store/useStore';
import { t } from 'i18next';
import { MdClose } from 'react-icons/md';

interface Props {
  setAddPaymentPopup: React.Dispatch<React.SetStateAction<boolean>>,
}

const AddPaymentPopup = ({ setAddPaymentPopup }: Props) => {
  const storeData = useStore((state: any) => state.userData.store);

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [selectedBank, setSelectedBank] = useState("");

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [IBAN, setIBAN] = useState("");

  const [countriesDrop, setCountriesDrop] = useState(false);
  const [bankDrop, setBankDrop] = useState(false);

  const { data: allCountries, loading } = useQuery<FetchCountries>(ALL_COUNTRIES, {
    variables: { idStore: storeData.id }
  });

  // Create
  const [createPayment, { error }] = useMutation(CreatePaymentMethod, {
    update: (cache, { data: { createPaymentMethod } }) => {
      cache.modify({
        fields: {
          allPaymentMethods(existedPaymentMethods = []) {
            return [...existedPaymentMethods, createPaymentMethod];
          }
        }
      })
    }
  });

  // Drops ref's
  const countriesRef = useRef<any>();
  const bankRef = useRef<any>();

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (countriesRef.current || bankRef.current) {
        if (countriesRef.current.contains(e.target as Node) || bankRef.current.contains(e.target as Node)) {
          return
        }

        // else close the dropdown
        setCountriesDrop(false);
        setBankDrop(false);
      }
    })
  }, [])

  const submit = async (e: any) => {
    e.preventDefault();

    if (selectedCountry === "") {
      setFailMsg(t('emptyCountry'));
      setFail(true);
      return
    }
    if (selectedBank === "") {
      setFailMsg(t('emptyBank'));
      setFail(true);
      return
    }

    if (name === "") {
      setFailMsg(t('emptyName'));
      setFail(true);
      return
    }
    if (number === "") {
      setFailMsg(t('emptyNumber'));
      setFail(true);
      return
    }
    if (IBAN === "") {
      setFailMsg(t('emptyIBAN'));
      setFail(true);
      return
    }

    await createPayment({
      variables: {
        content: {
          IBAN: IBAN,
          accountName: name,
          bankName: selectedBank,
          idCountry: selectedCountry.id,
          number: number,
          idStore: storeData.id
        }
      }
    })


    if (!error) {
      setSuccessMsg(t('paymentAddSuccess'));
      setSuccess(true);

      // CLear the form
      setSelectedCountry("");
      setSelectedBank("");
      setName("");
      setNumber("");
      setIBAN("");

      setAddPaymentPopup(false)
    }
  }

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='w-5/6 p-2 bg-white border rounded-md sm-w-3/6 md:w-4/6'>
        <div className='flex justify-end py-2 text-gray-600'>
          <div onClick={() => setAddPaymentPopup(false)} className='cursor-pointer'>
            <MdClose size={20} />
          </div>
        </div>

        <div className='my-3'>
          <div className="relative mb-2" ref={countriesRef}>
            <button onClick={(e) => { e.preventDefault(); setCountriesDrop(!countriesDrop) }} className="z-10 flex items-center justify-between w-full p-2 text-left text-gray-700 border border-gray-300 rounded-md bg-gray-50 focus:border-primary focus:outline-none">
              <span>
                {selectedCountry !== "" ? selectedCountry.name : t('select')}
              </span>

              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                <path d="M16.939 10.939 12 15.879l-4.939-4.94-2.122 2.122L12 20.121l7.061-7.06z"></path>
                <path d="M16.939 3.939 12 8.879l-4.939-4.94-2.122 2.122L12 13.121l7.061-7.06z"></path>
              </svg>
            </button>
            {countriesDrop &&
              <div className="absolute right-0 z-20 w-full py-2 mt-2 origin-top-right bg-white border border-gray-300 rounded-md shadow-xl">
                {loading ?
                  <LoadingWidget />
                  :
                  <div>
                    {
                      allCountries?.allCountries.length === 0 ?
                        <div className='px-3'>
                          no countries has been added
                        </div>
                        :
                        allCountries?.allCountries.map((country => {
                          return (
                            <div key={country.id} onClick={() => { setSelectedCountry(country); setCountriesDrop(false) }
                            }>
                              <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                                <span className="mx-1">
                                  {country.name}
                                </span>
                              </div>
                            </div>
                          )
                        }))}
                  </div>
                }
              </div>
            }
          </div>

          {/* <div className="relative mb-4" ref={bankRef}>
            <button onClick={(e) => { e.preventDefault(); setBankDrop(!bankDrop) }} className="z-10 flex items-center justify-between w-full p-2 text-left text-gray-700 border border-gray-300 rounded-md bg-gray-50 focus:border-primary focus:outline-none">
              <span>
                {selectedBank !== "" ? selectedBank : "select"}
              </span>

              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                <path d="M16.939 10.939 12 15.879l-4.939-4.94-2.122 2.122L12 20.121l7.061-7.06z"></path>
                <path d="M16.939 3.939 12 8.879l-4.939-4.94-2.122 2.122L12 13.121l7.061-7.06z"></path>
              </svg>
            </button>
            {bankDrop &&
              <div className="absolute right-0 z-20 w-full py-2 mt-2 origin-top-right bg-white border border-gray-300 rounded-md shadow-xl">
                <div>
                  <div onClick={() => { setSelectedBank("bank 1"); setBankDrop(false) }}>
                    <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                      <span className="mx-1">
                        bank 1
                      </span>
                    </div>
                  </div>
                  <div onClick={() => { setSelectedBank("bank 2"); setBankDrop(false) }}>
                    <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                      <span className="mx-1">
                        bank 2
                      </span>
                    </div>
                  </div>
                  <div onClick={() => { setSelectedBank("bank 3"); setBankDrop(false) }}>
                    <div className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                      <span className="mx-1">
                        bank 3
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div> */}

          <div className="mb-2" ref={bankRef}>
            <label htmlFor="bank" className="block mb-2 text-sm font-medium text-gray-900">{t('bank')}</label>
            <input type="text" id="bank" value={selectedBank} onChange={(e) => setSelectedBank(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('bank')} required />
          </div>

          <div className="mb-2">
            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">{t('name')}</label>
            <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('accountHolderName')} required />
          </div>

          <div className="mb-2">
            <label htmlFor="number" className="block mb-2 text-sm font-medium text-gray-900">{t('accountNumber')}</label>
            <input type="text" id="number" value={number} onChange={(e) => setNumber(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('accountNumber')} required />
          </div>

          <div className="mb-2">
            <label htmlFor="IBAN" className="block mb-2 text-sm font-medium text-gray-900">IBAN</label>
            <input type="text" id="IBAN" value={IBAN} onChange={(e) => setIBAN(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder='IBAN' required />
          </div>
        </div>

        <div className='flex justify-end my-2'>
          <div onClick={(e) => { submit(e) }} >
            <button className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
              <span className="mx-2">
                {t('save')}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div >
  )
}

export default AddPaymentPopup