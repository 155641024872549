import React, { useState } from 'react'
import { MdClose } from 'react-icons/md'
import SuccessAlert from '../../../../../components/Alerts/SuccessAlert';
import FailAlert from '../../../../../components/Alerts/FailAlert';

interface Props {
  setOpenedPopup: React.Dispatch<React.SetStateAction<false | string>>,
}

const GoogleAds = ({ setOpenedPopup }: Props) => {

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='w-5/6 p-2 bg-white border sm:w-4/6 md:w-3/6 rounded-md'>
        <div className='flex justify-end py-2 text-gray-600'>
          <div onClick={() => setOpenedPopup(false)} className='cursor-pointer'>
            <MdClose size={20} />
          </div>
        </div>
        <div className='my-3'>
          merchant
        </div>
      </div>
    </div>
  )
}

export default GoogleAds