import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Logo from '../../assets/originalLogo.png';
import useStore from '../../store/useStore';
import { useAllStore } from '../../graphql';
import { CgHomeAlt } from "react-icons/cg"
import { PiPackageFill } from "react-icons/pi"
import { BiCartAdd, BiBarChart, BiSolidMegaphone, BiLink } from "react-icons/bi"
import { TbUsers } from "react-icons/tb"
import { IoSettingsOutline } from "react-icons/io5"
import { IoIosArrowDown } from "react-icons/io"
import { MdClose } from "react-icons/md"
import { useTranslation } from 'react-i18next';

interface SideBarProps {
  menu: boolean,
  setMenu: React.Dispatch<React.SetStateAction<boolean>>
  direction: string
}

const SideBar = ({ menu, setMenu, direction }: SideBarProps) => {
  const { t } = useTranslation();

  const storeData = useStore((state: any) => state.userData.store);

  const [ProductsCollapsedMenu, setProductsCollapsedMenu] = useState(false);
  const [MarketingCollapsedMenu, setMarketingCollapsedMenu] = useState(false);

  const [SelectedPage, setSelectedPage] = useState<string>("dashboard");
  const [SelectedSubPage, setSelectedSubPage] = useState<string>("");

  const [storeLink, setStoreLink] = useState("");

  const [store, { data: dataStore }] = useAllStore();

  useEffect(() => {
    storeData &&
      (async () => {
        await store({
          variables: { storeId: storeData.id }
        })
      })()
  }, [storeData]);

  useEffect(() => {
    if (storeData && dataStore !== null && dataStore !== undefined) {
      setStoreLink(dataStore.store.link)
    }
  }, [dataStore, storeData])

  const changeDir = (page: string) => {
    setMenu(false);
    setSelectedPage(page)
    setSelectedSubPage("")
  }

  const changeSubDir = (page: string, parentPage: string) => {
    setMenu(false);
    setSelectedPage(parentPage)
    setSelectedSubPage(page)
  }

  return (
    <aside className={`${menu ? "w-full px-5 py-8" : "w-0"} duration-500 flex flex-col fixed md:w-64 h-screen bg-white border-r rtl:border-r-0 rtl:border-l overflow-hidden z-40`}>
      <button className='flex justify-end mb-3 text-sideBarText md:hidden' onClick={() => { setMenu(false) }}>
        <MdClose size={22} />
      </button>

      <div className='border-b border-gray-300 md:py-2'>
        <Link to='/home'>
          <div>
            <img src={Logo} className='h-16' alt="LOGO" />
          </div>
        </Link>
      </div>

      <div className="relative flex flex-col justify-between flex-1 mb-20 overflow-y-auto links mt-7 md:px-5">
        <nav className="flex-1 space-y-3 md:-mx-3">
          <Link to="/home" className={`flex items-center px-3 py-2 ${SelectedPage === 'dashboard' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { changeDir("dashboard") }}>
            <CgHomeAlt size={22} />

            <span className="pt-1 mx-2 font-semibold">{t('dashboard')}</span>
          </Link>

          <div>
            <span className={`flex items-center justify-between px-3 py-2 ${SelectedPage === 'products' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { setProductsCollapsedMenu(!ProductsCollapsedMenu) }}>
              <div className='flex items-center'>
                <PiPackageFill size={22} />

                <span className="pt-1 mx-2 font-semibold">{t('products')}</span>
              </div>
              <div className={`${ProductsCollapsedMenu && "rotate-180"} duration-500`}>
                <IoIosArrowDown />
              </div>
            </span>

            <ul className={`relative ${ProductsCollapsedMenu ? "max-h-[300px] my-2" : "max-h-[0] my-0"} transition-all duration-500 delay-300 overflow-hidden space-y-2`}>
              <div className={`absolute ${direction === "ltr" ? 'left-6' : 'right-6'} top-0 h-full w-0.5 bg-gray-300`}></div>
              <li onClick={() => { changeSubDir("products", "products") }}>
                <Link to="/products" className={`flex items-center px-4 py-2 ${SelectedSubPage === 'products' && "!text-primary bg-sideBarLinkHover"} ${direction === "ltr" ? 'ml-10' : 'mr-10'} text-sm transition duration-200 rounded-lg text-sideBarText hover:text-primary hover:bg-sideBarLinkHover`}>{t('products')}</Link>
              </li>
              <li onClick={() => { changeSubDir("categories", "products") }}>
                <Link to='/categories' className={`flex items-center px-4 py-2 ${SelectedSubPage === 'categories' && "!text-primary bg-sideBarLinkHover"} ${direction === "ltr" ? 'ml-10' : 'mr-10'} text-sm transition duration-200 rounded-lg text-sideBarText hover:text-primary hover:bg-sideBarLinkHover`}>{t('categories')}</Link>
              </li>
              <li onClick={() => { changeSubDir("brands", "products") }}>
                <Link to='/brands' className={`flex items-center px-4 py-2 ${SelectedSubPage === 'brands' && "!text-primary bg-sideBarLinkHover"} ${direction === "ltr" ? 'ml-10' : 'mr-10'} text-sm transition duration-200 rounded-lg text-sideBarText hover:text-primary hover:bg-sideBarLinkHover`}>{t('brands')}</Link>
              </li>
            </ul>
          </div>

          <Link to="/orders" className={`flex items-center px-3 py-2 ${SelectedPage === 'orders' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { changeDir("orders") }}>
            <BiCartAdd size={22} />

            <span className="pt-1 mx-2 font-semibold">{t('orders')}</span>
          </Link>

          <Link to="/clients" className={`flex items-center px-3 py-2 ${SelectedPage === 'clients' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { changeDir("clients") }}>
            <TbUsers size={22} />

            <span className="pt-1 mx-2 font-semibold">{t('clients')}</span>
          </Link>

          <Link to="/reporting" className={`flex items-center px-3 py-2 ${SelectedPage === 'reports' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { changeDir("reports") }}>
            <BiBarChart size={22} />

            <span className="pt-1 mx-2 font-semibold">{t('reports')}</span>
          </Link>

          <div>
            <span className={`flex items-center justify-between px-3 py-2 ${SelectedPage === 'marketing' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { setMarketingCollapsedMenu(!MarketingCollapsedMenu) }}>
              <div className='flex items-center'>
                <BiSolidMegaphone size={22} />

                <span className="pt-1 mx-2 font-semibold">{t('marketing')}</span>
              </div>
              <div className={`${MarketingCollapsedMenu && "rotate-180"} duration-500`}>
                <IoIosArrowDown />
              </div>
            </span>

            <ul className={`relative ${MarketingCollapsedMenu ? "max-h-[300px] my-2" : "max-h-[0] my-0"} transition-all duration-500 delay-300 overflow-hidden space-y-2`}>
              <div className={`absolute ${direction === "ltr" ? 'left-6' : 'right-6'} top-0 h-full w-0.5 bg-gray-300`}></div>

              <li onClick={() => { changeSubDir("affiliateMarketing", "marketing") }}>
                <Link to='/affiliate-marketing' className={`flex items-center px-4 py-2 ${SelectedSubPage === 'affiliateMarketing' && "!text-primary bg-sideBarLinkHover"} ${direction === "ltr" ? 'ml-10' : 'mr-10'} text-sm transition duration-200 rounded-lg text-sideBarText hover:text-primary hover:bg-sideBarLinkHover`}>
                  {t('affiliateMarketing')}
                </Link>
              </li>
              <li onClick={() => { changeSubDir("abandonedCarts", "marketing") }}>
                <Link to='/abandoned-cart' className={`flex items-center px-4 py-2 ${SelectedSubPage === 'abandonedCarts' && "!text-primary bg-sideBarLinkHover"} ${direction === "ltr" ? 'ml-10' : 'mr-10'} text-sm transition duration-200 rounded-lg text-sideBarText hover:text-primary hover:bg-sideBarLinkHover`}>
                  {t('abandonedCarts')}
                </Link>
              </li>
              <li onClick={() => { changeSubDir("discountCoupons", "marketing") }}>
                <Link to='/discount-coupons' className={`flex items-center px-4 py-2 ${SelectedSubPage === 'discountCoupons' && "!text-primary bg-sideBarLinkHover"} ${direction === "ltr" ? 'ml-10' : 'mr-10'} text-sm transition duration-200 rounded-lg text-sideBarText hover:text-primary hover:bg-sideBarLinkHover`}>
                  {t('discountCoupons')}
                </Link>
              </li>
            </ul>
          </div>

          <Link to="/settings" className={`flex items-center px-3 py-2 ${SelectedPage === 'settings' && "!text-primary bg-sideBarLinkHover"} text-sideBarText transition-colors duration-300 transform rounded-lg cursor-pointer hover:bg-sideBarLinkHover hover:text-primary`} onClick={() => { changeDir("settings") }}>
            <IoSettingsOutline size={22} />

            <span className="pt-1 mx-2 font-semibold">{t('settings')}</span>
          </Link>
        </nav>
      </div>

      <div className='absolute bottom-0 left-0 flex items-center justify-center w-full border-t border-gray-300 bg-gray-50'>
        <Link to={`https://store-landing.qafilaty.com/store/${storeLink}`} target="_blank" className='flex items-center justify-center w-5/6 gap-2 py-2 my-3 bg-gray-200 rounded-lg text-sideBarText'>
          <BiLink size={22} />

          <span className='font-semibold'>{t('visiteStore')}</span>
        </Link>
      </div>
    </aside>
  )
}

export default SideBar