import React, { useEffect, useState } from 'react'
import { MdDownloadDone } from 'react-icons/md'
import { IoClose } from 'react-icons/io5'
import i18n from 'i18next';
import { t } from 'i18next'

interface SuccessProps {
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  success: boolean,
  successMsg: string,
}

const SuccessAlert = ({ success, setSuccess, successMsg }: SuccessProps) => {
  const [direction, setDirection] = useState(i18n.dir());

  useEffect(() => {
    const handleLanguageChange = () => {
      setDirection(i18n.dir());
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  // Delete the Alerts after some time if any
  useEffect(() => {
    success && setTimeout(() => setSuccess(false), 5000)
  }, [success])

  return (
    <div className='fixed bottom-0 right-0 z-40 w-full'>
      <div className="flex justify-end">
        <div className={`flex items-center bg-green-100 w-3/6 gap-3 shadow-md px-2 py-3 mb-5 ${direction === "ltr" ? "mr-6" : "ml-6"} mr-6 rounded-xl`}>
          <div className='text-green-500'>
            <MdDownloadDone size={25} />
          </div>

          <div className="flex items-center justify-between w-full">
            <div>
              <p className='text-green-500 font-semibold'>
                {successMsg}
              </p>
            </div>

            <div className="mr-3 text-gray-500 cursor-pointer" onClick={() => setSuccess(false)}>
              <IoClose size={20} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessAlert