import { gql } from '@apollo/client';

export const CreateSocialMedia = gql`
mutation CreateSocialMedia($idStore: ID, $content: [contentSocialMediaStore!]) {
  createSocialMedia(idStore: $idStore, content: $content) {
    id
    name
    url
    createdAt
    updatedAt
    deletedAt
  }
}
`;