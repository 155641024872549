import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ClientsProps, FetchClients, checkedItems } from '../../types'
import { useAllClients } from '../../graphql'
import SuccessAlert from '../../components/Alerts/SuccessAlert'
import FailAlert from '../../components/Alerts/FailAlert'
import DeleteConfirmPopup from './DeleteConfirmPopup'
import ClientsTable from '../../components/Tables/ClientsTable'
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget'
import useStore from '../../store/useStore'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import { BsFilter } from 'react-icons/bs'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'
import { IoArrowBackSharp, IoArrowForward } from 'react-icons/io5'

interface Props {
  direction: string
}

const Clients = ({ direction }: Props) => {
  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    delete: false,
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          create: true,
          edit: true,
          delete: true,
        })

        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "clients")

      let createPermission = filtered[0]?.permissions.includes("create")
      let editPermission = filtered[0]?.permissions.includes("edit")
      let deletePermission = filtered[0]?.permissions.includes("delete")

      setPermissions({
        create: createPermission || false,
        edit: editPermission || false,
        delete: deletePermission || false,
      })
    }
  }, [userData])

  // The state that contains Fetched Client, i didn't use dataAllClients from useAllClients beacuse i couldn't modify it to contain filtered result
  const [Clients, setClients] = useState<FetchClients>();

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [allClients, { loading, data: dataAllClients }] = useAllClients();

  useEffect(() => {
    storeData &&
      (async () => {
        await allClients({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()

  }, [storeData]);

  useEffect(() => {
    storeData && dataAllClients !== undefined && setClients(dataAllClients)
  }, [dataAllClients, storeData]);

  // Alerts
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [deleteConfPopup, setDeleteConfPopup] = useState(false);

  // This state hook is to specify which item going to start from in the table
  const [startingIndex, setStartingIndex] = useState(0)

  const [showFilterOptions, setShowFilterOptions] = useState(false);

  // The Drop Down of the filter options
  const [CityFilterDropDown, setCityFilterDropDown] = useState(false);
  const [CityFilterQuery, setCityFilterQuery] = useState("");

  const [GanderDropDown, setGanderDropDown] = useState(false);
  const [GanderFilterQuery, setGanderFilterQuery] = useState("");

  const [DateFilter, setDateFilter] = useState(false);
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");

  // Search functionality
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState<ClientsProps[] | undefined>([]);

  useEffect(() => {
    if (storeData) {
      const filtered = dataAllClients?.allClient?.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setSearchResult(filtered);
    }
  }, [searchQuery, dataAllClients, storeData]);

  // This state contains the id of the client that will be deleted
  const [selectedIdForDelete, setSelectedIdForDelete] = useState("");

  // Collect cities
  let uniqueCities: any[] = [];

  // City Filter
  if (storeData) {
    const uniqueCitiesSet = new Set(dataAllClients?.allClient?.map((user) => user.address));
    uniqueCities = Array.from(uniqueCitiesSet);
  }

  useEffect(() => {
    if (CityFilterQuery !== "" && dataAllClients !== undefined) {
      const filtered = dataAllClients?.allClient.filter((item) =>
        item.address.toLowerCase().includes(CityFilterQuery.toLowerCase())
      );

      setClients({ allClient: filtered });
    }

  }, [CityFilterQuery, dataAllClients])

  useEffect(() => {
    if (GanderFilterQuery !== "" && dataAllClients !== undefined) {
      // check if there is date filter
      if (startDate !== "" && endDate !== "") {
        const filtered = dataAllClients?.allClient.filter((selectedCity) =>
          selectedCity.gander === GanderFilterQuery
        );

        const datefiltered = filtered.filter(client => {
          return client.createdAt >= startDate && client.createdAt <= endDate
        })

        setClients({ allClient: datefiltered });
      } else {
        const filtered = dataAllClients?.allClient.filter((selectedCity) =>
          selectedCity.gander === GanderFilterQuery
        );

        setClients({ allClient: filtered });
      }

    }
  }, [GanderFilterQuery, dataAllClients]);

  const clearFilters = () => {
    dataAllClients !== undefined && setClients(dataAllClients)
    setCityFilterQuery("");
    setGanderFilterQuery("");
    setStartDate("");
    setEndDate("");
  }

  const convertToUnixTimestamp = (e: any, place: string) => {
    const dateObject = new Date(e.target.value); // Create a Date object from the selected date string
    const unixTimestamp = Math.floor(dateObject.getTime());

    place === "start" ?
      setStartDate(unixTimestamp)
      :
      setEndDate(unixTimestamp + 8280000000) // add 24 hour to retriev the added in the same day
  }

  const filterDate = () => {
    if (startDate !== "" && endDate !== "" && dataAllClients) {
      if (CityFilterQuery !== "") {
        const filtered = dataAllClients?.allClient.filter(client => {
          return client.createdAt >= startDate && client.createdAt <= endDate
        })
        const Cityfiltered = filtered.filter((item) =>
          item.address.toLowerCase().includes(CityFilterQuery.toLowerCase())
        );

        setClients({ allClient: Cityfiltered });
      } else if (GanderFilterQuery !== "") {

        const filtered = dataAllClients?.allClient.filter(client => {
          return client.createdAt >= startDate && client.createdAt <= endDate
        })

        const Ganderfiltered = filtered.filter((selectedCity) =>
          selectedCity.gander === GanderFilterQuery
        );

        setClients({ allClient: Ganderfiltered });
      } else {
        const filtered = dataAllClients?.allClient.filter(client => {
          return client.createdAt >= startDate && client.createdAt <= endDate
        })

        setClients({ allClient: filtered });
      }
    }

    setDateFilter(false)
  }

  const convertUnixToInputFormat = (unixTimestamp: any) => {
    if (unixTimestamp !== "") {
      const dateObject = new Date(parseInt(unixTimestamp, 10)); // Convert string to integer
      return dateObject.toISOString().split('T')[0]; // Convert to 'YYYY-MM-DD' format
    }

    return ""; // Return empty string if the unixTimestamp is empty
  };

  const [checkedItems, setCheckedItems] = useState<checkedItems[]>([]);

  return (
    <section className='my-4 mx-3 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {deleteConfPopup && <DeleteConfirmPopup checkedItems={checkedItems} setDeleteConfPopup={setDeleteConfPopup} selectedIdForDelete={selectedIdForDelete} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('clients')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        <div className='p-2 mt-3 bg-white rounded-md'>
          <div>
            <div className='my-4 md:flex md:justify-between'>
              <div className="relative flex items-center w-full h-12 overflow-hidden bg-white border rounded-lg md:w-2/6">
                <div className="grid w-12 h-full text-gray-300 place-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>

                <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="w-full h-full pr-2 text-sm text-gray-700 outline-none peer" type="text" id="search" placeholder={t('searchSomething')} />
              </div>

              <div className='gap-3 sm:flex'>

                <button onClick={() => { setShowFilterOptions(!showFilterOptions) }} className='inline-flex items-center justify-center w-full px-5 py-3 my-2 overflow-hidden text-white transition-colors duration-300 rounded-lg shadow md:py-0 bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                  <BsFilter size={20} />

                  <span className="mx-2">
                    {t('filter')}
                  </span>
                </button>

                {permissions.create &&
                  <Link to="/clients/create" className='inline-flex items-center justify-center w-full px-4 py-3 my-2 overflow-hidden text-white transition-colors duration-300 rounded-lg shadow md:py-0 bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                    <AiOutlineAppstoreAdd size={20} />

                    <span className="mx-2">
                      {t('addClient')}
                    </span>
                  </Link>
                }
              </div>
            </div>

            {showFilterOptions &&
              <div>
                <div className='grid grid-cols-2 gap-2'>
                  <div className="relative inline-block">
                    <button onClick={() => { setCityFilterDropDown(!CityFilterDropDown) }} className="z-10 flex items-center justify-between w-full p-2 text-gray-700 bg-gray-100 border rounded-md focus:outline-none">
                      <span className="mx-1">
                        {CityFilterQuery !== "" ? CityFilterQuery : t('country')}
                      </span>
                      <svg className="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z" fill="currentColor"></path>
                      </svg>
                    </button>

                    {CityFilterDropDown &&
                      <div className="absolute right-0 z-20 w-full max-h-[270px] overflow-auto py-2 mt-2 origin-top-right bg-white border rounded-md shadow-xl">
                        {uniqueCities.map((city, index) => (
                          <span key={index} onClick={() => { setCityFilterQuery(city); setCityFilterDropDown(false) }} className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">{city}</span>
                        ))}
                      </div>
                    }
                  </div>

                  <div className="relative inline-block">
                    <button onClick={() => { setGanderDropDown(!GanderDropDown) }} className="z-10 flex items-center justify-between w-full p-2 text-gray-700 bg-gray-100 border rounded-md focus:outline-none">
                      <span className="mx-1">
                        {GanderFilterQuery !== "" ? t(GanderFilterQuery) : t('gander')}
                      </span>
                      <svg className="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z" fill="currentColor"></path>
                      </svg>
                    </button>

                    {GanderDropDown &&
                      <div className="absolute right-0 z-20 w-full max-h-[270px] overflow-auto py-2 mt-2 origin-top-right bg-white border rounded-md shadow-xl">
                        <span onClick={() => { setGanderFilterQuery('male'); setGanderDropDown(false) }} className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                          {t('male')}
                        </span>
                        <span onClick={() => { setGanderFilterQuery('female'); setGanderDropDown(false) }} className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
                          {t('female')}
                        </span>
                      </div>
                    }
                  </div>

                  <div className="relative inline-block col-span-2">
                    <button onClick={() => { setDateFilter(!DateFilter) }} className="z-10 flex items-center justify-between w-full p-2 text-gray-700 bg-gray-100 border rounded-md focus:outline-none">
                      <span className="mx-1">
                        {t('date')}
                      </span>
                      <svg className="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z" fill="currentColor"></path>
                      </svg>
                    </button>

                    {DateFilter &&
                      <div className="absolute right-0 z-20 w-full max-h-[270px] overflow-auto py-2 mt-2 origin-top-right bg-white border rounded-md shadow-xl">
                        <div className='grid grid-cols-2 gap-4 px-2'>
                          <div className='flex flex-col'>
                            <span>Start Date</span>
                            <input className='p-1 my-1 border rounded-md' type="date" value={convertUnixToInputFormat(startDate)} onChange={(e) => convertToUnixTimestamp(e, "start")} />
                          </div>
                          <div className='flex flex-col'>
                            <span>End Date</span>
                            <input className='p-1 my-1 border rounded-md' type="date" value={convertUnixToInputFormat(endDate)} onChange={(e) => convertToUnixTimestamp(e, "end")} />
                          </div>
                        </div>
                        <div className='flex justify-end mx-2'>
                          <button disabled={startDate === "" || endDate === ""} onClick={filterDate} className='px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary hover:bg-primaryHover disabled:opacity-50 disabled:hover:bg-primary'>Filter</button>
                        </div>
                      </div>
                    }
                  </div>
                </div>

                <div className='flex justify-end'>
                  <button onClick={clearFilters} className='px-4 py-3 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary hover:bg-primaryHover'>
                    {t('clear')}
                  </button>
                </div>
              </div>
            }
          </div>

          <div className='pt-2 overflow-x-scroll'>
            <ClientsTable checkedItems={checkedItems} setCheckedItems={setCheckedItems} permissions={permissions} direction={direction} searchQuery={searchQuery} searchResult={searchResult} allClients={Clients} startingIndex={startingIndex} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} setFailMsg={setFailMsg} />
          </div>

          {dataAllClients?.allClient.length === 0 &&
            <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
              </svg>
              <span className='px-2'>{t('noClients')}</span>
            </div>
          }

          {searchQuery !== "" &&
            searchResult !== undefined &&
            searchResult.length === 0 &&
            <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
              </svg>
              <span className='px-2'>{t('notMatch')}</span>
            </div>
          }

          {(GanderFilterQuery !== "" || CityFilterQuery !== "") &&
            Clients?.allClient.length === 0 &&
            <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
              </svg>
              <span className='px-2'>{t('notMatch')}</span>
            </div>
          }

          <div className="flex justify-end gap-1.5 text-gray-500 py-4">
            <span className="font-medium text-gray-700">{startingIndex} - {startingIndex + 10}</span>
            <span>{t('of')} {dataAllClients?.allClient.length} {t('records')}</span>
          </div>

          <div className='flex items-center justify-between'>
            {dataAllClients?.allClient &&
              startingIndex !== 0 &&
              <div className="gap-1.5 text-gray-500 py-2">
                <button onClick={() => setStartingIndex(startingIndex - 10)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  {direction === "ltr" ? <IoArrowBackSharp size={18} /> : <IoArrowForward size={18} />}
                  <span className="mx-2">
                    {t('prev')}
                  </span>
                </button>
              </div>
            }

            {dataAllClients?.allClient &&
              startingIndex + 10 < dataAllClients?.allClient.length &&
              <div className={`${direction === "ltr" ? "ml-auto" : "mr-auto"} gap-1.5 text-gray-500 py-2`} dir='ltr'>
                <button onClick={() => setStartingIndex(startingIndex + 10)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  {direction === "ltr" ? <IoArrowForward size={18} /> : <IoArrowBackSharp size={18} />}
                  <span className="mx-2">
                    {t('next')}
                  </span>
                </button>
              </div>
            }
          </div>
        </div>
      }
    </section>
  )
}

export default Clients