import { gql } from '@apollo/client';

export const CreateServices = gql`
mutation CreateServices($content: contentServices!) {
  createServices(content: $content) {
    id
    google {
      googleMerchantCenterApiKey
      googleAnalyticsApiKey
      googleAdsApiKey
      googleTagManagerApiKey
    }
    socialMedia {
      faceBookApiKey
      twitterApiKey
      tikTokApiKey
      snapchatApiKey
      facebookInstagramCatalogApiKey
    }
    conversation {
      tawkApiKey
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`;