import React, { useState } from 'react'
import { ClientsProps, FetchClients } from '../../types/Clients'
import ClientsDropDown from '../DropDowns/ClientsDropDown'
import { t } from 'i18next'
import { checkedItems } from '../../types'

interface TableProps {
  checkedItems: checkedItems[],
  setCheckedItems: React.Dispatch<React.SetStateAction<checkedItems[]>>
  searchQuery: string,
  searchResult: ClientsProps[] | undefined,
  allClients?: FetchClients
  setSelectedIdForDelete: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfPopup: React.Dispatch<React.SetStateAction<boolean>>
  setFailMsg: React.Dispatch<React.SetStateAction<string>>
  startingIndex: number
  direction: string,
  permissions: {
    create: boolean,
    edit: boolean,
    delete: boolean,
  }
}

const ClientsTable = ({ checkedItems, setCheckedItems, permissions, direction, searchQuery, searchResult, allClients, setSelectedIdForDelete, setDeleteConfPopup, startingIndex }: TableProps) => {

  const handleCheckAll = () => {
    if (allClients?.allClient.length !== checkedItems.length) {
      allClients !== undefined && setCheckedItems(allClients?.allClient.map(client => ({ id: client.id })))
    } else {
      setCheckedItems([])
    }
  };

  const handleCheck = (id: string) => {
    const isItemExist = checkedItems.some(item => item.id === id);

    if (isItemExist) {
      const newChckedItems = checkedItems.filter(item => item.id !== id);
      setCheckedItems(newChckedItems);
    } else {
      setCheckedItems([...checkedItems, { id: id }])
    }
  };

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="px-3 py-4 text-sm text-left text-gray-500 rtl:text-right">
            <div className='relative flex'>
              <input type="checkbox" checked={allClients?.allClient.length === checkedItems.length} onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </th>

          <th scope="col" className="py-3.5 px-3 font-semibold text-sm whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('clientName')}
          </th>

          <th scope="col" className="px-3 py-3.5 font-semibold text-sm whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('phone')}
          </th>

          <th scope="col" className="px-3 py-3.5 font-semibold text-sm whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('email')}
          </th>

          <th scope="col" className="px-3 py-3.5 font-semibold text-sm whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('city')}
          </th>

          <th scope="col" className="px-10 py-3.5 font-semibold text-sm whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('dateOfBirth')}
          </th>

          <th scope="col" className="px-3 py-3.5 font-semibold text-sm whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('gander')}
          </th>

          {permissions.edit || permissions.delete ?
            <th scope="col" className="font-semibold px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500">
              {t('option')}
            </th>
            :
            null
          }
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {/* Show the Search result only when search keyword is set */}
        {searchQuery === "" ?
          allClients?.allClient?.slice(startingIndex, startingIndex + 10).map(client => (
            <tr key={client.id}>
              <td className="px-3 py-4 text-sm font-medium">
                <div className='relative flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === client.id)} onChange={() => handleCheck(client.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>

              <td className="px-3 py-3 text-sm">
                <h2 className="text-gray-800">{client.name}</h2>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{client.phone}</p>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{client.email}</p>
              </td>

              <td className="px-3 py-3 text-sm whitespace-nowrap">
                <p className="text-gray-500">{client.address ? t(client.address) : "N/A"}</p>
              </td>

              <td className="px-10 py-3 text-sm whitespace-nowrap">
                <p className="text-gray-500">{client.dateBirth ? t(client.dateBirth) : "N/A"}</p>
              </td>

              <td className="px-3 py-4 text-sm whitespace-nowrap">
                <p className="text-gray-500">{client.gander ? t(client.gander) : "N/A"}</p>
              </td>

              {permissions.edit || permissions.delete ?
                <td className="px-3 py-4 text-sm">
                  <ClientsDropDown permissions={permissions} direction={direction} id={client.id} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} />
                </td>
                :
                null
              }
            </tr>
          )
          )
          :
          searchResult?.map(client => (
            <tr key={client.id}>
              <td className="px-4 py-4 text-sm font-medium">
                <div className='relative flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === client.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>

              <td className="px-3 py-3 text-sm">
                <h2 className="text-gray-800">{client.name}</h2>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{client.phone}</p>
              </td>

              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{client.email}</p>
              </td>

              <td className="px-3 py-3 text-sm whitespace-nowrap">
                <p className="text-gray-500">{client.address ? t(client.address) : "N/A"}</p>
              </td>

              <td className="px-10 py-3 text-sm whitespace-nowrap">
                <p className="text-gray-500">{client.dateBirth ? t(client.dateBirth) : "N/A"}</p>
              </td>

              <td className="px-3 py-4 text-sm whitespace-nowrap">
                <p className="text-gray-500">{client.gander ? t(client.gander) : "N/A"}</p>
              </td>

              {permissions.edit || permissions.delete ?
                <td className="px-3 py-4 text-sm">
                  <ClientsDropDown permissions={permissions} direction={direction} id={client.id} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} />
                </td>
                :
                null
              }
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

export default ClientsTable