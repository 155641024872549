import { useMutation } from '@apollo/client';
import { t } from 'i18next'
import React from 'react'
import { ALL_CLIENT_ARCHIVED, RestoreClient } from '../../../../graphql';
import useStore from '../../../../store/useStore';
import { FaTrashRestore } from 'react-icons/fa';

interface Props {
  setRestoreConfirmPopup: React.Dispatch<React.SetStateAction<boolean>>,
  setSuccessMsg: React.Dispatch<React.SetStateAction<string>>,
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  selectedIdForRestore: string
}

const RestoreclientPopup = ({ setRestoreConfirmPopup, selectedIdForRestore, setSuccess, setSuccessMsg }: Props) => {
  const storeData = useStore((state: any) => state.userData.store);

  const [restoreClient, { error }] = useMutation(RestoreClient, {
    refetchQueries: [{
      query: ALL_CLIENT_ARCHIVED,
      variables: { idStore: storeData.id }
    }]
  });

  const Restore = async () => {

    await restoreClient({
      variables: { idClient: selectedIdForRestore },
    })

    if (!error) {
      setSuccessMsg(t('clientRestoredSuccess'));
      setSuccess(true);
      setRestoreConfirmPopup(false)
    }
  }

  return (
    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full min-h-screen bg-gray-900/10 backdrop-blur-md'>
      <div className='w-5/6 p-2 bg-white border rounded-md sm:w-4/6 md:w-3/6'>
        <div className="relative p-4 text-center">
          <div className='flex items-center justify-center mb-6 text-gray-400'>
            <FaTrashRestore size={36} />
          </div>

          <p className="mb-4 text-gray-500">
            {t('restoreConfirm')}
          </p>
          <div className="flex items-center justify-center gap-4">
            <button onClick={() => setRestoreConfirmPopup(false)} className="px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-gray-900 focus:z-10">
              {t('cancel')}
            </button>
            <button onClick={() => Restore()} className="px-3 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700">
              {t('confirm')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RestoreclientPopup