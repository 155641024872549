import { gql, useLazyQuery } from "@apollo/client"
import { FetchClientsStatistics } from "../../types";

export const CLIENTS_STATISTICS_STORE = gql`
query ClientStatisticsStore($idStore: ID, $firstDay: String, $lastDay: String) {
  clientStatisticsStore(idStore: $idStore, firstDay: $firstDay, lastDay: $lastDay) {
    day
    totalClient
  }
}
`

export const useClientStatistics = () => {
  return useLazyQuery<FetchClientsStatistics>(CLIENTS_STATISTICS_STORE, {
    fetchPolicy: 'cache-and-network',
  });
}