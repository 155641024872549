import { gql, useLazyQuery } from "@apollo/client"
import { FetchLastThreeDeliveries } from "../../types";

export const LAST_THREE_DELIVERY = gql`
query LastThreeDeliveries($idStore: ID) {
  lastThreeDeliveries(idStore: $idStore) {
    id
    discount
    coupon
    orderNumber
    note
    store {
      id
      name
      link
      logo
      icon
      description
      address
      welcomeMessage
      cssFile
      listSubscription {
        subscription {
          id
          planName
          price
          discount
          features
          published
          createdAt
          updatedAt
          deletedAt
        }
        startDate
        endDate
        price
        createdAt
        updatedAt
        deletedAt
      }
      numberProducts
      numberOrders
      numberClients
      admin {
        id
        profilePicture
        name
        email
        phone
        role
        permissions {
          id
          name
          permissions
          createdAt
          updatedAt
          deletedAt
        }
        activation
        emailVerify
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    client {
      id
      profilePicture
      name
      email
      phone
      dateBirth
      address
      gander
      note
      createdAt
      updatedAt
      deletedAt
    }
    paymentMethod {
      id
      bankName
      accountName
      number
      IBAN
      country {
        id
        name
        currency
        asDefault
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    deliveryProduct {
      product {
        id
        name
        price
        priceAfterDiscount
        cost
        quantity
        weight
        shortDescription
        description
        notRequireDelivery
        freeShipping
        posting
        imagesProduct {
          id
          name
          alt
          createdAt
          updatedAt
          deletedAt
        }
        brand {
          id
          name
          image
          classFirstLevel {
            id
            name
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
          deletedAt
        }
        classThirdLevel {
          id
          name
          classSecondLevel {
            id
            name
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
          deletedAt
        }
        productOptions {
          id
          name
          subOptions {
            id
            name
            price
            quantity
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        rating {
          id
          mark
          description
          createdAt
          updatedAt
          deletedAt
        }
        numberSales
        createdAt
        updatedAt
        deletedAt
      }
      price
      quantity
    }
    addressClient {
      id
      name
      phone
      city
      address
      asDefault
    }
    deliveryMethod {
      id
      name
      price
      paymentWhenReceiving
      pricePaymentWhenReceiving
      estimatedTime
      cities
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`
export const useLastThreeDelivery = () => {
  return useLazyQuery<FetchLastThreeDeliveries>(LAST_THREE_DELIVERY, {
    fetchPolicy: 'cache-and-network',
  });
}