import { useMutation } from '@apollo/client';
import { t } from 'i18next'
import React from 'react'
import { CreateDomain, SuggestDomainStore } from '../../../graphql';
import { FetchSuggestDomainProps } from '../../../types';
import useStore from '../../../store/useStore';
import { TbWorldCheck } from 'react-icons/tb';

interface Props {
  setRequestPopup: Function
  selectedDomain: FetchSuggestDomainProps | undefined
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  setSuccessMsg: React.Dispatch<React.SetStateAction<string>>,
}

const DomainRequest = ({ setRequestPopup, selectedDomain, setSuccessMsg, setSuccess }: Props) => {
  const storeData = useStore((state: any) => state.userData.store);

  const [createDomain] = useMutation(CreateDomain, {
    onError: (error) => {
      console.error(error);
    },
    onCompleted: () => {
      setSuccessMsg(t('domainRequestSucc'));
      setSuccess(true);
      setRequestPopup(false)
    },
    refetchQueries: [{
      query: SuggestDomainStore,
      variables: { idStore: storeData.id }
    }]
  });

  const request = async () => {
    await createDomain({
      variables: {
        content: {
          status: "pending",
          available: true,
          currency: selectedDomain?.availability.currency,
          definitive: selectedDomain?.availability.definitive,
          domain: selectedDomain?.availability.domain,
          idStore: storeData.id,
          period: selectedDomain?.availability.period,
          price: selectedDomain?.availability.price
        }
      }
    })
  }

  return (
    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full min-h-screen bg-gray-900/10 backdrop-blur-md'>
      <div className='w-5/6 p-2 bg-white border rounded-md sm:w-4/6 md:w-3/6'>
        <div className="relative p-4 text-center">
          <div className='flex items-center justify-center pt-2 pb-3'>
            <TbWorldCheck size={42} className='text-gray-400' />
          </div>
          <p className="mb-4 text-gray-500">{t('domainRequestConfirm')}</p>
          <div className="flex items-center justify-center gap-4">
            <button onClick={() => setRequestPopup(false)} className="px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-gray-900 focus:z-10">
              {t('cancel')}
            </button>

            <button onClick={() => request()} className="px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primaryHover">
              {t('confirm')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DomainRequest