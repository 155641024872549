import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'
import { useAllSubscriptions, useAllStore } from '../../../graphql';
import LoadingWidget from '../../../components/LoadingWidget/LoadingWidget';
import { BsCheckAll } from 'react-icons/bs';
import useStore from '../../../store/useStore';
import AddSubscribePopup from './AddSubscribePopup';
import { SubscriptionProps } from '../../../types';

type CurrentSubscription = {
  plan: string,
  price: number,
  daysLeft: number
}

const Subscription = ({ direction }: { direction: string }) => {
  const storeData = useStore((state: any) => state.userData.store);

  // I did scroll to the top because when the component is render it show the bottom of the page because the layout
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const [initialLoading, setInitialLoading] = useState(true);
  const [addSubscribePopup, setAddSubscribePopup] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<undefined | SubscriptionProps>(undefined);

  const [allSubscriptions, { loading, data: dataAllSubscriptions }] = useAllSubscriptions();
  const [Store, { loading: storeLoading, data: dataStore }] = useAllStore();

  useEffect(() => {
    storeData &&
      (async () => {
        await allSubscriptions();
        await Store({
          variables: { storeId: storeData.id }
        })

        setInitialLoading(false)
      })()
  }, [storeData]);

  const [currentSubscriptionData, setCurrentSubscriptionData] = useState<null | CurrentSubscription>(null);

  function isSubscriptionActive(subscription: any) {
    // Get the current Unix timestamp
    var currentTimestamp = Math.floor(new Date().getTime() / 1000);

    // Check if the current timestamp is within the subscription period
    return currentTimestamp >= subscription.startDate && currentTimestamp <= subscription.endDate;
  }

  useEffect(() => {
    // Get the current Unix timestamp
    var currentTimestamp = Math.floor(new Date().getTime() / 1000);

    if (dataStore?.store.listSubscription.length === 0) {
      setCurrentSubscriptionData(null)
      return
    }

    dataStore?.store.listSubscription.forEach((subscription) => {
      if (isSubscriptionActive(subscription)) {
        var timeDifferenceInSeconds = subscription.endDate - currentTimestamp;

        setCurrentSubscriptionData({
          plan: subscription.subscription.planName,
          price: subscription.subscription.price,
          daysLeft: Math.floor(timeDifferenceInSeconds / (24 * 60 * 60))
        })

        return
      } else {
        setCurrentSubscriptionData(null)
        return
      }
    });
  }, [dataStore])


  return (
    <section className='mx-3 my-4 md:mx-7'>
      {addSubscribePopup && <AddSubscribePopup setAddSubscribePopup={setAddSubscribePopup} selectedPlan={selectedPlan} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('subscription')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div className='px-3 py-4 mt-3 bg-white rounded-md'>
        <h3 className='text-xl font-bold'>
          {t('currentSubscription')}: <span className='text-primary'>{currentSubscriptionData?.plan ? currentSubscriptionData?.plan : t('noPlan')}</span>
        </h3>
        <div className='flex items-center gap-2 py-1'>
          <p className='text-gray-600'>
            {t('nextPayment')}:
          </p>
          <div className='px-2 py-px text-white rounded-md bg-primary'>
            {currentSubscriptionData?.plan ? currentSubscriptionData?.daysLeft : 0} {t('day')}
          </div>
        </div>
      </div>

      <div className="my-6">
        <div className="flex flex-col w-full mb-6 text-center">
          <h1 className="mb-2 text-3xl font-medium text-gray-900 sm:text-4xl">{t('pricing')}</h1>
        </div>

        {initialLoading || loading ?
          <LoadingWidget />
          :
          dataAllSubscriptions?.allSubscriptions.length === 0 ?
            <div className='flex items-center justify-center w-full py-2 my-2 font-semibold text-red-600 bg-red-100'>
              <p>{t('No Plans to show')}</p>
            </div>
            :
            <div className='grid grid-cols-3 gap-3 my-4'>
              {dataAllSubscriptions?.allSubscriptions.map(plan => (
                plan.published !== false &&
                <div key={plan.id} className='flex flex-col h-full p-4 bg-white rounded-md shadow-md'>
                  <div className="flex-1">
                    <div className='relative'>
                      <div>
                        <h3 className='text-3xl font-bold text-gray-700'>{plan.planName}</h3>
                        <p className='font-bold text-primary'>{plan.price === null || plan.price === 0 ? t('free') : "$" + plan.price}/{t('mo')}</p>
                      </div>
                      <div className="w-full h-px my-5 bg-gray-300"></div>
                    </div>

                    <div className='flex flex-col flex-1'>
                      {plan.features.map((feature, index) => (
                        <div key={index} className="flex items-center flex-grow gap-3 mb-2">
                          <div className="text-primary">
                            <BsCheckAll size={20} />
                          </div>
                          <p className='text-sm'>{feature}</p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    {currentSubscriptionData === null ?
                      <button onClick={() => { setAddSubscribePopup(true); setSelectedPlan(plan) }} className='self-end inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow my-2 hover:bg-primaryHover'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                          <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                        </svg>

                        <span className="mx-2">
                          {t('subscribe')}
                        </span>
                      </button>
                      :
                      currentSubscriptionData.price < plan.price &&
                      <button onClick={() => { setAddSubscribePopup(true); setSelectedPlan(plan) }} className='self-end inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow my-2 hover:bg-primaryHover'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                          <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                        </svg>

                        <span className="mx-2">
                          {t('upgrade')}
                        </span>
                      </button>
                    }
                  </div>
                </div>
              )
              )}
            </div>
        }
      </div>

      <section className='mt-2'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('prevSubscription')}</h3>
        </div>

        {initialLoading || storeLoading ?
          <LoadingWidget />
          :
          <div className='px-3 py-4 mt-3 bg-white rounded-md'>
            {dataStore?.store.listSubscription.length !== 0 ?
              <table className="w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 px-4 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                      {t('subscription')}
                    </th>

                    <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                      {t('price')}
                    </th>

                    <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                      {t('startDate')}
                    </th>

                    <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                      {t('endDate')}
                    </th>
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200">
                  {dataStore?.store.listSubscription.map(subscription => (
                    <tr key={subscription.subscription.id}>
                      <td className="px-4 py-4 text-sm font-medium">
                        {subscription.subscription.planName}
                      </td>
                      <td className="px-4 py-4 text-sm font-medium">
                        ${subscription.subscription.price}
                      </td>
                      <td className="px-4 py-4 text-sm font-medium">
                        {new Date(subscription.startDate * 1000).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}
                      </td>
                      <td className="px-4 py-4 text-sm font-medium">
                        {new Date(subscription.endDate * 1000).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              :
              <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                  <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                </svg>
                <span className='px-2'>{t('noPreSubscription')}</span>
              </div>
            }

          </div>
        }

      </section>
    </section >
  )
}

export default Subscription