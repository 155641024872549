import { t } from 'i18next'
import { FetchMarketers, checkedItems } from '../../types'
import Dropdown from '../../pages/AffiliateMarketing/Dropdown'
import { useEffect, useState } from 'react'
import { IoCheckmarkDoneSharp, IoCopy } from 'react-icons/io5'

interface Props {
  checkedItems: checkedItems[],
  setCheckedItems: React.Dispatch<React.SetStateAction<checkedItems[]>>
  dataAllMarketers: FetchMarketers | undefined,
  direction: string,
  setSelectedIdForDelete: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfPopup: React.Dispatch<React.SetStateAction<boolean>>
  setFailMsg: React.Dispatch<React.SetStateAction<string>>
  startingIndex: number
  permissions: {
    create: boolean,
    edit: boolean,
    delete: boolean,
    view: boolean,
  }
}

const MarketersTable = ({ checkedItems, setCheckedItems, permissions, dataAllMarketers, setSelectedIdForDelete, setDeleteConfPopup, direction, setFailMsg, startingIndex }: Props) => {
  
  const handleCheckAll = () => {
    if (dataAllMarketers?.allMarketers.length !== checkedItems.length) {
      dataAllMarketers !== undefined && setCheckedItems(dataAllMarketers?.allMarketers.map(client => ({ id: client.id })))
    } else {
      setCheckedItems([])
    }
  };

  const [copySuccess, setCopySuccess] = useState(false);
  const [copyId, setCopyId] = useState("");

  const handleCheck = (id: string) => {
    const isItemExist = checkedItems.some(item => item.id === id);

    if (isItemExist) {
      const newCheckedItems = checkedItems.filter(item => item.id !== id);
      setCheckedItems(newCheckedItems);
    } else {
      setCheckedItems([...checkedItems, { id: id }])
    }
  };

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopySuccess(true)
      })
  };

  useEffect(() => {
    setTimeout(() => {
      setCopySuccess(false)
      setCopyId("")
    }, 3000)
  }, [copySuccess])

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="px-3 py-4 text-sm text-left text-gray-500 rtl:text-right">
            <div className='relative flex'>
              <input type="checkbox" checked={dataAllMarketers?.allMarketers.length === checkedItems.length} onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </th>

          <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('marketer')}
          </th>

          <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('commitionValue')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('marketingMethod')}
          </th>

          {permissions.edit || permissions.delete || permissions.view ?
            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
              {t('option')}
            </th>
            :
            null
          }
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {dataAllMarketers?.allMarketers.slice(startingIndex, startingIndex + 10).map((marketer, index) => {
          return (
            <tr key={index}>
              <td className="px-3 py-4 text-sm font-medium">
                <div className='relative flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === marketer.id)} onChange={() => handleCheck(marketer.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>
              <td className="px-4 py-4 text-sm">
                <h2 className="text-gray-800">{marketer.name}</h2>
              </td>
              <td className="px-12 py-4 text-sm">
                <p className="text-gray-500">{marketer.value}%</p>
              </td>
              <td className="flex items-center px-4 py-4 text-sm">
                <p>{`https://store-landing.qafilaty.com/store/${marketer.store.link}?utm_campaign=${marketer.referralLink}`}</p>
                {copySuccess === true && copyId === marketer.id ?
                  <div className='p-1.5 text-green-500 bg-gray-100 rounded'>
                    <IoCheckmarkDoneSharp />
                  </div>
                  :
                  <button onClick={() => { handleCopyClick(`https://store-landing.qafilaty.com/store/${marketer.store.link}?utm_campaign=${marketer.referralLink}`); setCopyId(marketer.id) }} className='p-1.5 text-gray-400 bg-gray-100 rounded'>
                    <IoCopy />
                  </button>
                }
              </td>

              {permissions.edit || permissions.delete || permissions.view ?
                <td className="px-4 py-4 text-sm">
                  <Dropdown permissions={permissions} direction={direction} id={marketer.id} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} />
                </td>
                :
                null
              }
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default MarketersTable