import { gql } from "@apollo/client";

export const CreateRectangleBanner = gql`
mutation CreateRectangleBanner($content: [contentRectangleBanner]!) {
  createRectangleBanner(content: $content) {
    id
    type
    link
    image
    createdAt
    updatedAt
    deletedAt
  }
}
`;
