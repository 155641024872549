import { gql, useLazyQuery } from '@apollo/client';
import { FetchCountries } from '../../../types';

export const ALL_COUNTRIES = gql`
query AllCountries($idStore: ID) {
  allCountries(idStore: $idStore) {
    id
    name
    currency
    asDefault
    createdAt
    updatedAt
    deletedAt
  }
}
`;

export const useAllCountries = () => {
  return useLazyQuery<FetchCountries>(ALL_COUNTRIES);
}