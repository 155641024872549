import { gql } from "@apollo/client";

export const CreateSeo = gql`
mutation CreateSeo($content: contentSeo!) {
  createSeo(content: $content) {
    id
    title
    description
    words
    createdAt
    updatedAt
    deletedAt
  }
}
`;
