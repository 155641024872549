import { gql, useLazyQuery } from '@apollo/client';
import { FetchWorkers } from '../../../types';

export const ALL_WORKERS = gql`
query AllWorker($idStore: ID) {
  allWorker(idStore: $idStore) {
    id
    name
    email
    phone
    permissions {
      id
      name
      permissions
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`;

export const useAllWorkers = () => {
  return useLazyQuery<FetchWorkers>(ALL_WORKERS);
}