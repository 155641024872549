import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { CreateSeo, UpdateSeo, useAllSeo } from '../../../graphql';
import SuccessAlert from '../../../components/Alerts/SuccessAlert';
import FailAlert from '../../../components/Alerts/FailAlert';
import LoadingWidget from '../../../components/LoadingWidget/LoadingWidget';
import useStore from '../../../store/useStore';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';
import PermissionsError from '../../../components/Alerts/PermissionsError';

const SeoSettings = ({ direction }: { direction: string }) => {
  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [permissions, setPermissions] = useState({
    view: false,
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          view: true,
        })

        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "settings")

      let viewPermission = filtered[0]?.permissions.includes("SEO")

      setPermissions({
        view: viewPermission || false,
      })
    }
  }, [userData])

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [keywords, setKeywords] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');

  // Form
  const [metaTag, setMetaTag] = useState("")
  const [description, setDescription] = useState("")

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleAddKeyword = () => {
    if (inputValue.trim() !== '') {
      setKeywords((prevKeywords: any) => [...prevKeywords, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleAddKeyword();
    }
  };

  const handleDeleteKeyword = (index: any) => {
    setKeywords((prevKeywords) => prevKeywords.filter((_, i) => i !== index));
  };

  const [copySuccess, setCopySuccess] = useState(false)

  const handleCopyText = () => {
    const textToCopy = 'https://mapp.sa/ooorashad/sitemap.xm';
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopySuccess(true)
      })
  };

  const [allSeo, { loading, refetch, data: dataAllSeo }] = useAllSeo();

  useEffect(() => {
    storeData &&
      (async () => {
        await allSeo({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()

  }, [storeData]);

  useEffect(() => {
    storeData && refetch()
  });

  useEffect(() => {
    if (dataAllSeo?.allSeo) {
      setMetaTag(dataAllSeo?.allSeo.title)
      setDescription(dataAllSeo?.allSeo.description)
      dataAllSeo?.allSeo.words !== "" && setKeywords(dataAllSeo?.allSeo?.words?.split(", "))

    }
  }, [dataAllSeo])

  const [createSeo, { error }] = useMutation(CreateSeo);
  const [updateSeo, { error: updateError }] = useMutation(UpdateSeo);

  const sumbit = async (e: any) => {
    e.preventDefault();

    if (metaTag === "") {
      setFailMsg(t('emptyTitle'));
      setFail(true);
      return
    }

    if (description === "") {
      setFailMsg(t('emptyDescriptionAlert'));
      setFail(true);
      return
    }

    if (keywords.length === 0) {
      setFailMsg(t('emptyKeywords'));
      setFail(true);
      return
    }

    if (dataAllSeo?.allSeo === null) {
      await createSeo({
        variables: {
          content: {
            description: description,
            idStore: storeData.id,
            title: metaTag,
            words: keywords.join(", ")
          }
        }
      })

      if (!error) {
        setSuccessMsg(t('SEOAddedsuccessfully'));
        setSuccess(true);
      }
    } else {

      await updateSeo({
        variables: {
          updateSeoId: dataAllSeo?.allSeo.id,
          content: {
            description: description,
            idStore: storeData.id,
            title: metaTag,
            words: keywords.join(", ")
          }
        }
      })

      if (!updateError) {
        setSuccessMsg(t('SEOUpdatedsuccessfully'));
        setSuccess(true);
      }
    }
  }

  // Delete the Alerts of copy success after some time if any
  useEffect(() => {
    copySuccess && setTimeout(() => setCopySuccess(false), 2000)
  }, [copySuccess])

  return (
    <section className='mx-3 my-4 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('SEOSettings')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div className='p-2 mt-3 bg-white rounded-md'>
        {initialLoading || loading ?
          <LoadingWidget />
          :
          permissions.view === false ?
            <PermissionsError />
            :
            <>
              <div className="mb-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">{t('title')}</label>
                <textarea value={metaTag} onChange={(e) => setMetaTag(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('metaTagTitle')} />
              </div>
              <div className="mb-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">{t('description')}</label>
                <textarea value={description} onChange={(e) => setDescription(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t("metaTagDescription")} />
              </div>

              <div className='mb-1'>
                <label className="block mb-2 text-sm font-medium text-gray-900">{t('keywords')}</label>
                {keywords.length > 0 &&
                  <div className='flex gap-2 mb-2'>
                    {keywords.map((keyword, index) => (
                      <div key={index} className='flex items-center'>
                        <div className={`px-2 py-1.5 text-sm text-white bg-blue-800 ${direction === 'ltr' ? 'rounded-l-md' : 'rounded-r-md'}`}>
                          <span>
                            {keyword}
                          </span>
                        </div>
                        <div onClick={() => { handleDeleteKeyword(index) }} className={`px-2 py-1 text-white bg-blue-800 cursor-pointer ${direction === 'ltr' ? 'rounded-r-md' : 'rounded-l-md'}`}>
                          <span>
                            x
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                }
                <input type="text" value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyDown} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('enterKeywordAndPressEnter')} />
              </div>

              <div className='my-2'>
                <button className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                  <span className="mx-2">
                    {t('updateSitemap')}
                  </span>
                </button>
              </div>

              <div className='my-4'>
                <div className='flex items-center overflow-hidden rounded-md'>
                  <div className='px-1.5 py-1 text-white bg-primary cursor-pointer' onClick={handleCopyText}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z"></path>
                    </svg>
                  </div>
                  <div className='w-full px-2 py-1 bg-gray-100'>
                    <Link to="/settings/seoSettings">
                      https://mapp.sa/ooorashad/sitemap.xm
                    </Link>
                  </div>
                </div>

                {copySuccess &&
                  <div className='absolute flex items-center gap-1 mt-px'>
                    <div className='text-green-500'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                        <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                      </svg>
                    </div>
                    <p className='text-sm'>{t('copied')}</p>
                  </div>
                }
              </div>

              {dataAllSeo?.allSeo !== null &&
                dataAllSeo?.allSeo !== undefined &&
                <div className='py-2'>
                  <p className='text-sm text-gray-600'>
                    {t('lastUpdated')} {new Date(dataAllSeo?.allSeo.updatedAt).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
                  </p>
                </div>
              }

              <div className='flex justify-end my-2'>
                <button onClick={(e) => sumbit(e)} className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
                  <span className="mx-2">
                    {t('save')}
                  </span>
                </button>
              </div>
            </>
        }
      </div>
    </section>
  )
}

export default SeoSettings