import React, { useState } from 'react'
import DefinitionPagesDropDown from '../../DropDowns/SettingsDrops/DefinitionPagesDropDown'
import { FetchDefinitionPages } from '../../../types'
import { t } from 'i18next'

interface TableProps {
  checkedItems: checkedItems[],
  setCheckedItems: React.Dispatch<React.SetStateAction<checkedItems[]>>
  direction: string
  allDefinitionPage?: FetchDefinitionPages
  setSelectedIdForDelete: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfPopup: React.Dispatch<React.SetStateAction<boolean>>
  setEditPublishPopup: React.Dispatch<React.SetStateAction<boolean>>
}

type checkedItems = {
  id: string,
}

const DefinitionPagesTable = ({ checkedItems, setCheckedItems, direction, allDefinitionPage, setSelectedIdForDelete, setDeleteConfPopup, setEditPublishPopup }: TableProps) => {

  const handleCheckAll = () => {
    if (allDefinitionPage?.allDefinitionPage.length !== checkedItems.length) {
      allDefinitionPage !== undefined && setCheckedItems(allDefinitionPage?.allDefinitionPage.map(brand => ({ id: brand.id })))
    } else {
      setCheckedItems([])
    }
  };

  const handleCheck = (id: string) => {
    const isItemExist = checkedItems.some(item => item.id === id);

    if (isItemExist) {
      const newChckedItems = checkedItems.filter(item => item.id !== id);
      setCheckedItems(newChckedItems);
    } else {
      setCheckedItems([...checkedItems, { id: id }])
    }
  };

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500">
            <div className='flex'>
              <input type="checkbox" checked={allDefinitionPage?.allDefinitionPage.length === checkedItems.length} onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </th>

          <th scope="col" className="px-4 py-4 text-sm font-normal text-left text-gray-500 rtl:text-right">
            {t('pageTitile')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('publishOnStore')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('option')}
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {allDefinitionPage?.allDefinitionPage.map(page => (
          <tr key={page.id}>
            <td className="px-4 py-3 text-sm text-left text-gray-500 rtl:text-right">
              <div className='flex'>
                <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === page.id)} onChange={() => handleCheck(page.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
            </td>
            <td className="px-4 py-4 text-sm">
              {page.title}
            </td>
            <td className="px-4 py-4 text-sm">
              {page.publish ?
                <div className="inline px-3 py-1 text-xs font-bold rounded-md text-emerald-500 gap-x-2 bg-emerald-100/60">
                  Active
                </div>
                :
                <div className="inline px-3 py-1 text-xs font-bold text-red-500 rounded-md gap-x-2 bg-red-100/60">
                  Not Active
                </div>
              }
            </td>

            <td className="px-4 py-4 text-sm">
              <DefinitionPagesDropDown direction={direction} id={page.id} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} setEditPublishPopup={setEditPublishPopup} publish={page.publish} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default DefinitionPagesTable