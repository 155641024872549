import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'
import useStore from '../../../store/useStore';
import { useAllRating } from '../../../graphql';
import LoadingWidget from '../../../components/LoadingWidget/LoadingWidget';
import { Link } from 'react-router-dom';

const Ratings = () => {
  const storeData = useStore((state: any) => state.userData.store);

  // I did scroll to the top because when the component is render it show the bottom of the page because the layout
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [allRating, { loading, data: dataAllaRating }] = useAllRating();

  useEffect(() => {
    storeData &&
      (async () => {
        await allRating({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()
  }, [storeData]);

  return (
    <section className='my-4 mx-3 md:mx-7'>
      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('reviews')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div className='p-2 mt-3 bg-white rounded-md'>
        {initialLoading || loading ?
          <LoadingWidget />
          :
          <div>
            {dataAllaRating?.allRating.map(rating => (
              <div key={rating.id} className='p-3 mb-3 border rounded-lg bg-gray-50'>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center gap-0.5'>
                    {[...Array(5)].map((_, index) => (
                      <div className={index < rating.mark ? 'text-yellow-400' : 'text-gray-300'} key={index}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"></path>
                        </svg>
                      </div>
                    ))}
                  </div>

                  <div className='font-bold text-gray-400'>
                    {new Date(rating.createdAt).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}
                  </div>
                </div>

                <div className='pt-3 text-sm text-gray-500'>
                  {rating.description}
                </div>

                <div className='pt-3'>
                  <p className='text-gray-700'>
                    {t('product')}: <Link to={`https://store-landing.qafilaty.com/store/${storeData.link}/product/${rating.product.id}`} className='pl-1 text-gray-600 cursor-pointer hover:underline'>{rating?.product?.name}</Link>
                  </p>
                </div>
                <div className='pt-1'>
                  <p className='text-gray-700'>
                    {t('client')}: {rating.client === null ? t("Client deleted") : rating.client.name}
                  </p>
                </div>
              </div>
            ))}
          </div>
        }

        {
          dataAllaRating?.allRating.length === 0 &&
          <div className='flex w-full p-2 my-2 text-red-600 bg-red-200'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
              <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
            </svg>
            <span className='px-2'>{t('noReviews')}</span>
          </div>
        }
      </div>
    </section>
  )
}

export default Ratings