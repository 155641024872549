import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useStore from "../../store/useStore";

interface DropProps {
  id: string,
  setSelectedIdForDelete: React.Dispatch<React.SetStateAction<string>>,
  setDeleteConfPopup: React.Dispatch<React.SetStateAction<boolean>>
  direction: string,
  permissions: {
    create: boolean,
    edit: boolean,
    delete: boolean,
  }
}

const Dropdown = ({ permissions, direction, id, setSelectedIdForDelete, setDeleteConfPopup }: DropProps) => {
  const [isDropDownOpen, setDropDownStatus] = useState(false);
  const ref = useRef<any>();

  const userData = useStore((state: any) => state.userData);

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (ref.current) {
        if (ref.current.contains(e.target as Node)) {
          return
        }

        // else close the dropdown
        setDropDownStatus(false);
      }
    })
  }, [])

  return (
    <div ref={ref}>
      <button onClick={() => { setDropDownStatus(!isDropDownOpen); }} className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
        </svg>
      </button>
      <div className={`${!isDropDownOpen && "hidden"} absolute ${direction === "ltr" ? 'right-3' : 'left-3'}  z-20 w-48 py-2 mt-2 origin-top-right bg-white rounded-md shadow-xl border`}>
        <a href={`https://store-landing.qafilaty.com/store/${userData.store.link}/product/${id}`} target="_blank" className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 9a3.02 3.02 0 0 0-3 3c0 1.642 1.358 3 3 3 1.641 0 3-1.358 3-3 0-1.641-1.359-3-3-3z"></path>
            <path d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 12c-5.351 0-7.424-3.846-7.926-5C4.578 10.842 6.652 7 12 7c5.351 0 7.424 3.846 7.926 5-.504 1.158-2.578 5-7.926 5z"></path>
          </svg>

          <span className="mx-1">
            {t('view')}
          </span>
        </a>

        {permissions.edit &&
          <Link to={`/products/edit/${id}`} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="m7 17.013 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58zM9 13.417l6.03-5.973 1.586 1.586-6.029 5.971L9 15.006v-1.589z"></path>
              <path d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"></path>
            </svg>

            <span className="mx-1">
              {t('edit')}
            </span>
          </Link>
        }

        {permissions.delete &&
          <div onClick={() => { setDeleteConfPopup(true); setSelectedIdForDelete(id) }} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
              <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
            </svg>

            <span className="mx-1">
              {t('delete')}
            </span>
          </div>
        }
      </div>
    </div>
  )
}

export default Dropdown