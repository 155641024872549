import React, { useState } from 'react'
import Dropdown from './Dropdown'
import AddAlert from './AddAlert/AddAlert';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';

const CartAlert = () => {
  const [addAlertPopup, setAddAlertPopup] = useState(false);

  return (
    <section className='my-4 mx-3 md:mx-7'>
      <div>
        {addAlertPopup &&
          <AddAlert setAddAlertPopup={setAddAlertPopup} />
        }
      </div>

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('alerts')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div className='flex items-center justify-between mt-3'>
        <h3>{t('Alerts Of Abandoned Carts')}</h3>

        <div>
          <button onClick={() => { setAddAlertPopup(true) }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
              <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
            </svg>

            <span className="mx-2">
              {t('Add New Alert')}
            </span>
          </button>
        </div>
      </div>

      <div className='p-2 mt-3 bg-white rounded-md'>
        <div className='h-full pt-2 overflow-x-scroll'>
          <table className="w-full divide-y divide-gray-200 ">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
                  {t('time')}
                </th>

                <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                  {t('SMS')}
                </th>

                <th scope="col" className="px-4 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
                  {t('email')}
                </th>

                <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                  {t('option')}
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-4 py-4 text-sm">
                  5hrs
                </td>
                <td className="px-4 py-4 text-sm text-red-600">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M16 2H8C4.691 2 2 4.691 2 8v13a1 1 0 0 0 1 1h13c3.309 0 6-2.691 6-6V8c0-3.309-2.691-6-6-6zm.706 13.293-1.414 1.414L12 13.415l-3.292 3.292-1.414-1.414 3.292-3.292-3.292-3.292 1.414-1.414L12 10.587l3.292-3.292 1.414 1.414-3.292 3.292 3.292 3.292z"></path>
                  </svg>
                </td>
                <td className="px-4 py-4 text-sm text-green-600">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M16 2H8C4.691 2 2 4.691 2 8v13a1 1 0 0 0 1 1h13c3.309 0 6-2.691 6-6V8c0-3.309-2.691-6-6-6zm-5 14.414-3.707-3.707 1.414-1.414L11 13.586l4.793-4.793 1.414 1.414L11 16.414z"></path>
                  </svg>
                </td>
                <td className="px-4 py-4 text-sm">
                  <Dropdown />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  )
}

export default CartAlert