import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useMutation } from '@apollo/client';
import { useAllStore, UpdateStore, STORE, useAllSocialMedia, CreateSocialMedia } from '../../../graphql';
import SuccessAlert from '../../../components/Alerts/SuccessAlert';
import FailAlert from '../../../components/Alerts/FailAlert';
import LoadingWidget from '../../../components/LoadingWidget/LoadingWidget';
import useStore from '../../../store/useStore';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';
import { AiOutlineClose } from 'react-icons/ai';
import Logo from '../../../assets/originalLogo.png';
import PermissionsError from '../../../components/Alerts/PermissionsError';

const StoreSettings = () => {
  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    view: false,
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          view: true,
        })

        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "settings")

      let viewPermission = filtered[0]?.permissions.includes("store settings")

      setPermissions({
        view: viewPermission || false,
      })
    }
  }, [userData])

  // I did scroll to the top because when the component is render it show the bottom of the page because the layout
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  // First form
  const [storeName, setStoreName] = useState("");
  const [storeLink, setStoreLink] = useState("");

  // For the current logo
  const [previewUrls, setPreviewUrls] = useState<string | null>(null);
  const [iconPreviewUrls, setIconPreviewUrls] = useState<string | null>(null);

  // Third Form
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [store, { loading, data: dataStore }] = useAllStore();
  const [socialMedia, { data: dataSocialMedia }] = useAllSocialMedia();

  useEffect(() => {
    storeData &&
      (async () => {
        await store({
          variables: { storeId: storeData.id }
        })
        await socialMedia({
          variables: { idStore: storeData.id }
        })
      })()
  }, [storeData]);

  useEffect(() => {
    if (dataStore) {
      setStoreName(dataStore.store.name)
      setStoreLink(dataStore.store.link)

      setPreviewUrls(dataStore.store.logo)
      setIconPreviewUrls(dataStore.store.icon)

      setDescription(dataStore.store.description)
      setAddress(dataStore.store.address);

      setInitialLoading(false)
    }
  }, [dataStore]);

  // File input
  const [selectedPreviewImageUrl, setSelectedPreviewImageUrl] = useState("");
  const [selectedPreviewIconUrl, setSelectedPreviewIconUrl] = useState("");
  const [imageToCrop, setImageToCrop] = useState<any>();
  const [cropPopup, setCropPopup] = useState(false);

  // For the api
  const [selectedLogo, setSelectedLogo] = useState<File>();
  const [selectedIcon, setSelectedIcon] = useState<File>();

  const cropperRef = useRef<any>();

  // this state contain what image is about to get crop (logo or icon)
  const [typeOfimg, setTypeOfimg] = useState("");

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>, type: string) => {
    type === "logo" && setTypeOfimg(type)
    type === "icon" && setTypeOfimg(type)

    const files = e.target.files;

    if (files && files.length > 0) {
      const urls = Array.from(files).map((file) => URL.createObjectURL(file));

      setImageToCrop(urls[0]);
      setCropPopup(true)
    }
  };

  const handleCrop = () => {
    if (cropperRef.current) {

      if (typeof cropperRef.current.cropper.getCroppedCanvas() === 'undefined') {
        return;
      }

      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      const croppedImageUrl = cropperRef.current.cropper.getCroppedCanvas().toDataURL();

      typeOfimg === "logo" && setSelectedPreviewImageUrl(croppedImageUrl);
      typeOfimg === "icon" && setSelectedPreviewIconUrl(croppedImageUrl);

      croppedCanvas.toBlob((blob: any) => {
        const file = new File([blob], "cropped-image.png", { type: "image/png" });
        typeOfimg === "logo" && setSelectedLogo(file);
        typeOfimg === "icon" && setSelectedIcon(file);
      });

      setCropPopup(false);
    }
  };

  const handlePopupClose = () => {
    setImageToCrop("");
    setCropPopup(false);
  };

  const [updateStore, { error }] = useMutation(UpdateStore, {
    onError: (error) => {
      // @ts-ignore
      const code = error.networkError?.result?.errors?.[0]?.extensions?.code;

      if (code === "LINK_ALREADY_EXISTS") {
        setFail(true);
        setFailMsg(t('storeLinkUsesInAnotherStore'));
        return

      } else {
        console.error(error);
      }
    },
    refetchQueries: [{
      query: STORE,
      variables: { storeId: storeData?.id }
    }]
  });

  const [createSocialMedia, { error: createSocialMediaError }] = useMutation(CreateSocialMedia);

  // Store name and link form
  const submit = async (e: any) => {
    e.preventDefault();

    if (storeName === "" || storeLink === "") {
      setFailMsg(t('fieldsEmpty'));
      setFail(true);
      return
    } else {
      const whiteSpaces = /\s/.test(storeLink);
      if (whiteSpaces === true) {
        setFailMsg(t('storeLinkHaveSpaces'));
        setFail(true);
        return
      }
    }

    if (storeName !== dataStore?.store.name) {
      await updateStore({
        variables: {
          updateStoreId: storeData.id,
          content: {
            name: storeName,
          }
        }
      })
    } else {
      await updateStore({
        variables: {
          updateStoreId: storeData.id,
          content: {
            name: storeName,
            link: storeLink,
          }
        }
      })
    }

    if (!error) {
      setSuccessMsg(t('updateSuccess'));
      setSuccess(true);
    }
  }

  // Logo image form
  const [loadingUploadLogo, setLoadingUploadLogo] = useState(false)
  const [loadingUploadIcon, setLoadingUploadIcon] = useState(false)

  const submitImage = async (e: any) => {
    setLoadingUploadLogo(true);
    e.preventDefault();

    if (selectedLogo === undefined) {
      setFailMsg(t('emptyImagesAlert'));
      setFail(true);
      setLoadingUploadLogo(false);
      return
    }

    await updateStore({
      variables: {
        updateStoreId: storeData.id,
        content: {
          logo: selectedLogo,
        }
      },
      refetchQueries: [{ query: STORE, variables: { storeId: storeData.id } }]
    })

    if (!error) {
      setSuccessMsg(t('updateSuccess'));
      setSuccess(true);

      setSelectedPreviewImageUrl("")
      setSelectedLogo(undefined)
    }

    setLoadingUploadLogo(false)
  }

  const submitIcon = async (e: any) => {
    setLoadingUploadIcon(true);
    e.preventDefault();

    if (selectedIcon === undefined) {
      setFailMsg(t('emptyImagesAlert'));
      setFail(true);
      setLoadingUploadIcon(false);
      return
    }

    await updateStore({
      variables: {
        updateStoreId: storeData.id,
        content: {
          icon: selectedIcon,
        }
      },
      refetchQueries: [{ query: STORE, variables: { storeId: storeData.id } }]
    })

    if (!error) {
      setSuccessMsg(t('updateSuccess'));
      setSuccess(true);

      setSelectedPreviewImageUrl("")
      setSelectedLogo(undefined)
    }

    setLoadingUploadIcon(false)
  }

  // Store description adn location form
  const submitThirdForm = async (e: any) => {
    e.preventDefault();

    if (description === "" || address === "") {
      setFailMsg(t('fieldsEmpty'));
      setFail(true);
      return
    }

    await updateStore({
      variables: {
        updateStoreId: storeData.id,
        content: {
          description: description,
          address: address,
        }
      }
    })

    if (!error) {
      setSuccessMsg(t('updateSuccess'));
      setSuccess(true);
    }
  }

  const [socialMedias, setSocialMedias] = useState([
    {
      name: "instagram",
      url: ""
    },
    {
      name: "snapchat",
      url: ""
    },
    {
      name: "twitter_x",
      url: ""
    },
    {
      name: "tiktok",
      url: ""
    },
    {
      name: "whatsapp",
      url: ""
    },
    {
      name: "maroof",
      url: ""
    },
    {
      name: "phone_number",
      url: ""
    },
    {
      name: "facebook",
      url: ""
    },
    {
      name: "play_store_link",
      url: ""
    },
    {
      name: "app_store_link",
      url: ""
    },
  ])

  const handleSocialMediaChange = (name: any, url: any) => {
    const updatedSocialMedia = socialMedias.map(item => {
      if (item.name === name) {
        return { ...item, url }; // Update the url for the matching item
      }
      return item;
    });

    setSocialMedias(updatedSocialMedia);
  }

  useEffect(() => {
    if (dataSocialMedia) {
      if (dataSocialMedia?.allSocialMedia.length > 0) {
        const updatedItems: any = socialMedias.map(item => {
          const updatedLink = dataSocialMedia?.allSocialMedia.find(apiItem => apiItem.name === item.name)?.url || item.url;
          return { ...item, url: updatedLink };
        });

        setSocialMedias(updatedItems);
      }
    }
  }, [dataSocialMedia])

  const submitSocialMedia = (e: any) => {
    e.preventDefault();

    const contentVariable: any[] = [];

    const filteredArray = socialMedias.filter((socialMedia) => socialMedia.url !== "")
    filteredArray.forEach(filtered => (
      contentVariable.push(filtered)
    ))

    createSocialMedia({
      variables: {
        idStore: storeData.id,
        content: contentVariable
      }
    })

    if (!createSocialMediaError) {
      setSuccessMsg(t('updateSuccess'));
      setSuccess(true);
    }
  }

  const deleteLogo = async (e: any) => {
    setLoadingUploadLogo(true);
    e.preventDefault();

    await updateStore({
      variables: {
        updateStoreId: storeData.id,
        content: {
          logo: null,
        }
      },
      refetchQueries: [{ query: STORE, variables: { storeId: storeData.id } }]
    })

    if (!error) {
      setSuccessMsg(t('updateSuccess'));
      setSuccess(true);

      setSelectedPreviewImageUrl("")
      setSelectedLogo(undefined)
    }

    setLoadingUploadLogo(false)
  }

  return (
    <section className='mx-3 my-4 md:mx-7'>
      {cropPopup && (
        <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>
          <div className='relative w-full max-h-full p-3 overflow-y-auto bg-white border md:w-5/6'>
            <Cropper
              ref={cropperRef}
              src={imageToCrop}
              style={{ height: 400, width: '100%' }}
            />

            <div className='flex justify-end gap-2 mt-3'>
              <button onClick={handleCrop} className="px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-gray-900 focus:z-10">{t('cropImage')}</button>
              <button onClick={handlePopupClose} className="px-3 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-md hover:bg-red-700">{t('close')}</button>
            </div>
          </div>
        </div>
      )}

      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('storeSetting')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        permissions.view === false ?
          <PermissionsError />
          :
          <>
            <form className='px-5 py-4 my-2 bg-white rounded-md'>
              <div className='grid grid-cols-2 gap-4 mt-2'>
                <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">{t('storeName')}</label>
                  <input type="text" value={storeName} onChange={(e) => setStoreName(e.target.value)} id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('name')} required />
                </div>
                <div>
                  <label htmlFor="link" className="block mb-2 text-sm text-gray-900 font-smedium">{t('storeLink')}</label>
                  <input type="text" value={storeLink} onChange={(e) => setStoreLink(e.target.value)} id="link" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('link')} required />
                </div>
              </div>

              <div className='flex justify-end pt-5'>
                <button onClick={(e) => { submit(e) }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-md shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                    <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                  </svg>

                  <span className="mx-2">
                    {t('save')}
                  </span>
                </button>
              </div>
            </form>

            <form className='px-5 py-4 my-2 bg-white rounded-md'>
              <span className="block mb-2 text-sm font-medium text-gray-900">
                {t('currentLogo')}
              </span>

              <div className='flex justify-center py-2'>
                {/* Current logo display */}
                {previewUrls !== null ?
                  <div className='relative'>
                    <img src={`https://store-api.qafilaty.com/images/${previewUrls}`} alt="" className='w-44' />

                    <div className="absolute -top-2 -right-2">
                      <div onClick={(e) => deleteLogo(e)} className='p-1 bg-red-600 rounded-full cursor-pointer'>
                        <AiOutlineClose size={13} className='text-white' />
                      </div>
                    </div>
                  </div>
                  :
                  <div className='py-3 text-sm'>
                    <img src={Logo} alt="" className='w-44' />
                  </div>
                }
              </div>

              <span className="block mb-2 text-sm font-medium text-gray-900">
                {t('uploadNewLogo')}
              </span>
              <div className="flex items-center justify-center w-full">
                <label className="flex flex-col w-full h-32 border-2 border-dashed rounded-md cursor-pointer hover:bg-gray-100 hover:border-gray-300">
                  <div className="flex flex-col items-center justify-center pt-7">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                    </svg>
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      {t('selectPhoto')}
                    </p>
                  </div>
                  <input type="file" onChange={(e) => { handleFileSelect(e, "logo") }} className="opacity-0" multiple={true} />
                </label>
              </div>
              <div className="flex flex-wrap gap-2 mt-2">

                {selectedPreviewImageUrl !== "" &&
                  <div className="relative overflow-hidden">
                    <img className="w-20 h-20 rounded-md" src={selectedPreviewImageUrl} alt="" />
                  </div>
                }
              </div>

              <div className='flex justify-end pt-5'>
                <button onClick={(e) => { submitImage(e) }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-md shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                    <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                  </svg>

                  <span className="mx-2">
                    {loadingUploadLogo ? t('loading') : t('save')}
                  </span>
                </button>
              </div>
            </form>

            <form className='px-5 py-4 my-2 bg-white rounded-md'>
              <span className="block mb-2 text-sm font-medium text-gray-900">
                {t('currentIcon')}
              </span>

              <div className='flex justify-center py-2'>
                {/* Current logo display */}
                {iconPreviewUrls !== null ?
                  <div className='p-3 border rounded-md'>
                    <img src={`https://store-api.qafilaty.com/images/${iconPreviewUrls}`} alt="" className='w-32 h-24' />
                  </div>
                  :
                  <div className='py-3 text-sm'>
                    {t('noIcon')}
                  </div>
                }
              </div>

              <span className="block mb-2 text-sm font-medium text-gray-900">
                {t('uploadNewIcon')}
              </span>
              <div className="flex items-center justify-center w-full">
                <label className="flex flex-col w-full h-32 border-2 border-dashed rounded-md cursor-pointer hover:bg-gray-100 hover:border-gray-300">
                  <div className="flex flex-col items-center justify-center pt-7">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                    </svg>
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      {t('selectPhoto')}
                    </p>
                  </div>
                  <input type="file" onChange={(e) => { handleFileSelect(e, 'icon') }} className="opacity-0" multiple={true} />
                </label>
              </div>
              <div className="flex flex-wrap gap-2 mt-2">

                {selectedPreviewIconUrl !== "" &&
                  <div className="relative overflow-hidden">
                    <img className="w-20 h-20 rounded-md" src={selectedPreviewIconUrl} alt="" />
                  </div>
                }
              </div>

              <div className='flex justify-end pt-5'>
                <button onClick={(e) => { submitIcon(e) }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-md shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                    <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                  </svg>

                  <span className="mx-2">
                    {loadingUploadIcon ? t('loading') : t('save')}
                  </span>
                </button>
              </div>
            </form>

            <form className='px-5 py-4 my-2 bg-white rounded-md'>
              <div className='my-2'>
                <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">{t('storeDescription')}</label>
                <textarea id="description" value={description || ""} onChange={(e) => setDescription(e.target.value)} name="message" className="w-full h-32 px-3 py-1 text-base leading-6 text-gray-700 transition-colors duration-200 ease-in-out bg-white border border-gray-300 rounded-md outline-none resize-none focus:border-primary"></textarea>
              </div>

              <div>
                <label htmlFor="location" className="block mb-2 text-sm font-medium text-gray-900">{t('location')}</label>
                <div className="relative flex items-center mt-2">
                  <span className="absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className='mx-2 text-gray-400'>
                      <path d="M12 14c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"></path>
                      <path d="M11.42 21.814a.998.998 0 0 0 1.16 0C12.884 21.599 20.029 16.44 20 10c0-4.411-3.589-8-8-8S4 5.589 4 9.995c-.029 6.445 7.116 11.604 7.42 11.819zM12 4c3.309 0 6 2.691 6 6.005.021 4.438-4.388 8.423-6 9.73-1.611-1.308-6.021-5.294-6-9.735 0-3.309 2.691-6 6-6z"></path>
                    </svg>
                  </span>
                  <input type="text" value={address || ""} onChange={(e) => setAddress(e.target.value)} id="location" className="px-9 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('location')} required />
                </div>
              </div>

              <div className='flex justify-end pt-5'>
                <button onClick={(e) => { submitThirdForm(e) }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-md shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                    <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                  </svg>

                  <span className="mx-2">
                    {t('save')}
                  </span>
                </button>
              </div>
            </form>

            <form className='px-5 py-4 my-2 bg-white rounded-md'>
              <h3 className='text-xl'>{t('socialMediaLink')}</h3>
              <div className='grid grid-cols-2 gap-4 mt-2'>
                <div>
                  <label htmlFor="instagram" className="block mb-2 text-sm font-medium text-gray-900">Instagram</label>
                  <input type="text" id="instagram" value={socialMedias[0].url} onChange={(e) => handleSocialMediaChange("instagram", e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder='Instagram' required />
                </div>
                <div>
                  <label htmlFor="snapChat" className="block mb-2 text-sm font-medium text-gray-900">Snapchat</label>
                  <input type="text" id="snapChat" value={socialMedias[1].url} onChange={(e) => handleSocialMediaChange("snapchat", e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder="Snapchat" required />
                </div>
                <div>
                  <label htmlFor="twitter" className="block mb-2 text-sm font-medium text-gray-900">Twitter X</label>
                  <input type="text" id="twitter" value={socialMedias[2].url} onChange={(e) => handleSocialMediaChange("twitter_x", e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder="Twitter" required />
                </div>
                <div>
                  <label htmlFor="Tiktok" className="block mb-2 text-sm font-medium text-gray-900">Tiktok</label>
                  <input type="text" id="Tiktok" value={socialMedias[3].url} onChange={(e) => handleSocialMediaChange("tiktok", e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder="Tiktok" required />
                </div>
                <div>
                  <label htmlFor="whatsapp" className="block mb-2 text-sm font-medium text-gray-900">Whatsapp</label>
                  <input type="text" id="whatsapp" value={socialMedias[4].url} onChange={(e) => handleSocialMediaChange("whatsapp", e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder="Whatsapp" required />
                </div>
                <div>
                  <label htmlFor="maroof" className="block mb-2 text-sm font-medium text-gray-900">Maroof</label>
                  <input type="text" id="maroof" value={socialMedias[5].url} onChange={(e) => handleSocialMediaChange("maroof", e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder="Maroof" required />
                </div>
                <div>
                  <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">{t('phone')}</label>
                  <input type="text" id="phone" value={socialMedias[6].url} onChange={(e) => handleSocialMediaChange("phone_number", e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('phone')} required />
                </div>
                <div>
                  <label htmlFor="facebook" className="block mb-2 text-sm font-medium text-gray-900">Facebook</label>
                  <input type="text" id="facebook" value={socialMedias[7].url} onChange={(e) => handleSocialMediaChange("facebook", e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder="Facebook" required />
                </div>
                <div>
                  <label htmlFor="playStore" className="block mb-2 text-sm font-medium text-gray-900">Link to Google Play Store</label>
                  <input type="text" id="playStore" value={socialMedias[8].url} onChange={(e) => handleSocialMediaChange("play_store_link", e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder="Play Store" required />
                </div>
                <div>
                  <label htmlFor="appStore" className="block mb-2 text-sm font-medium text-gray-900">Link to App Store Store</label>
                  <input type="text" id="appStore" value={socialMedias[9].url} onChange={(e) => handleSocialMediaChange("app_store_link", e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder="App Store" required />
                </div>
              </div>

              <div className='flex justify-end pt-5'>
                <button onClick={(e) => { submitSocialMedia(e) }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-md shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                    <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                  </svg>

                  <span className="mx-2">
                    {t('save')}
                  </span>
                </button>
              </div>
            </form>
          </>
      }
    </section>
  )
}

export default StoreSettings