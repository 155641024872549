import React, { useEffect, useState } from 'react'
import SuccessAlert from '../../../components/Alerts/SuccessAlert';
import FailAlert from '../../../components/Alerts/FailAlert';
import { MdClose } from 'react-icons/md'
import { t } from 'i18next';
import { PiShoppingCartSimple } from 'react-icons/pi';
import { useSuggestDomain } from '../../../graphql';
import { TbWorldSearch } from 'react-icons/tb';
import LoadingWidget from '../../../components/LoadingWidget/LoadingWidget';
import { RiErrorWarningFill } from 'react-icons/ri';
import DomainRequest from './DomainRequest';
import { FetchSuggestDomainProps } from '../../../types';

interface AddDomainProps {
  setBookDomainPopup: React.Dispatch<React.SetStateAction<boolean>>
}

const BookDomainPopup = ({ setBookDomainPopup }: AddDomainProps) => {

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [requestPopup, setRequestPopup] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState<FetchSuggestDomainProps>();

  const [keyword, setKeyword] = useState("");

  const [suggestDomain, { loading, data: dataSuggestDomain }] = useSuggestDomain();

  useEffect(() => {
    keyword !== "" &&
      (async () => {
        await suggestDomain({
          variables: {
            query: keyword,
            country: "",
            lengthMax: 20,
            lengthMin: 1,
            limit: 20
          }
        })
      })()
  }, [keyword]);

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>
      {requestPopup && <DomainRequest setRequestPopup={setRequestPopup} selectedDomain={selectedDomain} setSuccessMsg={setSuccessMsg} setSuccess={setSuccess} />}

      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='w-5/6 p-2 overflow-y-auto bg-white border sm:w-4/6 md:w-3/6 h-[90%] rounded-md'>
        <div className='flex justify-end py-2 text-gray-600'>
          <div onClick={() => setBookDomainPopup(false)} className='cursor-pointer'>
            <MdClose size={20} />
          </div>
        </div>

        <div>
          <div className="relative flex items-center w-full h-12 my-2 overflow-hidden bg-white border rounded-lg">
            <div className="grid w-12 h-full text-gray-300 place-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>

            <input value={keyword} onChange={(e) => setKeyword(e.target.value)} className="w-full h-full pr-2 text-sm text-gray-700 outline-none" type="text" id="search" placeholder={t('searchSomething')} />
          </div>

          {keyword === "" ?
            <div className='flex flex-col items-center justify-center mt-14'>
              <TbWorldSearch size={36} className='text-primary' />

              <h3 className='mt-2 text-2xl font-semibold'>{t('Search for domain')}</h3>
            </div>
            :
            loading ?
              <LoadingWidget />
              :
              dataSuggestDomain?.suggestDomain.length !== 0 ?
                dataSuggestDomain?.suggestDomain.map(domain => (
                  domain.availability.available === true &&
                  <div className='my-3' key={domain.domain}>
                    <div className='px-4 py-3 mb-2 border rounded'>
                      <div className='flex items-center justify-between '>
                        <h3 className='text-xl font-semibold'>
                          {domain.domain}
                        </h3>

                        <p className='font-medium text-gray-500'>${domain.availability.price / 1000000}/{t('yr')}</p>
                      </div>
                      <div className='flex justify-end mt-2'>
                        <button onClick={() => { setSelectedDomain(domain); setRequestPopup(true) }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                          <PiShoppingCartSimple size={20} />

                          <span className="mx-2">
                            {t('buy')}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                ))
                :
                <div className='flex flex-col items-center justify-center mt-14'>
                  <RiErrorWarningFill size={36} className='text-primary' />

                  <h3 className='mt-2 text-2xl font-semibold'>{t('notFound')}</h3>
                </div>
          }
        </div>
      </div>
    </div>
  )
}

export default BookDomainPopup