import React from 'react'
import {
  Chart as ChartJS,
  registerables
} from 'chart.js/auto'

import { Line } from "react-chartjs-2"
import { FetchOrdersStatistics } from '../../types';

ChartJS.register(
  ...registerables
);

const options = {
  scales: {
    y: {
      beginAtZero: true,
      suggestedMin: 0,
      grid: {
        display: false,
      },
      ticks: {
        min: 0,
        stepSize: 1
      }
    },
    x: {
      grid: {
        display: false,
      }
    },
  }
}

const OrdersChart = ({ dataOrderStatistics }: { dataOrderStatistics: FetchOrdersStatistics }) => {
  const labels = dataOrderStatistics?.orderStatisticsStore.map(entry => entry.day.split('-')[2])//.reverse();

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Orders",
        backgroundColor: "#7951F9",
        borderColor: "#7951F9",
        data: dataOrderStatistics?.orderStatisticsStore.map(entry => entry.totalOrders),
        tension: 0.5
      },
    ],
  };

  return (
    <Line data={data} options={options} />
  )
}

export default OrdersChart