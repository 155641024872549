import { gql } from '@apollo/client';

export const CreateCountry = gql`
mutation CreateCountry($content: contentCountry!) {
  createCountry(content: $content) {
    id
    name
    currency
    asDefault
    createdAt
    updatedAt
    deletedAt
  }
}
`;