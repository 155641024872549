import { gql, useLazyQuery } from '@apollo/client';
import { FetchPaymentMethods } from '../../../types';

export const ALL_PAYMENT_METHODS = gql`
query AllPaymentMethods($idStore: ID) {
  allPaymentMethods(idStore: $idStore) {
    id
    bankName
    accountName
    number
    IBAN
    country {
      id
      name
      currency
      asDefault
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`;

export const useAllPaymentMethods = () => {
  return useLazyQuery<FetchPaymentMethods>(ALL_PAYMENT_METHODS);
}