import React, { useState } from 'react'
import { FetchWorkers, checkedItems } from '../../../types'
import { t } from 'i18next'
import TeamWorkDropDown from '../../DropDowns/SettingsDrops/TeamWorkDropDown'
import useStore from '../../../store/useStore'

interface TableProps {
  checkedItems: checkedItems[],
  setCheckedItems: React.Dispatch<React.SetStateAction<checkedItems[]>>
  dataAllWorkers?: FetchWorkers
  setSelectedIdForDelete: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfPopup: React.Dispatch<React.SetStateAction<boolean>>
  direction: string
}

const TeamTable = ({ checkedItems, setCheckedItems, direction, dataAllWorkers, setSelectedIdForDelete, setDeleteConfPopup }: TableProps) => {
  const userData = useStore((state: any) => state.userData);

  const handleCheckAll = () => {
    if (dataAllWorkers?.allWorker.length !== checkedItems.length) {
      dataAllWorkers !== undefined && setCheckedItems(dataAllWorkers?.allWorker.map(brand => ({ id: brand.id })))
    } else {
      setCheckedItems([])
    }
  };

  const handleCheck = (id: string) => {
    const isItemExist = checkedItems.some(item => item.id === id);

    if (isItemExist) {
      const newCheckedItems = checkedItems.filter(item => item.id !== id);
      setCheckedItems(newCheckedItems);
    } else {
      setCheckedItems([...checkedItems, { id: id }])
    }
  };

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500">
            <div className='flex'>
              <input type="checkbox" checked={dataAllWorkers?.allWorker.length === checkedItems.length} onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </th>

          <th scope="col" className="py-3.5 px-3 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('name')}
          </th>

          <th scope="col" className="px-10 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('email')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('phone')}
          </th>

          <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
            {t('date')}
          </th>

          {userData.permissions.length === 0 &&
            <th scope="col" className="px-3 py-3.5 text-sm font-normal whitespace-nowrap text-left rtl:text-right text-gray-500">
              {t('option')}
            </th>
          }
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {dataAllWorkers?.allWorker.map((worker, index) => {
          return (
            <tr key={index}>
              <td className="px-4 py-3 text-sm text-left text-gray-500 rtl:text-right">
                <div className='flex'>
                  <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === worker.id)} onChange={() => handleCheck(worker.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
              </td>
              <td className="px-4 py-3 text-sm">
                <h2 className="text-gray-800">{worker.name}</h2>
              </td>
              <td className="px-10 py-3 text-sm">
                <p className="text-gray-500">{worker.email}</p>
              </td>
              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{worker.phone}</p>
              </td>
              <td className="px-3 py-4 text-sm">
                <p className="text-gray-500">{new Date(worker.createdAt).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>
              </td>
              {userData.permissions.length === 0 &&
                <td className="px-3 py-4 text-sm">
                  <TeamWorkDropDown direction={direction} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} id={worker.id} />
                </td>
              }
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default TeamTable