import React, { useEffect, useState } from 'react'
import SuccessAlert from '../../../components/Alerts/SuccessAlert';
import FailAlert from '../../../components/Alerts/FailAlert';
import statesData from '../../../data/yemen-states.json';
import { useMutation } from '@apollo/client';
import { CreateDeliveryMethod } from '../../../graphql';
import useStore from '../../../store/useStore';
import { MdClose } from 'react-icons/md'
import { t } from 'i18next';

interface AddShippingPopupProps {
  setAddShippingPopup: React.Dispatch<React.SetStateAction<boolean>>
}

const AddShippingPopup = ({ setAddShippingPopup }: AddShippingPopupProps) => {
  const storeData = useStore((state: any) => state.userData.store);

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  // Create
  const [createDeliveryMethod, { error }] = useMutation(CreateDeliveryMethod, {
    update: (cache, { data: { createDeliveryMethod } }) => {
      cache.modify({
        fields: {
          allDeliveryMethods(existedDeliveryMethods = []) {
            return [...existedDeliveryMethods, createDeliveryMethod];
          }
        }
      })
    }
  });

  const [cities, setCities] = useState(() =>
    statesData.map(city => ({ ...city, selected: false }))
  );

  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [cashOnDelivery, setCashOnDelivery] = useState(false);
  const [time, setTime] = useState("");

  const handleChange = (cityId: number) => {
    setCities(prevCities =>
      prevCities.map(city =>
        city.id === cityId ? { ...city, selected: !city.selected } : city
      )
    );
  };

  const handleCheckAll = (event: any) => {
    const isChecked = event.target.checked;
    setCities(prevCities =>
      prevCities.map(city => ({ ...city, selected: isChecked }))
    );
  };

  const Submit = async (e: any) => {
    e.preventDefault();
    if (name === "") {
      setFailMsg(t('emptyName'));
      setFail(true);
      return
    }

    if (price <= 0) {
      setFailMsg(t('invalidPriceAlert'));
      setFail(true);
      return
    }

    if (time === "") {
      setFailMsg(t('emptyDeliveryTime'));
      setFail(true);
      return
    }

    const selectedCities = cities.filter((city) => city.selected !== false);
    const citiesToStore: string[] = [];
    selectedCities.map((city: any) => {
      citiesToStore.push(city.name)
    })

    if (citiesToStore.length === 0) {
      setFailMsg(t('emptycitiesAlert'));
      setFail(true);
      return
    }

    await createDeliveryMethod({
      variables: {
        content: {
          estimatedTime: time,
          idStore: storeData.id,
          name: name,
          paymentWhenReceiving: cashOnDelivery,
          price: price,
          pricePaymentWhenReceiving: price,
          cities: citiesToStore
        }
      }
    })


    if (!error) {
      setSuccessMsg(t('shippingAddSuccess'));
      setSuccess(true);

      // CLear the form
      setName("");
      setPrice(0);
      setTime("");

      setAddShippingPopup(false)
    }
  }

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='w-full p-2 overflow-y-auto bg-white border md:w-5/6 h-[90%] rounded-md'>
        <div className='flex justify-end py-2 text-gray-600'>
          <div onClick={() => setAddShippingPopup(false)} className='cursor-pointer'>
            <MdClose size={20} />
          </div>
        </div>

        <div>
          <div className='mb-2'>
            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">{t('shippingOptionName')}</label>
            <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('shippingNamePlaceholder')} required />
          </div>

          <div className='mb-2'>
            <label htmlFor="cost" className="block mb-2 text-sm font-medium text-gray-900">{t('shippingCost')}</label>
            <input type="number" id="cost" value={price} onChange={(e) => setPrice(parseInt(e.target.value) || 0)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('cost')} required />
          </div>

          <div className="mb-2">
            <label htmlFor="noticetime" className="block mb-2 text-sm font-medium text-gray-900">{t('expectedShippingTime')}</label>
            <textarea id="time" value={time} onChange={(e) => setTime(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5" placeholder={t('ShippingTimePlaceholder')} />
          </div>

          <div className="flex relative items-center mb-2">
            <div className='flex'>
              <input type="checkbox" checked={cashOnDelivery} onChange={() => setCashOnDelivery(!cashOnDelivery)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>

            <label className="mx-2 font-medium text-gray-800">{t('payingOnDelivery')}</label>
          </div>

          <div className='mb-2'>
            <label className="block mb-2 text-sm font-medium text-gray-900">{t('shippingCities')}</label>
            <div className="py-2 mt-2 overflow-y-auto bg-white border border-gray-300 rounded-md h-52">
              <div className="flex flex-col p-3 text-sm">
                <div className='flex relative gap-2 pb-2'>
                  <div className='flex'>
                    <input type="checkbox" onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                    <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </div>
                  <label htmlFor='all' className="w-full mx-1">
                    {t('checkAll')}
                  </label>
                </div>

                {cities.map((state: any, index: any) => (
                  <div key={index} className='flex items-center gap-3 py-2 my-px text-gray-600 capitalize transition-colors duration-300 transform cursor-pointer hover:bg-gray-100'>
                    <div className='flex'>
                      <input type="checkbox" onChange={() => handleChange(state.id)} checked={state.selected} id={`city${index}`} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                      <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </div>
                    <label htmlFor={`city${index}`} className="w-full mx-1">
                      {state.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='flex justify-end pt-5'>
            <button onClick={(e) => { Submit(e) }} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
              </svg>

              <span className="mx-2">
                {t('save')}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddShippingPopup