import { gql, useLazyQuery } from "@apollo/client"
import { FetchProducts } from "../../types";

export const ALL_PRODUCTS = gql`
query Query($idStore: ID) {
  allProducts(idStore: $idStore) {
    id
    name
    price
    priceAfterDiscount
    cost
    quantity
    weight
    shortDescription
    description
    notRequireDelivery
    freeShipping
    posting
    imagesProduct {
      id
      name
      alt
      createdAt
      updatedAt
      deletedAt
    }
    brand {
      id
      name
      image
      createdAt
      updatedAt
      deletedAt
    }

    rating {
      id
      mark
      description
      product {
        id
        name
        price
        priceAfterDiscount
        cost
        quantity
        weight
        shortDescription
        description
        notRequireDelivery
        freeShipping
        posting
        createdAt
        updatedAt
        deletedAt
      }
      client {
        id
        profilePicture
        name
        email
        phone
        gander
        createdAt
        updatedAt
        deletedAt
        address
      }
      createdAt
      updatedAt
      deletedAt
    }

    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useAllProducts = () => {
  return useLazyQuery<FetchProducts>(ALL_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
  });
}