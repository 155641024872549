import React, { useState } from 'react'
import { MdClose } from 'react-icons/md'
import { t } from 'i18next';

interface VATpopupProps {
  setVatPopup: React.Dispatch<React.SetStateAction<boolean>>
}

const VATpopup = ({ setVatPopup }: VATpopupProps) => {

  const [Toggle, setToggle] = useState(false);
  const [tvaDealer, setTvaDealer] = useState(false)
  const [showTva, setShowTva] = useState(false)
  const [tvaRate, setTvaRate] = useState("")
  const [tvaNumber, setTvaNumber] = useState("")

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>
      <div className='w-full p-2 bg-white border md:w-5/6 lg:w-1/2 xl:w-1/2'>
        <div className='flex justify-end py-2 text-gray-600'>
          <div onClick={() => setVatPopup(false)} className='cursor-pointer'>
            <MdClose size={20} />
          </div>
        </div>

        <div className='flex items-center gap-4 mt-5 mb-3'>
          <div className="relative inline-flex items-center">
            <div className="h-6 bg-gray-200 rounded-full cursor-pointer w-11" onClick={() => { setToggle(!Toggle) }}>
              <div className={`w-6 h-6 bg-primary ${Toggle && "ml-6"} duration-200 rounded-full`}></div>
            </div>
          </div>

          <label className="block font-medium text-gray-900">
            {t('activeVAT')}
          </label>
        </div>

        <div className='my-6'>
          <div className="flex items-center my-1">
            <input type="checkbox" checked={tvaDealer || false} onChange={(e) => setTvaDealer(e.target.checked)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
            <label className="mx-2 font-medium text-gray-800">
              {t('VATIncurredDealer')}
            </label>
            <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          </div>

          <div className="flex items-center my-1">
            <input type="checkbox" checked={showTva || false} onChange={(e) => setShowTva(e.target.checked)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
            <label className="mx-2 font-medium text-gray-800">
              {t('showItOnStore')}
            </label>
            <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          </div>
        </div>

        <div className="flex items-center justify-center w-full mb-4">
          <label className="flex flex-col w-full h-32 border-2 border-dashed rounded-md cursor-pointer hover:bg-gray-100 hover:border-gray-300">
            <div className="flex flex-col items-center justify-center pt-7">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 23 20" fill="currentColor">
                <path d="M13 19v-4h3l-4-5-4 5h3v4z"></path>
                <path d="M7 19h2v-2H7c-1.654 0-3-1.346-3-3 0-1.404 1.199-2.756 2.673-3.015l.581-.102.192-.558C8.149 8.274 9.895 7 12 7c2.757 0 5 2.243 5 5v1h1c1.103 0 2 .897 2 2s-.897 2-2 2h-3v2h3c2.206 0 4-1.794 4-4a4.01 4.01 0 0 0-3.056-3.888C18.507 7.67 15.56 5 12 5 9.244 5 6.85 6.611 5.757 9.15 3.609 9.792 2 11.82 2 14c0 2.757 2.243 5 5 5z"></path>
              </svg>
              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                {t('selectPhoto')}
              </p>
            </div>
            <input type="file" multiple={false} className="opacity-0" />
          </label>
        </div>

        {Toggle
          ? <>
            <div className="mb-4">
              <label htmlFor="rate" className="block mb-2 text-sm font-medium text-gray-900">TVA Rate</label>
              <input
                type="text"
                value={tvaRate}
                onChange={(e) => setTvaRate(e.target.value)}
                id="rate"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5"
                placeholder='Tva rate'
              />
            </div>

            <div className="mb-4">
              <label htmlFor="number" className="block mb-2 text-sm font-medium text-gray-900">TVA Number</label>
              <input
                type="text"
                value={tvaNumber}
                onChange={(e) => setTvaNumber(e.target.value)}
                id="number"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary outline-none block w-full p-2.5"
                placeholder='Tva number'
              />
            </div>
          </>
          : null
        }

        <div className='flex justify-end my-2'>
          <div>
            <button type={"submit"} className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
              <span className="mx-2">
                {t('save')}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VATpopup