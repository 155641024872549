import React, { useEffect, useState } from 'react'
import AddCoupon from './AddCoupon';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import { useAllCoupons } from '../../graphql';
import { checkedItems } from '../../types';
import useStore from '../../store/useStore';
import CouponsTable from '../../components/Tables/CouponsTable';
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget';
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import FailAlert from '../../components/Alerts/FailAlert';
import DeleteConfirmPopup from './DeleteConfirmPopup';

interface Props {
  direction: string
}

const DiscountCoupons = ({ direction }: Props) => {

  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    delete: false,
    view: false
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          create: true,
          edit: true,
          delete: true,
          view: true,
        })

        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "marketing")

      let createPermission = filtered[0]?.permissions.includes("create")
      let editPermission = filtered[0]?.permissions.includes("edit")
      let deletePermission = filtered[0]?.permissions.includes("delete")
      let viewPermission = filtered[0]?.permissions.includes("view")

      setPermissions({
        create: createPermission || false,
        edit: editPermission || false,
        delete: deletePermission || false,
        view: viewPermission || false,
      })
    }
  }, [userData])

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  // This state hook is to specify which item going to start from in the table
  const [startingIndex, setStartingIndex] = useState(0)

  const [allCoupons, { loading, data: dataAllCoupons }] = useAllCoupons();

  useEffect(() => {
    storeData &&
      (async () => {
        await allCoupons({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()

  }, [storeData]);

  // Alerts
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  // This state contains the id of the client that will be deleted
  const [selectedIdForDelete, setSelectedIdForDelete] = useState("");

  const [deleteConfPopup, setDeleteConfPopup] = useState(false);

  const [addCouponPopup, setAddCouponPopup] = useState(false);

  const [checkedItems, setCheckedItems] = useState<checkedItems[]>([]);

  return (
    <section className='my-4 mx-3 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {addCouponPopup &&
        <AddCoupon setAddCouponPopup={setAddCouponPopup} setSuccessMsg={setSuccessMsg} setSuccess={setSuccess} />
      }

      {deleteConfPopup && <DeleteConfirmPopup checkedItems={checkedItems} setDeleteConfPopup={setDeleteConfPopup} selectedIdForDelete={selectedIdForDelete} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('discountCoupons')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        <div className='p-2 mt-3 bg-white rounded-md'>
          <div>
            <div className='flex justify-end my-2'>
              <div>
                {permissions.create &&
                  <button onClick={() => setAddCouponPopup(true)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                    <AiOutlineAppstoreAdd size={20} />

                    <span className="mx-2">
                      {t('addDiscountCoupons')}
                    </span>
                  </button>
                }
              </div>
            </div>
          </div>

          <div className='h-full pt-2 overflow-x-scroll'>
            <CouponsTable checkedItems={checkedItems} setCheckedItems={setCheckedItems} permissions={permissions} dataAllCoupons={dataAllCoupons} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} setFailMsg={setFailMsg} direction={direction} startingIndex={startingIndex} />
          </div>

          {dataAllCoupons?.allCoupons.length === 0 &&
            <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
              </svg>
              <span className='px-2'>{t('noDiscountCouponsShow')}</span>
            </div>
          }

          <div className="flex justify-end gap-1.5 text-gray-500 py-4">
            <span className="font-medium text-gray-700">{startingIndex} - {startingIndex + 10}</span>
            <span>{t('of')} {dataAllCoupons?.allCoupons.length} {t('records')}</span>
          </div>

          {dataAllCoupons?.allCoupons &&
            startingIndex + 10 < dataAllCoupons?.allCoupons.length &&
            <div className="flex justify-end gap-1.5 text-gray-500 py-2">
              <button onClick={() => setStartingIndex(startingIndex + 10)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path>
                </svg>
                <span className="mx-2">
                  {t('next')}
                </span>
              </button>
            </div>
          }

        </div>
      }
    </section>
  )
}

export default DiscountCoupons