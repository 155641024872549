import { gql, useLazyQuery } from "@apollo/client"
import { FetchClients } from "../../types";

export const ALL_ClIENTS = gql`
query AllClient($idStore: ID) {
  allClient(idStore: $idStore) {
    id
    name
    email
    phone
    dateBirth
    address
    gander
    note
    addressClient {
      id
      name
      phone
      city
      address
      asDefault
    }
    store {
      id
      name
      link
      logo
      icon
      description
      address
      numberProducts
      numberOrders
      numberClients
      admin {
        id
        profilePicture
        name
        email
        phone
        role
        activation
        emailVerify
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useAllClients = () => {
  return useLazyQuery<FetchClients>(ALL_ClIENTS, {
    fetchPolicy: 'cache-and-network',
  });
}