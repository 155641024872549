import React, { useEffect, useState } from 'react'
import AddCountriesPopup from './AddCountriesPopup';
import { useAllCountries } from '../../../graphql';
import LoadingWidget from '../../../components/LoadingWidget/LoadingWidget';
import CountryDropdown from '../../../components/DropDowns/SettingsDrops/CountryDropdown';
import DeleteConfirmPopup from './DeleteConfirmPopup';
import SuccessAlert from '../../../components/Alerts/SuccessAlert';
import FailAlert from '../../../components/Alerts/FailAlert';
import useStore from '../../../store/useStore';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';

const Countries = ({ direction }: { direction: string }) => {
  const storeData = useStore((state: any) => state.userData.store);

  // I did scroll to the top because when the component is render it show the bottom of the page because the layout
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  // This state contains the id of the product that will be deleted
  const [selectedIdForDelete, setSelectedIdForDelete] = useState("");
  const [deleteConfPopup, setDeleteConfPopup] = useState(false);

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [allCountries, { loading, refetch, data: dataAllCountries }] = useAllCountries();

  useEffect(() => {
    storeData &&
      (async () => {
        await allCountries({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()

  }, [storeData]);

  useEffect(() => {
    storeData && refetch();
  });

  const [addCountriesPopup, setAddCountriesPopup] = useState(false)

  return (
    <section className='my-4 mx-3 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {addCountriesPopup && <AddCountriesPopup direction={direction} setAddCountriesPopup={setAddCountriesPopup} success={success} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} successMsg={successMsg} />}

      {deleteConfPopup && <DeleteConfirmPopup setDeleteConfPopup={setDeleteConfPopup} selectedIdForDelete={selectedIdForDelete} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('countriesSettings')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div className='p-2 mt-3 bg-white rounded-md'>
        <div className='items-center md:flex md:justify-between'>
          <div>
            <h3 className='text-lg'>{t('countries')}</h3>
          </div>

          <div onClick={() => setAddCountriesPopup(true)} className='flex items-center justify-center px-4 py-3 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow cursor-pointer bg-primary hover:bg-primaryHover'>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="currentColor">
              <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
              <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
            </svg>

            <span className="mx-2">
              {t('addCountry')}
            </span>
          </div>
        </div>

        <div className='my-2 border rounded-md'>
          {initialLoading || loading ?
            <LoadingWidget />
            :
            <div>
              {dataAllCountries?.allCountries.map(country => (
                <div key={country.id} className='flex items-center justify-between px-4 py-3 border-b'>
                  <div>
                    {t(country.name)}
                    <span className='ml-3 text-sm text-gray-500'>
                      ({t(country.currency)})
                    </span>
                  </div>
                  <div>
                    <CountryDropdown direction={direction} id={country.id} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} />
                  </div>
                </div>
              ))
              }
              {dataAllCountries?.allCountries.length === 0 &&
                <div className='flex w-full p-2 text-red-600 bg-red-200'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                    <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                  </svg>
                  <span className='px-2'>{t('noCountries')}</span>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default Countries