import { gql } from '@apollo/client';

export const DeliveryMethod = gql`
query DeliveryMethod($deliveryMethodId: ID) {
  deliveryMethod(id: $deliveryMethodId) {
    id
    name
    price
    paymentWhenReceiving
    pricePaymentWhenReceiving
    estimatedTime
    cities
    createdAt
    updatedAt
    deletedAt
  }
}
`;