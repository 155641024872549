import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { FetchDefinitionPages } from '../../../types'
import { useMutation } from '@apollo/client'
import { ALL_DEFINITION_PAGE, UpdateDefinitionPage, UpdatePublishDefinitionPage } from '../../../graphql'
import useStore from '../../../store/useStore'
import { MdPublishedWithChanges } from 'react-icons/md'

interface EditPublishProps {
  setEditPublishPopup: React.Dispatch<React.SetStateAction<boolean>>,
  setSuccessMsg: React.Dispatch<React.SetStateAction<string>>,
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  pageId: string
  dataAllDefinitionPage?: FetchDefinitionPages
}

const PubishEditPopup = ({ dataAllDefinitionPage, setEditPublishPopup, pageId, setSuccessMsg, setSuccess }: EditPublishProps) => {
  const storeData = useStore((state: any) => state.userData.store);

  const [definitionPage, setDefinitionPage] = useState<any>();

  useEffect(() => {
    const page = dataAllDefinitionPage?.allDefinitionPage.find(page => page.id === pageId);
    setDefinitionPage(page)
  }, [])
  
  const [updatePublishDefinitionPage, { error }] = useMutation(UpdatePublishDefinitionPage);

  const submit = async () => {
    await updatePublishDefinitionPage({
      variables: {
        updatePublishDefinitionPageId: pageId,
        publish: definitionPage.publish === false ? true : false,
      },
      refetchQueries: [{ query: ALL_DEFINITION_PAGE, variables: { idStore: storeData.id } }]
    })

    if (!error) {
      setSuccessMsg(t('pageUpdateSuccess'));
      setSuccess(true);
      setEditPublishPopup(false)
    }
  }

  return (
    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full min-h-screen bg-gray-900/10 backdrop-blur-md'>
      <div className='w-5/6 p-2 bg-white border rounded-md sm:w-4/6 md:w-3/6'>
        <div className="relative p-4 text-center">
          <div className='flex justify-center pb-6'>
            <MdPublishedWithChanges size={38} className="text-gray-400" />
          </div>

          <p className="mb-4 text-gray-500">{t('publishConfirm1')} {definitionPage && definitionPage.publish === true ? t("stop") : t("active")} {t("publishConfirm2")}</p>
          <div className="flex items-center justify-center gap-4">
            <button onClick={() => setEditPublishPopup(false)} className="px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-gray-900 focus:z-10">
              {t('cancel')}
            </button>
            <button onClick={() => submit()} className="px-3 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700">
              {t('confirm')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PubishEditPopup