import { gql } from '@apollo/client';

export const Worker = gql`
query Worker($workerId: ID) {
  worker(id: $workerId) {
    id
    name
    email
    phone
    permissions {
      id
      name
      permissions
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`;