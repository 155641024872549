import { gql, useLazyQuery } from '@apollo/client';
import { FetchAllYouTubeVideo } from '../../../../types';

export const ALL_YOUTUBE_VIDEO = gql`
query AllYouTubeVideo($idStore: ID) {
  allYouTubeVideo(idStore: $idStore) {
    id
    link
    rank
    createdAt
    updatedAt
    deletedAt
  }
}`

export const useAllYouTubeVideo = () => {
  return useLazyQuery<FetchAllYouTubeVideo>(ALL_YOUTUBE_VIDEO);
}
