import { gql, useLazyQuery } from '@apollo/client';
import { FetchCarousels } from '../../../../types';

export const ALL_CAROUSELS = gql`
query AllCarousels($idStore: ID) {
  allCarousels(idStore: $idStore) {
    id
    type
    title
    description
    link
    image
    rank
    createdAt
    updatedAt
    deletedAt
  }
}`

export const useAllCarousels = () => {
  return useLazyQuery<FetchCarousels>(ALL_CAROUSELS);
}
