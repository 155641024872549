import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SuccessAlert from '../../../components/Alerts/SuccessAlert'
import FailAlert from '../../../components/Alerts/FailAlert'
import { useAllDefinitionPage } from '../../../graphql'
import { checkedItems } from '../../../types'
import DefinitionPagesTable from '../../../components/Tables/SettingsTables/DefinitionPagesTable'
import DeleteConfirmPopup from './DeleteConfirmPopup'
import LoadingWidget from '../../../components/LoadingWidget/LoadingWidget'
import useStore from '../../../store/useStore'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'
import PubishEditPopup from './PubishEditPopup'

const DefinitionPagesSettings = ({ direction }: { direction: string }) => {
  const storeData = useStore((state: any) => state.userData.store);

  // Alerts
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  // I did scroll to the top because when the component is render it show the bottom of the page because the layout
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [deleteConfPopup, setDeleteConfPopup] = useState(false);
  const [editPublishPopup, setEditPublishPopup] = useState(false);

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [allDefinitionPage, { loading, refetch, data: dataAllDefinitionPage }] = useAllDefinitionPage();

  useEffect(() => {
    storeData &&
      (async () => {
        await allDefinitionPage({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()

  }, [storeData]);

  useEffect(() => {
    storeData && refetch()
  });

  // This state contains the id of the product that will be deleted
  const [selectedIdForDelete, setSelectedIdForDelete] = useState("");

  const [checkedItems, setCheckedItems] = useState<checkedItems[]>([]);

  return (
    <section className='my-4 mx-3 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {deleteConfPopup && <DeleteConfirmPopup checkedItems={checkedItems} setDeleteConfPopup={setDeleteConfPopup} selectedIdForDelete={selectedIdForDelete} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      {editPublishPopup && <PubishEditPopup dataAllDefinitionPage={dataAllDefinitionPage} setEditPublishPopup={setEditPublishPopup} pageId={selectedIdForDelete} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('definitionPages')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      <div className='p-2 mt-3 bg-white rounded-md'>
        {initialLoading || loading ?
          <LoadingWidget />
          :
          <>
            <div>
              <p className='my-2 text-gray-500'>{t('definitionPagesPageDescription')}</p>
            </div>
            <div className='flex justify-end'>
              <Link to="/settings/definitionPages/create" className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                  <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                </svg>

                <span className="mx-2">
                  {t('addPages')}
                </span>
              </Link>
            </div>

            <div className='pt-2 overflow-x-scroll'>
              <DefinitionPagesTable checkedItems={checkedItems} setCheckedItems={setCheckedItems} direction={direction} allDefinitionPage={dataAllDefinitionPage} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} setEditPublishPopup={setEditPublishPopup} />
            </div>

            {dataAllDefinitionPage?.allDefinitionPage.length === 0 &&
              <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                  <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                </svg>
                <span className='px-2'>{t('noDefinitionPagesToShow')}</span>
              </div>
            }
          </>
        }
      </div>
    </section>
  )
}

export default DefinitionPagesSettings