import { gql } from "@apollo/client"

export const CreateBrand = gql`
mutation CreateBrand($content: contentBrand!) {
  createBrand(content: $content) {
    id
    name
    image
    createdAt
    updatedAt
    deletedAt
    store {
      id
    }
    classFirstLevel {
      id
      name
      classSecondLevel {
        id
        name
        classThirdLevel {
          id
          name
          classSecondLevel {
            id
          }
          createdAt
          updatedAt
          deletedAt
        }
        classFirstLevel {
          id
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
}
`