import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FetchProducts, ProductProps, checkedItems } from '../../types/Products'
import { useAllProducts } from '../../graphql'
import ProductsTable from '../../components/Tables/ProductsTable'
import SuccessAlert from '../../components/Alerts/SuccessAlert'
import FailAlert from '../../components/Alerts/FailAlert'
import DeleteConfirmPopup from './DeleteConfirmPopup'
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget'
import useStore from '../../store/useStore'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'
import { IoArrowBackSharp, IoArrowForward } from 'react-icons/io5'

const Products = ({ direction }: { direction: string }) => {
  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    delete: false,
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          create: true,
          edit: true,
          delete: true,
        })

        return
      }


      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "Products")

      let createPermission = filtered[0]?.permissions.includes("create")
      let editPermission = filtered[0]?.permissions.includes("edit")
      let deletePermission = filtered[0]?.permissions.includes("delete")

      setPermissions({
        create: createPermission || false,
        edit: editPermission || false,
        delete: deletePermission || false,
      })
    }
  }, [userData])

  // The state that contains Fetched Products, i didn't use allProducts from useAllProducts beacuse i couldn't modify it to contain filtered result
  const [Products, setProducts] = useState<FetchProducts>();

  // This state hook is to specify which item going to start from in the table
  const [startingIndex, setStartingIndex] = useState(0)

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [allProducts, { loading, data: dataAllProducts }] = useAllProducts();

  useEffect(() => {
    storeData &&
      (async () => {
        await allProducts({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()

  }, [storeData]);

  useEffect(() => {
    storeData && dataAllProducts !== undefined && setProducts(dataAllProducts)
  }, [dataAllProducts, storeData]);

  // Alerts
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [deleteConfPopup, setDeleteConfPopup] = useState(false);

  // This state contains the id of the product that will be deleted
  const [selectedIdForDelete, setSelectedIdForDelete] = useState("");

  // Search functionality
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState<ProductProps[] | undefined>([]);

  useEffect(() => {
    const filtered = dataAllProducts?.allProducts.filter(product =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setSearchResult(filtered);
  }, [searchQuery, dataAllProducts]);

  const [checkedItems, setCheckedItems] = useState<checkedItems[]>([]);

  return (
    <section className='mx-3 my-4 md:mx-7'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {deleteConfPopup && <DeleteConfirmPopup checkedItems={checkedItems} setDeleteConfPopup={setDeleteConfPopup} selectedIdForDelete={selectedIdForDelete} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('products')}</h3>
          <span className='text-gray-600'>{t('numberOfProducts')} : {dataAllProducts?.allProducts.length}</span>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        <div className='p-2 mt-3 bg-white rounded-md'>
          <div>
            <div className='my-2 md:flex md:justify-between'>
              <div className="relative flex items-center w-full h-12 overflow-hidden bg-white border rounded-lg md:w-2/6">
                <div className="grid w-12 h-full text-gray-300 place-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>

                <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="w-full h-full pr-2 text-sm text-gray-700 outline-none" type="text" id="search" placeholder={t('searchSomething')} />
              </div>

              {/* check for user permission */}
              {permissions.create === true &&
                <div>
                  <Link to="/products/create" className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                    <AiOutlineAppstoreAdd size={20} />

                    <span className="mx-2">
                      {t('addProducts')}
                    </span>
                  </Link>
                </div>
              }
            </div>
          </div>

          <div className='pt-2 overflow-x-scroll'>
            <ProductsTable checkedItems={checkedItems} setCheckedItems={setCheckedItems} permissions={permissions} direction={direction} searchQuery={searchQuery} searchResult={searchResult} allProducts={Products} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} setFailMsg={setFailMsg} startingIndex={startingIndex} />
          </div>

          {dataAllProducts?.allProducts.length === 0 &&
            <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
              </svg>
              <span className='px-2'>{t('noProducts')}</span>
            </div>
          }

          {searchQuery !== "" &&
            searchResult !== undefined &&
            searchResult.length === 0 &&
            <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
              </svg>
              <span className='px-2'>{t('notMatch')}</span>
            </div>
          }

          <div className="flex justify-end gap-1.5 text-gray-500 py-4">
            <span className="font-medium text-gray-700">{startingIndex} - {startingIndex + 10}</span>
            <span>{t('of')} {dataAllProducts?.allProducts.length} {t('records')}</span>
          </div>

          <div className='flex items-center justify-between'>
            {dataAllProducts?.allProducts &&
              startingIndex !== 0 &&
              <div className="gap-1.5 text-gray-500 py-2">
                <button onClick={() => setStartingIndex(startingIndex - 10)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  {direction === "ltr" ? <IoArrowBackSharp size={18} /> : <IoArrowForward size={18} />}
                  <span className="mx-2">
                    {t('prev')}
                  </span>
                </button>
              </div>
            }

            {dataAllProducts?.allProducts &&
              startingIndex + 10 < dataAllProducts?.allProducts.length &&
              <div className={`${direction === "ltr" ? "ml-auto" : "mr-auto"} gap-1.5 text-gray-500 py-2`} dir='ltr'>
                <button onClick={() => setStartingIndex(startingIndex + 10)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  {direction === "ltr" ? <IoArrowForward size={18} /> : <IoArrowBackSharp size={18} />}
                  <span className="mx-2">
                    {t('next')}
                  </span>
                </button>
              </div>
            }
          </div>
        </div>
      }
    </section>
  )
}

export default Products