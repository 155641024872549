import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { t } from 'i18next'
import { MdLanguage } from 'react-icons/md'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import BookDomainPopup from './BookDomainPopup'
import useStore from '../../../store/useStore'
import LoadingWidget from '../../../components/LoadingWidget/LoadingWidget'
import PermissionsError from '../../../components/Alerts/PermissionsError'
import { useDomainStore } from '../../../graphql'
import AlreadyBookDomainPopup from './AlreadyBookDomainPopup'

const Domain = () => {

  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [permissions, setPermissions] = useState({
    view: false,
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          view: true,
        })

        setInitialLoading(false)
        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "settings")

      let viewPermission = filtered[0]?.permissions.includes("domain")

      setPermissions({
        view: viewPermission || false,
      })

      setInitialLoading(false)
    }
  }, [userData])

  // I did scroll to the top because when the component is render it show the bottom of the page because the layout
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [bookDomainPopup, setBookDomainPopup] = useState(false);
  const [alreadyBookDomainPopup, setAlreadyBookDomainPopup] = useState(false);

  const [allDomainsStore, { loading, data: dataAllDomainsStore }] = useDomainStore();

  useEffect(() => {
    storeData &&
      (async () => {
        await allDomainsStore({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()

  }, [storeData]);

  return (
    <section className='mx-3 my-4 md:mx-7'>
      {bookDomainPopup && <BookDomainPopup setBookDomainPopup={setBookDomainPopup} />}
      {alreadyBookDomainPopup && <AlreadyBookDomainPopup setAlreadyBookDomainPopup={setAlreadyBookDomainPopup} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('domain')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {
        initialLoading || loading ?
          <LoadingWidget />
          :
          permissions.view === false ?
            <PermissionsError />
            :
            <>
              <div className='p-2 mt-3 bg-white rounded-md'>
                <div>
                  <p className='text-gray-600'>{t('domainPageDescription')}</p>
                </div>

                <div className='mt-10 mb-2'>
                  <div className="grid grid-cols-2 gap-6">
                    <button onClick={() => setBookDomainPopup(true)} className='flex items-center justify-center w-full px-4 py-3 my-2 overflow-hidden text-sm !text-white transition-colors duration-300 rounded-lg shadow bg-primary hover:bg-primaryHover'>
                      <MdLanguage size={20} />

                      <span className="mx-2">
                        {t('bookDomain')}
                      </span>
                    </button>

                    <button onClick={() => setAlreadyBookDomainPopup(true)} className='flex items-center justify-center w-full px-4 py-3 my-2 overflow-hidden text-sm !text-white transition-colors duration-300 rounded-lg shadow bg-primary hover:bg-primaryHover'>
                      <AiOutlineAppstoreAdd size={20} />

                      <span className="mx-2">
                        {t('alreadyHaveDomain')}
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              {dataAllDomainsStore?.allDomainsStore.length !== 0 &&
                <>
                  <div>
                    <h3 className='mt-3 text-xl font-semibold'>
                      {t('previewRequests')}
                    </h3>
                  </div>
                  <div className='flex flex-col gap-2 my-3'>
                    {dataAllDomainsStore?.allDomainsStore.map((domain, index) => (
                      <div key={index} className='p-2 bg-white rounded-md'>
                        <div className='flex items-center justify-between'>
                          <div className='text-lg'>
                            {domain.domain}
                          </div>
                          <div>
                            ${domain.price / 1000000}
                          </div>
                        </div>
                        <div className='pb-2 mt-3'>
                          <p>{t('status')}:
                            {domain.status === "pending" &&
                              <span className="p-1.5 text-sm font-semibold mx-2 text-orange-500 bg-orange-100 rounded-full w-fit">
                                {t('pending')}
                              </span>
                            }
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              }
            </>
      }
    </section>
  )
}

export default Domain