import { gql, useLazyQuery } from '@apollo/client';
import { FetchAnimatedProducts } from '../../../../types';

export const ALL_ANIMATED_PRODUCTS = gql`
query AllAnimatedProducts($idStore: ID) {
  allAnimatedProducts(idStore: $idStore) {
    id
    type
    link
    rank
    createdAt
    updatedAt
    deletedAt
  }
}`

export const useAllAnimatedProducts = () => {
  return useLazyQuery<FetchAnimatedProducts>(ALL_ANIMATED_PRODUCTS);
}
