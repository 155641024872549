import { gql, useLazyQuery } from "@apollo/client"
import { FetchMarketer } from "../../types";

export const Marketer = gql`
query Marketer($marketerId: ID) {
  marketer(id: $marketerId) {
    id
    name
    phone
    email
    otherInfo
    marketingMethod
    commissionType
    value
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useMarketer = () => {
  return useLazyQuery<FetchMarketer>(Marketer);
}