import React, { useEffect, useState } from 'react'
import CountriesData from '../../../data/arabic-countries.json';
import { CountriesProps, FetchCountries } from '../../../types';
import { ALL_COUNTRIES, CreateCountry, DeleteCountry } from '../../../graphql';
import { useMutation, useQuery } from '@apollo/client';
import SuccessAlert from '../../../components/Alerts/SuccessAlert';
import FailAlert from '../../../components/Alerts/FailAlert';
import useStore from '../../../store/useStore';
import { MdClose } from 'react-icons/md';
import { t } from 'i18next';

interface Props {
  setAddCountriesPopup: React.Dispatch<React.SetStateAction<boolean>>,
  success: boolean,
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  setSuccessMsg: React.Dispatch<React.SetStateAction<string>>,
  successMsg: string,
  direction: string,
}

const AddCountriesPopup = ({ direction, setAddCountriesPopup, success, setSuccess, setSuccessMsg, successMsg }: Props) => {
  const storeData = useStore((state: any) => state.userData.store);

  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [CountriesList, setCountriesList] = useState<CountriesProps[]>(CountriesData)

  const { data: allCountries, refetch } = useQuery<FetchCountries>(ALL_COUNTRIES, {
    variables: { idStore: storeData.id }
  });

  useEffect(() => {
    refetch();
  })

  useEffect(() => {
    const updatedCountries = CountriesList.map((country) => {
      const foundCountry = allCountries?.allCountries.find((fetchedCountry) =>
        fetchedCountry.name === country.country.en
      );

      return {
        ...country,
        selected: foundCountry ? true : false,
      };
    });

    setCountriesList(updatedCountries);

  }, [allCountries]);

  const Check = (Country: any) => {
    setCountriesList((prevCountries) => {
      return prevCountries.map((country) => {
        if (country.id === Country.id) {
          return { ...country, selected: !country.selected };
        }
        return country;
      });
    });
  }
  // Create
  const [createCountry, { error }] = useMutation(CreateCountry);
  const [deleteCountry] = useMutation(DeleteCountry);

  const submit = async (e: any) => {
    e.preventDefault();

    // Get the Selected countries
    var SelectedCountries: CountriesProps[] = [];


    CountriesList.map(country => {
      const isInDatabase = allCountries?.allCountries.find(Country => Country.name === country.country.en)

      if (isInDatabase === undefined) {
        if (country.selected) {
          return SelectedCountries = [...SelectedCountries, country]
        }
        return false
      }
    })

    SelectedCountries.map(async (country) => {
      await createCountry({
        variables: {
          content: {
            asDefault: false,
            currency: country.currency.en,
            idStore: storeData.id,
            name: country.country.en
          }
        }
      })
    })

    // Get the unselected countries from the database
    const unselectedCountries = allCountries?.allCountries.filter(
      (fetchedCountry) => {
        return CountriesList.find(
          (selectedCountry) => {
            if (selectedCountry.selected === false && selectedCountry.country.en === fetchedCountry.name) {
              return selectedCountry
            }
            return false
          }
        );
      }
    );

    unselectedCountries?.map(async (country) => {
      await deleteCountry({
        variables: {
          deleteCountryId: country.id
        }
      })
    })

    if (!error) {
      setSuccessMsg(t('countryUpdatedSuccess'));
      setSuccess(true);
      setAddCountriesPopup(false)
    }
  }

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen py-6 bg-gray-900/10 backdrop-blur-md'>
      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      <div className='w-5/6 p-2 bg-white border sm:w-4/6 md:w-3/6 rounded-md'>
        <div className='flex justify-end py-2 text-gray-600'>
          <div onClick={() => setAddCountriesPopup(false)} className='cursor-pointer'>
            <MdClose size={20} />
          </div>
        </div>

        <div className='py-2'>
          <div className='h-64 my-3 overflow-y-auto'>
            {CountriesList.map(country => (
              <div key={country.id} className="flex items-center px-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                <div className='flex'>
                  {country.selected}
                  <input checked={country.selected} onChange={() => Check(country)} type="checkbox" id={`check-${country.id}`} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                  <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <label htmlFor={`check-${country.id}`} className='w-full px-3 py-3 cursor-pointer'>
                  {direction === "rtl" ? country.country.ar : country.country.en}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className='flex justify-end my-2'>
          <div onClick={(e) => { submit(e) }} >
            <button className='inline-flex items-center justify-center px-4 py-2 my-2 overflow-hidden text-sm text-white transition-colors duration-300 rounded-lg shadow bg-primary sm:w-auto md:my-0 hover:bg-primaryHover'>
              <span className="mx-2">
                {t('save')}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCountriesPopup