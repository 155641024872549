import { gql } from "@apollo/client";

export const CreateRectangleBannerWide = gql`
mutation CreateRectangleBannerWide($content: contentRectangleBanner!) {
  createRectangleBannerWide(content: $content) {
    id
    type
    link
    image
    createdAt
    updatedAt
    deletedAt
  }
}
`;
