import React, { useEffect, useState } from 'react'
import AddPaymentPopup from './AddPaymentPopup';
import { useAllPaymentMethods } from '../../../graphql';
import { checkedItems } from '../../../types';
import PaymentMethodsTable from '../../../components/Tables/SettingsTables/PaymentMethodsTable';
import LoadingWidget from '../../../components/LoadingWidget/LoadingWidget';
import SuccessAlert from '../../../components/Alerts/SuccessAlert';
import FailAlert from '../../../components/Alerts/FailAlert';
import DeleteConfirmPopup from './DeleteConfirmPopup';
import useStore from '../../../store/useStore';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { t } from 'i18next';
import PermissionsError from '../../../components/Alerts/PermissionsError';

interface Props {
  direction: string
}

const PaymentSettings = ({ direction }: Props) => {
  const storeData = useStore((state: any) => state.userData.store);
  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    view: false,
  })

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          view: true,
        })

        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "settings")

      let viewPermission = filtered[0]?.permissions.includes("view payment methods")

      setPermissions({
        view: viewPermission || false,
      })

    }
  }, [userData])

  // I did scroll to the top because when the component is render it show the bottom of the page because the layout
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Alerts
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [fail, setFail] = useState(false);
  const [failMsg, setFailMsg] = useState("");

  const [deleteConfPopup, setDeleteConfPopup] = useState(false);

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  const [allPaymentMethods, { loading, data: dataAllPaymentMethods }] = useAllPaymentMethods();

  useEffect(() => {
    storeData &&
      (async () => {
        await allPaymentMethods({
          variables: { idStore: storeData.id }
        })

        setInitialLoading(false)
      })()

  }, [storeData]);

  const [addPaymentPopup, setAddPaymentPopup] = useState(false)

  // This state contains the id of the product that will be deleted
  const [selectedIdForDelete, setSelectedIdForDelete] = useState("");

  const [checkedItems, setCheckedItems] = useState<checkedItems[]>([]);

  return (
    <section className='mx-3 my-4 md:mx-7'>
      {addPaymentPopup && <AddPaymentPopup setAddPaymentPopup={setAddPaymentPopup} />}

      {success && <SuccessAlert success={success} setSuccess={setSuccess} successMsg={successMsg} />}

      {fail && <FailAlert fail={fail} setFail={setFail} failMsg={failMsg} />}

      {deleteConfPopup && <DeleteConfirmPopup checkedItems={checkedItems} setDeleteConfPopup={setDeleteConfPopup} selectedIdForDelete={selectedIdForDelete} setSuccess={setSuccess} setSuccessMsg={setSuccessMsg} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('paymentSettings')}</h3>
        </div>
        <div>
          <Breadcrumbs />
        </div>
      </div>

      {initialLoading || loading ?
        <LoadingWidget />
        :
        permissions.view === false ?
          <PermissionsError />
          :
          <>
            <div className='grid gap-5 my-5 grid-col-1 sm:grid-cols-2 lg:grid-cols-3'>
              <div className='bg-white rounded-md'>
                <div className='flex justify-center p-10'>
                  <img src="https://cdn.mapp.sa/cPanel/images/pay.png" className='h-14' alt="" />
                </div>
                <div className='flex items-center justify-center py-2 border-t'>
                  <button>
                    {t('activate')}
                  </button>
                </div>
              </div>

              <div className='bg-white rounded-md'>
                <div className='flex justify-center p-10'>
                  <img src="https://cdn.mapp.sa/cPanel/enableOnlinePayment/images/tap.png" className='h-14' alt="" />
                </div>
                <div className='flex items-center justify-center py-2 border-t'>
                  <button>
                    {t('activate')}
                  </button>
                </div>
              </div>

              <div className='bg-white rounded-md'>
                <div className='flex justify-center p-10'>
                  <img src="https://cdn.mapp.sa/cPanel/images/tabby-icon.png" className='h-14' alt="" />
                </div>
                <div className='flex items-center justify-center py-2 border-t'>
                  <button>
                    {t('activate')}
                  </button>
                </div>
              </div>
            </div>
            <div className='p-2 mt-3 bg-white rounded-md'>
              <div className='flex justify-end'>
                <button onClick={() => setAddPaymentPopup(true)} className='inline-flex items-center justify-center w-full px-4 py-2.5 overflow-hidden text-sm text-white transition-colors duration-300 bg-primary rounded-lg shadow sm:w-auto my-2 md:my-0 hover:bg-primaryHover'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path>
                    <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path>
                  </svg>

                  <span className="mx-2">
                    {t('addPaymentMethod')}
                  </span>
                </button>
              </div>
              <div className='pt-2 overflow-x-scroll'>
                <PaymentMethodsTable checkedItems={checkedItems} setCheckedItems={setCheckedItems} direction={direction} allPaymentMethods={dataAllPaymentMethods} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} />
              </div>

              {dataAllPaymentMethods?.allPaymentMethods.length === 0 &&
                <div className='flex w-full p-2 mt-2 text-red-600 bg-red-200'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM12 20c-4.411 0-8-3.589-8-8s3.567-8 7.953-8C16.391 4 20 7.589 20 12s-3.589 8-8 8z"></path>
                    <path d="M11 7h2v7h-2zm0 8h2v2h-2z"></path>
                  </svg>
                  <span className='px-2'>{t('noPaymentMethods')}</span>
                </div>
              }
            </div>
          </>
      }
    </section>
  )
}

export default PaymentSettings