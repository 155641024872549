import React, { useState } from 'react'
import PaymentMethodsDropdown from '../../DropDowns/SettingsDrops/PaymentMethodsDropdown'
import { FetchPaymentMethods, checkedItems } from '../../../types'
import { t } from 'i18next'

interface TableProps {
  checkedItems: checkedItems[],
  setCheckedItems: React.Dispatch<React.SetStateAction<checkedItems[]>>
  allPaymentMethods?: FetchPaymentMethods
  setSelectedIdForDelete: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfPopup: React.Dispatch<React.SetStateAction<boolean>>
  direction: string
}

const PaymentMethodsTable = ({ checkedItems, setCheckedItems, direction, allPaymentMethods, setSelectedIdForDelete, setDeleteConfPopup }: TableProps) => {

  const handleCheckAll = () => {
    if (allPaymentMethods?.allPaymentMethods.length !== checkedItems.length) {
      allPaymentMethods !== undefined && setCheckedItems(allPaymentMethods?.allPaymentMethods.map(brand => ({ id: brand.id })))
    } else {
      setCheckedItems([])
    }
  };

  const handleCheck = (id: string) => {
    const isItemExist = checkedItems.some(item => item.id === id);

    if (isItemExist) {
      const newCheckedItems = checkedItems.filter(item => item.id !== id);
      setCheckedItems(newCheckedItems);
    } else {
      setCheckedItems([...checkedItems, { id: id }])
    }
  };

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500">
            <div className='flex'>
              <input type="checkbox" checked={allPaymentMethods?.allPaymentMethods.length === checkedItems.length} onChange={handleCheckAll} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
              <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </th>

          <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('country')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('bank')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('name')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('accountNumber')}
          </th>

          <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
            {t('option')}
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {allPaymentMethods?.allPaymentMethods.map(method => (
          <tr key={method.id}>
            <td className="px-4 py-3 text-sm text-left text-gray-500 rtl:text-right">
              <div className='flex'>
                <input type="checkbox" checked={checkedItems.some(checkedItem => checkedItem.id === method.id)} onChange={() => handleCheck(method.id)} className="relative w-4 h-4 border border-gray-300 rounded appearance-none peer bg-gray-50 checked:bg-primary checked:border-0" />
                <svg className="absolute w-4 h-4 p-0.5 text-white hidden peer-checked:block pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
            </td>
            <td className="p-4 text-sm">
              {method.country !== null && method.country.name}
            </td>
            <td className="p-4 text-sm">
              {method.bankName}
            </td>
            <td className="p-4 text-sm">
              {method.accountName}
            </td>
            <td className="p-4 text-sm">
              {method.number}
            </td>

            <td className="p-4 text-sm">
              <PaymentMethodsDropdown direction={direction} id={method.id} setSelectedIdForDelete={setSelectedIdForDelete} setDeleteConfPopup={setDeleteConfPopup} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default PaymentMethodsTable