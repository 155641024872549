import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import VATpopup from './VATpopup/VATpopup'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import {
  TbArchive,
  TbBuildingStore,
  TbCreditCard,
  TbDiamond, TbFileDescription, TbFileDollar,
  TbMap2,
  TbPuzzle, TbSeo, TbSettingsPlus, TbStar,
  TbTruckDelivery,
  TbUser, TbUsersGroup,
  TbWand, TbWorldWww
} from "react-icons/tb";
import { t } from 'i18next';
import useStore from '../../store/useStore';
import LoadingWidget from '../../components/LoadingWidget/LoadingWidget';
import PermissionsError from '../../components/Alerts/PermissionsError';

const Settings = () => {
  // I did scroll to the top because when the component is render it show the bottom of the page because the layout
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const userData = useStore((state: any) => state.userData);

  const [permissions, setPermissions] = useState({
    view: false,
  })

  // i had to use a main state for control loading because of ui issues
  const [initialLoading, setInitialLoading] = useState(true);

  // Permission configuration
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      // admin
      if (userData.permissions.length === 0 && userData.role === "admin") {
        setPermissions({
          view: true,
        })

        setInitialLoading(false)
        return
      }

      const filtered = userData?.permissions?.filter((perm: { name: string }) => perm.name === "settings")

      let viewPermission = filtered[0]?.permissions.includes("view settings")

      setPermissions({
        view: viewPermission || false,
      })

      setInitialLoading(false)
    }
  }, [userData])

  const [VatPopup, setVATpopup] = useState(false);

  return (
    <section className='mx-3 my-4 md:mx-7'>
      {VatPopup && <VATpopup setVatPopup={setVATpopup} />}

      <div className='flex flex-col-reverse md:flex-row md:justify-between md:items-center'>
        <div>
          <h3 className='text-2xl font-semibold'>{t('settings')}</h3>
        </div>

        <div>
          <Breadcrumbs />
        </div>
      </div>

      {
        initialLoading ?
          <LoadingWidget />
          :
          permissions.view === false ?
            <PermissionsError />
            :
            <>
              <div className='my-4'>
                <div>
                  <h3 className='text-lg text-gray-700'>{t('accountSettings')}</h3>
                </div>
                <div className='grid grid-cols-2 gap-4 mt-2 md:grid-cols-3 lg:grid-cols-4'>

                  <div>
                    <Link to="/settings/subscription">
                      <div className="h-full p-6 bg-white rounded-lg shadow">
                        <div className='flex flex-col items-center h-full'>
                          <div className="p-2 text-primary">
                            <TbDiamond size={40} strokeWidth={1.5} />
                          </div>

                          <div className='flex flex-col items-center justify-between h-full'>
                            <h2 className="mb-1 text-xl text-center title-font">{t('subscriptionsManagement')}</h2>
                            <p className="pt-2 text-sm text-center text-gray-400">{t('subscriptionsManagement')}</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to='/settings/profile'>
                      <div className="flex flex-col items-center h-full p-6 bg-white rounded-lg shadow">
                        <div className="p-2 text-primary">
                          <TbUser size={40} strokeWidth={1.5} />
                        </div>

                        <div className='flex flex-col items-center justify-between h-full'>
                          <h2 className="mb-1 text-xl text-center title-font">{t('profileSettings')}</h2>
                          <p className="pt-2 text-sm text-center text-gray-400">{t('edit')} {t('profile')}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className='my-4'>
                <div>
                  <h3 className='text-lg text-gray-700'>{t('basicSettings')}</h3>
                </div>
                <div className='grid grid-cols-2 gap-4 mt-2 md:grid-cols-3 lg:grid-cols-4'>
                  <div>
                    <Link to='/settings/store-settings'>
                      <div className="h-full p-6 bg-white rounded-lg shadow">
                        <div className='flex flex-col items-center h-full'>
                          <div className="p-2 text-primary">
                            <TbBuildingStore size={40} strokeWidth={1.5} />
                          </div>

                          <div className='flex flex-col items-center justify-between h-full'>
                            <h2 className="mb-1 text-xl text-center title-font">{t('storeSetting')}</h2>
                            <p className="pt-2 text-sm text-center text-gray-400">{t('storeSettingDescription')}</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to="/settings/payment">
                      <div className="flex flex-col items-center h-full p-6 bg-white rounded-lg shadow">
                        <div className="p-2 text-primary">
                          <TbCreditCard size={40} strokeWidth={1.5} />
                        </div>

                        <div className='flex flex-col items-center justify-between h-full'>
                          <h2 className="mb-1 text-xl text-center title-font">{t('paymentMethods')}</h2>
                          <p className="pt-2 text-sm text-center text-gray-400">{t('paymentMethodsDescription')}</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to='/settings/shipping'>
                      <div className="flex flex-col items-center h-full p-6 bg-white rounded-lg shadow">
                        <div className="p-2 text-primary">
                          <TbTruckDelivery size={40} strokeWidth={1.5} />
                        </div>

                        <div className='flex flex-col items-center justify-between h-full'>
                          <h2 className="mb-1 text-xl text-center title-font">{t('shipping&Delivery')}</h2>
                          <p className="pt-2 text-sm text-center text-gray-400">{t('shipping&ActivateDescription')}</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to="/settings/store-design">
                      <div className="flex flex-col items-center h-full p-6 bg-white rounded-lg shadow">
                        <div className="p-2 text-primary">
                          <TbWand size={40} strokeWidth={1.5} />
                        </div>

                        <div className='flex flex-col items-center justify-between h-full'>
                          <h2 className="mb-1 text-xl text-center title-font">{t('storeDesign')}</h2>
                          <p className="pt-2 text-sm text-center text-gray-400">{t('storeDesignDescription')}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className='my-4'>
                <div>
                  <h3 className='text-lg text-gray-700'>{t('otherSettings')}</h3>
                </div>
                <div className='grid grid-cols-2 gap-4 mt-2 md:grid-cols-3 lg:grid-cols-4'>
                  {/* <div onClick={() => setVATpopup(true)} className='cursor-pointer'>
            <div className="h-full p-6 bg-white rounded-lg shadow">
              <div className='flex flex-col items-center h-full'>
                <div className="p-2 text-primary">
                  <TbFilePercent size={40} strokeWidth={1.5} />
                </div>

                <div className='flex flex-col items-center justify-between h-full'>
                  <h2 className="mb-1 text-xl text-center title-font">{t('VAT')}</h2>
                  <p className="pt-2 text-sm text-center text-gray-400">{t('VATDescription')}</p>
                </div>
              </div>
            </div>
          </div> */}

                  {/* <div>
            <Link to="/settings/smsSettings">
              <div className="h-full p-6 bg-white rounded-lg shadow">
                <div className='flex flex-col items-center h-full'>
                  <div className="p-2 text-primary">
                    <TbMessageCode size={40} strokeWidth={1.5} />
                  </div>

                  <div className='flex flex-col items-center justify-between h-full'>
                    <h2 className="mb-1 text-xl text-center title-font">{t('SMSSettings')}</h2>
                    <p className="pt-2 text-sm text-center text-gray-400">{t('SMSSettingsDescription')}</p>
                  </div>
                </div>
              </div>
            </Link>
          </div> */}

                  <div>
                    <Link to='/settings/team'>
                      <div className="h-full p-6 bg-white rounded-lg shadow">
                        <div className='flex flex-col items-center h-full'>
                          <div className="p-2 text-primary">
                            <TbUsersGroup size={40} strokeWidth={1.5} />
                          </div>

                          <div className='flex flex-col items-center justify-between h-full'>
                            <h2 className="mb-1 text-xl text-center title-font">{t('team')}</h2>
                            <p className="pt-2 text-sm text-center text-gray-400">{t('teamDescription')}</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to="/settings/definitionPages">
                      <div className="flex flex-col items-center h-full p-6 bg-white rounded-lg shadow">
                        <div className="p-2 text-primary">
                          <TbFileDescription size={40} strokeWidth={1.5} />
                        </div>

                        <div className='flex flex-col items-center justify-between h-full'>
                          <h2 className="mb-1 text-xl text-center title-font">{t('definitionPages')}</h2>
                          <p className="pt-2 text-sm text-center text-gray-400">{t('definitionPagesDescription')}</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to="/settings/domain">
                      <div className="flex flex-col items-center h-full p-6 bg-white rounded-lg shadow">
                        <div className="p-2 text-primary">
                          <TbWorldWww size={40} strokeWidth={1.5} />
                        </div>

                        <div className='flex flex-col items-center justify-between h-full'>
                          <h2 className="mb-1 text-xl text-center title-font">{t('domain')}</h2>
                          <p className="pt-2 text-sm text-center text-gray-400">{t('domainDescription')}</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to="/settings/bill">
                      <div className="flex flex-col items-center h-full p-6 bg-white rounded-lg shadow">
                        <div className="p-2 text-primary">
                          <TbFileDollar size={40} strokeWidth={1.5} />
                        </div>

                        <div className='flex flex-col items-center justify-between h-full'>
                          <h2 className="mb-1 text-xl text-center title-font">{t('billSettings')}</h2>
                          <p className="pt-2 text-sm text-center text-gray-400">{t('BillDescription')}</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to="/settings/plugins">
                      <div className="flex flex-col items-center h-full p-6 bg-white rounded-lg shadow">
                        <div className="p-2 text-primary">
                          <TbPuzzle size={40} strokeWidth={1.5} />
                        </div>

                        <div className='flex flex-col items-center justify-between h-full'>
                          <h2 className="mb-1 text-xl text-center title-font">{t('plugins')}</h2>
                          <p className="pt-2 text-sm text-center text-gray-400">{t('pluginsDescription')}</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to="/settings/reviews">
                      <div className="flex flex-col items-center h-full p-6 bg-white rounded-lg shadow">
                        <div className="p-2 text-primary">
                          <TbStar size={40} strokeWidth={1.5} />
                        </div>

                        <div className='flex flex-col items-center justify-between h-full'>
                          <h2 className="mb-1 text-xl text-center title-font">{t('reviews')}</h2>
                          <p className="pt-2 text-sm text-center text-gray-400">{t('reviewsDescription')}</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to="/settings/countries">
                      <div className="flex flex-col items-center h-full p-6 bg-white rounded-lg shadow">
                        <div className="p-2 text-primary">
                          <TbMap2 size={40} strokeWidth={1.5} />
                        </div>

                        <div className='flex flex-col items-center justify-between h-full'>
                          <h2 className="mb-1 text-xl text-center title-font">{t('countriesCurrencies')}</h2>
                          <p className="pt-2 text-sm text-center text-gray-400">{t('countriesCurrenciesDescription')}</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to="/settings/seoSettings">
                      <div className="flex flex-col items-center h-full p-6 bg-white rounded-lg shadow">
                        <div className="p-2 text-primary">
                          <TbSeo size={40} strokeWidth={1.5} />
                        </div>
                        <div className='flex flex-col items-center justify-between h-full'>
                          <h2 className="mb-1 text-xl text-center title-font">{t('SEOSettings')}</h2>
                          <p className="pt-2 text-sm text-center text-gray-400">{t('SEOSettingsDescription')}</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to="/settings/additionalSetting">
                      <div className="flex flex-col items-center h-full p-6 bg-white rounded-lg shadow">
                        <div className="p-2 text-primary">
                          <TbSettingsPlus size={40} strokeWidth={1.5} />
                        </div>

                        <div className='flex flex-col items-center justify-between h-full'>
                          <h2 className="mb-1 text-xl text-center title-font">{t('additionalSettings')}</h2>
                          <p className="pt-2 text-sm text-center text-gray-400">{t('additionalSettingsDescription')}</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to="/settings/archive">
                      <div className="flex flex-col items-center h-full p-6 bg-white rounded-lg shadow">
                        <div className="p-2 text-primary">
                          <TbArchive size={40} strokeWidth={1.5} />
                        </div>

                        <div className='flex flex-col items-center justify-between h-full'>
                          <h2 className="mb-1 text-xl text-center title-font">{t('archive')}</h2>
                          <p className="pt-2 text-sm text-center text-gray-400">{t('archiveDescription')}</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                </div>
              </div>
            </>
      }
    </section>
  )
}

export default Settings