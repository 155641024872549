import { gql, useLazyQuery } from "@apollo/client"
import { FetchBestSellerProducts } from "../../types";

export const BEST_SELLER_PRODUCTS = gql`
query BestSellerProducts($idStore: ID) {
  bestSellerProducts(idStore: $idStore) {
    id
    name
    price
    priceAfterDiscount
    cost
    quantity
    weight
    shortDescription
    description
    notRequireDelivery
    freeShipping
    posting
    rating {
      id
      mark
      description
      createdAt
      updatedAt
      deletedAt
    }
    imagesProduct {
      id
      name
      alt
      createdAt
      updatedAt
      deletedAt
    }
    brand {
      id
      name
      image
      classFirstLevel {
        id
        name
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    classThirdLevel {
      id
      name
      classSecondLevel {
        id
        name
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
    productOptions {
      id
      name
      subOptions {
        id
        name
        price
        quantity
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`

export const useBestSellerProducts = () => {
  return useLazyQuery<FetchBestSellerProducts>(BEST_SELLER_PRODUCTS);
}